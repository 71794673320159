/* eslint-disable */
import { modalTradeConstants } from '../constants';

export function modalTrade(state, action) {
    if (state === undefined) {
        return {
            modalTrade: false,
            amount: '',
            suggestedPrice: '',
            cartNumber: '',
            currentOrder: null,
            errors: {
                amountError: '',
                suggestedPriceError: '',
                cartNumberError: '',
            },
        };
    }
    switch (action.type) {
        case modalTradeConstants.OPEN_MODAL_TRADE:
            return {
                ...state,
                modalTrade: true,
                currentOrder: action.id,
            };

        case modalTradeConstants.CLOSE_MODAL_TRADE:
            return {
                ...state,
                modalTrade: false,
                amount: '',
                suggestedPrice: '',
                cartNumber: '',
                errors: {
                    amountError: '',
                    suggestedPriceError: '',
                    cartNumberError: '',
                },
            };

        case modalTradeConstants.INPUT_CHANGES:
            return {
                ...state,
                ...action.inputValues,
            };

        case modalTradeConstants.INPUT_ERRORS:
            const { errors } = action;
            return {
                ...state,
                errors,
            };

        default:
            return state;
    }
}
