import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { message } from 'antd';
import * as moment from 'moment';

import GetService from '../../../../../../services/get-service';
import PayoutsView from './payouts-view';
import Spinner from '../../../../../spinner';
import ErrorIndicator from '../../../../error-page/error-indicator';
import { compose } from '../../../../../../utils';
import {
    ONE_DAY,
    LAST_WEEK,
    LAST_MONTH,
    ALL_TIME,
} from '../../../../../../constants';

export class PayoutsContainer extends Component {
    mounted = true;

    getService = new GetService();

    state = {
        loading: true,
        error: false,
        filterParams: {},
        referralTransactions: {},
        time: '',
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { filterParams } = this.state;
        const { currentPageNumber } = this.props;

        if (currentPageNumber && currentPageNumber !== prevProps.currentPageNumber) {
            this.setState(state => ({
                filterParams: {
                    ...state.filterParams,
                    currentPageNumber,
                },
            }));
        }

        if (filterParams !== prevState.filterParams) {
            const newParams = Object.keys(filterParams)
                .map(
                    keyParams => `${encodeURIComponent(keyParams)}=${encodeURIComponent(
                        filterParams[keyParams],
                    )}`,
                )
                .join('&');

            this.loadData(newParams);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadData = data => {
        this.getService
            .getReferralTransactions(data)
            .then(referralTransactions => {
                if (this.mounted) {
                    this.setState({
                        referralTransactions,
                        loading: false,
                    });
                }
            })
            .catch(this.onError);
    };

    datePicker = date => {
        if (date.length > 1) {
            const { _d: createdDateFrom } = date[0];
            const { _d: createdDateTo } = date[1];
            const { filterParams } = this.state;
            this.setState({
                filterParams: {
                    ...filterParams,
                    createdDateFrom: createdDateFrom.getTime(),
                    createdDateTo: createdDateTo.getTime(),
                },
                time: '',
            });
        }
    };

    selectFilter = (value, name) => {
        if (name.props.name !== 'time') {
            this.setState(state => ({
                filterParams: {
                    ...state.filterParams,
                    [name.props.name]: value,
                },
            }));
        }

        let createdDateFrom = '';
        let createdDateTo = '';

        if (value === ONE_DAY) {
            createdDateFrom = moment().unix() * 1000;
            createdDateTo = moment().unix() * 1000;
        } else if (value === LAST_WEEK) {
            createdDateFrom = moment().subtract(1, 'weeks').unix() * 1000;
            createdDateTo = moment().unix() * 1000;
        } else if (value === LAST_MONTH) {
            createdDateFrom = moment().subtract(1, 'months').unix() * 1000;
            createdDateTo = moment().unix() * 1000;
        } else if (value === ALL_TIME) {
            createdDateFrom = moment().subtract(5, 'years').unix() * 1000;
            createdDateTo = moment().unix() * 1000;
        }

        if (name.props.name === 'time') {
            this.setState(state => ({
                filterParams: {
                    ...state.filterParams,
                    createdDateFrom,
                    createdDateTo,
                },
                [name.props.name]: value,
            }));
        }
    };

    deleteFilter = deleteFilter => {
        const { filterParams } = this.state;
        const copyFilterParams = Object.assign({}, filterParams);
        delete copyFilterParams[deleteFilter];
        this.setState({
            filterParams: {
                ...copyFilterParams,
            },
        });

        if (deleteFilter === 'date') {
            delete copyFilterParams.createdDateFrom;
            delete copyFilterParams.createdDateTo;
            this.setState({
                filterParams: {
                    ...copyFilterParams,
                },
                time: '',
            });
        }

        if (deleteFilter === 'time') {
            this.setState(state => ({
                filterParams: {
                    ...state.filterParams,
                    createdDateFrom: moment().unix() * 1000,
                    createdDateTo: moment().unix() * 1000,
                },
                time: '',
            }));
        }
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    copyToClipBoard = () => {
        const { t } = this.props;
        message.success(t('referralSystem.copySuccessful'), 5);
    };

    render() {
        const {
            loading, error, filterParams, referralTransactions, time,
        } = this.state;
        const { allCoins, username } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <PayoutsView
                copyToClipBoard={this.copyToClipBoard}
                allCoins={allCoins}
                filterParams={filterParams}
                referralTransactions={referralTransactions}
                time={time}
                username={username}
                datePicker={this.datePicker}
                selectFilter={this.selectFilter}
                deleteFilter={this.deleteFilter}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        getAllCoins: { allCoins },
        currentFilter: { filterParams: { currentPageNumber } },
        getUserInfo: {
            userInfo: { username },
        },
    } = state;

    return {
        allCoins,
        currentPageNumber,
        username,
    };
};

PayoutsContainer.defaultProps = {
    t: () => {},
    allCoins: [],
    currentPageNumber: '',
    username: '',
};

PayoutsContainer.propTypes = {
    t: PropTypes.func,
    allCoins: PropTypes.instanceOf(Array),
    currentPageNumber: PropTypes.any,
    username: PropTypes.string,
};

export default compose(withTranslation(), connect(mapStateToProps))(PayoutsContainer);
