import { message } from 'antd';
import i18n from '../i18n';
import store, { authHeader } from '../helpers';
import PostService from './post-service';
import { UNDEFINED_USER_WITH_TOKEN } from '../constants';
import { userActions } from '../actions';

export default class PatchService {
    postService = new PostService();

    getResource = async (url, data) => {
        const requestOptions = {
            method: 'PATCH',
            headers: authHeader(),
            body: JSON.stringify(data),
        };

        const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, requestOptions);

        if (res.status === 401) {
            this.removeUserFromStorage();
        }

        if (res.status !== 200) {
            res.json().then(errorMessage => {
                if (errorMessage.error === UNDEFINED_USER_WITH_TOKEN) {
                    message.error(i18n.t('error.tokenForUserInvalid'), 5);
                } else {
                    message.error(errorMessage.error, 5);
                }
            });
        }

        if (!res.ok) {
            console.error(`Could not fetch ${url}, received ${res.status}`);
        }

        const result = await res.json();
        return result;
    };

    removeUserFromStorage = () => {
        store.dispatch(userActions.logout());
    }

    readAllNotifications = async data => {
        const res = await this.getResource('/api/user/notifications/read-all', data);
        return res;
    };

    createNewPassword = async (data, token) => {
        const res = await this.getResource(`/public/password/reset/token/check/${token}`, data);
        return res;
    };

    switchNotification = async id => {
        const res = await this.getResource(`/api/user/notification/settings/switch/${id}`);
        return res;
    };

    switchTrustedDevices = async () => {
        const res = await this.getResource('/api/user/switch-check-trusted-devices');
        return res;
    };

    returnRent = async data => {
        const res = await this.getResource('/api/rent/return', data);
        return res;
    };
}
