/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    Collapse, Icon, Upload, Switch, Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Moment from 'react-moment';
import FileBase64 from 'react-file-base64';

import Img from '../../../../small-components/img';
import Field from '../../../../small-components/field';
import Button from '../../../../small-components/button';
import UploadSvg from '../../../../small-components/upload-svg';
import AreUSureModal from '../../../../small-components/are-u-sure-modal';
import { countries } from '../../../../../helpers/countries';
import { personalAreaPath, profilePath } from '../../../../../constants';
import selfie from '../../../../assets/images/selfie.png';

import style from './settings-page.module.scss';
import './settings-page.scss';

const SettingsView = ({
    userIfo,
    verification,
    verificationCode,
    blacklist,
    nameError,
    phoneNumberError,
    emailError,
    codeError,
    twoFactorError,
    twoFactorStatus,
    twoFactor,
    visibleDeleteAccount,
    defaultCollapseActiveKey,
    userConfirmationStatus,
    notification,
    phoneInputChange,
    firstDocumentUploaded,
    userHistory,
    activeUserHistory,
    checkTrustedDevices,
    loadingDocuments,
    loadingSelfie,
    visibleSelfie,
    getFiles,
    isShowFieldsForDisable2FA,
    InputOnchange = () => {},
    submitProfileForm = () => {},
    submitVerification = () => {},
    openDeleteModal = () => {},
    submitTrustedDevices = () => {},
    switchNotification = () => {},
    switchTrustedDevices = () => {},
    blacklistSwitch = () => {},
    twoFactorSwitch = () => {},
    submitTwoFactor = () => {},
    refreshVerifyCode = () => {},
    closeDeleteAccount = () => {},
    submitDeleteAccount = () => {},
    openUploadPanel = () => {},
    onKeyDownPhone = () => {},
}) => {
    const { verificationStatus, code: verifyByFriends } = verification;
    const {
        isConfirmed, email, name, phone,
    } = userIfo;

    const { items: history } = userHistory;

    const { items: activeHistory } = activeUserHistory;

    const { code } = verificationCode;
    const { authorization_code } = twoFactor;

    const { t } = useTranslation();
    const { Panel } = Collapse;

    // Profile tab
    const uploadStyle = classNames(
        style.uploadDocuments_subTitleBlue,
        style.uploadDocuments_upload,
    );

    const uploadDocumentsMobile = classNames(
        style.uploadDocuments,
        style.uploadDocuments__mobile,
    );

    const profileButtonMobile = classNames(
        style.profile__button,
        style.profile__button_mobile,
    );

    const emailStyles = classNames(style.profile__input, style.profile__input_email);

    // 2 Factor Authenticationc tab
    const twoFactorSwitchStyle = twoFactorStatus
        ? style.twoFactor__disable
        : style.twoFactor__enable;

    let verifyPicStyle;
    let verifyPicDescription;
    let verifyPicTitle;

    const userConfirmationStatuss = userConfirmationStatus && userConfirmationStatus.handle;

    if (userConfirmationStatuss === 'WAITING') {
        verifyPicStyle = 'verification__upload';
        verifyPicTitle = style.verification__uploadTitle;
    }

    if (userConfirmationStatuss === 'REVIEWING') {
        verifyPicStyle = 'verification__upload verification__upload_done';
        verifyPicDescription = t('settings.fileOnReviewing');
        verifyPicTitle = style.verification__uploadTitleSelected;
    }

    if (userConfirmationStatuss === 'CONFIRMED') {
        verifyPicStyle = 'verification__upload verification__upload_done';
        verifyPicDescription = t('settings.fileConfirmed');
        verifyPicTitle = style.verification__uploadTitleSelected;
    }

    if (userConfirmationStatuss === 'REJECTED') {
        verifyPicStyle = 'verification__upload';
        verifyPicDescription = t('settings.fileRejected');
        verifyPicTitle = style.verification__uploadTitle;
    }

    let isShowUploadDocuments = true;

    if (userConfirmationStatuss === 'WAITING' || userConfirmationStatuss === 'REJECTED') {
        isShowUploadDocuments = true;
    }

    if (
        userConfirmationStatuss === 'REVIEWING'
        || userConfirmationStatuss === 'CONFIRMED'
    ) {
        isShowUploadDocuments = false;
    }

    let isActiveUploadBlock = '';

    if (userConfirmationStatuss === 'WAITING' && !visibleSelfie) {
        isActiveUploadBlock = 'disabledVerifyBlock';
    } else if (userConfirmationStatuss === 'REJECTED' && !visibleSelfie) {
        isActiveUploadBlock = 'disabledVerifyBlock';
    } else {
        isActiveUploadBlock = 'activeVerifyBlock';
    }

    // const requieredList = classNames(
    //     style.profile__button,
    //     style.profile__button_mobile,
    // );

    return (
        <div className={style.settings}>
            <p className={style.settings__title}>{t('general.settings')}</p>

            <Collapse
                accordion
                forceRender
                bordered={false}
                defaultActiveKey={['1']}
                activeKey={defaultCollapseActiveKey}
                onChange={openUploadPanel}
                expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                )}
            >
                {/* Profile */}
                <Panel
                    className={style.settings__collapseTitle}
                    header={t('general.profile')}
                    key="1"
                    id="settingCollapsePanel1"
                >
                    <form className={style.profile} onSubmit={submitProfileForm}>
                        <div className={style.profile__leftSide}>
                            <div className={style.profile__inputWrapper}>
                                <Field
                                    id="email"
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={email}
                                    onChange={InputOnchange}
                                    error={emailError}
                                    inputStyle={emailStyles}
                                    labelText="Email"
                                    labelStyle={style.profile__label}
                                    disabled
                                />
                            </div>
                            <div className={style.profile__inputWrapper}>
                                <p className={style.profile__label}>
                                    {t('general.phoneNumber')} (
                                    {t('general.internationalFormat')})
                                </p>
                                <PhoneInput
                                    placeholder={t('general.phoneNumber')}
                                    value={phone}
                                    onChange={phoneInputChange}
                                    onKeyDown={onKeyDownPhone}
                                    className="profile-phoneInput"
                                />
                                {phoneNumberError ? (
                                    <div className={style.profile__inputWrapper_invalid}>
                                        {t('error.phone')}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className={style.profile__rightSide}>
                            <div className={style.profile__inputWrapper}>
                                <Field
                                    id="name"
                                    type="text"
                                    placeholder={t('general.trueName')}
                                    name="name"
                                    value={name}
                                    onChange={InputOnchange}
                                    error={nameError}
                                    inputStyle={style.profile__input}
                                    labelText={t('general.trueName')}
                                    labelStyle={style.profile__label}
                                />
                            </div>
                            <div className={style.uploadDocuments}>
                                <div className={style.uploadDocuments_item}>
                                    <p className={style.uploadDocuments_title}>
                                        {t('general.accountStatus')}
                                    </p>
                                    {isConfirmed ? (
                                        <p className={style.uploadDocuments_subTitle}>
                                            {t('settings.verified')}
                                        </p>
                                    ) : (
                                        <p className={style.uploadDocuments_subTitleRed}>
                                            {t('general.notSubmitted')}
                                        </p>
                                    )}
                                </div>
                                <div className={style.uploadDocuments_itemUpload}>
                                    {isShowUploadDocuments ? (
                                        <div
                                            className={uploadStyle}
                                            onClick={() => openUploadPanel('2')}
                                        >
                                            {t('general.uploadDocuments')}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <Button
                                id="saveSubmit"
                                type="submit"
                                className={style.profile__button}
                            >
                                {t('general.save')}
                            </Button>
                        </div>

                        <div className={uploadDocumentsMobile}>
                            <div className={style.uploadDocuments_item}>
                                <p className={style.uploadDocuments_title}>
                                    {t('general.accountStatus')}
                                </p>
                                {isConfirmed ? (
                                    <p className={style.uploadDocuments_subTitle}>
                                        {isConfirmed}
                                    </p>
                                ) : (
                                    <p className={style.uploadDocuments_subTitleRed}>
                                        {t('general.notSubmitted')}
                                    </p>
                                )}
                            </div>
                            <div className={style.uploadDocuments_itemUpload}>
                                {!isConfirmed ? (
                                    <Upload>
                                        <p className={uploadStyle}>
                                            {t('general.uploadDocuments')}
                                        </p>
                                    </Upload>
                                ) : null}
                            </div>
                        </div>

                        <Button id="save" type="submit" className={profileButtonMobile}>
                            {t('general.save')}
                        </Button>
                    </form>
                </Panel>

                {/* Verification */}
                <Panel
                    header={
                        isConfirmed
                            ? t('settings.yourAccountIsVerified')
                            : t('settings.verification')
                    }
                    className={
                        isConfirmed
                            ? style.settings__verificationStyle
                            : style.settings__collapseTitle
                    }
                    key="2"
                    id="settingCollapsePanel2"
                    disabled={isConfirmed}
                    showArrow={!isConfirmed}
                >
                    <div className={style.verification__wrapper}>
                        <div className={style.verification__wrapperUpload}>
                            <div className="activeVerifyBlock">
                                <div
                                    className={
                                        firstDocumentUploaded
                                            ? 'verification__upload verification__upload_done'
                                            : verifyPicStyle
                                    }
                                >
                                    <div className={style.verification__wrapper_item}>
                                        <FileBase64
                                            multiple
                                            onDone={item => getFiles(item, 'uploadPassport')
                                            }
                                        />
                                        {loadingDocuments ? <Spin /> : <UploadSvg />}
                                        <p
                                            className={
                                                style.verification__fileIsntSelected
                                            }
                                        >
                                            {verifyPicDescription}
                                        </p>
                                    </div>

                                    <p className={verifyPicTitle}>
                                        {t('settings.passport')}
                                    </p>
                                </div>
                            </div>
                            <div className={isActiveUploadBlock}>
                                <div className={verifyPicStyle}>
                                    <div className={style.verification__wrapper_item}>
                                        <FileBase64
                                            multiple
                                            onDone={item => getFiles(item, 'uploadSelfie')
                                            }
                                        />
                                        {loadingSelfie ? <Spin /> : <UploadSvg />}
                                        <p
                                            className={
                                                style.verification__fileIsntSelected
                                            }
                                        >
                                            {verifyPicDescription}
                                        </p>
                                    </div>
                                    <div className={verifyPicTitle}>
                                        <p>{t('settings.sеlfieWith')}</p>
                                        <p>{t('settings.sеlfieWithPhoto')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.verification__requiered}>
                        <p className={style.verification__requiered_title}>
                            {t('verification.content1')}
                        </p>
                        <img className={style.verification__requiered_img} src={selfie} alt="selfie" />
                        <div className={style.verification__requiered_list}>
                            <p className={style.verification__requiered_content}>
                                <span>{t('verification.passport')}</span>{' '}
                                {t('verification.content2')}
                            </p>
                            <p className={style.verification__requiered_content}>
                                <span>{t('verification.idCard')}</span>{' '}
                                {t('verification.content3')}
                            </p>
                            <p className={style.verification__requiered_content}>
                                <span>{t('verification.drivingLicense')}</span>{' '}
                                {t('verification.content4')}
                            </p>
                        </div>
                        <p className={style.verification__requiered_subContent}>
                            {t('verification.content5')}
                        </p>
                        <p className={style.verification__requiered_content}>
                            <span>{t('verification.note')}</span>{' '}
                            {t('verification.content6')}
                        </p>
                    </div>
                </Panel>

                {/* Friend Verification */}
                <Panel
                    header={t('settings.friendVerification')}
                    className={style.settings__collapseTitle}
                    key="3"
                    id="settingCollapsePanel3"
                >
                    <form
                        onSubmit={submitVerification}
                        className={style.friendVerification__wrapper}
                    >
                        <div className={style.friendVerification__leftSide}>
                            {verificationStatus ? (
                                <Fragment>
                                    <p className={style.friendVerification__title}>
                                        {t('settings.youShouldBeVerified')}
                                    </p>
                                    <p className={style.friendVerification__subTitle}>
                                        {t('settings.verifiedUsersCanHelp')}
                                    </p>
                                    <p className={style.friendVerification__mainText}>
                                        {t('settings.unverifiedUsersGenerateCode')}
                                    </p>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <p className={style.friendVerification__title}>
                                        {t('settings.ask2Friends')}
                                    </p>
                                    <p className={style.friendVerification__title}>
                                        {t('settings.verifiedUsersCanHelp')}
                                    </p>
                                    <p className={style.friendVerification__subTitle}>
                                        {t('settings.getHelp')}
                                    </p>
                                    <p className={style.friendVerification__mainText}>
                                        {t('settings.getInTouch')}
                                    </p>
                                    <p className={style.friendVerification__subTitle}>
                                        {t('settings.tellYouFriends')}
                                        <span
                                            className={style.friendVerification__blueCode}
                                        >
                                            {verifyByFriends}
                                        </span>
                                        {t('settings.onP2PMessage')}
                                    </p>
                                    <p className={style.friendVerification__mainText}>
                                        {t('settings.giveTemporaryCode', {
                                            count: verifyByFriends,
                                        })}
                                    </p>
                                    <p className={style.friendVerification__subTitle}>
                                        {t('settings.noFriendsHelped')}
                                    </p>
                                    <p className={style.friendVerification__mainText}>
                                        {t('settings.notEnoughFriends')}
                                    </p>
                                </Fragment>
                            )}
                        </div>
                        <div className={style.friendVerification__rightSide}>
                            <p className={style.friendVerification__verificationStatus}>
                                {t('settings.verificationStatus')}
                            </p>
                            {verificationStatus ? (
                                <p className={style.friendVerification__verified}>
                                    {t('settings.verified')}
                                </p>
                            ) : (
                                <p className={style.friendVerification__notVerified}>
                                    {t('settings.notVerified')}
                                </p>
                            )}
                            <div className={style.friendVerification__inputWrapper}>
                                {verificationStatus ? (
                                    <Field
                                        id="code"
                                        type="text"
                                        placeholder={t('settings.pasteCode')}
                                        name="code"
                                        value={code}
                                        onChange={InputOnchange}
                                        error={codeError}
                                        inputStyle={style.friendVerification__input}
                                        labelText={t('settings.code')}
                                        labelStyle={style.friendVerification__label}
                                    />
                                ) : (
                                    <Field
                                        id="verifyByFriends"
                                        type="text"
                                        name="verifyByFriends"
                                        value={verifyByFriends}
                                        inputStyle={style.friendVerification__input}
                                        labelText={t('settings.code')}
                                        labelStyle={style.friendVerification__label}
                                    />
                                )}
                            </div>
                            {verificationStatus ? (
                                <Button
                                    id="submitVerification"
                                    type="submit"
                                    className={style.friendVerification__buttonSubmit}
                                >
                                    {t('general.send')}
                                </Button>
                            ) : (
                                <CopyToClipboard
                                    text={verifyByFriends}
                                    onCopy={refreshVerifyCode}
                                >
                                    <Button
                                        id="copy"
                                        type="button"
                                        className={
                                            style.friendVerification__buttonRefresh
                                        }
                                    >
                                        {t('general.copy')}
                                    </Button>
                                </CopyToClipboard>
                            )}
                        </div>
                    </form>
                </Panel>

                {/* Notifications */}
                <Panel
                    header={t('settings.notification')}
                    className={style.settings__collapseTitle}
                    key="4"
                    id="settingCollapsePanel4"
                >
                    <div className={style.notification__wrapper}>
                        {notification.map(item => {
                            const {
                                isActive,
                                notificationType: { handle, id },
                            } = item;
                            return (
                                <div key={id} className={style.notification__item}>
                                    <p className={style.notification__label}>
                                        {t(`notifications.${handle}`)}
                                    </p>
                                    <Switch
                                        checkedChildren="On"
                                        unCheckedChildren="Off"
                                        defaultChecked={isActive}
                                        onChange={() => switchNotification(id)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Panel>

                {/* 2 Factor Authentication */}
                <Panel
                    header={t('settings.2FactorAuthentication')}
                    className={style.settings__collapseTitle}
                    key="5"
                    id="settingCollapsePanel5"
                >
                    <div className={style.twoFactor}>
                        <div className={style.twoFactor__leftSide}>
                            {twoFactorStatus ? (
                                <div className={style.twoFactor__title}>
                                    <p>{t('settings.highAccountSecurity')}</p>
                                    <p className={style.twoFactor__statusEnabled}>
                                        {t('general.enabled')}
                                    </p>
                                </div>
                            ) : (
                                <div className={style.twoFactor__title}>
                                    <p>{t('settings.lowAccountSecurity')}</p>
                                    <p className={style.twoFactor__statusDisabled}>
                                        {t('general.disabled')}
                                    </p>
                                </div>
                            )}
                            {twoFactorStatus && isShowFieldsForDisable2FA ? (
                                <Fragment>
                                    <div className={style.twoFactor__inputWrapper}>
                                        <Field
                                            id="authorization_code"
                                            type="text"
                                            placeholder={t('settings.inputYourCode')}
                                            name="authorization_code"
                                            value={authorization_code}
                                            onChange={InputOnchange}
                                            error={twoFactorError}
                                            inputStyle={style.twoFactor__input}
                                            labelText={t('settings.code')}
                                            labelStyle={style.twoFactor__label}
                                        />
                                    </div>
                                    <p className={style.twoFactor__title}>
                                        {t('settings.codeFromEmail')}
                                    </p>
                                </Fragment>
                            ) : null}
                        </div>
                        <div className={style.twoFactor__rightSide}>
                            <Button
                                type="button"
                                onClick={twoFactorSwitch}
                                className={twoFactorSwitchStyle}
                            >
                                {twoFactorStatus
                                    ? t('general.disable')
                                    : t('general.enable')}
                            </Button>
                            {twoFactorStatus && isShowFieldsForDisable2FA ? (
                                <Button
                                    type="button"
                                    onClick={submitTwoFactor}
                                    className={style.twoFactor__submit}
                                >
                                    {t('general.send')}
                                </Button>
                            ) : null}
                        </div>
                    </div>
                </Panel>

                {/* Last account activities */}
                <Panel
                    header={t('settings.lastAccountActivities')}
                    className={style.settings__collapseTitle}
                    key="6"
                    id="settingCollapsePanel6"
                >
                    <div className={style.lastAccountActivities__wrapper}>
                        <div className={style.lastAccountActivities__leftSide}>
                            {history
                                && history.map(item => {
                                    const { token, geo, ip } = item;
                                    return (
                                        <Fragment key={token}>
                                            <p
                                                className={
                                                    style.lastAccountActivities__leftSide_location
                                                }
                                            >
                                                {countries.map(countryItem => {
                                                    const {
                                                        name: countryName,
                                                        value,
                                                    } = countryItem;
                                                    return (
                                                        <Fragment key={value}>
                                                            {value === geo
                                                                ? countryName
                                                                : ''}
                                                        </Fragment>
                                                    );
                                                })}{' '}
                                                ({ip})
                                            </p>
                                        </Fragment>
                                    );
                                })}
                        </div>
                        <div className={style.lastAccountActivities__rightSide}>
                            {history
                                && history.map(item => {
                                    const { token, createdAt } = item;
                                    return (
                                        <div
                                            key={token}
                                            className={
                                                style.lastAccountActivities__rightSide
                                            }
                                        >
                                            <Moment
                                                className={
                                                    style.lastAccountActivities__rightSide_time
                                                }
                                                format="DD/MM/YYYY HH:mm:ss"
                                                unix
                                            >
                                                {createdAt}
                                            </Moment>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </Panel>

                {/* Blacklist */}
                {blacklist.length > 0 ? (
                    <Panel
                        header={t('settings.blacklist')}
                        className={style.settings__collapseTitle}
                        key="7"
                        id="settingCollapsePanel7"
                    >
                        <div className={style.blacklist}>
                            {blacklist
                                && blacklist.map(item => {
                                    const {
                                        user: { id, username: blackListName, logoSrc },
                                    } = item;

                                    return (
                                        <div key={id} className={style.blacklist__row}>
                                            <Link
                                                to={`${personalAreaPath}${profilePath}/${id}`}
                                                className={style.blacklist__leftSide}
                                            >
                                                <Img
                                                    className={style.blacklist__img}
                                                    src={logoSrc}
                                                    userName={blackListName}
                                                />
                                                <p className={style.blacklist__name}>
                                                    {blackListName}
                                                </p>
                                            </Link>
                                            <div className={style.blacklist__rightSide}>
                                                <p
                                                    className={
                                                        style.blacklist__blockedAccaunt
                                                    }
                                                >
                                                    {t('settings.blockedAccaunt')}
                                                </p>
                                                <Switch
                                                    checkedChildren="On"
                                                    unCheckedChildren="Off"
                                                    onChange={() => blacklistSwitch(id)}
                                                    defaultChecked
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </Panel>
                ) : null}

                {/* Trusted Devices */}
                <Panel
                    header={t('settings.trustedDevices')}
                    className={style.settings__collapseTitle}
                    key="8"
                    id="settingCollapsePanel8"
                >
                    <div className={style.trustedDevices}>
                        <div className={style.trustedDevices__confirmNewBrowser}>
                            <p className={style.trustedDevices__confirmNewBrowser_label}>
                                {t('general.confirmNewBrowser')}
                            </p>
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                defaultChecked={checkTrustedDevices}
                                onChange={switchTrustedDevices}
                            />
                        </div>
                        {activeHistory
                            && activeHistory.map(item => {
                                const {
                                    createdAt,
                                    geo,
                                    platform,
                                    ip,
                                    id,
                                    browser,
                                    isCurrent,
                                    token,
                                } = item;

                                return (
                                    <div
                                        key={token}
                                        className={style.trustedDevices__row}
                                    >
                                        <div>
                                            <p
                                                className={
                                                    style.trustedDevices__row_title
                                                }
                                            >
                                                {t('general.date')}
                                            </p>

                                            <Moment
                                                className={style.trustedDevices__row_date}
                                                format="DD/MM/YYYY"
                                                unix
                                            >
                                                {createdAt}
                                            </Moment>
                                        </div>
                                        <div>
                                            <p
                                                className={
                                                    style.trustedDevices__row_title
                                                }
                                            >
                                                {t('settings.time')}
                                            </p>
                                            <Moment
                                                className={style.trustedDevices__row_time}
                                                format="HH:mm:ss"
                                                unix
                                            >
                                                {createdAt}
                                            </Moment>
                                        </div>
                                        <div>
                                            <p
                                                className={
                                                    style.trustedDevices__row_title
                                                }
                                            >
                                                {t('settings.userAgent')}
                                            </p>
                                            <p
                                                className={
                                                    style.trustedDevices__row_userAgent
                                                }
                                            >
                                                {platform}
                                            </p>
                                        </div>
                                        <div>
                                            <p
                                                className={
                                                    style.trustedDevices__row_title
                                                }
                                            >
                                                {t('general.location')}
                                            </p>
                                            <p
                                                className={
                                                    style.trustedDevices__row_location
                                                }
                                            >
                                                {countries.map(countryItem => {
                                                    const {
                                                        name: countryName,
                                                        value,
                                                    } = countryItem;
                                                    return (
                                                        <Fragment key={value}>
                                                            {value === geo
                                                                ? countryName
                                                                : ''}
                                                        </Fragment>
                                                    );
                                                })}
                                            </p>
                                        </div>
                                        <div>
                                            <p
                                                className={
                                                    style.trustedDevices__row_title
                                                }
                                            >
                                                Ip
                                            </p>
                                            <p className={style.trustedDevices__row_ip}>
                                                {ip}
                                            </p>
                                        </div>
                                        {isCurrent ? (
                                            <div>
                                                <p
                                                    className={
                                                        style.trustedDevices__row_title
                                                    }
                                                >
                                                    {t('settings.browser')}
                                                </p>
                                                <p
                                                    className={
                                                        style.trustedDevices__row_curentBrowser
                                                    }
                                                >
                                                    {t('settings.curentBrowser')}
                                                </p>
                                            </div>
                                        ) : (
                                            <div>
                                                <p
                                                    className={
                                                        style.trustedDevices__row_title
                                                    }
                                                >
                                                    {t('settings.browser')}
                                                </p>
                                                <p
                                                    className={
                                                        style.trustedDevices__row_ip
                                                    }
                                                >
                                                    {browser}
                                                </p>
                                            </div>
                                        )}
                                        <Button
                                            type="button"
                                            onClick={() => submitTrustedDevices(id)}
                                            className={style.trustedDevices__row_button}
                                        >
                                            {t('settings.revokeAccess')}
                                        </Button>
                                    </div>
                                );
                            })}
                    </div>
                </Panel>

                {/* Delete Account */}
                <Panel
                    header={t('settings.deleteAccount')}
                    className={style.settings__collapseTitle}
                    key="9"
                    id="settingCollapsePanel9"
                >
                    <div className={style.deleteAccount__wrapper}>
                        <div className={style.deleteAccount__leftSide}>
                            <p className={style.deleteAccount__leftSide_text}>
                                {t('settings.deleteAccountText')}
                            </p>
                        </div>
                        <div className={style.deleteAccount__rightSide}>
                            <Button
                                id="openDeleteModal"
                                type="button"
                                onClick={openDeleteModal}
                                className={style.deleteAccount__rightSide_button}
                            >
                                {t('general.delete')}
                            </Button>
                        </div>
                    </div>
                </Panel>
            </Collapse>

            <AreUSureModal
                visibleModal={visibleDeleteAccount}
                noButton={closeDeleteAccount}
                yesButton={submitDeleteAccount}
            />
        </div>
    );
};

SettingsView.defaultProps = {
    userIfo: {},
    verification: {},
    verificationCode: {},
    twoFactor: {},
    blacklist: [],
    nameError: '',
    phoneNumberError: '',
    emailError: '',
    codeError: '',
    twoFactorError: '',
    userConfirmationStatus: {},
    userHistory: {},
    activeUserHistory: {},
    defaultCollapseActiveKey: '1',
    checkTrustedDevices: false,
    twoFactorStatus: false,
    visibleDeleteAccount: false,
    firstDocumentUploaded: false,
    loadingDocuments: false,
    loadingSelfie: false,
    visibleSelfie: false,
    isShowFieldsForDisable2FA: false,
    getFiles: () => {},
    phoneInputChange: () => {},
    InputOnchange: () => {},
    submitProfileForm: () => {},
    submitVerification: () => {},
    openDeleteModal: () => {},
    submitTrustedDevices: () => {},
    switchNotification: () => {},
    switchTrustedDevices: () => {},
    blacklistSwitch: () => {},
    twoFactorSwitch: () => {},
    submitTwoFactor: () => {},
    refreshVerifyCode: () => {},
    closeDeleteAccount: () => {},
    submitDeleteAccount: () => {},
    openUploadPanel: () => {},
    onKeyDownPhone: () => {},
    notification: [],
};

SettingsView.propTypes = {
    userIfo: PropTypes.object,
    verification: PropTypes.object,
    userConfirmationStatus: PropTypes.object,
    verificationCode: PropTypes.object,
    twoFactor: PropTypes.object,
    userHistory: PropTypes.arrayOf(PropTypes.object),
    activeUserHistory: PropTypes.arrayOf(PropTypes.object),
    defaultCollapseActiveKey: PropTypes.string,
    blacklist: PropTypes.arrayOf(PropTypes.object),
    nameError: PropTypes.string,
    phoneNumberError: PropTypes.string,
    codeError: PropTypes.string,
    twoFactorError: PropTypes.string,
    checkTrustedDevices: PropTypes.bool,
    twoFactorStatus: PropTypes.bool,
    visibleDeleteAccount: PropTypes.bool,
    firstDocumentUploaded: PropTypes.bool,
    emailError: PropTypes.string,
    loadingDocuments: PropTypes.bool,
    loadingSelfie: PropTypes.bool,
    visibleSelfie: PropTypes.bool,
    isShowFieldsForDisable2FA: PropTypes.bool,
    getFiles: PropTypes.func,
    phoneInputChange: PropTypes.func,
    InputOnchange: PropTypes.func,
    submitProfileForm: PropTypes.func,
    submitVerification: PropTypes.func,
    openDeleteModal: PropTypes.func,
    switchNotification: PropTypes.func,
    switchTrustedDevices: PropTypes.func,
    blacklistSwitch: PropTypes.func,
    twoFactorSwitch: PropTypes.func,
    submitTwoFactor: PropTypes.func,
    refreshVerifyCode: PropTypes.func,
    closeDeleteAccount: PropTypes.func,
    submitDeleteAccount: PropTypes.func,
    openUploadPanel: PropTypes.func,
    submitTrustedDevices: PropTypes.func,
    onKeyDownPhone: PropTypes.func,
    notification: PropTypes.instanceOf(Array),
};

const mapStateToProps = state => {
    const {
        currentCollapsePanel: { defaultCollapseActiveKey },
        getUserInfo: {
            userInfo: { userConfirmationStatus, checkTrustedDevices },
        },
    } = state;

    return {
        defaultCollapseActiveKey,
        userConfirmationStatus,
        checkTrustedDevices,
    };
};

SettingsView.defaultProps = {
    defaultCollapseActiveKey: '',
};

SettingsView.propTypes = {
    defaultCollapseActiveKey: PropTypes.string,
};

export default connect(mapStateToProps)(SettingsView);
