import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import style from './return-table.module.scss';

const ReturnTable = ({ rentCompleted, t }) => (
    <Fragment>
        {rentCompleted.length ? (
            <div className={style.table}>
                <div className={style.tHead}>
                    <div className={classNames(style.tItem, style.itemDate)}>
                        <span className={style.tText}>{t('general.date')}</span>
                    </div>
                    <div className={classNames(style.tItem, style.itemId)}>
                        <span className={style.tText}>{t('general.operationId')}</span>
                    </div>
                    <div className={classNames(style.tItem, style.itemRentail)}>
                        <span className={style.tText}>15% EDC20</span>
                    </div>
                    <div className={classNames(style.tItem, style.itemAmount)}>
                        <span className={style.tText}>{t('edcRent.totalPaid')} BTC</span>
                    </div>
                    <div className={classNames(style.tItem, style.itemPeriod)}>
                        <span className={style.tText}>{t('edcRent.totalGot')} EDC</span>
                    </div>
                    <div className={classNames(style.tItem, style.itemBtn)}>
                        <span className={style.tText}>85% BTC</span>
                    </div>
                    <div className={classNames(style.tItem, style.itemPeriod)}>
                        <span className={style.tText}>{t('edcRent.rentalPeriod')}</span>
                    </div>
                </div>
                <div className={style.tBody}>
                    {rentCompleted.map(item => {
                        const {
                            completedAt,
                            refunded,
                            id,
                            income,
                            paidForRent,
                            period,
                            rentAmount,
                        } = item;

                        return (
                            <div key={id} className={style.tBody__Item}>
                                <div className={classNames(style.tItem, style.itemDate)}>
                                    <span className={style.tText}>
                                        <Moment format="DD.MM.YYYY HH:mm" unix>
                                            {completedAt}
                                        </Moment>
                                    </span>
                                </div>
                                <div className={classNames(style.tItem, style.itemId)}>
                                    <span className={style.tText}>{id}</span>
                                </div>
                                <div
                                    className={classNames(style.tItem, style.itemRentail)}
                                >
                                    <span className={style.tText}>{income}</span>
                                </div>
                                <div
                                    className={classNames(style.tItem, style.itemAmount)}
                                >
                                    <span className={style.tText}>{paidForRent}</span>
                                </div>
                                <div
                                    className={classNames(style.tItem, style.itemPeriod)}
                                >
                                    <span className={style.tText}>
                                        {rentAmount}
                                    </span>
                                </div>
                                <div className={classNames(style.tItem, style.itemBtn)}>
                                    <span className={style.tText}>
                                        {refunded}
                                    </span>
                                </div>
                                <div
                                    className={classNames(style.tItem, style.itemPeriod)}
                                >
                                    <span className={style.tText}>
                                        {period}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        ) : (
            <p className={style.noHistory}> {t('general.noHistory')}</p>
        )}
    </Fragment>
);

ReturnTable.defaultProps = {
    t: () => {},
    rentCompleted: [],
};

ReturnTable.propTypes = {
    t: PropTypes.func,
    rentCompleted: PropTypes.instanceOf(Array),
};

export default ReturnTable;
