import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import Field from '../../../../../small-components/field';
import Button from '../../../../../small-components/button';
import PaginationComponent from '../../../../../layouts/pagination';
import { registartionPath } from '../../../../../../constants';
import copyImg from '../../../../../assets/images/copy-content.svg';
import cancelImg from '../../../../../assets/images/cancel.svg';
import searchImg from '../../../../../assets/images/search.svg';
import style from './structure.module.scss';

const StructureView = ({
    search,
    inputOnchange,
    copyToClipBoard,
    searchOnSubmit,
    clearSearch,
    submitBecomeReferral,
    leaderUsername,
    leaderUsernameFromRedux,
    leaderUsernameError,
    username,
    myReferrals: { items, totalCount },
}) => {
    const { t } = useTranslation();

    const comissionData = [
        {
            percent: '20',
            text: t('referralSystem.firstLevelReferalLink'),
        },
        {
            percent: '10',
            text: t('referralSystem.secondLevelReferalLink'),
        },
        {
            percent: '8',
            text: t('referralSystem.thirdLevelReferalLink'),
        },
        {
            percent: '7',
            text: t('referralSystem.fourthLevelReferalLink'),
        },
        {
            percent: '5',
            text: t('referralSystem.fiveLevelReferalLink'),
        },
    ];

    const columns = [
        {
            title: t('referralSystem.level'),
            dataIndex: 'level',
            key: 'level',
        },
        {
            title: t('referralSystem.username'),
            dataIndex: 'username',
            key: 'username',
        },
        // {
        //     title: 'Email',
        //     dataIndex: 'email',
        //     key: 'email',
        // },
        {
            title: t('referralSystem.peopleInTheStructure'),
            dataIndex: 'usersInStructure',
            key: 'usersInStructure',
        },
        {
            title: `${t('referralSystem.earned')} BTC`,
            dataIndex: 'btc',
            key: 'btc',
        },
        {
            title: `${t('referralSystem.earned')} USD`,
            dataIndex: 'usd',
            key: 'usd',
        },
    ];

    const refLink = `${window.location.origin}${registartionPath}?ref=${username}`;

    return (
        <div className={style.structure}>
            <div className={style.header}>
                <div className={style.header__linkWrapper}>
                    <p className={style.header_referralTitle}>
                        {t('referralSystem.referralLink')}
                    </p>
                    <div className={style.header_clipboardWrapper}>
                        <p className={style.header_link}>{refLink}</p>
                        <CopyToClipboard text={refLink} onCopy={copyToClipBoard}>
                            <img
                                src={copyImg}
                                className={style.header_copyIcon}
                                alt="copyImg"
                            />
                        </CopyToClipboard>
                    </div>
                </div>
                <form onSubmit={searchOnSubmit} className={style.search}>
                    <label className={style.search_label} htmlFor="search">
                        {t('referralSystem.searchByUsernameEmail')}
                    </label>
                    <div className={style.search_inputWrapper}>
                        <input
                            id="search"
                            type="text"
                            className={style.search_input}
                            placeholder={t('referralSystem.enterEmailOrUsername')}
                            value={search}
                            onChange={inputOnchange}
                            name="search"
                            maxLength="100"
                        />
                        {search ? (
                            <div
                                className={style.search_cancelImg}
                                onClick={clearSearch}
                            >
                                <img src={cancelImg} alt="cancelImg" />
                            </div>
                        ) : null}
                        <div
                            className={style.search_searchImg}
                            onClick={searchOnSubmit}
                        >
                            <img src={searchImg} alt="searchImg" />
                        </div>
                    </div>
                </form>
            </div>
            {items.length ? (
                <div className="myReferralsTable">
                    <Table columns={columns} dataSource={items} pagination={false} />
                </div>
            ) : (
                <p className={style.noHistory}>{t('referralSystem.noReferralsYet')}</p>
            )}
            {totalCount > 10 ? <PaginationComponent totalCount={totalCount} /> : null}
            <form onSubmit={submitBecomeReferral} className={style.becomeReferral}>
                <Field
                    id="leaderUsername"
                    type="text"
                    placeholder={t('referralSystem.enterEmailOrReferralLink')}
                    name="leaderUsername"
                    value={leaderUsername}
                    onChange={inputOnchange}
                    error={leaderUsernameError}
                    inputWrapperStyle={style.becomeReferral__inputWrapperStyle}
                    inputStyle={style.becomeReferral__input}
                    disabled={!!leaderUsernameFromRedux}
                    labelText={
                        leaderUsernameFromRedux
                            ? t('referralSystem.emailOfYourHigherPartner')
                            : t('referralSystem.becomeReferral')
                    }
                    labelStyle={style.becomeReferral__label}
                />
                {!leaderUsernameFromRedux ? (
                    <Button
                        id="becomeReferral-button"
                        type="submit"
                        className={style.becomeReferral__button}
                        onClick={submitBecomeReferral}
                    >
                        {t('referralSystem.becomeReferral')}
                    </Button>
                ) : null}
            </form>
            <div className={style.comission}>
                <h3 className={style.comission__title}>
                    {t('referralSystem.howMuchIWillGet')}
                </h3>

                <div className={style.comission__wrapper}>
                    {comissionData.map(item => {
                        const { percent, text } = item;

                        return (
                            <div key={percent} className={style.comission__item}>
                                <p className={style.comission__item_label}>
                                    {t('referralSystem.fromCommission')}
                                </p>
                                <p className={style.comission__item_text}>{text}</p>
                                <p className={style.comission__item_percent}>
                                    {percent}%
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

StructureView.defaultProps = {
    copyToClipBoard: () => {},
    inputOnchange: () => {},
    searchOnSubmit: () => {},
    clearSearch: () => {},
    submitBecomeReferral: () => {},
    myReferrals: {},
    search: '',
    username: '',
    leaderUsername: '',
    leaderUsernameFromRedux: '',
    leaderUsernameError: '',
};

StructureView.propTypes = {
    copyToClipBoard: PropTypes.func,
    inputOnchange: PropTypes.func,
    searchOnSubmit: PropTypes.func,
    clearSearch: PropTypes.func,
    submitBecomeReferral: PropTypes.func,
    myReferrals: PropTypes.object,
    search: PropTypes.string,
    username: PropTypes.string,
    leaderUsername: PropTypes.string,
    leaderUsernameFromRedux: PropTypes.string,
    leaderUsernameError: PropTypes.string,
};

export default StructureView;
