import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Drawer } from 'antd';

import withGetService from '../../hoc';
import SelectLanguage from '../../language';
import UserComponent from './layouts/user-component';
import { compose } from '../../../utils';
import { getUserCountryActions, FilterActions } from '../../../actions';
import GetService from '../../../services/get-service';
import getAllCoinsAction from '../../../actions/getAllCoins.actions';
import { personalAreaPath, newOrder } from '../../../constants/pathLocation';
import Aside from '../../pages/personal-area/personal-area-view/aside';
import { countries } from '../../../helpers/countries';

import Img from '../../small-components/img';
import location from './images/location.svg';
import logo from '../../assets/images/logo.svg';

import style from './header.module.scss';
import './header.scss';

export class HeaderPersonalArea extends Component {
    mounted = true;

    getService = new GetService();

    state = {
        showNotificationState: false,
        dropDownMenuState: false,
        mobileMenu: false,
    };

    componentDidMount() {
        setTimeout(() => {
            document.body.classList.add('domLoaded');
        }, 100);
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const { locale, getAllCoins } = this.props;
        if (locale !== prevProps.locale) {
            getAllCoins();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadData = () => {
        const { setFilterParams, setUserCountry } = this.props;

        this.getService
            .getUserLocation()
            .then(locationItem => {
                const filterCountry = countries.find(
                    element => element.value === locationItem.location,
                );
                const filterParams = {
                    country: filterCountry.value,
                };
                setFilterParams(filterParams);
                setUserCountry(filterCountry);
            })
            .catch(err => {
                console.log(err, 'error');
            });
    };

    showNotification = () => {
        const { showNotificationState } = this.state;
        if (this.mounted) {
            this.setState({
                showNotificationState: !showNotificationState,
            });
        }
    };

    showdropDownMenu = () => {
        const { dropDownMenuState } = this.state;
        if (this.mounted) {
            this.setState({
                dropDownMenuState: !dropDownMenuState,
            });
        }
    };

    closeNotification = () => {
        if (this.mounted) {
            this.setState({
                showNotificationState: false,
            });
        }
    };

    closeDropdown = () => {
        if (this.mounted) {
            this.setState({
                dropDownMenuState: false,
            });
        }
    };

    // show mobile menu
    showDrawer = () => {
        const { mobileMenu } = this.state;
        if (this.mounted) {
            this.setState({
                mobileMenu: !mobileMenu,
            });
        }

        const button = document.getElementById('humburgerMenu');
        button.classList.toggle('active');
    };

    render() {
        const { t, country } = this.props;
        const { mobileMenu } = this.state;

        let drawerWidth;
        let drawerPaddingTop;
        if (window.innerWidth < 500) {
            drawerWidth = '100vw';
            drawerPaddingTop = '27vw';
        } else if (window.innerWidth > 499 && window.innerWidth < 1050) {
            drawerWidth = '50vw';
            drawerPaddingTop = '12vw';
        }

        const mobileMenuBody = {
            backgroundColor: '#FAFBFF',
            padding: `${drawerPaddingTop} 0 0 0`,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
        };
        return (
            <Fragment>
                <div className="filter header-personal-area">
                    <header className={style.header}>
                        <div className={style.header__leftSide}>
                            <div className="animationPersonalAreaLogo">
                                <Link to="/" className={style.header__personalArea_logo}>
                                    <Img src={logo} alt="logo" />
                                </Link>
                            </div>

                            <div className="animationLocation">
                                <div className={style.header__leftSide_locationWrapper}>
                                    <Img
                                        src={location}
                                        alt="location"
                                        className={style.header__leftSide_location}
                                    />
                                    {country.name}
                                </div>
                            </div>
                        </div>
                        <div className={style.header__rightSide}>
                            <div className="animationNewOrder">
                                <Link
                                    to={`${personalAreaPath}${newOrder}`}
                                    className={style.header__rightSide_newOrder}
                                >
                                    <svg
                                        width="22px"
                                        height="22px"
                                        viewBox="0 0 22 22"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={style.header__rightSide_newOrderIcon}
                                    >
                                        <g
                                            id="Symbols"
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fillRule="evenodd"
                                        >
                                            <g
                                                id="header/dashboard"
                                                transform="translate(-757.000000, -30.000000)"
                                                fill="#fff"
                                                fillRule="nonzero"
                                            >
                                                <g id="place">
                                                    <g transform="translate(285.000000, 27.000000)">
                                                        <path
                                                            d="M482.960938,25.0117188 C476.9375,25.0117188 471.980469,20.0546875 471.980469,
                                                                14.03125 C471.980469,8.0078125 476.925781,3.05078125 482.949219,3.05078125 C488.972656,
                                                                3.05078125 493.941406,8.0078125 493.941406,14.03125 C493.941406,20.0546875 488.984375,
                                                                25.0117188 482.960938,25.0117188 Z M482.960938,23.8398438 C488.386719,
                                                                23.8398438 492.769531,19.4570312 492.769531,14.03125 C492.757812,8.60546875 488.375,
                                                                4.22265625 482.949219,4.22265625 C477.535156,4.22265625 473.152344,8.60546875 473.152344,
                                                                14.03125 C473.152344,19.4570312 477.535156,23.8398438 482.960938,23.8398438 Z M482.9375,
                                                                19.28125 C482.585938,19.28125 482.363281,19.0234375 482.363281,18.6484375 L482.363281,
                                                                14.6289062 L478.273438,14.6289062 C477.898438,14.6289062 477.617188,14.40625 477.617188,
                                                                14.0546875 C477.617188,13.6914062 477.875,13.4570312 478.273438,13.4570312 L482.363281,
                                                                13.4570312 L482.363281,9.34375 C482.363281,8.96875 482.585938,8.69921875 482.9375,
                                                                8.69921875 C483.3125,8.69921875 483.546875,8.95703125 483.546875,9.34375 L483.546875,
                                                                13.4570312 L487.648438,13.4570312 C488.035156,13.4570312 488.292969,
                                                                13.6914062 488.292969,14.0546875 C488.292969,14.40625 488.011719,
                                                                14.6289062 487.648438,14.6289062 L483.546875,14.6289062 L483.546875,
                                                                18.6484375 C483.546875,19.0351562 483.3125,19.28125 482.9375,
                                                                19.28125 Z"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    {t('orders.new')}
                                </Link>
                                <UserComponent />
                            </div>
                            <div className="animationSelectLang">
                                <SelectLanguage />
                                <div className={style.header__mobileMenu}>
                                    <svg
                                        className="ham hamRotate ham8"
                                        id="humburgerMenu"
                                        viewBox="0 0 100 100"
                                        onClick={this.showDrawer}
                                    >
                                        <path
                                            className="line top"
                                            d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
                                        />
                                        <path className="line middle" d="m 30,50 h 40" />
                                        <path
                                            className="line bottom"
                                            d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
                                        />
                                    </svg>
                                    <Drawer
                                        placement="right"
                                        closable={false}
                                        onClose={this.showDrawer}
                                        visible={mobileMenu}
                                        bodyStyle={mobileMenuBody}
                                        height="100vh"
                                        width={drawerWidth}
                                    >
                                        <div className={style.asideMenu}>
                                            <Aside onClickAside={this.showDrawer} />
                                        </div>
                                        <div className={style.header__mobileMenu_footer}>
                                            <p
                                                className={
                                                    style.header__mobileMenu_copyright
                                                }
                                            >
                                                Copyright
                                                <span
                                                    className={
                                                        style.header__mobileMenu_symbol
                                                    }
                                                >
                                                    &#169;
                                                </span>
                                                <span
                                                    className={
                                                        style.header__mobileMenu_p2p
                                                    }
                                                >
                                                    CROCOBIT
                                                </span>
                                            </p>
                                            <div className="mobileMenu__selectLanguage">
                                                <SelectLanguage />
                                            </div>
                                        </div>
                                    </Drawer>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </Fragment>
        );
    }
}

HeaderPersonalArea.defaultProps = {
    t: () => {},
    getAllCoins: () => {},
    setFilterParams: () => {},
    setUserCountry: () => {},
    country: {},
    locale: {},
};

HeaderPersonalArea.propTypes = {
    t: PropTypes.func,
    getAllCoins: PropTypes.func,
    setFilterParams: PropTypes.func,
    setUserCountry: PropTypes.func,
    country: PropTypes.object,
    locale: PropTypes.any,
};

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
        getUserCountry: { country },
        getUserCountry: { country: userCoutry },
        getCurrentLocale: { locale },
    } = state;

    return {
        loggedIn,
        country,
        userCoutry,
        locale,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        getAllCoins: getAllCoinsAction(getService),
        setFilterParams: filterParams => FilterActions.selectFilter(filterParams),
        setUserCountry: filterCountry => getUserCountryActions.getUserCountry(filterCountry),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(HeaderPersonalArea);
