/* eslint-disable */

export class DummyService {
    orders = [
        {
            id: 1,
            logoSrc: 'https://picsum.photos/400/400',
            userName: 'Nikita Soia',
            raiting: '4',
            cryptoCurrency: 'BTC / ETC',
            transfer: 'Privat 24',
            country: 'United States of America',
            limitsFrom: '0.1',
            limitsTo: '2.5',
            cost: '8814.12',
            buy: true,
            statusOrder: 'PENDING',
            currency: 'UAH',
            newTrader: true,
            verifiedEmail: true,
            verifiedPhone: true,
            online: true,
            fast: false,
        },
        {
            id: 2,
            logoSrc: '',
            userName: 'Some Name',
            raiting: '3',
            cryptoCurrency: 'BTC',
            currency: 'UAH',
            transfer: 'Privat 24',
            country: 'United States of America',
            limitsFrom: '0.1',
            limitsTo: '2.5',
            cost: '8814.12',
            buy: false,
            statusOrder: 'COMPLETED',
            newTrader: true,
            verifiedEmail: true,
            verifiedPhone: true,
            online: false,
            fast: true,
        },
        {
            id: 3,
            logoSrc: '',
            userName: 'Willow',
            raiting: '5',
            cryptoCurrency: 'BTC',
            currency: 'UAH',
            transfer: 'Privat 24',
            country: 'United States of America',
            limitsFrom: '0.1',
            limitsTo: '2.5',
            cost: '8814.12',
            buy: true,
            statusOrder: 'CANCELLED',
            newTrader: false,
            verifiedEmail: false,
            verifiedPhone: false,
            online: true,
            fast: true,
        },
        {
            id: 4,
            logoSrc: '',
            userName: 'Willow',
            raiting: '2',
            cryptoCurrency: 'BTC',
            currency: 'UAH',
            transfer: 'Privat 24',
            country: 'United States of America',
            limitsFrom: '0.1',
            limitsTo: '2.5',
            cost: '8814.12',
            buy: false,
            statusOrder: 'COMPLETED',
            newTrader: true,
            verifiedEmail: true,
            verifiedPhone: true,
            online: false,
            fast: false,
        },
        {
            id: 5,
            logoSrc: '',
            userName: 'Willow',
            raiting: '4',
            cryptoCurrency: 'BTC',
            currency: 'UAH',
            transfer: 'Privat 24',
            country: 'United States of America',
            limitsFrom: '0.1',
            limitsTo: '2.5',
            cost: '8814.12',
            buy: true,
            statusOrder: 'CANCELLED',
            newTrader: true,
            verifiedEmail: true,
            verifiedPhone: true,
            online: true,
            fast: false,
        },
        {
            id: 6,
            logoSrc: '',
            userName: 'Willow',
            raiting: '5',
            cryptoCurrency: 'BTC',
            transfer: 'Privat 24',
            country: 'United States of America',
            limitsFrom: '0.1',
            limitsTo: '2.5',
            cost: '8814.12',
            buy: false,
            statusOrder: 'PENDING',
            currency: 'UAH',
            newTrader: true,
            verifiedEmail: true,
            verifiedPhone: true,
            online: false,
            fast: true,
        },
    ];

    historyTransactions = [
        {
            id: 1,
            number: 490,
            amount: 0.12302354,
            currency: 'BTC',
            date: '11.01.2019',
            time: '16:08:47',
            tx: '#6677772T60879035',
            type: 'Deposit',
            statusOrder: 'CANCELLED',
        },
        {
            id: 2,
            number: 491,
            amount: 0.12302354,
            currency: 'BTC',
            date: '11.01.2019',
            time: '16:08:47',
            tx: '#6677772T60879035',
            type: 'Withdraw',
            statusOrder: 'COMPLETED',
        },
        {
            id: 3,
            number: 490,
            amount: 0.12302354,
            currency: 'BTC',
            date: '11.01.2019',
            time: '16:08:47',
            tx: '#6677772T60879035',
            type: 'Deposit',
            statusOrder: 'PENDING',
        },
        {
            id: 4,
            number: 491,
            amount: 0.12302354,
            currency: 'BTC',
            date: '11.01.2019',
            time: '16:08:47',
            tx: '#6677772T60879035',
            type: 'Withdraw',
            statusOrder: 'COMPLETED',
        },
        {
            id: 5,
            number: 491,
            amount: 0.12302354,
            currency: 'BTC',
            date: '11.01.2019',
            time: '16:08:47',
            tx: '#6677772T60879035',
            type: 'Withdraw',
            statusOrder: 'CANCELLED',
        },
        {
            id: 6,
            number: 491,
            amount: 0.12302354,
            currency: 'BTC',
            date: '11.01.2019',
            time: '16:08:47',
            tx: '#6677772T60879035',
            type: 'Withdraw',
            statusOrder: 'PENDING',
        },
    ];

    wallet = [
        {
            id: 1,
            userCoinAddress: {
                image: "https://chart.googleapis.com/chart?cht=qr&chs=400x400&chl=0x95eF99956456DFB46Cee9A023FdE562290FA544a",
                address: "0x95eF99956456DFB46Cee9A023FdE562290FA544a",
            },
            coin: {
                name: "Ethereum",
                handle: "ETH",
                commission: {percentage: 10, withdrawPercentage: 10, depositPercentage: 10},
                percentage: 10,
                withdrawPercentage: 10,
                depositPercentage: 10,
            },
            amount: 33.5,
            amountOnOrders: 52.42,
            amountToWithdraw: null,
        },
        {
            id: 2,
            userCoinAddress: {
                image: "https://chart.googleapis.com/chart?cht=qr&chs=400x400&chl=0x95eF99956456DFB46Cee9A023FdE562290FA544a",
                address: "0x95eF99956456DFB46Cee9A023FdE562290FA544a",
            },
            coin: {
                name: "Ethereum",
                handle: "ETH",
                commission: {percentage: 10, withdrawPercentage: 10, depositPercentage: 10},
                percentage: 10,
                withdrawPercentage: 10,
                depositPercentage: 10,
            },
            amount: 33.5,
            amountOnOrders: 52.42,
            amountToWithdraw: 36.89,
        },
        {
            id: 3,
            userCoinAddress: {
                image: "https://chart.googleapis.com/chart?cht=qr&chs=400x400&chl=0x95eF99956456DFB46Cee9A023FdE562290FA544a",
                address: "0x95eF99956456DFB46Cee9A023FdE562290FA544a",
            },
            coin: {
                name: "Ethereum",
                handle: "ETH",
                commission: {percentage: 10, withdrawPercentage: 10, depositPercentage: 10},
                percentage: 10,
                withdrawPercentage: 10,
                depositPercentage: 10,
            },
            amount: 33.5,
            amountOnOrders: 52.42,
            amountToWithdraw: 36.89,
        },
        {
            id: 4,
            userCoinAddress: {
                image: "https://chart.googleapis.com/chart?cht=qr&chs=400x400&chl=0x95eF99956456DFB46Cee9A023FdE562290FA544a",
                address: "0x95eF99956456DFB46Cee9A023FdE562290FA544a",
            },
            coin: {
                name: "Ethereum",
                handle: "ETH",
                commission: {percentage: 10, withdrawPercentage: 10, depositPercentage: 10},
                percentage: 10,
                withdrawPercentage: 10,
                depositPercentage: 10,
            },
            amount: 33.5,
            amountOnOrders: 52.42,
            amountToWithdraw: 36.89,
        },
        {
            id: 5,
            userCoinAddress: {
                image: "https://chart.googleapis.com/chart?cht=qr&chs=400x400&chl=0x95eF99956456DFB46Cee9A023FdE562290FA544a",
                address: "0x95eF99956456DFB46Cee9A023FdE562290FA544a",
            },
            coin: {
                name: "Ethereum",
                handle: "ETH",
                commission: {percentage: 10, withdrawPercentage: 10, depositPercentage: 10},
                percentage: 10,
                withdrawPercentage: 10,
                depositPercentage: 10,
            },
            amount: 33.5,
            amountOnOrders: 52.42,
            amountToWithdraw: 36.89,
        },
        {
            id: 6,
            userCoinAddress: {
                image: "https://chart.googleapis.com/chart?cht=qr&chs=400x400&chl=0x95eF99956456DFB46Cee9A023FdE562290FA544a",
                address: "0x95eF99956456DFB46Cee9A023FdE562290FA544a",
            },
            coin: {
                name: "Ethereum",
                handle: "ETH",
                commission: {percentage: 10, withdrawPercentage: 10, depositPercentage: 10},
                percentage: 10,
                withdrawPercentage: 10,
                depositPercentage: 10,
            },
            amount: 33.5,
            amountOnOrders: 52.42,
            amountToWithdraw: 36.89,
        },
    ];

    chat = [
        {
            id: '1',
            img: 'https://picsum.photos/400/400',
            name: 'Max Smitch',
            rating: '5',
            previewText:
                'Analysis of foreign experience, as it is Analysis of foreign experience, as it is Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience',

            online: false,
            unreadMessages: '29',
            lastSeen: '3',
            paymentMethod: 'Bank transfer',
            tradeLimits: ' 10 - 1499 UAH',
            location: 'Ukraine',
            registered: ' Jun 11, 2019',
            totalTrades: '240 mins',
            emailVerified: 'Yes',
            cardNumber: '5168 - 2455 - 2343 - 2343',
        },
        {
            id: '2',
            img: 'https://picsum.photos/400/400',
            name: 'Sam Black',
            rating: '4',
            previewText:
                'Analysis of foreign experience, as it is Analysis of foreign experience, as it is Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience',

            online: true,
            unreadMessages: '3',
            lastSeen: '',
            paymentMethod: 'Bank transfer',
            tradeLimits: ' 10 - 1499 UAH',
            location: 'Ukraine',
            registered: ' Jun 11, 2019',
            totalTrades: '240 mins',
            emailVerified: 'Yes',
            cardNumber: '5168 - 2455 - 2343 - 2343',
        },
        {
            id: '3',
            img: 'https://picsum.photos/400/400',
            name: 'John Doe',
            rating: '1',
            previewText:
                'Analysis of foreign experience, as it is Analysis of foreign experience, as it is Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience',

            online: true,
            unreadMessages: '6',
            lastSeen: '',
            paymentMethod: 'Bank transfer',
            tradeLimits: ' 10 - 1499 UAH',
            location: 'Ukraine',
            registered: ' Jun 11, 2019',
            totalTrades: '240 mins',
            emailVerified: 'Yes',
            cardNumber: '5168 - 2455 - 2343 - 2343',
        },
        {
            id: '4',
            img: '',
            name: 'Sam Black',
            rating: '2',
            previewText:
                'Analysis of foreign experience, as it is Analysis of foreign experience, as it is Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience',

            online: false,
            unreadMessages: '7',
            lastSeen: '5',
            paymentMethod: 'Bank transfer',
            tradeLimits: ' 10 - 1499 UAH',
            location: 'Ukraine',
            registered: ' Jun 11, 2019',
            totalTrades: '240 mins',
            emailVerified: 'Yes',
            cardNumber: '5168 - 2455 - 2343 - 2343',
        },
        {
            id: '5',
            img: 'https://picsum.photos/400/400',
            name: 'Alex Nord',
            rating: '4',
            previewText:
                'Analysis of foreign experience, as it is Analysis of foreign experience, as it is Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience',

            online: true,
            unreadMessages: '4',
            lastSeen: '',
            paymentMethod: 'Bank transfer',
            tradeLimits: ' 10 - 1499 UAH',
            location: 'Ukraine',
            registered: ' Jun 11, 2019',
            totalTrades: '240 mins',
            emailVerified: 'Yes',
            cardNumber: '5168 - 2455 - 2343 - 2343',
        },
        {
            id: '6',
            img: 'https://picsum.photos/400/400',
            name: 'Fill Sun',
            rating: '4',
            previewText:
                'Analysis of foreign experience, as it is Analysis of foreign experience, as it is Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience Analysis of foreign experience, as it is Analysis of foreign experience, as it is …Analysis of foreign experience',

            online: false,
            unreadMessages: '2',
            lastSeen: '3',
            paymentMethod: 'Bank transfer',
            tradeLimits: ' 10 - 1499 UAH',
            location: 'Ukraine',
            registered: ' Jun 11, 2019',
            totalTrades: '240 mins',
            emailVerified: 'Yes',
            cardNumber: '5168 - 2455 - 2343 - 2343',
        },
    ];

    dashboard = [
        {
            id: '1',
            logoSrc: 'https://picsum.photos/400/400',
            userName: 'Nikita Soia',
            raiting: '4',
            cryptoCurrency: 'BTC',
            transfer: 'Privat 24',
            country: 'United States of America',
            cost: '8814.12',
            buy: true,
            advertisementStatus: true,
            statusOrder: 'PENDING',
            cardNumber: '5168 - 2455 - 2343 - 2343',
            cardHolderName: 'Nikita Soia',
            limitsFrom: '0.1',
            limitsTo: '2.5',
            pair: 'BTC/USD',
            online: true,
            fast: false,
            paymentWindow: '240 min',
        },
        {
            id: '2',
            logoSrc: '',
            userName: 'Some Name',
            raiting: '5',
            cryptoCurrency: 'BTC',
            transfer: 'Privat 24',
            country: 'United States of America',
            cost: '8814.12',
            buy: false,
            advertisementStatus: false,
            statusOrder: 'COMPLETED',
            cardNumber: '5168 - 2455 - 2343 - 2343',
            cardHolderName: 'Some Name',
            limitsFrom: '0.1',
            limitsTo: '2.5',
            pair: 'BTC/USD',
            online: true,
            fast: true,
            paymentWindow: '240 min',
        },
        {
            id: '3',
            logoSrc: '',
            userName: 'Willow',
            raiting: '2',
            cryptoCurrency: 'BTC',
            transfer: 'Privat 24',
            country: 'United States of America',
            cost: '8814.12',
            buy: true,
            advertisementStatus: false,
            statusOrder: 'COMPLETED',
            cardNumber: '5168 - 2455 - 2343 - 2343',
            limitsFrom: '0.1',
            limitsTo: '2.5',
            pair: 'BTC/USD',
            online: true,
            fast: false,
            paymentWindow: '240 min',
        },
        {
            id: 4,
            logoSrc: '',
            userName: 'Willow',
            raiting: '4',
            cryptoCurrency: 'BTC',
            transfer: 'Privat 24',
            country: 'United States of America',
            cost: '8814.12',
            buy: true,
            advertisementStatus: false,
            statusOrder: 'PENDING',
            cardNumber: '5168 - 2455 - 2343 - 2343',
            cardHolderName: 'Willow',
            limitsFrom: '0.1',
            limitsTo: '2.5',
            pair: 'BTC/USD',
            online: true,
            fast: false,
            paymentWindow: '240 min',
        },
        {
            id: 5,
            logoSrc: '',
            userName: 'Willow',
            raiting: '4',
            cryptoCurrency: 'BTC',
            transfer: 'Privat 24',
            country: 'United States of America',
            cost: '8814.12',
            buy: true,
            advertisementStatus: true,
            statusOrder: 'CANCELLED',
            cardNumber: '5168 - 2455 - 2343 - 2343',
            cardHolderName: 'Willow',
            limitsFrom: '0.1',
            limitsTo: '2.5',
            pair: 'BTC/USD',
            online: true,
            fast: false,
            paymentWindow: '240 min',
        },
        {
            id: 6,
            logoSrc: '',
            userName: 'Willow',
            raiting: '4',
            cryptoCurrency: 'BTC',
            transfer: 'Privat 24',
            country: 'United States of America',
            cost: '8814.12',
            buy: true,
            advertisementStatus: false,
            statusOrder: 'PENDING',
            cardNumber: '5168 - 2455 - 2343 - 2343',
            cardHolderName: 'Willow',
            limitsFrom: '0.1',
            limitsTo: '2.5',
            pair: 'BTC/USD',
            online: true,
            fast: false,
            paymentWindow: '240 min',
        },
    ];

    edit = {
        order: '1',
        isSell: 'true',
        coinBuy: 'BTC',
        coinSell: 'BTC',
        smallestAmount: '1',
        sellAmount: '2',
        country: 'BY',
        cartHandler: 'Some Name',
        cartNumber: '1111111111111111',
        paymentMethod: 'BANK_TRANSFER',
        radioGroup: 'marketPrice',
        fixedPrice: '',
        marginValue: '25',
    };

    profileInfo = [
        {
            id: '1',
            img: 'https://picsum.photos/400/400',
            name: 'Lovel Night',
            successfulTrades: '245',
            accountCreated: 'Month ago',
            identity: '',
            phonenNumber: '',
            numberPartners: '10',
            lastSeen: 'Few seconds ago',
            rating: '4',
        },
    ];

    feedback = [
        {
            id: '1',
            img: 'https://picsum.photos/400/400',
            name: 'Great buyer. Thanks for your patronage',
            feedbackText:
                'So fast I received my cash within 5mins olowo is a trust worthy man kip it !remitano 9ja olowo best buyer. So fast I received my cash within 5mins olowo is a trust worthy man kip it !remitano 9ja olowo best buyer. So fast I received my cash within 5mins olowo is a trust worthy man kip it !remitano 9ja olowo best buyer. So fast I received my cash within 5mins olowo is a trust worthy man kip it !remitano 9ja olowo best buyer.',
            date: '1565692980',
        },
        {
            id: '2',
            img: '',
            name: 'Emet Dettmering',
            feedbackText:
                'So fast I received my cash within 5mins olowo is a trust worthy man kip it !remitano 9ja olowo best buyer.',
            date: '1565692980',
        },
        {
            id: '3',
            img: 'https://picsum.photos/400/400',
            name: 'Max Smitch',
            feedbackText: 'Great buyer. Thanks for your patronage.',
            date: '1565692980',
        },
        {
            id: '4',
            img: 'https://picsum.photos/400/400',
            name: 'Antony Dettmering',
            feedbackText: 'Always contact him for trading. He is good and fast. Thanks.',
            date: '1565692980',
        },
    ];

    profile = {
        id: '1',
        email: 'johndoe@gmail.com',
        username: 'John Doe',
        identity: '',
        phoneNumber: '(099) 999-99-99',
    };

    verification = {
        id: '1',
        verificationStatus: false,
        code: '628132',
    };

    lastAccountActivities = [
        {
            id: '1',
            location: 'Ukraine (193.111.49.67)',
            time: '1565692980',
        },
        {
            id: '2',
            location: 'Ukraine (109.207.199.104)',
            time: '1565692980',
        },
        {
            id: '3',
            location: 'Ukraine (46.149.81.105)',
            time: '1565692980',
        },
        {
            id: '4',
            location: 'United States (38.132.120.94)',
            time: '1565692980',
        },
        {
            id: '5',
            location: 'Ukraine (193.111.49.67)',
            time: '1565692980',
        },
        {
            id: '6',
            location: 'Ukraine (193.111.49.67)',
            time: '1565692980',
        },
    ];

    trustedDevices = [
        {
            id: '1',
            time: '1565692980',
            location: 'Ukraine',
            userAgent: 'Chrome/Windows',
            ip: '192.111.49.67',
            curentBrowser: true,
        },
        {
            id: '2',
            time: '1565692980',
            location: 'Ukraine',
            userAgent: 'Safari/iOS',
            ip: '192.111.49.67',
            curentBrowser: false,
        },
        {
            id: '3',
            time: '1565692980',
            location: 'United States',
            userAgent: 'Chrome/Windows',
            ip: '192.111.49.67',
            curentBrowser: false,
        },
        {
            id: '4',
            time: '1565692980',
            location: 'Ukraine',
            userAgent: 'Chrome/Windows',
            ip: '192.111.49.67',
            curentBrowser: false,
        },
        {
            id: '5',
            time: '1565692980',
            location: 'Ukraine',
            userAgent: 'Chrome/Windows',
            ip: '192.111.49.67',
            curentBrowser: false,
        },
        {
            id: '6',
            time: '1565692980',
            location: 'Ukraine',
            userAgent: 'Chrome/Windows',
            ip: '192.111.49.67',
            curentBrowser: false,
        },
    ];

    blacklist = [
        {
            id: '1',
            name: 'Kit Lambra',
            img: 'https://picsum.photos/400/400',
            online: true,
            fast: false,
        },
        {
            id: '2',
            name: 'Sam Black',
            img: '',
            online: false,
            fast: false,
        },
        {
            id: '3',
            name: 'John Doe',
            img: '',
            online: false,
            fast: true,
        },
    ];

    getAllOrders() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.orders);
            }, 100);
        });
    }

    getAllHistoryTransactions() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.historyTransactions);
            }, 100);
        });
    }

    getAllWallet() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.wallet);
            }, 100);
        });
    }

    getChat() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.chat);
            }, 100);
        });
    }

    getAllDashboard() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.dashboard);
            }, 100);
        });
    }

    getEditAdvertisement() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.edit);
            }, 100);
        });
    }

    getProfileInfo() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.profileInfo);
            }, 100);
        });
    }

    getFeedback() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.feedback);
            }, 100);
        });
    }

    getProfile() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.profile);
            }, 100);
        });
    }

    getVerification() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.verification);
            }, 100);
        });
    }

    getLastAccountActivities() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.lastAccountActivities);
            }, 100);
        });
    }

    getTrustedDevices() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.trustedDevices);
            }, 100);
        });
    }

    getBlacklist() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.blacklist);
            }, 100);
        });
    }
}
