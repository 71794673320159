import { userConstants } from '../constants';

const getUserInfo = (state, action) => {
    if (state === undefined) {
        return {
            userInfo: {},
            loading: true,
            error: null,
        };
    }

    switch (action.type) {
    case userConstants.FETCH_USER_REQUEST:
        return {
            userInfo: {},
            loading: true,
            error: null,
        };

    case userConstants.FETCH_USER_SUCCESS:
        return {
            userInfo: action.payload,
            loading: false,
            error: null,
        };

    case userConstants.FETCH_USER_FAILURE:
        return {
            userInfo: {},
            loading: false,
            error: action.payload,
        };

    default:
        return state;
    }
};

export default getUserInfo;
