import React from 'react';
import PropTypes from 'prop-types';

import './slick-arrows.scss';

const ArrowPrev = props => {
    const { onClick } = props;

    return (
        <svg onClick={onClick} className="slickSlider__arrowPrev" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="slickSlider__arrowNext_fill" d="M21.4142 24L26.7071 18.7071C27.0976 18.3166 27.0976 17.6834 26.7071 17.2929C26.3166 16.9024 25.6834 16.9024 25.2929 17.2929L19.2929 23.2929C18.9024 23.6834 18.9024 24.3166 19.2929 24.7071L25.2929 30.7071C25.6834 31.0976 26.3166 31.0976 26.7071 30.7071C27.0976 30.3166 27.0976 29.6834 26.7071 29.2929L21.4142 24Z" />
            <path className="slickSlider__arrowNext_stroke" fillRule="evenodd" clipRule="evenodd" d="M24 47C36.7025 47 47 36.7025 47 24C47 11.2975 36.7025 1 24 1C11.2975 1 1 11.2975 1 24C1 36.7025 11.2975 47 24 47Z" strokeWidth="2" />
        </svg>
    );
};

const ArrowNext = props => {
    const { onClick } = props;

    return (
        <svg onClick={onClick} className="slickSlider__arrowNext" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="slickSlider__arrowNext_fill" d="M26.5858 24L21.2929 29.2929C20.9024 29.6834 20.9024 30.3166 21.2929 30.7071C21.6834 31.0976 22.3166 31.0976 22.7071 30.7071L28.7071 24.7071C29.0976 24.3166 29.0976 23.6834 28.7071 23.2929L22.7071 17.2929C22.3166 16.9024 21.6834 16.9024 21.2929 17.2929C20.9024 17.6834 20.9024 18.3166 21.2929 18.7071L26.5858 24Z" />
            <path className="slickSlider__arrowNext_stroke" fillRule="evenodd" clipRule="evenodd" d="M24 47C36.7025 47 47 36.7025 47 24C47 11.2975 36.7025 1 24 1C11.2975 1 1 11.2975 1 24C1 36.7025 11.2975 47 24 47Z" strokeWidth="2" />
        </svg>
    );
};

ArrowPrev.defaultProps = {
    onClick: () => { },
};

ArrowPrev.propTypes = {
    onClick: PropTypes.func,
};

ArrowNext.defaultProps = {
    onClick: () => { },
};

ArrowNext.propTypes = {
    onClick: PropTypes.func,
};

export {
    ArrowPrev,
    ArrowNext,
};
