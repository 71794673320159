import React, { Fragment, Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Select, Spin } from 'antd';

import PostService from '../../../services/post-service';
import { compose } from '../../../utils';
import { modalTradeActions, chatActions } from '../../../actions';
import Field from '../../small-components/field';
import Button from '../../small-components/button';
import Img from '../../small-components/img';
import { countries } from '../../../helpers/countries';
import {
    personalAreaPath,
    profilePath,
    chat,
    room,
    EXCHANGE,
    BUY,
    SELL,
    USER_NOT_CONFIRMED,
    NOT_ENOUGH_BALANCE,
    BANK_TRANSFER,
    CRYPTO_TRANSFER,
    SFC,
} from '../../../constants';

import close from '../../assets/images/close.svg';
import ok from '../../assets/images/ok.svg';
import done from '../../assets/images/done.svg';
import redClose from '../../assets/images/redClose.svg';

import style from './trade-modal-window.module.scss';
import './trade-modal-window.scss';

class TradeModalWindow extends Component {
    postService = new PostService();

    state = {
        cardId: '',
        paymentMethodNumber: '',
        isDisableSubmitBtn: false,
    };

    componentDidUpdate(prevProps) {
        const {
            orders: { items: ordersItems },
            currentOrder,
            dispatch,
            modalTrade,
        } = this.props;

        if (modalTrade !== prevProps.modalTrade) {
            ordersItems
                .filter(item => item.id === currentOrder)
                .map(item => {
                    const {
                        rate,
                        sellAmount,
                        orderType: { handle },
                    } = item;
                    let fixedRate = '';
                    if (handle !== EXCHANGE) {
                        const fixed = parseFloat(rate).toFixed(5);
                        const toNumber = +fixed;
                        fixedRate = toNumber;
                    } else {
                        fixedRate = rate;
                    }
                    const inputValues = {
                        amount: sellAmount,
                        suggestedPrice: fixedRate,
                    };
                    dispatch(modalTradeActions.inputChanges(inputValues));
                    return true;
                });
        }
    }

    // save select changes to state
    selectOnChange = (value, name) => {
        this.setState({
            [name.props.name]: value,
        });
    };

    // Close modal widow
    closeModalWindow = () => {
        const { dispatch } = this.props;
        dispatch(modalTradeActions.closeModalTrade());
    };

    paymentNumberChanges = event => {
        const { value, name } = event.target;

        this.setState({
            [name]: value,
        });
    };

    // Save input value to state
    InputOnchange = event => {
        const { dispatch } = this.props;
        const { value, name } = event.target;

        const {
            orders: { items: ordersItems },
            currentOrder,
        } = this.props;

        ordersItems
            .filter(item => item.id === currentOrder)
            .map(item => {
                const {
                    orderType: { handle: orderType },
                } = item;
                const onlyNumbers = /^[0-9.]+$/;

                const isCryptoTransfer = orderType !== EXCHANGE && name === 'suggestedPrice' ? 6 : 9;

                if (value === '' || onlyNumbers.test(value)) {
                    if (value.indexOf('.') > -1) {
                        const trimValue = value.substring(
                            0,
                            value.indexOf('.') + isCryptoTransfer,
                        );
                        const inputValues = {
                            [name]: trimValue,
                        };
                        dispatch(modalTradeActions.inputChanges(inputValues));
                    } else {
                        const inputValues = {
                            [name]: value,
                        };
                        dispatch(modalTradeActions.inputChanges(inputValues));
                    }
                }
                return true;
            });
    };

    // Submit trade form
    formSubmit = (
        smallestAmount,
        sellAmount,
        cardId,
        orderType,
        paymentMethodHandle,
        paymentMethodNumber,
    ) => event => {
        event.preventDefault();
        const {
            t, amount, suggestedPrice, history, dispatch, currentOrder,
        } = this.props;
        const errors = {};

        if (+amount < 0.00000001) {
            errors.amountError = t('error.amountMustBeLess', {
                count: (1e-8).toFixed(8),
            });
        }

        if (+amount > 9999999.99999999) {
            errors.amountError = t('error.amountMustBeGreater', {
                count: 9999999.99999999,
            });
        }

        if (orderType !== EXCHANGE && +suggestedPrice < 0.00001) {
            errors.suggestedPriceError = t('error.amountMustBeLess', { count: 0.00001 });
        }

        if (orderType === EXCHANGE && +suggestedPrice < 0.00000001) {
            errors.suggestedPriceError = t('error.amountMustBeLess', {
                count: (1e-8).toFixed(8),
            });
        }

        if (orderType !== EXCHANGE && +suggestedPrice > 999999999.99999) {
            errors.suggestedPriceError = t('error.amountMustBeGreater', {
                count: 999999999.99999,
            });
        }

        if (orderType === EXCHANGE && +suggestedPrice > 999999999.99999) {
            errors.suggestedPriceError = t('error.amountMustBeGreater', {
                count: 999999999.99999999,
            });
        }

        if (amount.length < 1) {
            errors.amountError = t('error.input', { count: 1 });
        }

        if (+amount > +sellAmount) {
            errors.amountError = `${t('general.maxAmount')} ${sellAmount}`;
        }

        if (+amount < +smallestAmount) {
            errors.amountError = `${t('general.minAmount')} ${smallestAmount}`;
        }

        if (
            orderType === BUY
            && paymentMethodHandle !== BANK_TRANSFER
            && paymentMethodNumber.length < 1
        ) {
            errors.paymentMethodNumberError = t('error.input', { count: 1 });
        }

        if (suggestedPrice.length < 1) {
            errors.suggestedPriceError = t('error.input', { count: 1 });
        }

        if (orderType === BUY && paymentMethodHandle === BANK_TRANSFER && cardId.length < 1) {
            errors.cardIdError = t('general.selectFromTheList');
        }

        if (Object.keys(errors).length > 0) {
            dispatch(modalTradeActions.inputErrors(errors));
        } else {
            dispatch(modalTradeActions.inputErrors({}));
            this.setState({
                isDisableSubmitBtn: true,
            });

            if (amount && suggestedPrice) {
                const data = {
                    order: currentOrder,
                    suggestedRate: suggestedPrice,
                    cardId,
                    amount,
                    paymentMethodNumber,
                };
                this.postService
                    .chatCreate(data)
                    .then(chatCreate => {
                        this.closeModalWindow();
                        this.setState({
                            chatCreate,
                            isDisableSubmitBtn: false,
                        });
                    })
                    .then(() => {
                        const {
                            chatCreate: { id },
                        } = this.state;
                        dispatch(chatActions.selectChat(id.toString()));
                        history.push(`${personalAreaPath}${chat}${room}/${id}`);
                    })
                    .catch(err => {
                        this.setState({
                            isDisableSubmitBtn: false,
                        });
                        errors.suggestedPriceError = err;

                        if (errors.suggestedPriceError === USER_NOT_CONFIRMED) {
                            const errorData = {
                                suggestedPriceError: t('error.verifyYourAccount'),
                            };
                            dispatch(modalTradeActions.inputErrors(errorData));
                        } else if (errors.suggestedPriceError === NOT_ENOUGH_BALANCE) {
                            const errorData = {
                                amountError: t('error.insufficientFunds'),
                            };
                            dispatch(modalTradeActions.inputErrors(errorData));
                        } else {
                            dispatch(modalTradeActions.inputErrors(errors));
                        }
                    });
            }
        }
    };

    render() {
        const {
            t,
            modalTrade,
            currentOrder,
            amount,
            suggestedPrice,
            cardIdError,
            amountError,
            suggestedPriceError,
            userId,
            cardList,
            orders: { items: ordersItems },
        } = this.props;

        const {
            cardId,
            isDisableSubmitBtn,
            paymentMethodNumber,
            paymentMethodNumberError,
        } = this.state;

        const { Option } = Select;

        if (modalTrade) {
            document.documentElement.style.overflowY = 'hidden';
        } else {
            document.documentElement.style.overflowY = 'inherit';
        }

        const registrModalStyle = {
            opacity: '0.5',
            pointerEvents: 'none',
            userSelect: 'none',
            transition: 'all 0.25s',
        };

        const loadingStyle = isDisableSubmitBtn ? registrModalStyle : null;

        return (
            <Fragment>
                <Modal
                    isOpen={modalTrade}
                    onRequestClose={this.closeModalWindow}
                    ariaHideApp={false}
                    className="tradeModalWindow reactModal"
                    contentLabel="Example Modal"
                    overlayClassName="modalOverlay"
                >
                    {isDisableSubmitBtn ? <Spin size="large" /> : null}
                    <div className={style.tradeModal} style={loadingStyle}>
                        {ordersItems
                            ? ordersItems
                                .filter(item => item.id === currentOrder)
                                .map(item => {
                                    const {
                                        id,
                                        smallestAmount,
                                        sellAmount,
                                        rate,
                                        coinBuy: { handle: coinBuy },
                                        coinSell: { handle: coinSell },
                                        orderType: { handle: orderType },
                                        country: { handle: country },
                                        paymentMethod: { name: paymentMethod, handle: paymentMethodHandle },
                                        seller: {
                                            logoSrc,
                                            username,
                                            verifiedEmail,
                                            verifiedPhone,
                                            newTrader,
                                        },
                                    } = item;

                                    const isSfcCoinSell = [SFC].includes(coinSell);
                                    const isSfcCoinBuy = [SFC].includes(coinBuy);

                                    let tradeModalStyle = '';
                                    let tradeModalLabelStyle = '';

                                    if (isSfcCoinSell || isSfcCoinBuy) {
                                        tradeModalStyle = classNames(
                                            style.tradeModal__input,
                                            style.tradeModal__input_disabled,
                                        );
                                        tradeModalLabelStyle = classNames(
                                            style.tradeModal__label,
                                            style.tradeModal__label_disabled,
                                        );
                                    } else {
                                        tradeModalStyle = style.tradeModal__input;
                                        tradeModalLabelStyle = style.tradeModal__label;
                                    }

                                    let orderTypeText = '';
                                    let userTradeText = '';
                                    let submitBtnText = '';
                                    let submitBtnStyle = '';

                                    if (orderType === BUY) {
                                        orderTypeText = t('general.sell');
                                        userTradeText = t('general.youAreSellingFrom');
                                        submitBtnText = t('general.sell');
                                        submitBtnStyle = style.tradeModal__sellSubmitBtn;
                                    } else if (orderType === SELL) {
                                        orderTypeText = t('general.buy');
                                        userTradeText = t('general.youAreBuyingFrom');
                                        submitBtnText = t('general.buy');
                                        submitBtnStyle = style.tradeModal__buySubmitBtn;
                                    } else if (orderType === EXCHANGE) {
                                        orderTypeText = t('general.exchange');
                                        userTradeText = t(
                                            'general.youAreExchangingFrom',
                                        );
                                        submitBtnText = t('general.exchange');
                                        submitBtnStyle = style.tradeModal__exchangeSubmitBtn;
                                    }

                                    let fixedRate = '';

                                    if (orderType !== EXCHANGE) {
                                        const fixed = parseFloat(rate).toFixed(5);
                                        const toNumber = +fixed;
                                        fixedRate = toNumber;
                                    } else {
                                        fixedRate = rate;
                                    }

                                    let total = '';
                                    if (orderType === EXCHANGE) {
                                        const totalTotal = parseFloat(+amount * +suggestedPrice).toFixed(8);
                                        total = totalTotal.replace(/0*$/, '');
                                    } else {
                                        const totalTotal = parseFloat(+amount * +suggestedPrice).toFixed(5);
                                        total = totalTotal.replace(/0*$/, '');
                                    }

                                    return (
                                        <Fragment key={id}>
                                            <h2 className={style.tradeModal__title}>
                                                {orderTypeText}{' '}
                                                {orderType === BUY ? coinBuy : coinSell}{' '}
                                                  /{' '}
                                                {orderType === BUY ? coinSell : coinBuy}{' '}
                                                {t('general.using')} {paymentMethod}{' '}
                                                {t('general.in')}{' '}
                                                {countries.map(itemCountry => {
                                                    const { name, value } = itemCountry;
                                                    return (
                                                        <Fragment key={value}>
                                                            {value === country
                                                                ? name
                                                                : ''}
                                                        </Fragment>
                                                    );
                                                })}{' '}
                                                {t('general.with')}{' '}
                                                {orderType === BUY ? coinSell : coinBuy}
                                            </h2>
                                            <div onClick={this.closeModalWindow}>
                                                <img
                                                    src={close}
                                                    className={style.tradeModal__close}
                                                    alt="close"
                                                />
                                            </div>
                                            <div
                                                className={style.tradeModal__namePrice}
                                            >
                                                <div
                                                    className={
                                                        style.tradeModal__namePrice_nameWrapper
                                                    }
                                                >
                                                    <Img
                                                        className={
                                                            style.tradeModal__logo
                                                        }
                                                        src={logoSrc}
                                                        userName={username}
                                                        alt="logo"
                                                    />
                                                    <p
                                                        className={
                                                            style.tradeModal__namePrice_name
                                                        }
                                                    >
                                                        {userTradeText}
                                                        <br />
                                                        <span>{username}</span>
                                                    </p>
                                                </div>

                                                <div
                                                    className={
                                                        style.tradeModal__namePrice_priceWrapper
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            style.tradeModal__namePrice_price
                                                        }
                                                    >
                                                        {t('general.price')}: <br />
                                                        <span>
                                                            {fixedRate}{' '}
                                                            {orderType === BUY
                                                                ? coinSell
                                                                : coinBuy}{' '}
                                                              = 1{' '}
                                                            {orderType === BUY
                                                                ? coinBuy
                                                                : coinSell}
                                                        </span>
                                                    </p>
                                                    <br />
                                                </div>
                                            </div>
                                            <div className={style.tradeModal__userInfo}>
                                                {newTrader ? (
                                                    <div
                                                        className={
                                                            style.tradeModal__newTrader
                                                        }
                                                    >
                                                        <img
                                                            src={ok}
                                                            className={
                                                                style.tradeModal__newTrader_ok
                                                            }
                                                            alt="ok"
                                                        />
                                                        <span>
                                                            {t('general.newTrader')}
                                                        </span>
                                                    </div>
                                                ) : null}
                                                <div
                                                    className={
                                                        style.tradeModal__newTrader
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            verifiedEmail
                                                                ? done
                                                                : redClose
                                                        }
                                                        className={
                                                            style.tradeModal__newTrader_done
                                                        }
                                                        alt="done"
                                                    />
                                                    <span>
                                                        {t('general.verifiedEmail')}
                                                    </span>
                                                </div>
                                                <div
                                                    className={
                                                        style.tradeModal__newTrader
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            verifiedPhone
                                                                ? done
                                                                : redClose
                                                        }
                                                        className={
                                                            style.tradeModal__newTrader_done
                                                        }
                                                        alt="done"
                                                    />
                                                    <span>
                                                        {t('general.verifiedPhone')}
                                                    </span>
                                                </div>
                                            </div>
                                            <form className={style.tradeModal__form}>
                                                <div
                                                    className={
                                                        style.tradeModal__inputWrapper
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            style.tradeModal__inputItem
                                                        }
                                                    >
                                                        <Field
                                                            id="amount"
                                                            type="text"
                                                            placeholder={t(
                                                                'general.input',
                                                            )}
                                                            name="amount"
                                                            value={amount}
                                                            onChange={
                                                                this.InputOnchange
                                                            }
                                                            error={amountError}
                                                            inputStyle={
                                                                style.tradeModal__input
                                                            }
                                                            labelText={t(
                                                                'general.amount',
                                                            )}
                                                            labelStyle={
                                                                style.tradeModal__label
                                                            }
                                                            min={parseFloat(
                                                                smallestAmount,
                                                                16,
                                                            )}
                                                            max={parseFloat(
                                                                sellAmount,
                                                                16,
                                                            )}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            style.tradeModal__inputItem
                                                        }
                                                    >
                                                        <Field
                                                            id="suggestedPrice"
                                                            type="text"
                                                            placeholder={t(
                                                                'general.input',
                                                            )}
                                                            name="suggestedPrice"
                                                            value={suggestedPrice}
                                                            onChange={isSfcCoinSell || isSfcCoinBuy
                                                                ? () => {} : this.InputOnchange
                                                            }
                                                            error={suggestedPriceError}
                                                            inputStyle={tradeModalStyle}
                                                            labelText={t(
                                                                'general.suggestYourPrice',
                                                            )}
                                                            labelStyle={tradeModalLabelStyle}
                                                            min={parseFloat(
                                                                smallestAmount,
                                                                16,
                                                            )}
                                                            max={parseFloat(
                                                                sellAmount,
                                                                16,
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={style.tradeModal__inputWrapper}
                                                >
                                                    <Field
                                                        id="total"
                                                        type="number"
                                                        name="paymentMethodNumber"
                                                        value={+total}
                                                        disabled
                                                        inputStyle="newOrder__wrapper_input disabled width100"
                                                        labelText={t('general.total')}
                                                        labelStyle="filterBlock-select__wrapper_label"
                                                    />
                                                </div>
                                                {orderType === BUY
                                                  && paymentMethodHandle === BANK_TRANSFER ? (
                                                        <Fragment>
                                                            <div
                                                                className={
                                                                    style.tradeModal__form_select
                                                                }
                                                            >
                                                                <p className="filterBlock-select__title">
                                                                    {t(
                                                                        'newOrder.chooseYourCard',
                                                                    )}
                                                                </p>
                                                                {cardList.length > 0 ? (
                                                                    <Select
                                                                        className="filterBlock-select"
                                                                        placeholder={t(
                                                                            'general.choose',
                                                                        )}
                                                                        onChange={
                                                                            this
                                                                                .selectOnChange
                                                                        }
                                                                        value={
                                                                            cardId
                                                                          || undefined
                                                                        }
                                                                    >
                                                                        {cardList.map(
                                                                            items => {
                                                                                const {
                                                                                    cardNumber,
                                                                                    id: cardNumberId,
                                                                                } = items;
                                                                                return (
                                                                                    <Option
                                                                                        key={
                                                                                            cardNumberId
                                                                                        }
                                                                                        name="cardId"
                                                                                        value={
                                                                                            cardNumberId
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            cardNumber
                                                                                        }
                                                                                    </Option>
                                                                                );
                                                                            },
                                                                        )}
                                                                    </Select>
                                                                ) : (
                                                                    <Select
                                                                        className="filterBlock-select"
                                                                        placeholder={t(
                                                                            'general.choose',
                                                                        )}
                                                                    >
                                                                        <Option
                                                                            name="cardId"
                                                                            key="cardId"
                                                                        >
                                                                            <Link
                                                                                to={`${personalAreaPath}${profilePath}/${userId}`}
                                                                            >
                                                                                {t(
                                                                                    'settings.addCard',
                                                                                )}
                                                                            </Link>
                                                                        </Option>
                                                                    </Select>
                                                                )}
                                                                {cardIdError ? (
                                                                    <div
                                                                        className={
                                                                            style.inputWrapper__invalid
                                                                        }
                                                                    >
                                                                        {cardIdError}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </Fragment>
                                                    ) : null}

                                                {orderType === BUY
                                                  && paymentMethodHandle !== BANK_TRANSFER
                                                  && paymentMethodHandle !== CRYPTO_TRANSFER ? (
                                                        <div
                                                            className="filterBlock-select__wrapper-input
                                            newOrderField newOrder__row_paymentWindow paymentMethod"
                                                        >
                                                            <Field
                                                                id="paymentMethodNumber"
                                                                type="text"
                                                                placeholder={t(
                                                                    'general.typeHere',
                                                                )}
                                                                name="paymentMethodNumber"
                                                                value={paymentMethodNumber}
                                                                error={
                                                                    paymentMethodNumberError
                                                                }
                                                                onChange={
                                                                    this
                                                                        .paymentNumberChanges
                                                                }
                                                                inputStyle="newOrder__wrapper_input width100"
                                                                labelText={t(
                                                                    'newOrder.paymentSystemNumber',
                                                                )}
                                                                labelStyle="filterBlock-select__wrapper_label"
                                                            />
                                                        </div>
                                                    ) : null}
                                                <div
                                                    className={
                                                        style.tradeModal__buttonWrapper
                                                    }
                                                >
                                                    <Button
                                                        onClick={this.formSubmit(
                                                            smallestAmount,
                                                            sellAmount,
                                                            cardId,
                                                            orderType,
                                                            paymentMethodHandle,
                                                            paymentMethodNumber,
                                                        )}
                                                        disabled={isDisableSubmitBtn}
                                                        className={submitBtnStyle}
                                                        type="submit"
                                                    >
                                                        {submitBtnText}
                                                    </Button>
                                                </div>
                                            </form>
                                        </Fragment>
                                    );
                                })
                            : null}
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        modalTrade: {
            currentOrder,
            modalTrade,
            amount,
            suggestedPrice,
            cardId,
            errors: { amountError, suggestedPriceError, cardIdError },
        },
        getUserInfo: {
            userInfo: { id: userId },
        },
        getAllOrders: { orders },
    } = state;

    return {
        currentOrder,
        modalTrade,
        amount,
        suggestedPrice,
        amountError,
        suggestedPriceError,
        cardId,
        cardIdError,
        orders,
        userId,
    };
};

TradeModalWindow.defaultProps = {
    t: () => {},
    dispatch: () => {},
    history: {},
    modalTrade: false,
    cardList: [],
    amount: '',
    suggestedPrice: '',
    amountError: '',
    suggestedPriceError: '',
    cardIdError: '',
    userId: '',
    currentOrder: 1,
    orders: {},
};

TradeModalWindow.propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.func,
    history: PropTypes.object,
    modalTrade: PropTypes.bool,
    cardList: PropTypes.any,
    amount: PropTypes.any,
    suggestedPrice: PropTypes.any,
    amountError: PropTypes.string,
    suggestedPriceError: PropTypes.string,
    userId: PropTypes.any,
    cardIdError: PropTypes.string,
    currentOrder: PropTypes.number,
    orders: PropTypes.object,
};

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter,
)(TradeModalWindow);
