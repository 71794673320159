import ordersConstants from '../constants/orders.constants';

const getAllOrdersRequested = () => ({
    type: ordersConstants.FETCH_ALL_ORDERS_REQUEST,
});

const getAllOrdersLoaded = data => ({
    type: ordersConstants.FETCH_ALL_ORDERS_SUCCESS,
    payload: data,
});

const getAllOrdersError = error => ({
    type: ordersConstants.FETCH_ALL_ORDERS_FAILURE,
    payload: error,
});

const getAllOrdersAction = getService => params => dispatch => {
    dispatch(getAllOrdersRequested());
    getService
        .getAllOrders(params)
        .then(data => dispatch(getAllOrdersLoaded(data)))
        .catch(err => dispatch(getAllOrdersError(err)));
};

export default getAllOrdersAction;
