import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FilterBlock from '../../../../layouts/filter-block';
import TradeModalWindow from '../../../../layouts/trade-modal-window';
import TableRow from '../../../../small-components/table-row';
import EmptyFilterResult from '../../../../small-components/emptyFilterResult';
import PaginationComponent from '../../../../layouts/pagination';
import { personalAreaPath, newOrder } from '../../../../../constants';
import Spinner from '../../../../spinner';

import style from './p2p-page.module.scss';
import 'rc-pagination/assets/index.css';

const P2PView = ({
    orders, loading, cardList, openTrade = () => {},
}) => {
    const { t } = useTranslation();
    return (
        <div className={style.p2pPage}>
            <div className={style.orders}>
                <div className={style.mobileNewOrder}>
                    <p className={style.orders__title}>P2P</p>
                    <Link
                        to={`${personalAreaPath}${newOrder}`}
                        className={style.mobileNewOrder_link}
                    >
                        {t('orders.new')}
                    </Link>
                </div>
                <FilterBlock />
                {loading ? (
                    <Spinner />
                ) : (
                    <div>
                        {orders.items.length >= 1 ? (
                            <Fragment>
                                {orders.items.map(item => {
                                    const {
                                        id,
                                        smallestAmount,
                                        sellAmount,
                                        rate,
                                        isSell,
                                        coinBuy: { handle: coinBuy },
                                        coinSell: { handle: coinSell },
                                        orderType: { handle: orderType },
                                        country: { handle: country },
                                        paymentMethod: { name: paymentMethod },
                                        seller: {
                                            id: userId,
                                            fast,
                                            logoSrc,
                                            rating,
                                            username,
                                            online,
                                        },
                                    } = item;

                                    const status = true;
                                    const speed = true;

                                    return (
                                        <Fragment key={id}>
                                            <TableRow
                                                userName={username}
                                                raiting={parseInt(rating, 16)}
                                                transfer={orderType}
                                                country={country}
                                                limitsFrom={smallestAmount}
                                                limitsTo={sellAmount}
                                                cost={rate}
                                                buy={isSell}
                                                logoSrc={logoSrc}
                                                status={status}
                                                speed={speed}
                                                online={online}
                                                fast={fast}
                                                coinBuy={coinBuy}
                                                coinSell={coinSell}
                                                userId={userId}
                                                paymentMethod={paymentMethod}
                                                onClick={() => openTrade(id)}
                                            />
                                        </Fragment>
                                    );
                                })}
                            </Fragment>
                        ) : (
                            <EmptyFilterResult />
                        )}
                    </div>
                )}
            </div>

            {orders.totalCount > 10 ? (
                <PaginationComponent totalCount={orders.totalCount} />
            ) : null}

            <TradeModalWindow cardList={cardList} />
        </div>
    );
};

P2PView.defaultProps = {
    orders: {},
    openTrade: () => {},
    loading: true,
    cardList: [],
};

P2PView.propTypes = {
    orders: PropTypes.object,
    openTrade: PropTypes.func,
    loading: PropTypes.bool,
    cardList: PropTypes.any,
};

export default P2PView;
