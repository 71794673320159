import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import ReactWOW from 'react-wow';
import { ArrowPrev, ArrowNext } from '../../small-components/slick-arrows';

import Img from '../../small-components/img';

import style from './slider-with-right-side-text.module.scss';
import './slider-with-right-side-text.scss';

const SliderWithRightSideText = props => {
    const {
        id, title, subtitle, slickImg, setCoinToFilter,
    } = props;

    const slickSettings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 700,
        variableWidth: true,
        prevArrow: <ArrowPrev />,
        nextArrow: <ArrowNext />,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    variableWidth: false,
                },
            },
        ],
    };

    return (
        <Fragment>
            <ReactWOW animation="fadeInUp" delay="0.05s">
                <div className={style.workWith} id={id}>
                    <div className={style.workWith__leftSide}>
                        <p className={style.workWith__leftSide_title}>{title}</p>
                        <p className={style.workWith__leftSide_subTitle}>{subtitle}</p>
                    </div>
                    <div className={style.workWith__rightSide}>
                        <Slider {...slickSettings} className="slickSlider">
                            {slickImg.map(item => {
                                const { img, alt } = item;
                                return (
                                    <div onClick={() => setCoinToFilter(alt)} key={alt}>
                                        <Img
                                            className={
                                                style.workWith__rightSide_sliderImg
                                            }
                                            src={img}
                                            alt={alt}
                                        />
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </ReactWOW>
        </Fragment>
    );
};

SliderWithRightSideText.defaultProps = {
    id: '',
    title: '',
    subtitle: '',
    slickSettings: {},
    slickImg: [],
    setCoinToFilter: () => {},
};

SliderWithRightSideText.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    slickSettings: PropTypes.object,
    slickImg: PropTypes.instanceOf(Array),
    setCoinToFilter: PropTypes.func,
};

export default SliderWithRightSideText;
