import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ReactWOW from 'react-wow';

import Img from '../../../small-components/img';

import lowFees from './images/low-fees.svg';
import secure from './images/secure-escrow.svg';
import KYC from './images/strong-KYC.svg';
import cloudFlare from './images/cloudFlare.svg';
import worldWide from './images/worldWide.svg';
import fiatOperations from './images/fiatOperations.svg';
import style from './advantages.module.scss';

const Advantages = () => {
    const { t } = useTranslation();

    const images = [
        {
            advantages: KYC,
            alt: 'KYC',
            title: t('advantages.kyc'),
        },
        {
            advantages: secure,
            alt: 'secure',
            title: t('advantages.protection'),
        },
        {
            advantages: lowFees,
            alt: 'lowFees',
            title: t('advantages.fees'),
        },
        {
            advantages: cloudFlare,
            alt: 'cloudFlare',
            title: t('advantages.cloudFlare'),
        },
        {
            advantages: worldWide,
            alt: 'worldWide',
            title: t('advantages.worldWide'),
        },
        {
            advantages: fiatOperations,
            alt: 'fiatOperations',
            title: t('advantages.fiatOperations'),
        },
    ];

    return (
        <Fragment>
            <ReactWOW animation="fadeInUp" delay="0.05s">
                <div className={style.advantages}>
                    <p className={style.advantages__title}>{t('advantages.title')}</p>
                    <div className={style.advantages__wrapper}>
                        {images.map(item => {
                            const { advantages, alt, title } = item;
                            return (
                                <div key={alt} className={style.advantages__wrapper_item}>
                                    <Img
                                        className={style.advantages__wrapper_img}
                                        src={advantages}
                                        alt={alt}
                                    />
                                    {title}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </ReactWOW>
        </Fragment>
    );
};

export default Advantages;
