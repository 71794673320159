/* eslint-disable */
import { dashboardConstants } from '../constants';

export function currentDashboard(state, action) {
    if (state === undefined) {
        return {
            currentDashboard: '',
        };
    }
    switch (action.type) {
        case dashboardConstants.SELECT_DASHBOARD:
            return {
                currentDashboard: action.id,
            };
        default:
            return state;
    }
}
