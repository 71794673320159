import React from 'react';

const UploadSvg = () => (
    <svg
        width="39"
        height="46"
        viewBox="0 0 39 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="verification__upload_svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 21.4653C19.2091 21.4653 21 19.6328 21 17.3723C21 15.1118 19.2091 13.2793 17 13.2793C14.7909
                13.2793 13 15.1118 13 17.3723C13 19.6328 14.7909 21.4653 17 21.4653Z"
            stroke="#92969C"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 26.2404C11 24.7335 12.4328 23.5117 14.2 23.5117H19.8C21.5672 23.5117 23 24.7335 23
                26.2404V27.6047H11V26.2404Z"
            stroke="#92969C"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M33.1626 30.3851V10.807C33.1626 9.73003 32.7901 8.68667 32.111 7.8616L27.8072 2.63411C26.9545
                1.59839 25.6964 1 24.371 1H5.4878C3.00904 1 1 3.05008 1 5.57949V35.3462C1 37.8756 3.00904
                39.9257 5.4878 39.9257H24.9664"
            stroke="#92969C"
            strokeLinecap="round"
        />
        <path d="M31.667 40.689V34.583" stroke="#92969C" strokeLinecap="round" />
        <path d="M34.6576 37.6355H28.6738" stroke="#92969C" strokeLinecap="round" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.6673 44.5051C35.3851 44.5051 38.399 41.4296 38.399 37.6358C38.399 33.8421 35.3851
                30.7666 31.6673 30.7666C27.9494 30.7666 24.9355 33.8421 24.9355 37.6358C24.9355 41.4296
                27.9494 44.5051 31.6673 44.5051Z"
            stroke="#92969C"
        />
    </svg>
);

export default UploadSvg;
