/* eslint-disable */
import { authModalConstants } from '../constants';

export function activeFeedback(state, action) {
    if (state === undefined) {
        return {
            showFeedback: false,
        };
    }
    switch (action.type) {
        case authModalConstants.ACTIVE_FEEDBACK:
            return {
                showFeedback: true,
            };

        default:
            return state;
    }
}
