import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Questions from '../home-page/questions';

import style from './contact-page.module.scss';

const ContactPage = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="filter">
                <div className={style.contactPage}>
                    <div className={style.contactPage__leftSide}>
                        <h3 className={style.contactPage__leftSide_title}>
                            {t('contact.title')}
                        </h3>
                        <div className={style.contactPage__subtitleWrapper}>
                            <p className={style.contactPage__leftSide_subtitle}>
                                {t('contact.subtitle1')}
                            </p>
                            <p className={style.contactPage__leftSide_subtitle}>
                                {t('contact.subtitle2')}
                            </p>
                        </div>
                    </div>
                    <div className={style.contactPage__rightSide}>
                        <div className={style.contactPage__rightSide_item}>
                            <p className={style.contactPage__rightSide_label}>
                                {t('contact.troublePayments')}
                            </p>
                            <a
                                href="mailto:p2p.support@crocobit.net"
                                className={style.contactPage__rightSide_mail}
                            >
                            p2p.support@crocobit.net
                            </a>
                            <p className={style.contactPage__rightSide_contactInfo}>
                                {/*Harju maakond,*/}
                                {/*<span>Tallinn,</span> Peterburi tee 47, 11415*/}
                            </p>
                        </div>
                        <div className={style.contactPage__rightSide_item}>
                            <p className={style.contactPage__leftSide_subtitleMobile}>
                                {t('contact.subtitle2')}
                            </p>
                            <p className={style.contactPage__rightSide_label}>
                                {t('contact.partnership')}
                            </p>
                            <a
                                href="mailto:info@crocobit.net"
                                className={style.contactPage__rightSide_mail}
                            >
                                info@crocobit.net
                            </a>
                            <div className={style.contactPage__rightSide_contactInfo}>
                                {/*<p>“ECRO chain”, registry code 14164797</p>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <Questions />
            </div>
        </Fragment>
    );
};

export default ContactPage;
