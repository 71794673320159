import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ParallaxProvider } from 'react-scroll-parallax';

import App from './components/app';
import ErrorBoundry from './components/pages/error-page/error-boundry';
import Spinner from './components/spinner';
import GetService from './services/get-service';
import { GetServiceProvider } from './components/get-service-context';
import store from './helpers/store';
import './i18n';

import './components/app/app.scss';

const getService = new GetService();

ReactDOM.render(
    <Provider store={store}>
        <GetServiceProvider value={getService}>
            <Suspense fallback={<Spinner />}>
                <ErrorBoundry>
                    <ParallaxProvider>
                        <App />
                    </ParallaxProvider>
                </ErrorBoundry>
            </Suspense>
        </GetServiceProvider>
    </Provider>,
    document.getElementById('root'),
);
