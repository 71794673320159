import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FooterSite from './footer-site';
import FooterPersonalArea from './footer-personal-area';

import { personalAreaPath } from '../../../constants';
import { compose } from '../../../utils';

const Footer = ({ loggedIn }) => {
    const pathName = window.location.pathname;
    const matchPathName = pathName.split('/')[1];

    const activeFooter =
        `/${matchPathName}` === personalAreaPath ? (
            <FooterPersonalArea />
        ) : (
            <FooterSite />
        );

    if (!loggedIn) {
        return <Fragment>{activeFooter}</Fragment>;
    }

    return activeFooter;
};

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
    } = state;

    return {
        loggedIn,
    };
};

Footer.defaultProps = {
    loggedIn: false,
};

Footer.propTypes = {
    loggedIn: PropTypes.bool,
};

export default compose(
    connect(mapStateToProps),
    withRouter,
)(Footer);
