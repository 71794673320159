/* eslint-disable */

import { userCountryConstants } from '../constants';

function getCurrentLocale(locale) {
    return {
        type: userCountryConstants.GET_CURRENT_LOCALE,
        locale,
    };
}

export const getCurrentLocaleActions = {
    getCurrentLocale,
};
