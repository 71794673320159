import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { connect } from 'react-redux';

import { authModalActions } from '../../../actions';
import Spinner from '../../spinner';
import ErrorIndicator from '../error-page/error-indicator';
import { compose } from '../../../utils';
import PostService from '../../../services/post-service';

export class ConfirmationDevicesPage extends Component {
    postService = new PostService();

    static defaultProps = {
        t: () => {},
        openLogin: () => {},
        match: {},
        history: {},
    };

    static propTypes = {
        t: PropTypes.func,
        openLogin: PropTypes.func,
        match: PropTypes.object,
        history: PropTypes.object,
    };

    state = {
        loading: true,
        error: false,
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const {
            t, match, history, openLogin = () => {},
        } = this.props;
        const {
            params: { id },
        } = match;

        this.postService
            .confirmationDevice(id)
            .then(() => {
                this.setState({
                    loading: false,
                    error: false,
                });
                message.success(t('general.successfulConfirmation'), 5);
                history.push('/');
                openLogin();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: true,
                });

                message.error(err, 5);
            });
    };

    render() {
        const { loading, error } = this.state;

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
    } = state;

    return {
        loggedIn,
    };
};

const mapDispatchToProps = dispatch => ({
    openLogin: () => {
        dispatch(authModalActions.openLogin());
    },
});

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)(ConfirmationDevicesPage);
