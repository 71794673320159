import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ChatView from './chat-page-view';
import { compose } from '../../../../../utils';
import GetService from '../../../../../services/get-service';
import Spinner from '../../../../spinner';
import ErrorIndicator from '../../../error-page/error-indicator';
import { feedbackModalActions } from '../../../../../actions';
import {
    ACCEPT_OFFER,
    SEND_MESSAGE,
    FRONTEND_STATUS,
    OFFER_CREATE,
    TRADE_CLOSED,
    ARBITER_PAYMENT_DECLINE,
} from '../../../../../constants';

export class ChatContainer extends Component {
    mounted = true;

    getService = new GetService();

    scrollBottomChatRef = React.createRef();

    state = {
        chatData: {},
        chatStatus: '',
        chatMessages: [],
        userInfo: {
            countTrades: null,
            fast: false,
            logoSrc: null,
            newTrader: true,
            online: false,
            rating: null,
            username: '',
            verifiedEmail: true,
            verifiedPhone: true,
            lastSeen: null,
        },
        guestInfo: {
            countTrades: null,
            fast: false,
            logoSrc: null,
            newTrader: true,
            online: false,
            rating: null,
            username: '',
            verifiedEmail: true,
            verifiedPhone: true,
            lastSeen: null,
        },
        order: {
            cartHolder: null,
            coinBuy: { handle: '' },
            coinSell: { handle: '' },
            country: { handle: '' },
            creditCart: null,
            orderType: { handle: '' },
            rate: null,
            sellAmount: null,
            timeTrades: null,
        },
        userName: '',
        loading: true,
        error: false,
    };

    componentDidMount() {
        this.uploadTradeStatus();
        this.receiveChatMessages();
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const { match, locale } = this.props;

        const {
            params: { id: matchId },
        } = match;
        if (matchId !== prevProps.match.params.id) {
            this.uploadTradeStatus();
            this.receiveChatMessages();
            this.loadData();
        }

        if (locale !== prevProps.locale) {
            this.receiveChatMessages();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    connectSocket = () => {
        const {
            chatData: { socketRoomHash },
        } = this.state;
        const {
            isSocketConnect,
            emit: socket,
            dispatch,
            userInfo: currentUserInfo,
        } = this.props;

        if (isSocketConnect) {
            socket.emit('join', socketRoomHash);

            socket.on(SEND_MESSAGE, data => {
                this.translateAutoMessages([data]);
            });

            socket.on(FRONTEND_STATUS, data => {
                const { order } = this.state;

                const { frontendStatus, timeToStartTrades } = data;
                if (this.mounted) {
                    this.setState({
                        chatStatus: frontendStatus,
                    });
                }

                if (ACCEPT_OFFER === frontendStatus) {
                    if (this.mounted) {
                        this.setState({
                            order: {
                                ...order,
                                timeToStartTrades,
                            },
                        });
                    }
                }

                if (
                    frontendStatus === TRADE_CLOSED
                    || frontendStatus === ARBITER_PAYMENT_DECLINE
                ) {
                    const { chatData } = this.state;
                    if (chatData.arbiter === null) {
                        dispatch(feedbackModalActions.openFeedback());
                    } else if (currentUserInfo.id !== chatData.arbiter.id) {
                        dispatch(feedbackModalActions.openFeedback());
                    }
                }
            });

            socket.on(OFFER_CREATE, data => {
                const { suggestedRate, frontendStatus } = data;
                const { chatData } = this.state;
                if (this.mounted) {
                    this.setState({
                        chatData: {
                            ...chatData,
                            suggestedRate,
                        },
                    });
                }

                if (frontendStatus) {
                    if (this.mounted) {
                        this.setState({
                            chatStatus: frontendStatus,
                        });
                    }
                }
            });
        }
    };

    // Scroll to bottom chat
    scrollToBottom = () => {
        const { current } = this.scrollBottomChatRef;
        if (current !== null) {
            current.scrollIntoView({
                inline: 'center',
                block: 'center',
            });
        }
    };

    // Receive chat messages
    receiveChatMessages = () => {
        const { match } = this.props;
        const {
            params: { id: matchChat },
        } = match;

        this.setState(
            {
                chatMessages: [],
            },
            () => {
                this.getService
                    .getChatMessages(matchChat)
                    .then(chatMessages => {
                        this.translateAutoMessages(chatMessages);
                    })
                    .catch(this.onError);
            },
        );
    };

    translateAutoMessages = data => {
        const { chatMessages } = this.state;
        const { t } = this.props;
        const copyChatMessages = Object.assign([], chatMessages);

        data.map(item => {
            if (item.isAutogenerated) {
                const { message } = item;
                const parseMessageObject = JSON.parse(message);
                const { message: constant, amount, rate } = parseMessageObject;
                if (constant === 'I_WANT_TO_BUY') {
                    const autogeneratedMessages = {
                        ...item,
                        message: t('autogeneratedMessages.i_want_to_buy', {
                            amount,
                            rate,
                        }),
                    };
                    copyChatMessages.push(autogeneratedMessages);
                }
                if (constant === 'I_WANT_TO_SELL') {
                    const autogeneratedMessages = {
                        ...item,
                        message: t('autogeneratedMessages.i_want_to_sell', {
                            amount,
                            rate,
                        }),
                    };
                    copyChatMessages.push(autogeneratedMessages);
                }
                if (constant === 'I_WANT_TO_EXCHANGE') {
                    const autogeneratedMessages = {
                        ...item,
                        message: t('autogeneratedMessages.i_want_to_exchange', {
                            amount,
                            rate,
                        }),
                    };
                    copyChatMessages.push(autogeneratedMessages);
                }
                if (constant === 'TRADE_STARTED') {
                    const autogeneratedMessages = {
                        ...item,
                        message: t('autogeneratedMessages.trade_started', {
                            amount,
                            rate,
                        }),
                    };
                    copyChatMessages.push(autogeneratedMessages);
                }
                if (constant === 'NO_THANKS') {
                    const autogeneratedMessages = {
                        ...item,
                        message: t('autogeneratedMessages.no_thanks'),
                    };
                    copyChatMessages.push(autogeneratedMessages);
                }
                if (constant === 'PAYMENT_FAILED') {
                    const autogeneratedMessages = {
                        ...item,
                        message: t('autogeneratedMessages.payment_failed'),
                    };
                    copyChatMessages.push(autogeneratedMessages);
                }
                if (constant === 'PAYMENT_SUCCESSFUL') {
                    const autogeneratedMessages = {
                        ...item,
                        message: t('autogeneratedMessages.payment_successful'),
                    };
                    copyChatMessages.push(autogeneratedMessages);
                }
                if (constant === 'PAYMENT_SENT') {
                    const autogeneratedMessages = {
                        ...item,
                        message: t('autogeneratedMessages.payment_sent'),
                    };
                    copyChatMessages.push(autogeneratedMessages);
                }
                if (constant === 'ORDER_COMPLETED_BY_ANOTHER_USER') {
                    const autogeneratedMessages = {
                        ...item,
                        message: t(
                            'autogeneratedMessages.order_completed_by_another_user',
                        ),
                    };
                    copyChatMessages.push(autogeneratedMessages);
                }
                if (constant === 'MOVED_CHAT_TO_ARBITRAGE') {
                    const autogeneratedMessages = {
                        ...item,
                        message: t('autogeneratedMessages.moved_chat_to_arbitrage'),
                    };
                    copyChatMessages.push(autogeneratedMessages);
                }
            } else {
                copyChatMessages.push(item);
            }
            return true;
        });
        if (this.mounted) {
            this.setState({
                chatMessages: copyChatMessages,
            });
        }
        this.scrollToBottom();
    };

    uploadTradeStatus = () => {
        const { match } = this.props;
        const {
            params: { id: matchChat },
        } = match;
        this.getService
            .getChatStatus(matchChat)
            .then(handle => {
                const {
                    frontendStatus: { handle: chatStatus },
                } = handle;
                if (this.mounted) {
                    this.setState({
                        chatStatus,
                    });
                }
            })
            .catch(this.onError);
    };

    loadData = () => {
        const { match } = this.props;
        const {
            params: { id: matchChat },
        } = match;
        const locale = localStorage.getItem('i18nextLngCrocobit');

        this.getService
            .getChatInfo(matchChat, locale)
            .then(chatData => {
                const {
                    userInfo: { username },
                } = this.props;
                if (this.mounted) {
                    this.setState({
                        chatData,
                        loading: false,
                        error: false,
                        userName: username,
                    });

                    this.filterUserInfo();
                    this.connectSocket();
                }
            })
            .catch(this.onError);
    };

    filterUserInfo = () => {
        const { chatData, userName } = this.state;
        const chatDataBuyer = chatData.buyer.username === userName ? chatData.buyer : chatData.seller;

        const chatDataGuest = chatData.buyer.username !== userName ? chatData.buyer : chatData.seller;

        if (this.mounted) {
            this.setState({
                userInfo: chatDataBuyer,
                guestInfo: chatDataGuest,
                order: chatData.order,
            });
        }
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const { userInfo: currentUserInfo } = this.props;
        const {
            chatData,
            loading,
            error,
            userInfo,
            guestInfo,
            order,
            userName,
            chatStatus,
            chatMessages,
        } = this.state;

        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <ChatView
                chatData={chatData}
                chatMessages={chatMessages}
                chatStatus={chatStatus}
                userInfo={userInfo}
                currentUserInfo={currentUserInfo}
                guestInfo={guestInfo}
                order={order}
                userName={userName}
                scrollBottomChatRef={this.scrollBottomChatRef}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        getUserInfo: { userInfo },
        socketConnected: { isSocketConnect, emit },
        getCurrentLocale: { locale },
    } = state;

    return {
        userInfo,
        isSocketConnect,
        emit,
        locale,
    };
};

ChatContainer.defaultProps = {
    t: () => {},
    dispatch: () => {},
    match: {},
    userInfo: {},
    isSocketConnect: false,
    emit: {},
    locale: {},
};

ChatContainer.propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.func,
    match: PropTypes.object,
    userInfo: PropTypes.object,
    isSocketConnect: PropTypes.bool,
    emit: PropTypes.object,
    locale: PropTypes.any,
};

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter,
)(ChatContainer);
