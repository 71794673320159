/* eslint-disable */

import { authModalConstants } from '../constants';

function openFeedback() {
    return {
        type: authModalConstants.OPEN_FEEDBACK_MODAL
    };
}

function closeFeedback() {
    return {
        type: authModalConstants.CLOSE_FEEDBACK_MODAL
    };
}

export const feedbackModalActions = {
    openFeedback,
    closeFeedback,
};
