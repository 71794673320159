/* eslint-disable */
import { authModalConstants } from '../constants';

export function authModal(state, action) {
    if (state === undefined) {
        return {
            visibleLogin: false,
            login: false,
            signUp: false
        };
    }
    switch (action.type) {
        case authModalConstants.OPEN_LOGIN:
            return {
                visibleLogin: true,
                login: true,
                signUp: false
            };

        case authModalConstants.OPEN_SIGNUP:
            return {
                visibleLogin: true,
                login: false,
                signUp: true
            };

        case authModalConstants.CLOSE_AUTH_MODAL:
            return {
                visibleLogin: false,
                login: false,
                signUp: false
            };

        default:
            return state;
    }
}
