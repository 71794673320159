/* eslint-disable */
// import { authHeader } from '../helpers';
import * as moment from 'moment';
import { message } from 'antd';
import i18n from '../i18n';
import {
    clientId,
    clientSecret,
    REQUIRED_2FA_CODE,
    DEVICE_NOT_TRUSTED,
    USER_NOT_CONFIRMED,
    TOO_MANY_REQUESTS,
    USER_BLOCKED,
    INVALID_2FA_CODE,
    UNABLE_TO_FIND_LEADER,
    WRONG_LEADER_EMAIL,
} from '../constants';
import { setStatus, resetStatus } from '../reducers/jwt.reducer';
import { userActions, authModalActions } from '../actions';
import store from '../helpers';

function logout() {
    store.dispatch(userActions.logout());
}

let userJson = JSON.parse(localStorage.getItem('user'));

const getToken = () => {
    const userJson = JSON.parse(localStorage.getItem('user'));
    return userJson.refresh_token;
};

const getCurrentLang = () => localStorage.getItem('i18nextLngCrocobit').toLowerCase();

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        const handledErrors = [
            DEVICE_NOT_TRUSTED,
            USER_NOT_CONFIRMED,
            TOO_MANY_REQUESTS,
            USER_BLOCKED,
            REQUIRED_2FA_CODE,
            INVALID_2FA_CODE,
            UNABLE_TO_FIND_LEADER,
            WRONG_LEADER_EMAIL,
        ];

        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }

            if (response.status === 500 && !handledErrors.includes(data.error)) {
                message.error(data.error, 5);
            }

            if (response.status === 500 && data.error === UNABLE_TO_FIND_LEADER) {
                message.error(i18n.t('referralSystem.couldNotFindLeader'), 5);
            }

            if (response.status === 500 && data.error === WRONG_LEADER_EMAIL) {
                message.error(i18n.t('referralSystem.couldNotFindLeader'), 5);
            }

            if (response.status === 500 && data.error === DEVICE_NOT_TRUSTED) {
                message.success(i18n.t('auth.confirmNewBrowser'), 5);
            }

            if (response.status === 500 && data.error === USER_NOT_CONFIRMED) {
                message.error(i18n.t('auth.userNotVerifiedCheckMail'), 5);
            }

            if (response.status === 500 && data.error === REQUIRED_2FA_CODE) {
                message.success(i18n.t('general.checkEmail'), 5);
            }

            if (response.status === 500 && data.error === INVALID_2FA_CODE) {
                message.error(i18n.t('error.code'), 5);
            }

            if (
                response.status === 400 &&
                data.error !== REQUIRED_2FA_CODE &&
                data.error !== TOO_MANY_REQUESTS
            ) {
                message.error(
                    data.error_description ? data.error_description : data.error,
                    5,
                );
            }

            if (response.status === 400 && data.error === TOO_MANY_REQUESTS) {
                message.error(
                    i18n.t('auth.tooManyRequestsToRestore', { count: data.minutes }),
                    5,
                );
            }

            if (response.status === 423) {
                restoreAccount();
            }
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function restoreAccount() {
    const {
        userLoginName: { userName },
    } = store.getState();
    const data = {
        username: userName,
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(data),
    };

    return fetch(
        `${process.env.REACT_APP_API_URL}/public/user/request/restore`,
        requestOptions,
    )
        .then(handleResponse)
        .then(user => {
            message.success(i18n.t('general.checkMailAndFollow'), 5);
        })
        .catch(err => {
            if (err === USER_BLOCKED) {
                message.error(i18n.t('error.userBlocked'), 5);
            }
        });
}

function login(username, password) {
    const data = {
        grant_type: 'password',
        client_id: clientId,
        client_secret: clientSecret,
        username,
        password,
        language: getCurrentLang(),
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(data),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/oauth/v2/token`, requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('updateTokenTime', moment().unix());
            return user;
        });
}

function twoFactorAuthorization(twoAuthCode, username, password) {
    const data = {
        grant_type: 'password',
        authorization_code: twoAuthCode,
        client_id: clientId,
        client_secret: clientSecret,
        password,
        username,
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(data),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/oauth/v2/token`, requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('updateTokenTime', moment().unix());
            return user;
        });
}

function register(user, dispatch) {
    const data = {
        ...user,
        language: getCurrentLang(),
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/public/register`, requestOptions)
        .then(handleResponse)
        .then(data => {
            message.success(i18n.t('general.successfulRegistration'), 5);
            store.dispatch(authModalActions.closeModal());
            return data;
        });
}

function refreshToken(dispatch) {
    const data = {
        grant_type: 'refresh_token',
        client_id: clientId,
        client_secret: clientSecret,
        refresh_token: getToken(),
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(data),
    };

    const response = fetch(
        `${process.env.REACT_APP_API_URL}/oauth/v2/token`,
        requestOptions,
    );
    response
        .then(handleResponse)
        .then(user => {
            store.dispatch(resetStatus());
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('updateTokenTime', moment().unix());
            return user.access_token
                ? Promise.resolve(store.dispatch(setStatus(null)))
                : Promise.reject(store.dispatch(setStatus(null)));
        })
        .catch(e => {
            console.log('error refreshing token', e);
            store.dispatch(userActions.logout());
            store.dispatch(resetStatus());
            return Promise.reject(e);
        });

    store.dispatch(setStatus(response));
    return response;
}

export const userService = {
    login,
    logout,
    register,
    twoFactorAuthorization,
};

export default refreshToken;
