/* eslint-disable */

import { filterConstants } from '../constants';

function selectFilter(payload) {
    return {
        type: filterConstants.SELECT_FILTER,
        payload,
    };
}

export const FilterActions = {
    selectFilter,
};
