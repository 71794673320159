/* eslint-disable */

import { chatConstants } from '../constants';

function selectChat(id) {
    return {
        type: chatConstants.SELECT_CHAT,
        id,
    };
}

export const chatActions = {
    selectChat,
};
