import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { compose } from '../../../../utils';
import { authModalActions } from '../../../../actions';
import TradeModalWindow from '../../../layouts/trade-modal-window';
import TableRow from '../../../small-components/table-row';
import { ordersPath } from '../../../../constants';
import EmptyFilterResult from '../../../small-components/emptyFilterResult';

import style from './orders.module.scss';

const TableRowView = ({
    orders,
    openLogin = () => {},
    openTrade = () => {},
    loggedIn,
    cardList,
}) => {
    const { t } = useTranslation();

    return (
        <div className={style.orders}>
            {orders.items.length >= 1 ? (
                <Fragment>
                    {orders.items.slice(0, 10).map(item => {
                        const {
                            id,
                            smallestAmount,
                            sellAmount,
                            rate,
                            coinBuy: { handle: coinBuy },
                            coinSell: { handle: coinSell },
                            orderType: { handle: orderType },
                            country: { handle: country },
                            seller: {
                                id: userId, fast, logoSrc, rating, username, online,
                            },
                            paymentMethod: { name: paymentMethod },
                        } = item;

                        return (
                            <Fragment key={id}>
                                <TableRow
                                    userName={username}
                                    raiting={parseInt(rating, 16)}
                                    transfer={orderType}
                                    country={country}
                                    limitsFrom={smallestAmount}
                                    limitsTo={sellAmount}
                                    cost={rate}
                                    logoSrc={logoSrc}
                                    status={loggedIn}
                                    speed={loggedIn}
                                    online={online}
                                    fast={fast}
                                    coinBuy={coinBuy}
                                    coinSell={coinSell}
                                    userId={userId}
                                    paymentMethod={paymentMethod}
                                    onClick={loggedIn ? () => openTrade(id) : () => openLogin()}
                                />
                            </Fragment>
                        );
                    })}
                </Fragment>
            ) : (
                <EmptyFilterResult />
            )}
            <Link to={ordersPath} className={style.orders__allOrdersButton}>
                {t('general.allOrders')}
            </Link>

            <TradeModalWindow cardList={cardList} />
        </div>
    );
};

TableRowView.defaultProps = {
    orders: {},
    openTrade: () => {},
    openLogin: () => {},
    loggedIn: false,
    cardList: [],
};

TableRowView.propTypes = {
    orders: PropTypes.object,
    openTrade: PropTypes.func,
    openLogin: PropTypes.func,
    loggedIn: PropTypes.bool,
    cardList: PropTypes.any,
};

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
    } = state;

    return {
        loggedIn,
    };
};

const mapDispatchToProps = dispatch => ({
    openLogin: () => {
        dispatch(authModalActions.openLogin());
    },

    closeModal() {
        dispatch(authModalActions.closeModal());
    },
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(TableRowView);
