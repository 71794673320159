import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { message, Spin } from 'antd';
import { CSSTransition } from 'react-transition-group';

import Field from '../../small-components/field';
import { compose } from '../../../utils';
import Button from '../../small-components/button';
import { authModalActions, userActions } from '../../../actions';
import { REQUIRED_2FA_CODE, TOO_MANY_REQUESTS_TO_RESET_PASSWORD, USER_BLOCKED } from '../../../constants';
import PostService from '../../../services/post-service';

import close from '../../assets/images/close.svg';
import eye from '../../assets/images/eye.svg';
import style from './login.module.scss';

class Login extends Component {
    postService = new PostService();

    state = {
        username: '',
        password: '',
        twoAuthCode: '',
        type: 'password',
        passwordRecovery: '',
        showHideRecovery: false,
        is2FAEnabledState: false,
        errors: {
            usernameError: '',
            passwordError: '',
            twoAuthCodeError: '',
            passwordRecoveryError: '',
        },
    };


    componentDidMount() {
        const { loginError } = this.props;

        this.setState({
            is2FAEnabledState: false,
        });

        if (loginError === REQUIRED_2FA_CODE) {
            this.setState({
                is2FAEnabledState: true,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { loginError } = this.props;

        if (loginError !== prevProps.loginError && loginError === REQUIRED_2FA_CODE) {
            this.setState({
                is2FAEnabledState: true,
            });
        }
    }

    InputOnchange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    closeModal = () => {
        const { dispatch } = this.props;
        dispatch(authModalActions.closeModal());
    };

    LoginSubmit = event => {
        event.preventDefault();
        const {
            t, dispatch, history,
        } = this.props;
        const { username, password } = this.state;
        const errors = {};

        if (username.length < 4) {
            errors.usernameError = t('error.input', { count: 4 });
        }

        if (password.length < 5) {
            errors.passwordError = t('error.input', { count: 5 });
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
        } else {
            this.setState({
                errors: {},
            });

            if (username && password) {
                dispatch(userActions.login(username, password, history, t));
            }
        }
    };

    submitTwoFactorAuth = () => {
        const { t, dispatch, history } = this.props;
        const { twoAuthCode, username, password } = this.state;
        const errors = {};

        if (twoAuthCode.length < 4) {
            errors.twoAuthCodeError = t('error.input', { count: 4 });
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
        } else {
            this.setState({
                errors: {},
                twoAuthCode: '',
            });

            if (twoAuthCode) {
                dispatch(userActions.twoFactorAuthorization(twoAuthCode, username, password, history, t));
            }
        }
    };

    showHidePassword = () => {
        const { type } = this.state;
        this.setState({
            type: type === 'password' ? 'text' : 'password',
        });
    };

    switchRegistr = () => {
        const { dispatch } = this.props;
        dispatch(authModalActions.openSignUp());
    };

    switchForgotPassword = () => {
        const { showHideRecovery } = this.state;
        this.setState({
            showHideRecovery: !showHideRecovery,
        });
    };

    passwordRecovery = event => {
        event.preventDefault();
        const { passwordRecovery, errors: errorsState } = this.state;
        const { t } = this.props;
        const errors = {};

        if (
            !!passwordRecovery === false
            || passwordRecovery.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null
        ) {
            errors.passwordRecoveryError = t('error.email');
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
        } else {
            this.setState({
                errors: {},
            });

            if (passwordRecovery) {
                const data = {
                    username: passwordRecovery,
                };
                this.postService
                    .passwordRecovery(data)
                    .then(response => {
                        const { timeToResend, success } = response;
                        if (timeToResend) {
                            message.error(t('general.tryIn', { seconds: timeToResend }), 5);
                        }
                        if (success === true) {
                            message.success(t('general.checkEmail'), 5);
                            this.setState({
                                passwordRecovery: '',
                                errors: {
                                    ...errorsState,
                                    passwordRecoveryError: '',
                                },
                            });
                        }
                    })
                    .catch(error => {
                        if (error === TOO_MANY_REQUESTS_TO_RESET_PASSWORD) {
                            message.error(t('error.tooManyPasswordRequests'), 5);
                        } else if (error === USER_BLOCKED) {
                            message.error(t('error.userBlocked'), 5);
                        }
                    });
            }
        }
    };

    passwordRecoveryOnChange = event => {
        this.setState({
            [event.target.name]: event.target.value.replace(/^\s+|\s+$/g, ''),
        });
    };

    checkEmail = event => {
        event.preventDefault();
        const { t } = this.props;
        message.success(t('general.checkEmail'), 5);
    }

    render() {
        const { t, loggingIn } = this.props;
        const {
            errors: {
                usernameError,
                passwordError,
                twoAuthCodeError,
                passwordRecoveryError,
            },
            username,
            password,
            twoAuthCode,
            type,
            passwordRecovery,
            showHideRecovery,
            is2FAEnabledState,
        } = this.state;

        const loginModalStyle = {
            opacity: '0.5',
            pointerEvents: 'none',
            userSelect: 'none',
            transition: 'all 0.25s',
        };

        const loadingStyle = loggingIn ? loginModalStyle : null;

        return (
            <Fragment>
                {loggingIn ? <Spin size="large" /> : null}
                <div className={style.login} style={loadingStyle}>
                    <h2 className={style.login__title}>{t('form.login')}</h2>
                    <div onClick={this.closeModal}>
                        <img src={close} className={style.login__close} alt="logo" />
                    </div>
                    <form className={style.login__form}>
                        <div className={style.login__inputWrapper}>
                            <Field
                                id="usernameLogin"
                                type="username"
                                placeholder={t('general.usernameOrEmail')}
                                name="username"
                                value={username}
                                onChange={this.InputOnchange}
                                error={usernameError}
                                inputStyle={style.login__input}
                                labelText={t('general.usernameOrEmail')}
                                labelStyle={style.login__label}
                            />
                        </div>
                        <div className={style.login__inputWrapper}>
                            <Field
                                id="password"
                                type={type}
                                placeholder={t('form.password')}
                                name="password"
                                value={password}
                                onChange={this.InputOnchange}
                                error={passwordError}
                                inputStyle={style.login__input}
                                labelText={t('form.password')}
                                labelStyle={style.login__label}
                            />
                            {password.length >= 1 ? (
                                <div
                                    onClick={this.showHidePassword}
                                    className={style.login__inputWrapper_eye}
                                >
                                    <img src={eye} alt="eye" />
                                </div>
                            ) : null}
                        </div>

                        <div className={style.login__buttonWrapper}>
                            <Button
                                id="login-button"
                                type="submit"
                                disabled={loggingIn}
                                className={style.login__button}
                                onClick={this.LoginSubmit}
                            >
                                {t('form.login')}
                            </Button>
                            <Button
                                id="registr-button"
                                type="button"
                                disabled={loggingIn}
                                className={style.login__switchRegistrButton}
                                onClick={this.switchRegistr}
                            >
                                {t('form.registration')}
                            </Button>
                        </div>

                        {is2FAEnabledState ? (
                            <div className={style.login__twoFactorAuth}>
                                <p className={style.login__twoFactorAuth_title}>
                                    {t('settings.verifyFromLetter')}
                                </p>
                                <div className={style.login__twoFactorAuth_wrapper}>
                                    <div className={style.login__twoFactorAuth_inputWrapper}>
                                        <Field
                                            id="twoAuthCode"
                                            type="text"
                                            placeholder={t('settings.inputCode')}
                                            name="twoAuthCode"
                                            value={twoAuthCode}
                                            onChange={this.InputOnchange}
                                            error={twoAuthCodeError}
                                            inputStyle={style.login__twoFactorAuth_input}
                                            labelText={t('settings.code')}
                                            labelStyle={style.login__twoFactorAuth_label}
                                        />
                                    </div>
                                    <Button
                                        id="twoFactorAuth"
                                        type="button"
                                        onClick={this.submitTwoFactorAuth}
                                        className={style.login__twoFactorAuth_submit}
                                    >
                                        {t('general.send')}
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </form>
                    <div className={style.login__passwordRecovery}>
                        <div
                            className={style.login__passwordRecovery_title}
                            onClick={this.switchForgotPassword}
                        >
                            {t('auth.forgotPassword')}
                        </div>
                        <CSSTransition
                            in={showHideRecovery}
                            timeout={400}
                            classNames="showHide-transition"
                            unmountOnExit
                            appear
                        >
                            <form
                                onSubmit={this.passwordRecovery}
                                className={style.login__passwordRecovery_form}
                            >
                                <Field
                                    id="passwordRecovery"
                                    type="email"
                                    placeholder="Email"
                                    name="passwordRecovery"
                                    maxLength="80"
                                    value={passwordRecovery}
                                    onChange={this.passwordRecoveryOnChange}
                                    error={passwordRecoveryError}
                                    inputStyle={style.login__input}
                                    labelStyle={style.login__label}
                                />

                                <button
                                    className={style.login__passwordRecovery_btn}
                                    type="submit"
                                >
                                    {t('auth.restore')}
                                </button>
                            </form>
                        </CSSTransition>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggingIn },
        alert: {
            message: loginError,
        },
    } = state;

    return {
        loggingIn,
        loginError,
    };
};

Login.defaultProps = {
    t: () => {},
    dispatch: () => {},
    history: {},
    loggingIn: false,
    loginError: '',
};

Login.propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.func,
    history: PropTypes.object,
    loggingIn: PropTypes.bool,
    loginError: PropTypes.string,
};

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter,
)(Login);
