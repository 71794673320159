import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import PayoutsContainer from './payouts';
import StructureContainer from './structure';
import {
    personalAreaPath,
    referralSystemPayoutsPath,
    referralSystemPath,
} from '../../../../../constants';

import style from './referral-system.module.scss';


const ReferralSystem = ({ history }) => {
    const { t } = useTranslation();
    const { TabPane } = Tabs;

    const structureFullPath = `${personalAreaPath}${referralSystemPath}`;
    const payoutsFullPath = `${personalAreaPath}${referralSystemPayoutsPath}`;

    const tabCallBack = key => {
        if (key === payoutsFullPath) {
            history.push(payoutsFullPath);
        } else {
            history.push(structureFullPath);
        }
    };

    return (
        <div className={style.referralSystem}>
            <Tabs defaultActiveKey={history.location.pathname} onChange={tabCallBack}>
                <TabPane
                    tab={t('referralSystem.structure')}
                    key={structureFullPath}
                >
                    <StructureContainer />
                </TabPane>
                <TabPane
                    tab={t('referralSystem.payouts')}
                    key={payoutsFullPath}
                >
                    <PayoutsContainer />
                </TabPane>
            </Tabs>
        </div>
    );
};

ReferralSystem.defaultProps = {
    history: {},
};

ReferralSystem.propTypes = {
    history: PropTypes.object,
};

export default withRouter(ReferralSystem);
