import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    // feesPath,
    faqPath,
    termOfServicePath,
    privacyPolicyPath,
} from '../../../constants';

import style from './footer.module.scss';

const FooterPersonalArea = () => {
    const { t } = useTranslation();

    return (
        <div className="filter filterFooter" id="footer">
            <footer className={style.footerPersonalArea}>
                <div className={style.footerPersonalArea__leftSide}>
                    <p className={style.footerPersonalArea__copyright}>
                        Copyright
                        <span className={style.footerPersonalArea__copyright_symbol}>
                            &#169;
                        </span>
                        <span className={style.footerPersonalArea__copyright_p2p}>
                        CROCOBIT
                        </span>
                    </p>
                    {/*
                        <Link
                            to={feesPath}
                            className={style.footerPersonalArea__leftSide_links}
                        >
                            {t('footer.fees')}
                        </Link>
                    */}
                    <Link
                        to={faqPath}
                        className={style.footerPersonalArea__leftSide_links}
                    >
                        {t('footer.faq')}
                    </Link>
                </div>
                <div className={style.footerPersonalArea__rightSide}>
                    <Link
                        to={privacyPolicyPath}
                        className={style.footerPersonalArea__rightSide_links}
                    >
                        {t('footer.privacyPolicy')}
                    </Link>
                    <Link
                        to={termOfServicePath}
                        className={style.footerPersonalArea__rightSide_links}
                    >
                        {t('footer.termsOfService')}
                    </Link>
                </div>
            </footer>
        </div>
    );
};

export default FooterPersonalArea;
