/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Select, DatePicker } from 'antd';
import * as moment from 'moment';

import PaginationComponent from '../../../../../layouts/pagination';
import deleteIcon from '../../../../../layouts/filter-block/images/delete.svg';
import calendarIcon from '../../../../../assets/images/calendar.svg';
import copyImg from '../../../../../assets/images/copy-content.svg';

import style from './payouts.module.scss';
import '../referral-system.scss';
import {
    ONE_DAY, LAST_WEEK, LAST_MONTH, ALL_TIME, registartionPath,
} from '../../../../../../constants';

const PayoutsView = ({
    copyToClipBoard,
    deleteFilter,
    selectFilter,
    datePicker,
    time,
    allCoins,
    filterParams,
    username,
    referralTransactions: { totalCount, items },
}) => {
    const { t } = useTranslation();
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const dateFormat = 'DD/MM/YYYY';
    const {
        coin,
        orderType: typeOfTransactionState,
        createdDateFrom,
        createdDateTo,
    } = filterParams;

    const refLink = `${window.location.origin}${registartionPath}?ref=${username}`;

    return (
        <div className={style.payouts}>
            <div className={style.header}>
                <div>
                    <p className={style.header_referralTitle}>
                        {t('referralSystem.referralLink')}
                    </p>
                    <div className={style.header_clipboardWrapper}>
                        <p className={style.header_link}>
                            {refLink}
                        </p>
                        <CopyToClipboard
                            text={refLink}
                            onCopy={copyToClipBoard}
                        >
                            <img
                                src={copyImg}
                                className={style.header_copyIcon}
                                alt="copyImg"
                            />
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
            <div className="referralSystemFilter">
                <div className="referralSystemFilter__item">
                    {createdDateFrom && createdDateTo ? (
                        <div onClick={() => deleteFilter('date')}>
                            <img
                                src={deleteIcon}
                                className="referralSystemFilter__deleteIcon"
                                alt="deleteIcon"
                            />
                        </div>
                    ) : null}
                    <p className="referralSystemFilter__title">{t('general.date')}</p>
                    <RangePicker
                        value={createdDateFrom && [moment(createdDateFrom), moment(createdDateTo)]}
                        onChange={datePicker}
                        format={dateFormat}
                        className="referralSystemFilter__picker"
                        placeholder={[
                            t('referralSystem.startDate'),
                            t('referralSystem.endDate'),
                        ]}
                        suffixIcon={
                            !createdDateFrom && !createdDateTo ? (
                                <img
                                    src={calendarIcon}
                                    className="referralSystemFilter__calendarIcon"
                                    alt="calendarIcon"
                                />
                            ) : null
                        }
                    />
                </div>
                <div className="referralSystemFilter__item">
                    <div className="referralSystemFilter__title">
                        {time ? (
                            <div onClick={() => deleteFilter('time')}>
                                <img
                                    src={deleteIcon}
                                    className="referralSystemFilter__deleteIcon"
                                    alt="deleteIcon"
                                />
                            </div>
                        ) : null}
                        {t('settings.time')}
                    </div>
                    <Select
                        className="referralSystemFilter__select"
                        placeholder={t('general.choose')}
                        value={time || undefined}
                        onChange={selectFilter}
                        showArrow={Boolean(!time)}
                    >
                        <Option name="time" value={ONE_DAY}>
                            {t('referralSystem.24h')}
                        </Option>
                        <Option name="time" value={LAST_WEEK}>
                            {t('referralSystem.lastWeek')}
                        </Option>
                        <Option name="time" value={LAST_MONTH}>
                            {t('referralSystem.lastMonth')}
                        </Option>
                        <Option name="time" value={ALL_TIME}>
                            {t('referralSystem.allTime')}
                        </Option>
                    </Select>
                </div>
                <div className="referralSystemFilter__item">
                    {coin ? (
                        <div onClick={() => deleteFilter('coin')}>
                            <img
                                src={deleteIcon}
                                className="referralSystemFilter__deleteIcon"
                                alt="deleteIcon"
                            />
                        </div>
                    ) : null}
                    <p className="referralSystemFilter__title">{t('general.currency')}</p>
                    <Select
                        className="referralSystemFilter__select"
                        placeholder={t('general.choose')}
                        value={coin}
                        onChange={selectFilter}
                        showArrow={Boolean(!coin)}
                    >
                        {allCoins.map(item => {
                            const { handle, name } = item;
                            return (
                                <Option key={handle} name="coin" value={handle}>
                                    {name}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
                <div className="referralSystemFilter__item">
                    {typeOfTransactionState ? (
                        <div onClick={() => deleteFilter('orderType')}>
                            <img
                                src={deleteIcon}
                                className="referralSystemFilter__deleteIcon"
                                alt="deleteIcon"
                            />
                        </div>
                    ) : null}
                    <p className="referralSystemFilter__title">
                        {t('referralSystem.typeOfTransaction')}
                    </p>
                    <Select
                        className="referralSystemFilter__select"
                        placeholder={t('general.choose')}
                        onChange={selectFilter}
                        value={typeOfTransactionState}
                        showArrow={Boolean(!typeOfTransactionState)}
                    >
                        <Option name="orderType" value="BUY">
                            {t('general.buy')}
                        </Option>
                        <Option name="orderType" value="SELL">
                            {t('general.sell')}
                        </Option>
                        <Option name="orderType" value="EXCHANGE">
                            {t('general.exchange')}
                        </Option>
                    </Select>
                </div>
            </div>
            {items.length ? (
                <div className={style.table}>
                    <div className={style.table__row}>
                        <div className={style.table__column}>
                            <p className={style.table__column_title}>
                                {t('referralSystem.from')}
                            </p>
                            {items.map((item, index) => {
                                const { username: name } = item;
                                return (
                                    <div key={index} className={style.table__column_nameWrapper}>
                                        <p className={style.table__column_name}>
                                            {name}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={style.table__column}>
                            <p className={style.table__column_title}>
                                {t('general.date')}
                            </p>
                            {items.map(item => {
                                const { date } = item;
                                return (
                                    <p key={date} className={style.table__column_date}>
                                        <Moment format="DD.MM.YYYY HH:mm" unix>
                                            {date}
                                        </Moment>
                                    </p>
                                );
                            })}
                        </div>
                        <div className={style.table__column}>
                            <p className={style.table__column_title}>
                                {t('referralSystem.typeOfTransaction')}
                            </p>
                            {items.map((item, index) => {
                                const { orderType } = item;
                                return (
                                    <p key={index} className={style.table__column_orderType}>
                                        {orderType}
                                    </p>
                                );
                            })}
                        </div>
                        <div className={style.table__column}>
                            <p className={style.table__column_title}>
                                {t('referralSystem.orderId')}
                            </p>
                            {items.map((item, index) => {
                                const { orderId } = item;
                                return (
                                    <p key={index} className={style.table__column_amount}>{orderId}</p>
                                );
                            })}
                        </div>
                        <div className={style.table__column}>
                            <p className={style.table__column_title}>
                                {t('referralSystem.referralLevel')}
                            </p>
                            {items.map((item, index) => {
                                const { referralLevel } = item;
                                return (
                                    <p key={index} className={style.table__column_amount}>{referralLevel}</p>
                                );
                            })}
                        </div>
                        <div className={style.table__column}>
                            <p className={style.table__column_title}>
                                {t('referralSystem.referralPercentage')}
                            </p>
                            {items.map((item, index) => {
                                const { referralPercentage } = item;
                                return (
                                    <p key={index} className={style.table__column_amount}>{referralPercentage}</p>
                                );
                            })}
                        </div>
                        <div className={style.table__column}>
                            <p className={style.table__column_title}>
                                {t('general.amount')}
                            </p>
                            {items.map((item, index) => {
                                const { value } = item;
                                return (
                                    <p key={index} className={style.table__column_amount}>{value}</p>
                                );
                            })}
                        </div>
                        <div className={style.table__column}>
                            <p className={style.table__column_title}>
                                {t('referralSystem.currencies')}
                            </p>
                            {items.map((item, index) => {
                                const { coin: currencies } = item;
                                return (
                                    <p key={index} className={style.table__column_currencies}>
                                        {currencies}
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                </div>
            ) : (
                <p className={style.noHistory}>
                    {t('referralSystem.noPayoutHistoryYet')}
                </p>
            )}
            <div className={style.mobileTable}>
                {items.length
                    ? items.map((item, index) => {
                        const {
                            username: name,
                            date,
                            coin: currency,
                            orderType,
                            value,
                            orderId,
                            referralLevel,
                            referralPercentage,
                        } = item;

                        return (
                            <div key={index} className={style.mobileTable__item}>
                                <div className={style.mobileTable__itemFullWidth}>
                                    <p className={style.mobileTable__title}>
                                        {t('referralSystem.from')}
                                    </p>
                                    <div className={style.mobileTable__nameWrapper}>
                                        <p
                                            className={
                                                style.mobileTable__nameWrapper_value
                                            }
                                        >
                                            {name}
                                        </p>
                                    </div>
                                </div>
                                <div className={style.mobileTable__itemFullWidth}>
                                    <p className={style.mobileTable__title}>
                                        {t('general.date')}
                                    </p>
                                    <p className={style.mobileTable__value}>
                                        <Moment format="DD.MM.YYYY HH:mm" unix>
                                            {date}
                                        </Moment>
                                    </p>
                                </div>
                                <div className={style.mobileTable__halfWrapper}>
                                    <div className={style.mobileTable__itemHalfWidth}>
                                        <p className={style.mobileTable__title}>
                                            {t('referralSystem.orderId')}
                                        </p>
                                        <p className={style.mobileTable__value}>
                                            {orderId}
                                        </p>
                                    </div>
                                    <div className={style.mobileTable__itemHalfWidth}>
                                        <p className={style.mobileTable__title}>
                                            {t('referralSystem.typeOfTransaction')}
                                        </p>
                                        <p className={style.mobileTable__value}>
                                            {orderType}
                                        </p>
                                    </div>
                                </div>
                                <div className={style.mobileTable__halfWrapper}>
                                    <div className={style.mobileTable__itemHalfWidth}>
                                        <p className={style.mobileTable__title}>
                                            {t('referralSystem.referralLevel')}
                                        </p>
                                        <p className={style.mobileTable__value}>
                                            {referralLevel}
                                        </p>
                                    </div>
                                    <div className={style.mobileTable__itemHalfWidth}>
                                        <p className={style.mobileTable__title}>
                                            {t('referralSystem.referralPercentage')}
                                        </p>
                                        <p className={style.mobileTable__value}>
                                            {referralPercentage}
                                        </p>
                                    </div>
                                </div>
                                <div className={style.mobileTable__halfWrapper}>
                                    <div className={style.mobileTable__itemHalfWidth}>
                                        <p className={style.mobileTable__title}>
                                            {t('general.amount')}
                                        </p>
                                        <p className={style.mobileTable__value}>
                                            {value}
                                        </p>
                                    </div>
                                    <div className={style.mobileTable__itemHalfWidth}>
                                        <p className={style.mobileTable__title}>
                                            {t('referralSystem.currencies')}
                                        </p>
                                        <p className={style.mobileTable__value}>
                                            {currency}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                    : null}
            </div>

            {totalCount > 10 ? <PaginationComponent totalCount={totalCount} /> : null}
        </div>
    );
};

PayoutsView.defaultProps = {
    copyToClipBoard: () => {},
    datePicker: () => {},
    selectFilter: () => {},
    deleteFilter: () => {},
    allCoins: [],
    referralTransactions: {},
    time: '',
    username: '',
    filterParams: {},
};

PayoutsView.propTypes = {
    copyToClipBoard: PropTypes.func,
    datePicker: PropTypes.func,
    selectFilter: PropTypes.func,
    deleteFilter: PropTypes.func,
    allCoins: PropTypes.arrayOf(PropTypes.object),
    referralTransactions: PropTypes.object,
    time: PropTypes.string,
    username: PropTypes.string,
    filterParams: PropTypes.object,
};

export default PayoutsView;
