import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import facebook from '../../assets/images/social-icons/facebook.svg';
import talk from '../../assets/images/social-icons/talk.svg';
import btc from '../../assets/images/social-icons/btc.svg';
import instagram from '../../assets/images/social-icons/instagram.svg';
import telegram from '../../assets/images/social-icons/telegram.svg';
import weChat from '../../assets/images/social-icons/weChat.svg';
import twitter from '../../assets/images/social-icons/twitter.svg';
import youtube from '../../assets/images/social-icons/youtube.svg';

import {
    ordersPath,
    // aboutPath,
    contactPath,
    termOfServicePath,
    privacyPolicyPath,
    promoVideoPath,
    faqPath,
    personalAreaPath,
    p2p,
    feePath,
} from '../../../constants';
import { compose } from '../../../utils';
import { authModalActions } from '../../../actions';

import Img from '../../small-components/img';
import logo from '../../assets/images/logo.svg';

import style from './footer.module.scss';

const FooterSite = ({ openLogin = () => {}, loggedIn }) => {
    const { t } = useTranslation();

    return (
        <div className="filter filterFooter">
            <footer className={style.footer}>
                <div className={style.footer__copyright}>
                    <Link to="/">
                        <Img className={style.footer__logo} src={logo} alt="logo" />
                    </Link>
                    <p className={style.footer__copyright_content}>
                        Copyright
                        <span className={style.footer__copyright_symbol}>&#169;</span>
                        <span className={style.footer__copyright_p2p}>CROCOBIT</span>
                    </p>
                </div>
                <div className={style.footer__linksWrapper}>
                    <div className={style.footer__about}>
                        <h3 className={style.footer__title}>{t('footer.info')}</h3>
                        <Link to="/" className={style.footer__links}>
                            {t('header.main')}
                        </Link>
                        <Link to={feePath} className={style.footer__links}>
                            {t('fee.fee')}
                        </Link>

                        <Link
                            to={privacyPolicyPath}
                            target="_blank"
                            className={style.footer__links}
                        >
                            {t('footer.privacyPolicy')}
                        </Link>
                        <Link
                            to={termOfServicePath}
                            target="_blank"
                            className={style.footer__links}
                        >
                            {t('footer.termsOfService')}
                        </Link>
                    </div>
                    <div className={style.footer__support}>
                        <h3 className={style.footer__title}>{t('footer.support')}</h3>
                        <Link to={contactPath} className={style.footer__links}>
                            {t('footer.contact')}
                        </Link>
                        <Link
                            to={promoVideoPath}

                            className={style.footer__links}
                        >
                            {t('footer.promoVideo')}
                        </Link>
                        <a
                            className={style.footer__links}
                            target="_blank"
                            href="/instructionOfUse.pdf"
                        >
                            {t('footer.instructionOfUse')}
                        </a>
                        <Link to={faqPath} className={style.footer__links}>
                            {t('footer.faq')}
                        </Link>
                    </div>
                    <div className={style.footer__copyrightMobile}>
                        <Link to="/">
                            <Img className={style.footer__logo} src={logo} alt="logo" />
                        </Link>
                        <p className={style.footer__copyrightMobile_content}>
                            Copyright
                            <span className={style.footer__copyrightMobile_symbol}>
                                &#169;
                            </span>
                            <span className={style.footer__copyrightMobile_p2p}>
                                CROCOBIT
                            </span>
                        </p>
                    </div>
                    <div className={style.footer__services}>
                        <h3 className={style.footer__title}>{t('footer.services')}</h3>
                        {loggedIn ? (
                            <Link
                                to={`${personalAreaPath}${p2p}`}
                                className={style.footer__links}
                            >
                                {t('footer.personalArea')}
                            </Link>
                        ) : (
                            <button
                                onClick={() => openLogin()}
                                type="button"
                                className={style.footer__links}
                            >
                                {t('footer.personalArea')}
                            </button>
                        )}

                        <Link to={ordersPath} className={style.footer__links}>
                            {t('footer.orders')}
                        </Link>
                        <a
                            href="https://extension.crocobit.net/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={style.footer__links}
                        >
                            {t('footer.extension')}
                        </a>
                    </div>
                    <div className={style.footer__community}>
                        <h3 className={style.footer__title}>{t('footer.community')}</h3>
                        <div className={style.footer__socialIconsWrapper}>
                            <a
                                className={style.footer__bottomWrapper_socialIcons}
                                href="https://t.me/crocobit_official"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src={telegram} alt="telegram" />
                            </a>
                            <a
                                className={style.footer__bottomWrapper_socialIcons}
                                href="/"
                                target="_blank"
                            >
                                <img src={talk} alt="talk" />
                            </a>
                            <a
                                className={style.footer__bottomWrapper_socialIcons}
                                href="https://twitter.com/CrocobitNet"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src={twitter} alt="twitter" />
                            </a>
                            <a
                                className={style.footer__bottomWrapper_socialIcons}
                                href="/"
                                target="_blank"
                            >
                                <img src={facebook} alt="facebook" />
                            </a>
                            <a
                                className={style.footer__bottomWrapper_socialIcons}
                                href="https://www.instagram.com/crocobit_official/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src={instagram} alt="instagram" />
                            </a>
                            <a
                                className={style.footer__bottomWrapper_socialIcons}
                                href="/"
                                target="_blank"
                            >
                                <img src={weChat} alt="weChat" />
                            </a>
                            <a
                                className={style.footer__bottomWrapper_socialIcons}
                                href="/"
                                target="_blank"
                            >
                                <img src={btc} alt="btc" />
                            </a>
                            <a
                                className={style.footer__bottomWrapper_socialIcons}
                                href="https://www.youtube.com/channel/UCIHG33F6JiWLnGRBQN-onPA"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src={youtube} alt="youtube" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

FooterSite.defaultProps = {
    openLogin: () => {},
    loggedIn: false,
};

FooterSite.propTypes = {
    openLogin: PropTypes.func,
    loggedIn: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        authModal: { visibleLogin },
        authentication: { loggedIn },
    } = state;

    return {
        visibleLogin,
        loggedIn,
    };
};

const mapDispatchToProps = dispatch => ({
    openLogin: () => {
        dispatch(authModalActions.openLogin());
    },

    closeModal() {
        dispatch(authModalActions.closeModal());
    },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(FooterSite);
