import React from 'react';
import { Tabs } from 'antd';

import HistoryCrypto from './history-crypto';
import HistoryFiat from './history-fiat';
import style from './history-page.module.scss';

const HistoryPage = () => {
    const { TabPane } = Tabs;
    return (
        <div className={style.historyPage}>
            <Tabs defaultActiveKey="1">
                <TabPane
                    tab="Crypto"
                    key="1"
                >
                    <HistoryCrypto />
                </TabPane>
                <TabPane
                    tab="Fiat"
                    key="2"
                >
                    <HistoryFiat />
                </TabPane>
            </Tabs>
        </div>

    );
};
export default HistoryPage;
