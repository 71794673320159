import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Edit from './edit-page-view';
import GetService from '../../../../../services/get-service';
import Spinner from '../../../../spinner';
import ErrorIndicator from '../../../error-page/error-indicator';

export class EditContainer extends Component {
    getService = new GetService();

    state = {
        edit: [],
        loading: true,
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { match } = this.props;

        const {
            params: { id },
        } = match;
        this.getService
            .getOneOrder(id)
            .then(edit => {
                this.setState({
                    edit,
                    loading: false,
                    error: false,
                });
            })
            .catch(this.onError);
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const { edit, loading, error } = this.state;

        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? <Edit edit={edit} /> : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

EditContainer.defaultProps = {
    match: {},
};

EditContainer.propTypes = {
    match: PropTypes.object,
};

export default withRouter(EditContainer);
