import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import style from './rent-table.module.scss';

const RentTable = ({
    t, rentActive, openReturnRentModal, generateRentPdf,
}) => (
    <Fragment>
        {rentActive.length ? (
            <div className={style.table}>
                <div className={style.tHead}>
                    <div className={classNames(style.tItem, style.itemDate)}>
                        <span className={style.tText}>{t('general.date')}</span>
                    </div>
                    <div className={classNames(style.tItem, style.itemId)}>
                        <span className={style.tText}>{t('general.operationId')}</span>
                    </div>
                    <div className={classNames(style.tItem, style.itemRentail)}>
                        <span className={style.tText}>EDC {t('edcRent.rental')}</span>
                    </div>
                    <div className={classNames(style.tItem, style.itemAmount)}>
                        <span className={style.tText}>{t('general.amount')} BTC</span>
                    </div>
                    <div className={classNames(style.tItem, style.itemPeriod)}>
                        <span className={style.tText}>{t('edcRent.rentalPeriod')}</span>
                    </div>
                    <div className={classNames(style.tItem, style.itemBtn)}>
                        <span className={style.tText} />
                    </div>
                    <div className={classNames(style.tItem, style.itemBtn)}>
                        <span className={style.tText} />
                    </div>
                </div>
                <div className={style.tBody}>
                    {rentActive.map(item => {
                        const {
                            paidForRent,
                            rentAmount,
                            createdAt,
                            id,
                            period,
                            canReturn,
                        } = item;
                        return (
                            <div key={id} className={style.tBody__Item}>
                                <div className={classNames(style.tItem, style.itemDate)}>
                                    <span className={style.tText}>
                                        <Moment format="DD.MM.YYYY HH:mm" unix>
                                            {createdAt}
                                        </Moment>
                                    </span>
                                </div>
                                <div className={classNames(style.tItem, style.itemId)}>
                                    <span className={style.tText}>{id}</span>
                                </div>
                                <div
                                    className={classNames(style.tItem, style.itemRentail)}
                                >
                                    <span className={style.tText}>{rentAmount}</span>
                                </div>
                                <div
                                    className={classNames(style.tItem, style.itemAmount)}
                                >
                                    <span className={style.tText}>{paidForRent}</span>
                                </div>
                                <div
                                    className={classNames(style.tItem, style.itemPeriod)}
                                >
                                    <span className={style.tText}>
                                        {period}{' '}
                                        {+period === 3
                                            ? t('edcRent.month')
                                            : t('edcRent.months')}
                                    </span>
                                </div>
                                <button
                                    className={style.downloadButton}
                                    onClick={() => generateRentPdf(id)}
                                >
                                    <span>{t('edcRent.download')}</span>
                                </button>
                                {canReturn ? (
                                    <div
                                        className={classNames(style.tItem, style.itemBtn)}
                                    >
                                        <button
                                            className={style.returnButton}
                                            onClick={() => openReturnRentModal(id, rentAmount)
                                            }
                                        >
                                            <span>{t('edcRent.return')}</span>
                                        </button>
                                    </div>
                                ) : (
                                    <div
                                        className={classNames(style.tItem, style.itemBtn)}
                                    >
                                        <button className={style.returnButtonDisabled}>
                                            <span>{t('edcRent.return')}</span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        ) : (
            <p className={style.noHistory}> {t('general.noHistory')}</p>
        )}
    </Fragment>
);

RentTable.defaultProps = {
    t: () => {},
    openReturnRentModal: () => {},
    generateRentPdf: () => {},
    rentActive: [],
};

RentTable.propTypes = {
    t: PropTypes.func,
    openReturnRentModal: PropTypes.func,
    generateRentPdf: PropTypes.func,
    rentActive: PropTypes.instanceOf(Array),
};

export default RentTable;
