/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { message } from 'antd';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import withGetService from '../../../../hoc';
import SettingsView from './settings-page-view';
import GetService from '../../../../../services/get-service';
import PostService from '../../../../../services/post-service';
import PatchService from '../../../../../services/patch-service';
import DeleteService from '../../../../../services/delete-service';
import { compose } from '../../../../../utils';
import Spinner from '../../../../spinner';
import ErrorIndicator from '../../../error-page/error-indicator';
import { collapsePanelActions, userActions } from '../../../../../actions';
import fetchUserInfoAction from '../../../../../actions/getUserInfo.actions';
import {
    REQUIRED_2FA_CODE,
    INVALID_2FA_CODE,
    MAXIMUM_FRIEND_CONFIRMATIONS_REACHED,
    USER_ALREADY_CONFIRMED,
    YOU_ALREADY_CONFIRMED_THIS_USER,
    YOU_CANT_CONFIRM_YOURSELF,
    YOU_ARE_NOT_CONFIRMED,
    USER_NOT_FOUND,
} from '../../../../../constants';

export class SettingsContainer extends Component {
    getService = new GetService();

    postService = new PostService();

    patchService = new PatchService();

    deleteService = new DeleteService();

    state = {
        userIfo: {
            isConfirmed: false,
            email: '',
            name: '',
            phone: '',
        },
        userHistory: {},
        activeUserHistory: {},
        verification: {},
        blacklist: [],
        notification: [],
        verificationCode: {
            code: '',
        },
        twoFactor: {
            authorization_code: '',
        },
        errors: {
            emailError: '',
            nameError: '',
            phoneError: '',
            codeError: '',
            twoFactorError: '',
        },
        loading: true,
        twoFactorStatus: false,
        visibleDeleteAccount: false,
        firstDocumentUploaded: false,
        loadingDocuments: false,
        loadingSelfie: false,
        passport: [],
        selfie: [],
        visibleSelfie: false,
        isShowFieldsForDisable2FA: false,
    };

    profile = {
        submitProfileForm: event => {
            event.preventDefault();
            const {
                userIfo: { email, name, phone },
            } = this.state;
            const { t } = this.props;
            const errors = {};
            if (
                !!email === false
                || email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null
            ) {
                errors.emailError = t('error.email');
            }

            if (name.length < 3) {
                errors.nameError = t('error.input', { count: 3 });
            }

            if (Object.keys(errors).length > 0) {
                this.setState({
                    errors,
                });
            } else {
                this.setState({
                    errors: {},
                });
                const data = {
                    name,
                    phone,
                    telegram: '',
                    wechat: '',
                };
                this.postService
                    .userProfileEdit(data)
                    .then(() => {
                        message.success('Profile successfully updated', 5);
                    })
                    .catch(err => {
                        message.error(err, 5);
                    });
            }
        },
    };

    friendVerify = {
        submitVerification: event => {
            event.preventDefault();
            const {
                verificationCode: { code },
                verificationCode,
            } = this.state;
            const { t } = this.props;
            const errors = {};

            if (code.length < 4) {
                errors.codeError = t('error.input', { count: 4 });
            }

            if (Object.keys(errors).length > 0) {
                this.setState({
                    errors,
                });
            } else {
                this.setState({
                    errors: {},
                    verificationCode: {
                        code: '',
                    },
                });

                const data = {
                    code: verificationCode,
                };

                this.postService
                    .friendConfirm(data)
                    .then(() => {
                        message.success(t('general.friendVerifiedSuccessfully'), 5);
                    })
                    .catch(err => {
                        if (err === MAXIMUM_FRIEND_CONFIRMATIONS_REACHED) {
                            message.error(t('error.friendAlreadyVerified'), 5);
                        } else if (err === USER_ALREADY_CONFIRMED) {
                            message.error(t('error.userConfirmed'), 5);
                        } else if (err === YOU_ALREADY_CONFIRMED_THIS_USER) {
                            message.error(t('error.youAlreadyConfirmedThisUser'), 5);
                        } else if (err === YOU_CANT_CONFIRM_YOURSELF) {
                            message.error(t('error.youCannotConfirmedYourself'), 5);
                        } else if (err === YOU_ARE_NOT_CONFIRMED) {
                            message.error(t('error.youAreNotVerified'), 5);
                        } else if (err === USER_NOT_FOUND) {
                            message.error(t('error.userNotFound'), 5);
                        } else {
                            message.error(err, 5);
                        }
                    });
            }
        },

        refreshVerifyCode: () => {
            const { t } = this.props;
            message.success(t('general.copySuccessful'), 5);
        },

        submitVerifyCode: () => {
            message.success('Coming soon');
        },
    };

    notifications = {
        switchNotification: id => {
            this.patchService.switchNotification(id).catch(this.onError);
        },
    };

    twoFactorAuth = {
        submitTwoFactor: () => {
            const {
                twoFactor: { authorization_code },
                twoFactor,
            } = this.state;
            const { t } = this.props;
            const errors = {};

            if (authorization_code.length < 4) {
                errors.twoFactorError = t('error.input', { count: 4 });
            }

            if (Object.keys(errors).length > 0) {
                this.setState({
                    errors,
                });
            } else {
                this.setState({
                    errors: {},
                    twoFactor: {},
                });

                this.postService
                    .send2faCode(twoFactor)
                    .then(() => {
                        message.success(t('settings.twoFaDisabled'), 5);
                        this.setState({
                            twoFactorStatus: false,
                        });
                    })
                    .catch(err => {
                        if (err === INVALID_2FA_CODE) {
                            message.error(t('error.invalidCode'), 5);
                        } else {
                            message.error(t(err), 5);
                        }
                    });
            }
        },

        twoFactorSwitch: () => {
            const { t } = this.props;
            const { twoFactorStatus } = this.state;

            this.getService
                .toggle2fa()
                .then(toggle2fa => {
                    if (toggle2fa.error === REQUIRED_2FA_CODE) {
                        message.success(t('general.checkEmail'), 5);
                        this.setState({
                            isShowFieldsForDisable2FA: true,
                        });
                    }

                    if (toggle2fa.error !== REQUIRED_2FA_CODE) {
                        this.setState({
                            twoFactorStatus: !twoFactorStatus,
                        });
                        message.success(t('settings.twoFaEnabled'), 5);
                    }
                })
                .catch(this.onError);
        },
    };

    blacklist = {
        blacklistSwitch: id => {
            const { t } = this.props;
            const { blacklist } = this.state;
            this.deleteService
                .unBlockUser(id)
                .then(() => {
                    this.setState(() => {
                        const idx = blacklist.findIndex(el => el.user.id === id);
                        const newArray = [
                            ...blacklist.slice(0, idx),
                            ...blacklist.slice(idx + 1),
                        ];

                        return {
                            blacklist: newArray,
                        };
                    });
                    message.success(t('settings.accountUnlocked'), 5);
                })
                .catch(this.onError);
        },
    };

    trustedDevices = {
        switchTrustedDevices: () => {
            this.patchService
                .switchTrustedDevices()
                .catch(console.log('switchTrustedDevices error'));
        },

        submitTrustedDevices: id => {
            const { t, logOut } = this.props;
            const {
                activeUserHistory: { items },
            } = this.state;
            const data = {
                id,
            };

            this.postService
                .revokeAccess(data)
                .then(() => {
                    message.success(t('settings.sessionCompleted'), 5);
                    this.setState(() => {
                        const idx = items.findIndex(el => el.id === id);

                        const newArray = [
                            ...items.slice(0, idx),
                            ...items.slice(idx + 1),
                        ];

                        if (items[idx].isCurrent) {
                            logOut();
                        }

                        return {
                            activeUserHistory: {
                                items: newArray,
                            },
                        };
                    });
                })
                .catch(err => {
                    message.error(err, 5);
                });
        },
    };

    deleteAccount = {
        openDeleteModal: () => {
            const { visibleDeleteAccount } = this.state;

            this.setState({
                visibleDeleteAccount: !visibleDeleteAccount,
            });
        },

        closeDeleteAccount: () => {
            const { visibleDeleteAccount } = this.state;

            this.setState({
                visibleDeleteAccount: !visibleDeleteAccount,
            });
        },

        submitDeleteAccount: () => {
            const { logOut } = this.props;
            const { visibleDeleteAccount } = this.state;

            this.getService
                .removeUser()
                .then(() => {
                    this.setState({
                        visibleDeleteAccount: !visibleDeleteAccount,
                    });
                    logOut();
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
    };

    componentDidMount() {
        const {
            id, is2FAEnabled, friendConfirmationCode, isConfirmed,
        } = this.props;
        if (id) {
            this.loadData();
            this.setState({
                verification: {
                    verificationStatus: isConfirmed,
                    code: friendConfirmationCode,
                },
            });
        }

        this.setState({
            twoFactorStatus: is2FAEnabled,
        });
    }

    componentDidUpdate(prevProps) {
        const {
            id, is2FAEnabled, friendConfirmationCode, isConfirmed,
        } = this.props;

        if (id !== prevProps.id) {
            this.loadData();
        }

        if (is2FAEnabled !== prevProps.is2FAEnabled && is2FAEnabled) {
            this.setState({
                twoFactorStatus: is2FAEnabled,
            });
        }

        if (friendConfirmationCode !== prevProps.friendConfirmationCode) {
            this.setState({
                verification: {
                    verificationStatus: isConfirmed,
                    code: friendConfirmationCode,
                },
            });
        }
    }

    loadData = () => {
        const { id } = this.props;
        if (id) {
            this.getService
                .getCurrentUser(id)
                .then(userIfo => {
                    const {
                        isConfirmed, email, name, phone,
                    } = userIfo;
                    this.setState({
                        userIfo: {
                            isConfirmed,
                            email,
                            name,
                            phone,
                        },
                        loading: false,
                        error: false,
                    });
                })
                .catch(this.onError);
        }

        this.getService
            .getNotificationSattings()
            .then(notification => {
                this.setState({
                    notification,
                    loading: false,
                    error: false,
                });
            })
            .catch(this.onError);

        this.getService
            .getUserHistory()
            .then(userHistory => {
                this.setState({
                    userHistory,
                    loading: false,
                    error: false,
                });
            })
            .catch(err => {
                console.log(err, 'error');
            });

        this.getService
            .getActiveUserHistory()
            .then(activeUserHistory => {
                this.setState({
                    activeUserHistory,
                });
            })
            .catch(err => {
                console.log(err, 'error');
            });

        this.getService
            .getBlackList()
            .then(blacklist => {
                this.setState({
                    blacklist,
                    loading: false,
                    error: false,
                });
            })
            .catch(this.onError);
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    InputOnchange = event => {
        const { name, value } = event.target;
        const { userIfo } = this.state;

        this.setState({
            userIfo: {
                ...userIfo,
                [name]: value,
            },

            verificationCode: {
                [name]: value,
            },
            twoFactor: {
                [name]: value,
            },
        });
    };

    onKeyDownPhone = e => {
        const {
            userIfo: { phone },
        } = this.state;
        if (phone && phone.length > 16 && e.keyCode !== 8) {
            e.preventDefault();
        }
        let checkIfNum;
        if (e.key !== undefined) {
            checkIfNum = e.key === 'e' || e.key === '-';
        } else if (e.keyCode !== undefined) {
            checkIfNum = e.keyCode === 69 || e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
    };

    phoneInputChange = event => {
        const { userIfo } = this.state;
        this.setState({
            userIfo: {
                ...userIfo,
                phone: event,
            },
        });
    };

    // Open upload panel
    openUploadPanel = defaultCollapseActiveKey => {
        const { openCllapse = () => {} } = this.props;
        openCllapse(defaultCollapseActiveKey);
    };

    getFiles = (files, uploadPassport) => {
        const { t } = this.props;
        const isJpgOrPng = [];

        if (Array.isArray(files)) {
            files.map(item => {
                const { type } = item;
                const typeFormat = type === 'image/jpeg' || type === 'image/png';
                isJpgOrPng.push(typeFormat);
                return true;
            });
        }

        if (Array.isArray(uploadPassport)) {
            uploadPassport.map(item => {
                const { type } = item;
                const typeFormat = type === 'image/jpeg' || type === 'image/png';
                isJpgOrPng.push(typeFormat);
                return true;
            });
        }

        function isValidFiles(file) {
            return file === true;
        }

        const isValidImages = isJpgOrPng.every(isValidFiles);

        if (isValidImages) {
            if (uploadPassport === 'uploadPassport') {
                this.setState(
                    {
                        passport: files,
                        loadingDocuments: true,
                    },
                    () => {
                        this.sendDocuments();
                    },
                );
            }

            if (uploadPassport === 'uploadSelfie') {
                this.setState(
                    {
                        selfie: files,
                        loadingSelfie: true,
                    },
                    () => {
                        this.sendDocuments();
                    },
                );
            }
        } else {
            message.error(t('general.youCanUploadPNGFile'), 5);
        }
    };

    sendDocuments = () => {
        const { passport, selfie } = this.state;
        const { t, fetchUserInfo } = this.props;
        const data = {
            documents: [],
        };

        passport.forEach(file => {
            const { base64 } = file;
            const dataPassport = {
                file: base64,
                type: 'PASSPORT',
            };
            data.documents.push(dataPassport);
        });

        selfie.forEach(file => {
            const { base64 } = file;
            const dataPassport = {
                file: base64,
                type: 'SELFIE',
            };
            data.documents.push(dataPassport);
        });

        this.postService
            .userVerification(data)
            .then(res => {
                if (res.success === 'Single files uploaded') {
                    message.success(t('general.firstDocVerify'), 5);
                    this.setState({
                        firstDocumentUploaded: true,
                        loadingDocuments: false,
                        loadingSelfie: false,
                        visibleSelfie: true,
                    });
                }

                if (res.success === true) {
                    message.success(t('general.secondDocVerify'), 5);
                    this.setState({
                        loadingDocuments: false,
                        loadingSelfie: false,
                    });
                    fetchUserInfo();
                }
            })
            .catch(err => {
                if (err.error) {
                    message.success(err.error, 5);
                }

                this.setState({
                    loadingDocuments: false,
                    loadingSelfie: false,
                });
            });
    };

    render() {
        const {
            loading,
            error,
            userIfo,
            blacklist,
            verification,
            twoFactorStatus,
            verificationCode,
            twoFactor,
            visibleDeleteAccount,
            notification,
            firstDocumentUploaded,
            userHistory,
            activeUserHistory,
            loadingDocuments,
            loadingSelfie,
            isShowFieldsForDisable2FA,
            errors: {
                emailError, nameError, phoneError, codeError, twoFactorError,
            },
            visibleSelfie,
        } = this.state;

        const hasData = !(loading || error);
        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <SettingsView
                userIfo={userIfo}
                verification={verification}
                verificationCode={verificationCode}
                blacklist={blacklist}
                twoFactor={twoFactor}
                nameError={nameError}
                phoneError={phoneError}
                emailError={emailError}
                codeError={codeError}
                twoFactorError={twoFactorError}
                twoFactorStatus={twoFactorStatus}
                visibleDeleteAccount={visibleDeleteAccount}
                InputOnchange={this.InputOnchange}
                phoneInputChange={this.phoneInputChange}
                notification={notification}
                loadingDocuments={loadingDocuments}
                loadingSelfie={loadingSelfie}
                userHistory={userHistory}
                activeUserHistory={activeUserHistory}
                firstDocumentUploaded={firstDocumentUploaded}
                visibleSelfie={visibleSelfie}
                isShowFieldsForDisable2FA={isShowFieldsForDisable2FA}
                submitProfileForm={this.profile.submitProfileForm}
                submitVerifyCode={this.friendVerify.submitVerifyCode}
                refreshVerifyCode={this.friendVerify.refreshVerifyCode}
                submitVerification={this.friendVerify.submitVerification}
                OFFER_ACCEPTED={this.notifications.OFFER_ACCEPTED}
                OFFER_CREATED={this.notifications.OFFER_CREATED}
                switchNotification={this.notifications.switchNotification}
                OFFER_DECLINED={this.notifications.OFFER_DECLINED}
                CHAT_CANCELED={this.notifications.CHAT_CANCELED}
                PAYMENT_SENT={this.notifications.PAYMENT_SENT}
                PAYMENT_ACCEPTED={this.notifications.PAYMENT_ACCEPTED}
                PAYMENT_DECLINED={this.notifications.PAYMENT_DECLINED}
                twoFactorSwitch={this.twoFactorAuth.twoFactorSwitch}
                submitTwoFactor={this.twoFactorAuth.submitTwoFactor}
                blacklistSwitch={this.blacklist.blacklistSwitch}
                submitTrustedDevices={this.trustedDevices.submitTrustedDevices}
                switchTrustedDevices={this.trustedDevices.switchTrustedDevices}
                openDeleteModal={this.deleteAccount.openDeleteModal}
                closeDeleteAccount={this.deleteAccount.closeDeleteAccount}
                submitDeleteAccount={this.deleteAccount.submitDeleteAccount}
                openUploadPanel={this.openUploadPanel}
                getFiles={this.getFiles}
                onKeyDownPhone={this.onKeyDownPhone}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        currentCollapsePanel: { defaultCollapseActiveKey },
        getUserInfo: {
            userInfo: {
                id, is2FAEnabled, friendConfirmationCode, isConfirmed,
            },
            loading,
        },
    } = state;

    return {
        defaultCollapseActiveKey,
        id,
        loading,
        is2FAEnabled,
        friendConfirmationCode,
        isConfirmed,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        fetchUserInfo: fetchUserInfoAction(getService),
        openCllapse: defaultCollapseActiveKey => collapsePanelActions.selectCollapsePanel(defaultCollapseActiveKey),
        logOut: () => userActions.logout(),
    },
    dispatch,
);

SettingsContainer.defaultProps = {
    t: () => {},
    logOut: () => {},
};

SettingsContainer.propTypes = {
    t: PropTypes.func,
    logOut: PropTypes.func,
};

export default compose(
    withTranslation(),
    withGetService(),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(SettingsContainer);
