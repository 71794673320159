import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { compose } from '../../../utils';
import { authModalActions } from '../../../actions';
import TableRow from '../../small-components/table-row';
import EmptyFilterResult from '../../small-components/emptyFilterResult';

import TradeModalWindow from '../../layouts/trade-modal-window';
import PaginationComponent from '../../layouts/pagination';

import style from './orders-page.module.scss';
import 'rc-pagination/assets/index.css';

class TableRowView extends PureComponent {
    static defaultProps = {
        orders: {},
        openTrade: () => {},
        openLogin: () => {},
        loggedIn: false,
        cardList: [],
    };

    static propTypes = {
        orders: PropTypes.object,
        openTrade: PropTypes.func,
        openLogin: PropTypes.func,
        loggedIn: PropTypes.bool,
        cardList: PropTypes.any,
    };

    render() {
        const {
            orders,
            openTrade = () => {},
            openLogin = () => {},
            loggedIn,
            cardList,
        } = this.props;

        return (
            <Fragment>
                <div className={style.orders}>
                    {orders.items.length >= 1 ? (
                        <Fragment>
                            {orders.items.map(item => {
                                const {
                                    id,
                                    smallestAmount,
                                    sellAmount,
                                    rate,
                                    isSell,
                                    coinBuy: { handle: coinBuy },
                                    coinSell: { handle: coinSell },
                                    orderType: { handle: orderType },
                                    country: { handle: country },
                                    paymentMethod: { name: paymentMethod },
                                    seller: {
                                        id: userId, fast, logoSrc, rating, username, online,
                                    },
                                } = item;

                                return (
                                    <Fragment key={id}>
                                        <TableRow
                                            userName={username}
                                            raiting={parseInt(rating, 16)}
                                            transfer={orderType}
                                            country={country}
                                            limitsFrom={smallestAmount}
                                            limitsTo={sellAmount}
                                            cost={rate}
                                            buy={isSell}
                                            logoSrc={logoSrc}
                                            status={loggedIn}
                                            speed={loggedIn}
                                            online={online}
                                            fast={fast}
                                            coinBuy={coinBuy}
                                            coinSell={coinSell}
                                            userId={userId}
                                            paymentMethod={paymentMethod}
                                            onClick={
                                                loggedIn
                                                    ? () => openTrade(id)
                                                    : () => openLogin()
                                            }
                                        />
                                    </Fragment>
                                );
                            })}
                        </Fragment>
                    ) : (
                        <EmptyFilterResult />
                    )}
                </div>
                {orders.totalCount > 10 ? (
                    <PaginationComponent totalCount={orders.totalCount} />
                ) : null}

                <TradeModalWindow cardList={cardList} />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
    } = state;

    return {
        loggedIn,
    };
};

const mapDispatchToProps = dispatch => ({
    openLogin: () => {
        dispatch(authModalActions.openLogin());
    },

    closeModal() {
        dispatch(authModalActions.closeModal());
    },
});

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(TableRowView);
