/* eslint-disable */

export const authModalConstants = {
    OPEN_LOGIN: 'OPEN_LOGIN',
    OPEN_SIGNUP: 'OPEN_SIGNUP',
    CLOSE_AUTH_MODAL: 'CLOSE_AUTH_MODAL',
    OPEN_FEEDBACK_MODAL: 'OPEN_FEEDBACK_MODAL',
    CLOSE_FEEDBACK_MODAL: 'CLOSE_FEEDBACK_MODAL',
    ACTIVE_FEEDBACK: 'ACTIVE_FEEDBACK',
    SOCKET_CONNECTED: 'SOCKET_CONNECTED',
    SOCKET_DICONECTED: 'SOCKET_DICONECTED',
};
