/* eslint-disable */
import { userConstants } from '../constants';

export default function userLoginName(state, action) {
    if (state === undefined) {
        return {
            userName: '',
        };
    }
    switch (action.type) {
        case userConstants.USER_EMAIL:
            return {
                userName: action.userName,
            };
        default:
            return state;
    }
}
