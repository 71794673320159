const PENDING = 'PENDING';
const COMPLETED = 'COMPLETED';
const CANCELLED = 'CANCELLED';
const OPENED = 'OPENED';
const CLOSED = 'CLOSED';
const ARBITRAGE = 'ARBITRAGE';
const YOUROPENED = 'YOUROPENED';

const WAITING = 'WAITING';
const REJECTED = 'REJECTED';

const ONLINE = true;
const OFFLINE = false;

const CRYPTO_TRANSFER = 'CRYPTO_TRANSFER';
const BANK_TRANSFER = 'BANK_TRANSFER';
const BUY = 'BUY';
const SELL = 'SELL';
const EXCHANGE = 'EXCHANGE';

const USD = 'USD';
const EUR = 'EUR';
const EDC = 'EDC';
const SFC = 'SFC';

const MODERATOR = 'MODERATOR';

export {
    PENDING,
    COMPLETED,
    CANCELLED,
    ONLINE,
    OFFLINE,
    OPENED,
    CLOSED,
    YOUROPENED,
    CRYPTO_TRANSFER,
    BANK_TRANSFER,
    ARBITRAGE,
    BUY,
    SELL,
    EXCHANGE,
    USD,
    EUR,
    MODERATOR,
    WAITING,
    REJECTED,
    EDC,
    SFC,
};
