import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import Button from '../button';
import close from '../../assets/images/close.svg';
import style from './are-u-sure-modal.module.scss';
import './are-u-sure-modal.scss';

const AreUSureModal = ({ visibleModal, noButton, yesButton }) => {
    const { t } = useTranslation();

    if (visibleModal) {
        document.documentElement.style.overflowY = 'hidden';
    } else {
        document.documentElement.style.overflowY = 'inherit';
    }

    return (
        <Modal
            isOpen={visibleModal}
            onRequestClose={noButton}
            ariaHideApp={false}
            className="areYouSureModal reactModal"
            contentLabel="Example Modal"
            overlayClassName="modalOverlay"
        >
            <div className={style.deleteModal}>
                <h2 className={style.deleteModal__title}>{t('general.areYouSure')}</h2>
                <div onClick={noButton}>
                    <img src={close} className={style.deleteModal__close} alt="close" />
                </div>
                <div className={style.deleteModal__buttonWrapper}>
                    <Button
                        id="deleteNo"
                        type="button"
                        onClick={noButton}
                        className={style.deleteModal__no}
                    >
                        {t('general.no')}
                    </Button>
                    <Button
                        id="deleteYes"
                        type="button"
                        onClick={yesButton}
                        className={style.deleteModal__yes}
                    >
                        {t('general.yes')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

AreUSureModal.defaultProps = {
    visibleModal: false,
    noButton: () => {},
    yesButton: () => {},
};

AreUSureModal.propTypes = {
    visibleModal: PropTypes.bool,
    noButton: PropTypes.func,
    yesButton: PropTypes.func,
};

export default AreUSureModal;
