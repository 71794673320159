import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import PropTypes from 'prop-types';

import withGetService from '../../../../../hoc';
import GetService from '../../../../../../services/get-service';
import PostService from '../../../../../../services/post-service';
import StructureView from './structure-view';
import Spinner from '../../../../../spinner';
import ErrorIndicator from '../../../../error-page/error-indicator';
import { compose } from '../../../../../../utils';
import fetchUserInfoAction from '../../../../../../actions/getUserInfo.actions';
import { FilterActions } from '../../../../../../actions';
import {
    UNABLE_TO_FIND_LEADER,
    USER_LEADER_ALREADY_SET,
    WRONG_LEADER_EMAIL,
} from '../../../../../../constants';

export class StructureContainer extends Component {
    mounted = true;

    postService = new PostService();

    getService = new GetService();

    state = {
        loading: true,
        error: false,
        search: '',
        leaderUsername: '',
        myReferrals: [],
        errors: {
            leaderUsernameError: '',
        },
    };

    componentDidMount() {
        const { location, leaderUsernameFromRedux } = this.props;
        this.loadData();

        if (location.search) {
            this.setState({
                leaderUsername: location.search.split('=')[1],
            });
        }

        if (leaderUsernameFromRedux) {
            this.setState({
                leaderUsername: leaderUsernameFromRedux,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { currentPageNumber, leaderUsernameFromRedux } = this.props;

        if (leaderUsernameFromRedux !== prevProps.leaderUsernameFromRedux) {
            this.setState({
                leaderUsername: leaderUsernameFromRedux,
            });
        }

        if (currentPageNumber && currentPageNumber !== prevProps.currentPageNumber) {
            this.getMyReferralsPagination(currentPageNumber);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getMyReferralsPagination = pagination => {
        this.getService
            .getMyReferralsPagination(pagination)
            .then(myReferrals => {
                if (this.mounted) {
                    this.setState({
                        myReferrals,
                        loading: false,
                    });
                }
            })
            .catch(this.onError);
    }

    loadData = search => {
        this.getService
            .getMyReferrals(search)
            .then(myReferrals => {
                if (this.mounted) {
                    this.setState({
                        myReferrals,
                        loading: false,
                    });
                    this.changePaginationPage();
                }
            })
            .catch(this.onError);
    };

    changePaginationPage = () => {
        const { setPaginationPage, filterParams } = this.props;
        this.setState(
            {
                currentFilterParams: {
                    ...filterParams,
                    currentPageNumber: 1,
                },
            },
            () => {
                const { currentFilterParams: newfilter } = this.state;
                setPaginationPage(newfilter);
            },
        );
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    inputOnchange = event => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });

        if (name === 'search' && value.length === 0) {
            this.loadData();
        }
    };

    copyToClipBoard = () => {
        const { t } = this.props;
        message.success(t('referralSystem.copySuccessful'), 5);
    };

    searchOnSubmit = event => {
        event.preventDefault();
        const { search } = this.state;
        this.loadData(search);
    };

    clearSearch = () => {
        this.setState({
            search: '',
        });
        this.loadData();
    };

    submitBecomeReferral = event => {
        event.preventDefault();
        const { t, fetchUserInfo } = this.props;
        const { leaderUsername } = this.state;
        const errors = {};

        if (leaderUsername.length < 1) {
            errors.leaderUsernameError = t('error.field_cannot_be_empty');
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
        } else {
            this.setState({
                errors: {},
            });

            const data = {
                leaderUsername,
            };

            this.postService
                .setLeader(data)
                .then(() => {
                    message.success(t('referralSystem.successfullySetLeader'), 5);
                    this.setState({
                        leaderUsername: '',
                        errors: {
                            leaderUsernameError: '',
                        },
                    });
                    fetchUserInfo();
                })
                .catch(error => {
                    if (error === USER_LEADER_ALREADY_SET) {
                        message.error(t('referralSystem.leaderAlreadySet'), 5);
                    } else if (error === UNABLE_TO_FIND_LEADER) {
                        message.error(t('referralSystem.couldNotFindLeader'), 5);
                    } else if (error === WRONG_LEADER_EMAIL) {
                        message.error(t('referralSystem.couldNotFindLeader'), 5);
                    }
                });
        }
    };

    render() {
        const { username, leaderUsernameFromRedux } = this.props;
        const {
            loading,
            error,
            search,
            leaderUsername,
            errors: { leaderUsernameError },
            myReferrals,
        } = this.state;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <StructureView
                search={search}
                leaderUsername={leaderUsername}
                leaderUsernameFromRedux={leaderUsernameFromRedux}
                leaderUsernameError={leaderUsernameError}
                myReferrals={myReferrals}
                username={username}
                submitBecomeReferral={this.submitBecomeReferral}
                inputOnchange={this.inputOnchange}
                copyToClipBoard={this.copyToClipBoard}
                searchOnSubmit={this.searchOnSubmit}
                clearSearch={this.clearSearch}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        getAllCoins: { allCoins },
        getUserInfo: {
            userInfo: { username, leaderUsername: leaderUsernameFromRedux },
        },
        currentFilter: { filterParams: { currentPageNumber } },
    } = state;

    return {
        allCoins,
        username,
        currentPageNumber,
        leaderUsernameFromRedux,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        fetchUserInfo: fetchUserInfoAction(getService),
        setPaginationPage: newfilter => FilterActions.selectFilter(newfilter),
    },
    dispatch,
);

StructureContainer.defaultProps = {
    t: () => {},
    fetchUserInfo: () => {},
    setPaginationPage: () => {},
    location: {},
    username: '',
    leaderUsernameFromRedux: '',
    currentPageNumber: '',
    filterParams: {},
};

StructureContainer.propTypes = {
    t: PropTypes.func,
    fetchUserInfo: PropTypes.func,
    setPaginationPage: PropTypes.func,
    location: PropTypes.object,
    username: PropTypes.string,
    leaderUsernameFromRedux: PropTypes.string,
    currentPageNumber: PropTypes.any,
    filterParams: PropTypes.object,
};

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(StructureContainer);
