import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './term-of-service.module.scss';

const TermOfService = () => {
    const { t } = useTranslation();

    return (
        <div className={style.termOfService}>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.TermsOfService')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.TermsOfServiceText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.acceptanceAndChangeOfTerms')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.acceptanceAndChangeOfTermsText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.registrationAndUserAccount')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.registrationAndUserText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.companyAccounts')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.companyAccountsText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.identityVerification')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.identityVerificationText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.cryptocurrencyTrading')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.cryptocurrencyTradingText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.disputingCryptocurrencyTrades')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.disputingCryptocurrencyTradesText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.unresponsiveness')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.unresponsivenessText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.disputeReview')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.disputeReviewText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.disputeResolution')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.disputeResolutionText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.disputeResolutionCriteria')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.disputeResolutionCriteriaText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.incorrectDisputeResolution')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.incorrectDisputeResolutionText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>{t('termOfUse.trades')}</h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.tradesText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.limitation')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.limitationText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.feesAndPricing')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.feesAndPricingText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.supportingBlockchainForks')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.supportingBlockchainForksText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.allowedJurisdictions')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.allowedJurisdictionsText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.intellectualPropertyRights')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.intellectualPropertyRightsText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.disclaimerOfWarranties')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.disclaimerOfWarrantiesText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>{t('termOfUse.indemnity')}</h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.indemnityText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>{t('termOfUse.general')}</h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.generalText')}
                </p>
            </section>
            <section className={style.termOfService__section}>
                <h3 className={style.termOfService__title}>
                    {t('termOfUse.governingLaw')}
                </h3>
                <p className={style.termOfService__subTitle}>
                    {t('termOfUse.governingLawText')}
                </p>
            </section>
        </div>
    );
};

export default TermOfService;
