const SOCKET_CONNECT = 'connect';
const SOCKET_DISCONNECT = 'disconnect';
const NOTIFICATION_CREATED = 'notificationCreated';
const SEND_MESSAGE = 'sendMessage';
const FRONTEND_STATUS = 'frontendStatus';
const OFFER_CREATE = 'offerCreate';
const CHAT_CREATE = 'chatCreate';
const CHAT_UPDATED = 'chatUpdated';
const MESSAGES_READ = 'messagesRead';

export {
    SOCKET_CONNECT,
    SOCKET_DISCONNECT,
    NOTIFICATION_CREATED,
    SEND_MESSAGE,
    FRONTEND_STATUS,
    OFFER_CREATE,
    CHAT_CREATE,
    CHAT_UPDATED,
    MESSAGES_READ,
};
