import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { connect } from 'react-redux';
import * as moment from 'moment';

import { alertActions, userActions } from '../../../actions';
import Spinner from '../../spinner';
import ErrorIndicator from '../error-page/error-indicator';
import { compose } from '../../../utils';
import PostService from '../../../services/post-service';

import {
    personalAreaPath,
    p2p,
    clientId,
    INVALID_TOKEN,
} from '../../../constants';

export class RestoreAccountPage extends Component {
    postService = new PostService();

    static defaultProps = {
        t: () => {},
        match: {},
        dispatch: () => {},
        history: {},
    };

    static propTypes = {
        t: PropTypes.func,
        match: PropTypes.object,
        dispatch: PropTypes.func,
        history: PropTypes.object,
    };

    state = {
        loading: true,
        error: false,
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { t, dispatch, history } = this.props;
        const { match } = this.props;

        const {
            params: { id },
        } = match;

        const data = {
            client_id: clientId,
        };

        this.postService
            .restoreAccount(id, data)
            .then(user => {
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('updateTokenTime', moment().unix());
                dispatch(userActions.success(user));
                dispatch(alertActions.success('Authorization successful'));
                history.push(`${personalAreaPath}${p2p}`);
                message.success(t('general.accountRestoredSuccesfully'), 5);
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: true,
                });

                if (err === INVALID_TOKEN) {
                    message.error(t('error.youCanRestoreOnlyOnce'), 5);
                } else {
                    message.error(err, 5);
                }
            });
    };

    render() {
        const { loading, error } = this.state;

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
    } = state;

    return {
        loggedIn,
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter,
)(RestoreAccountPage);
