const coinsTranslation = (coins, t) => {
    if (coins === 'EUR') {
        return t('coins.EUR');
    }
    if (coins === 'USD') {
        return t('coins.USD');
    }
    if (coins === 'INR') {
        return t('coins.INR');
    }
    if (coins === 'PKR') {
        return t('coins.PKR');
    }
    if (coins === 'NGN') {
        return t('coins.NGN');
    }
    if (coins === 'CNY') {
        return t('coins.CNY');
    }
    if (coins === 'IDR') {
        return t('coins.IDR');
    }
    if (coins === 'COP') {
        return t('coins.COP');
    }
    if (coins === 'VES') {
        return t('coins.VES');
    }
    if (coins === 'TRY') {
        return t('coins.TRY');
    }
    if (coins === 'KZT') {
        return t('coins.KZT');
    }
    if (coins === 'CLP') {
        return t('coins.CLP');
    }
    if (coins === 'RUB') {
        return t('coins.RUB');
    }
    if (coins === 'UAH') {
        return t('coins.UAH');
    }
    if (coins === 'AMD') {
        return t('coins.AMD');
    }
    return coins;
};

export default coinsTranslation;
