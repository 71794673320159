const OFFER_BUYER = 'OFFER_BUYER';
const OFFER_SELLER = 'OFFER_SELLER';
const TRADE_CANCELED = 'TRADE_CANCELED';
const DECLINE_OFFER = 'DECLINE_OFFER';
const ACCEPT_OFFER = 'ACCEPT_OFFER';
const PAYMENT_SEND = 'PAYMENT_SEND';
const TRADE_CLO = 'TRADE_CLO';
const PAYMENT_DECLINE = 'PAYMENT_DECLINE';
const TRADE_CLOSED = 'TRADE_CLOSED';
const ARBITRAGE = 'ARBITRAGE';
const ORDER_PENDING = 'ORDER_PENDING';
const ARBITER_CONFIRMED_PAYMENT = 'ARBITER_CONFIRMED_PAYMENT';
const ARBITER_NOT_CONFIRMED_PAYMENT = 'ARBITER_NOT_CONFIRMED_PAYMENT';
const ARBITER_PAYMENT_DECLINE = 'ARBITER_PAYMENT_DECLINE';

export {
    // сделка открыта
    OFFER_BUYER,

    // предложение цены
    OFFER_SELLER,

    // цена подтверждена
    ACCEPT_OFFER,

    // я заплатил
    PAYMENT_SEND,

    // получил деньги
    TRADE_CLO,

    // цена отклонена
    DECLINE_OFFER,

    // сделка отменена
    TRADE_CANCELED,

    // не получил деньги
    PAYMENT_DECLINE,

    // сделка закрыта, договорились, чат закрыт
    TRADE_CLOSED,

    // подключили арбитра
    ARBITRAGE,

    // Сделка открыта третьей стороной
    ORDER_PENDING,

    // Арбитр не подтвердил оплату
    ARBITER_PAYMENT_DECLINE,

    // к статусам не относится

    ARBITER_CONFIRMED_PAYMENT,

    ARBITER_NOT_CONFIRMED_PAYMENT,

    // к статусам не относится
};
