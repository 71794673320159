import React, { Fragment } from 'react';

import { MODERATOR } from './statusOrder.constants';


const ORDER_CREATED = 'ORDER_CREATED';
const OFFER_CREATED = 'OFFER_CREATED';
const OFFER_ACCEPTED = 'OFFER_ACCEPTED';
const OFFER_DECLINED = 'OFFER_DECLINED';
const CHAT_CANCELED = 'CHAT_CANCELED';
const PAYMENT_SENT = 'PAYMENT_SENT';
const PAYMENT_ACCEPTED = 'PAYMENT_ACCEPTED';
const PAYMENT_DECLINED = 'PAYMENT_DECLINED';
const ARBITRAGE = 'ARBITRAGE';

const notificationTranslation = (handle, name, t) => {
    let notificationText = '';

    const initiatorName = name === MODERATOR ? t('general.arbitrator') : name;

    if (ORDER_CREATED === handle) {
        notificationText = (
            <Fragment>
                {t('notifications.ORDER_CREATED')}{' '}
                <span>{initiatorName}</span>.
            </Fragment>
        );
    }
    if (OFFER_CREATED === handle) {
        notificationText = (
            <Fragment>
                <span>{initiatorName}</span> {' '}
                {t('notifications.OFFER_CREATED')}
            </Fragment>
        );
    }
    if (OFFER_ACCEPTED === handle) {
        notificationText = (
            <Fragment>
                <span>{initiatorName}</span> {' '}
                {t('notifications.offer_acceptedText')}
            </Fragment>
        );
    }
    if (OFFER_DECLINED === handle) {
        notificationText = (
            <Fragment>
                <span>{initiatorName}</span> {' '}
                {t('notifications.offer_declinedText')}
            </Fragment>
        );
    }
    if (CHAT_CANCELED === handle) {
        notificationText = (
            <Fragment>
                {t('notifications.chat_canceledText', { name: initiatorName })}
            </Fragment>
        );
    }
    if (PAYMENT_SENT === handle) {
        notificationText = (
            <Fragment>
                <span>{initiatorName}</span> {' '}
                {t('notifications.payment_sentText')}
            </Fragment>
        );
    }
    if (PAYMENT_ACCEPTED === handle) {
        notificationText = (
            <Fragment>
                <span>{initiatorName}</span> {' '}
                {t('notifications.payment_acceptedText')}
            </Fragment>
        );
    }
    if (ARBITRAGE === handle) {
        notificationText = (
            <Fragment>
                {t('notifications.orderMovedToArbitrage')}
            </Fragment>
        );
    }
    if (PAYMENT_DECLINED === handle) {
        notificationText = (
            <Fragment>
                <span>{initiatorName}</span> {' '}
                {t('notifications.payment_declinedText')}
            </Fragment>
        );
    }
    return notificationText;
};

const handleTranslation = (handle, name, t) => {
    const initiatorName = name === MODERATOR ? t('general.arbitrator') : name;

    let handleText = '';

    if (ORDER_CREATED === handle) {
        handleText = t('notifications.ORDER_CREATED');
    }
    if (OFFER_CREATED === handle) {
        handleText = t('notifications.offer_createdText');
    }
    if (OFFER_ACCEPTED === handle) {
        handleText = t('notifications.offer_acceptedText');
    }
    if (OFFER_DECLINED === handle) {
        handleText = t('notifications.offer_declinedText');
    }
    if (CHAT_CANCELED === handle) {
        handleText = t('notifications.chat_canceledText', { name: initiatorName });
    }
    if (PAYMENT_SENT === handle) {
        handleText = t('notifications.payment_sentText');
    }
    if (PAYMENT_ACCEPTED === handle) {
        handleText = t('notifications.payment_acceptedText');
    }
    if (ARBITRAGE === handle) {
        handleText = t('notifications.orderMovedToArbitrage');
    }
    if (PAYMENT_DECLINED === handle) {
        handleText = t('notifications.payment_declinedText');
    }
    return handleText;
};

export {
    ORDER_CREATED,
    OFFER_CREATED,
    OFFER_ACCEPTED,
    OFFER_DECLINED,
    CHAT_CANCELED,
    PAYMENT_SENT,
    PAYMENT_ACCEPTED,
    PAYMENT_DECLINED,
    ARBITRAGE,
    notificationTranslation,
    handleTranslation,
};
