/* eslint-disable react/no-array-index-key */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Collapse, Icon } from 'antd';
import { connect } from 'react-redux';
import Pagination from 'rc-pagination';

import Spinner from '../../spinner';
import GetService from '../../../services/get-service';
import withGetService from '../../hoc';
import { compose } from '../../../utils';

import style from './faq-page.module.scss';

class FaqPage extends Component {
    getService = new GetService();

    static defaultProps = {
        t: () => {},
        getService: {},
        locale: {},
    };

    static propTypes = {
        t: PropTypes.func,
        getService: PropTypes.object,
        locale: PropTypes.any,
    };

    state = {
        faqData: {},
        currentPaginationPage: 1,
        loading: true,
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const { locale } = this.props;

        if (locale !== prevProps.locale) {
            this.loadData();
            this.setState({
                currentPaginationPage: 1,
            });
        }
    }

    getCurrentLang = () => localStorage.getItem('i18nextLngCrocobit').toLowerCase();

    loadData = id => {
        const { getService } = this.props;
        getService
            .getFaq(id || 1, this.getCurrentLang())
            .then(faqData => {
                this.setState({
                    faqData,
                    loading: false,
                });
            })
            .catch(err => this.onError(err));
    };

    textItemRender = (current, type, element) => {
        const { t } = this.props;
        if (type === 'prev') {
            return t('general.previous');
        }
        if (type === 'next') {
            return t('general.next');
        }
        return element;
    };

    changePaginationPage = currentPaginationPage => {
        this.setState({
            currentPaginationPage,
        });
        this.loadData(currentPaginationPage);
    };

    onError = err => {
        console.log(err, 'error');
    };

    render() {
        const { t } = this.props;
        const { faqData, currentPaginationPage, loading } = this.state;
        const { Panel } = Collapse;
        const { items, totalCount } = faqData;

        return (
            <div className={style.faq}>
                {loading ? (
                    <Spinner />
                ) : (
                    <Fragment>
                        <div className={style.faq__container}>
                            <h1 className={style.faq__title}>{t('header.faq')}</h1>
                            <Collapse
                                accordion
                                forceRender
                                bordered={false}
                                defaultActiveKey={['1']}
                                expandIcon={({ isActive }) => (
                                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                                )}
                            >
                                {items.map((data, index) => {
                                    const { answer, question } = data;

                                    return (
                                        <Panel header={question} key={index + 1}>
                                            <div className={style.faq__wrapper}>
                                                <p className={style.faq__answer}>{answer}</p>
                                            </div>
                                        </Panel>
                                    );
                                })}
                            </Collapse>
                        </div>
                        {totalCount > 10 ? (
                            <Pagination
                                showTitle={false}
                                total={totalCount}
                                onChange={this.changePaginationPage}
                                itemRender={this.textItemRender}
                                current={currentPaginationPage}
                            />
                        ) : null}
                    </Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        currentCollapsePanel: { defaultCollapseActiveKey },
        getUserInfo: {
            userInfo: {
                id, is2FAEnabled, friendConfirmationCode, isConfirmed,
            },
            loading,
        },
        getCurrentLocale: { locale },
    } = state;

    return {
        defaultCollapseActiveKey,
        id,
        loading,
        is2FAEnabled,
        friendConfirmationCode,
        isConfirmed,
        locale,
    };
};

export default compose(
    withTranslation(),
    withGetService(),
    connect(mapStateToProps),
)(FaqPage);
