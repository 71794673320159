import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ReactWOW from 'react-wow';

import Img from '../../../small-components/img';

import step1 from './images/step1.svg';
import step2 from './images/step2.svg';
import step3 from './images/step3.svg';
import step4 from './images/step4.svg';
import step5 from './images/step5.svg';
import stepLine from './images/stepLine.svg';

import style from './how-to-start.module.scss';

const HowToStart = () => {
    const { t } = useTranslation();

    const imgAboveLine = [
        {
            id: 1,
            howToStart: step1,
            alt: 'step1',
            title: t('howToStart.registration'),
            step: t('howToStart.step'),
        },
        {
            id: 3,
            howToStart: step3,
            alt: 'step3',
            title: t('howToStart.advertisement'),
            step: t('howToStart.step'),
        },
        {
            id: 5,
            howToStart: step5,
            alt: 'step5',
            title: t('howToStart.profit'),
            step: t('howToStart.step'),
        },
    ];

    const imgUnderLine = [
        {
            id: 2,
            howToStart: step2,
            alt: 'step2',
            title: t('howToStart.verificate'),
            step: t('howToStart.step'),
        },
        {
            id: 4,
            howToStart: step4,
            alt: 'step4',
            title: t('howToStart.sendMoney'),
            step: t('howToStart.step'),
        },
    ];

    return (
        <Fragment>
            <ReactWOW animation="fadeInUp" delay="0.05s">
                <div className={style.howToStart}>
                    <p className={style.howToStart__title}>{t('howToStart.title')}</p>
                    <div className={style.howToStart__container}>
                        <div className={style.howToStart__wrapper}>
                            {imgAboveLine.map(item => {
                                const {
                                    id, step, howToStart, alt, title,
                                } = item;
                                return (
                                    <div
                                        key={alt}
                                        className={style.howToStart__wrapper_itemAbove}
                                    >
                                        <Img
                                            className={style.howToStart__wrapper_img}
                                            src={howToStart}
                                            alt={alt}
                                        />
                                        <div className={style.howToStart__wrapper_step}>
                                            <span>
                                                {step} {id}
                                            </span>
                                            {title}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <Img
                            className={style.howToStart__stepLine}
                            src={stepLine}
                            alt="stepLine"
                        />
                        <div className={style.howToStart__wrapper}>
                            {imgUnderLine.map(item => {
                                const {
                                    id, step, howToStart, alt, title,
                                } = item;
                                return (
                                    <div
                                        key={alt}
                                        className={style.howToStart__wrapper_itemUnder}
                                    >
                                        <Img
                                            className={style.howToStart__wrapper_img}
                                            src={howToStart}
                                            alt={alt}
                                        />
                                        <div className={style.howToStart__wrapper_step}>
                                            <span>
                                                {step} {id}
                                            </span>
                                            {title}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </ReactWOW>
        </Fragment>
    );
};

export default HowToStart;
