/* eslint-disable */

import { collapsePanelConstants } from '../constants';

function selectCollapsePanel(id) {
    return {
        type: collapsePanelConstants.SELECT_COLLAPSE_PANEL,
        id,
    };
}

export const collapsePanelActions = {
    selectCollapsePanel,
};
