import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import socketClient from 'socket.io-client';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PostService from '../../services/post-service';
import GetService from '../../services/get-service';
import { socketConnectedActions } from '../../actions';
import { compose } from '../../utils';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import {
    HomePage,
    OrdersPage,
    TermOfService,
    PrivacyPolicy,
    PageNotFound,
    PersonalArea,
    ContactPage,
    ConfirmationPage,
    ConfirmationDevicesPage,
    RecoveryPasswordPage,
    FaqPage,
    RestoreAccountPage,
    Fee,
    PromoVideo,
} from '../pages';

import ScrollToTop from '../../utils/scrollToTop';

import {
    loginPath,
    registartionPath,
    ordersPath,
    contactPath,
    termOfServicePath,
    privacyPolicyPath,
    personalAreaPath,
    confirmationPath,
    promoVideoPath,
    recoveryPasswordPath,
    confirmationDevicesPath,
    faqPath,
    feePath,
    restoreAccountPath,
    SOCKET_CONNECT,
    SOCKET_DISCONNECT,
} from '../../constants';

import '../assets/styles/reset.scss';
import './app.scss';
import '../assets/styles/fonts.scss';

export class App extends Component {
    postService = new PostService();

    getService = new GetService();

    interval = null;

    state = {
        focus: null,
    };

    componentDidMount() {
        this.connectSocket();
        this.setState({
            focus: true,
        });
        window.addEventListener('focus', this.setFocus);
        window.addEventListener('blur', this.clearFocus);
    }

    componentDidUpdate(prevProps, prevState) {
        const { focus } = this.state;
        if (focus !== prevState.focus) {
            if (focus) {
                this.checkVersion();
                this.interval = setInterval(this.checkVersion, 60000);
            } else {
                this.clearCheckVersion();
            }
        }
    }

    connectSocket = () => {
        this.socket = socketClient(process.env.REACT_APP_API_SOCKET, {
            reconnection: true,
            reconnectionDelay: 30000,
            reconnectionDelayMax: 30000,
            reconnectionAttempts: 5,
        });

        this.socket.on(SOCKET_CONNECT, () => {
            this.saveSocketToRedux();
        });

        this.socket.on(SOCKET_DISCONNECT, () => {
            this.connectSocket();
        });
    };

    saveSocketToRedux = () => {
        const {
            userInfo: { socketRoomHash },
        } = this.props;
        const { id } = this.socket;

        this.socket.emit('join', socketRoomHash);
        const { socketConnectionPassToRedux, loggedIn } = this.props;

        if (id && loggedIn) {
            const date = new Date();
            date.setTime(date.getTime() + 1 * 3600 * 1000);
            document.cookie = `socketIo=${id};expires=${date};path=/`;
            const data = {
                socketIo: id,
            };
            this.postService
                .setCookie(data)
                .catch(err => {
                    console.log(err, 'error');
                });
        }

        socketConnectionPassToRedux(this.socket);
    };

    checkVersion = () => {
        this.getService
            .getVersion()
            .then(result => {
                const { version } = result;
                const currentVersion = localStorage.getItem('p2p_version');
                if (localStorage.getItem('p2p_version') === null) {
                    localStorage.setItem('p2p_version', version);
                } else if (currentVersion !== version) {
                    localStorage.setItem('p2p_version', version);
                    document.location.reload(true);
                }
            });
    }

    setFocus = () => {
        this.setState({
            focus: true,
        });
    }

    clearFocus = () => {
        this.setState({
            focus: false,
        });
    }


    clearCheckVersion = () => {
        clearInterval(this.interval);
    }

    render() {
        return (
            <Router>
                <ScrollToTop>
                    <Header />
                    <Switch>
                        <Route path="/" component={HomePage} exact />
                        <Route path={loginPath} component={HomePage} exact />
                        <Route path={registartionPath} component={HomePage} exact />
                        <Route path={ordersPath} component={OrdersPage} />
                        <Route path={feePath} component={Fee} />
                        <Route path={promoVideoPath} component={PromoVideo} />
                        <Route path={faqPath} component={FaqPage} />
                        <Route path={contactPath} component={ContactPage} />
                        <Route path={termOfServicePath} component={TermOfService} />
                        <Route path={privacyPolicyPath} component={PrivacyPolicy} />
                        <Route path={personalAreaPath} component={PersonalArea} />
                        <Route
                            path={`${confirmationPath}/:id?`}
                            component={ConfirmationPage}
                        />
                        <Route
                            path={`${confirmationDevicesPath}/:id?`}
                            component={ConfirmationDevicesPage}
                        />
                        <Route
                            path={`${recoveryPasswordPath}/:id?`}
                            component={RecoveryPasswordPage}
                        />
                        <Route
                            path={`${restoreAccountPath}/:id?`}
                            component={RestoreAccountPage}
                        />
                        <Route component={PageNotFound} />
                    </Switch>
                    <Footer />
                </ScrollToTop>
            </Router>
        );
    }
}

App.defaultProps = {
    userInfo: {},
    socketConnectionPassToRedux: () => {},
    loggedIn: false,
};

App.propTypes = {
    userInfo: PropTypes.object,
    socketConnectionPassToRedux: PropTypes.func,
    loggedIn: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        getUserInfo: { userInfo },
        authentication: { loggedIn },
    } = state;

    return {
        userInfo,
        loggedIn,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        socketConnectionPassToRedux: emit => socketConnectedActions.socketConnected(emit),
    },
    dispatch,
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(App);
