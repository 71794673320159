import { message } from 'antd';
import store, { authHeader } from '../helpers';
import PostService from './post-service';
import { userActions } from '../actions';

export default class DeleteService {
    postService = new PostService();

    getResource = async (url, data) => {
        const requestOptions = {
            method: 'DELETE',
            headers: authHeader(),
            body: JSON.stringify(data),
        };

        const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, requestOptions);

        if (res.status === 401) {
            this.removeUserFromStorage();
        }

        if (res.status !== 200) {
            res.json().then(errorMessage => {
                message.error(errorMessage.error, 5);
            });
        }

        if (!res.ok) {
            console.error(`Could not fetch ${url}, received ${res.status}`);
        }

        const result = await res.json();
        return result;
    };

    removeUserFromStorage = () => {
        store.dispatch(userActions.logout());
    }

    unBlockUser = async id => {
        const res = await this.getResource(`/api/user/black-list/unblock/${id}`);
        return res;
    };

    deleteCard = async id => {
        const res = await this.getResource(`/api/user-bank-card/${id}/delete`);
        return res;
    };
}
