/* eslint-disable */

import { authModalConstants } from '../constants';

function activeFeedback() {
    return {
        type: authModalConstants.ACTIVE_FEEDBACK
    };
}

export const statusFeedbackModalActions = {
    activeFeedback,
};
