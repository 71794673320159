import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './privacy-policy.module.scss';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className={style.privacyPolicy}>
            <section className={style.privacyPolicy__section}>
                <h3 className={style.privacyPolicy__title}>
                    {t('privacyPolicy.privacyPolicy')}
                </h3>
                <p className={style.privacyPolicy__subTitle}>{t('privacyPolicy.date')}</p>
            </section>
            <section className={style.privacyPolicy__section}>
                <h3 className={style.privacyPolicy__title}>
                    {t('privacyPolicy.introduction')}
                </h3>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.introduction1')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.introduction2')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.introduction3')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.introduction4')}
                </p>
            </section>
            <section className={style.privacyPolicy__section}>
                <h3 className={style.privacyPolicy__title}>
                    {t('privacyPolicy.personalData')}
                </h3>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData1')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData2')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData3')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData4')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData5')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData6')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData7')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData8')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData9')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData10')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData11')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData12')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData13')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData14')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.personalData15')}
                </p>
            </section>
            <section className={style.privacyPolicy__section}>
                <h3 className={style.privacyPolicy__title}>
                    {t('privacyPolicy.publiclyInformation')}
                </h3>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.publiclyInformation1')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.publiclyInformation2')}
                </p>
            </section>
            <section className={style.privacyPolicy__section}>
                <h3 className={style.privacyPolicy__title}>
                    {t('privacyPolicy.providingPersonalData')}
                </h3>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.providingPersonalData1')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.providingPersonalData2')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.providingPersonalData3')}
                </p>
            </section>
            <section className={style.privacyPolicy__section}>
                <h3 className={style.privacyPolicy__title}>
                    {t('privacyPolicy.retainingAndDeleting')}
                </h3>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.retainingAndDeleting1')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.retainingAndDeleting2')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.retainingAndDeleting3')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.retainingAndDeleting4')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.retainingAndDeleting5')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.retainingAndDeleting6')}
                </p>
            </section>
            <section className={style.privacyPolicy__section}>
                <h3 className={style.privacyPolicy__title}>
                    {t('privacyPolicy.yourRights')}
                </h3>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.yourRights1')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.yourRights2')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.yourRights3')}
                </p>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.yourRights4')}
                </p>
            </section>
            <section className={style.privacyPolicy__section}>
                <h3 className={style.privacyPolicy__title}>
                    {t('privacyPolicy.cookiePolicy')}
                </h3>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.cookiePolicy1')}
                </p>
            </section>
            <section className={style.privacyPolicy__section}>
                <h3 className={style.privacyPolicy__title}>
                    {t('privacyPolicy.cookiePolicy')}
                </h3>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.cookiePolicy1')}
                </p>
            </section>
            <section className={style.privacyPolicy__section} id="walletExtension">
                <h3 className={style.privacyPolicy__title}>
                    8. Decentralized wallet extension
                </h3>
                <p className={style.privacyPolicy__subTitle}>
                    8.1. Crocobit (decentralized wallet extension) does not collect any
                    personal data of its users. No private keys, no seed, no nothing.
                    Crocobit (decentralized wallet extension) stores all information
                    locally in your Chrome browser user data storage. We do not store any
                    users credentials on our servers. We use SSL (Secure Sockets Layer)
                    for connections between client side (website & app) and our servers to
                    protect your data Crocobit (decentralized wallet extension) is
                    distributed "AS IS". Crocobit (decentralized wallet extension) does
                    not collect any payment information of any kind. Crocobit
                    (decentralized wallet extension) does not collect data from any other
                    website or any other activity.
                </p>
            </section>

            <section className={style.privacyPolicy__section}>
                <h3 className={style.privacyPolicy__title}>
                    {t('privacyPolicy.contactUs')}
                </h3>
                <p className={style.privacyPolicy__subTitle}>
                    {t('privacyPolicy.contactUs1')}
                </p>
            </section>
            <p className={style.privacyPolicy__title}>
                {t('privacyPolicy.protectionContact')}
            </p>
            <div className={style.privacyPolicy__protectionContact_wrapper}>
                <div>
                    <p className={style.privacyPolicy__protectionContact_address}>
                        “ECRO chain”,
                    </p>
                    <p className={style.privacyPolicy__protectionContact_address}>
                        {t('privacyPolicy.registryCode')} 14164797,
                    </p>
                    <p className={style.privacyPolicy__protectionContact_address}>
                        {t('privacyPolicy.address')} Harju maakond, Tallinn,{' '}
                    </p>
                    <p className={style.privacyPolicy__protectionContact_address}>
                        Lasnamäe linnaosa{' '}
                    </p>
                    <p className={style.privacyPolicy__protectionContact_address}>
                        Peterburi tee 47, 11415.{' '}
                    </p>
                </div>
                <a href="/">CROCOBIT.com </a>
            </div>
        </div>
    );
};
export default PrivacyPolicy;
