import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import Spinner from '../../../../../spinner';
import GetService from '../../../../../../services/get-service';
import { compose } from '../../../../../../utils';
import style from './history-fiat.module.scss';
import './history-fiat.scss';

class HistoryFiat extends Component {
    getService = new GetService();

    state = {
        fiatRequests: {},
        loading: true,
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        this.getService
            .getFiatRequests()
            .then(fiatRequests => {
                this.setState({
                    fiatRequests,
                    loading: false,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        const { t } = this.props;
        const {
            fiatRequests: { items },
            loading,
        } = this.state;

        if (loading) {
            return <Spinner />;
        }

        return (
            <div className={style.fiatHistory}>
                <div className={style.table}>
                    <div className={style.tHead}>
                        <div className={classNames(style.tItem, style.itemDate)}>
                            <span className={style.tText}>{t('general.date')}</span>
                        </div>
                        <div className={classNames(style.tItem, style.itemId)}>
                            <span className={style.tText}>
                                {t('general.operationId')}
                            </span>
                        </div>
                        <div className={classNames(style.tItem, style.itemType)}>
                            <span className={style.tText}>{t('general.type')}</span>
                        </div>
                        <div className={classNames(style.tItem, style.itemCurrency)}>
                            <span className={style.tText}>{t('general.currency')}</span>
                        </div>
                        <div className={classNames(style.tItem, style.itemAmount)}>
                            <span className={style.tText}>{t('general.amount')}</span>
                        </div>
                        <div className={classNames(style.tItem, style.itemFiat)}>
                            <span className={style.tText}>
                                {t('general.fiatCurrency')}
                            </span>
                        </div>
                        <div className={classNames(style.tItem, style.itemTotal)}>
                            <span className={style.tText}>{t('general.total')}</span>
                        </div>
                        <div className={classNames(style.tItem, style.itemPrice)}>
                            <span className={style.tText}>{t('general.price')}</span>
                        </div>
                        <div className={classNames(style.tItem, style.itemStatus)}>
                            <span className={style.tText}>{t('general.status')}</span>
                        </div>
                    </div>
                    {items
                        && items.length
                        && items.map(item => {
                            const {
                                createdAt,
                                cryptoAmount,
                                cryptoCurrency,
                                fiatCurrency,
                                id,
                                rate,
                                fiatAmount,
                                requestTypeHandle,
                                statusForUser,
                            } = item;

                            let statusStyle = '';
                            let statusText = '';
                            let requestTypeText = '';

                            if (statusForUser === 'NEW') {
                                statusStyle = style.new;
                                statusText = t('general.new');
                            } else if (statusForUser === 'PENDING') {
                                statusStyle = style.pending;
                                statusText = t('general.pending');
                            } else if (statusForUser === 'FAILED') {
                                statusStyle = style.failed;
                                statusText = t('general.failed');
                            } else if (statusForUser === 'COMPLETED') {
                                statusStyle = style.complete;
                                statusText = t('general.completed');
                            }

                            if (requestTypeHandle === 'DEPOSIT') {
                                requestTypeText = t('balances.deposit');
                            } else if (requestTypeHandle === 'WITHDRAW') {
                                requestTypeText = t('balances.withdrawal');
                            } else {
                                requestTypeText = requestTypeHandle;
                            }

                            return (
                                <div className={style.tBody} key={id}>
                                    <div className={style.tBody__Item}>
                                        <div
                                            className={classNames(
                                                style.tItem,
                                                style.itemDate,
                                            )}
                                        >
                                            <span className={style.tText}>
                                                <Moment format="DD.MM.YYYY HH:mm" unix>
                                                    {createdAt}
                                                </Moment>
                                            </span>
                                        </div>
                                        <div
                                            className={classNames(
                                                style.tItem,
                                                style.itemId,
                                            )}
                                        >
                                            <span className={style.tText}>{id}</span>
                                        </div>
                                        <div
                                            className={classNames(
                                                style.tItem,
                                                style.itemType,
                                            )}
                                        >
                                            <span className={style.tText}>{requestTypeText}</span>
                                        </div>
                                        <div
                                            className={classNames(
                                                style.tItem,
                                                style.itemCurrency,
                                            )}
                                        >
                                            <span className={style.tText}>
                                                {cryptoCurrency}
                                            </span>
                                        </div>
                                        <div
                                            className={classNames(
                                                style.tItem,
                                                style.itemAmount,
                                            )}
                                        >
                                            <span className={style.tText}>
                                                {cryptoAmount}
                                            </span>
                                        </div>
                                        <div
                                            className={classNames(
                                                style.tItem,
                                                style.itemFiat,
                                            )}
                                        >
                                            <span className={style.tText}>
                                                {fiatCurrency}
                                            </span>
                                        </div>
                                        <div
                                            className={classNames(
                                                style.tItem,
                                                style.itemTotal,
                                            )}
                                        >
                                            <span className={style.tText}>
                                                {fiatAmount} {fiatCurrency}
                                            </span>
                                        </div>
                                        <div
                                            className={classNames(
                                                style.tItem,
                                                style.itemPrice,
                                            )}
                                        >
                                            <span className={style.tText}>{rate}</span>
                                        </div>
                                        <div
                                            className={classNames(
                                                style.tItem,
                                                style.itemStatus,
                                                statusStyle,
                                            )}
                                        >
                                            <span className={style.tText}>{statusText}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

HistoryFiat.defaultProps = {
    t: {},
};

HistoryFiat.propTypes = {
    t: PropTypes.func,
};

export default compose(withTranslation())(HistoryFiat);
