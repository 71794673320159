import React from 'react';

import style from './promo-video-page.module.scss';

const PromoVideo = () => (
    <div className={style.PromoVideo}>
        <div className={style.title}>
            <h2>Promo Video</h2>
        </div>
        <div className={style.container}>
            <div className={style.item}>
                <div className={style.caption}>
                    <h3>What is P2P Platform?</h3>
                </div>
                <div className={style.description}>
                    <p>
                        Proident nulla do aliqua exercitation amet ut. Cillum sint
                        deserunt non ad exercitation veniam laborum.
                    </p>
                </div>
                <div className={style.videoOuter}>
                    <div className={style.labelP2P}>
                        <span>p2p</span>
                    </div>
                    <div className={style.videoInner}>
                        <iframe
                            title="p2p"
                            src="https://www.youtube.com/embed/9D7XStA2k_E"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                </div>
            </div>
            <div className={style.item}>
                <div className={style.caption}>
                    <h3>Why should I buy a Crocobit Card?</h3>
                </div>
                <div className={style.description}>
                    <p>
                        Exercitation amet ut. Cillum sint deserunt non ad exercitation
                        veniam laborum.
                    </p>
                </div>
                <div className={style.videoOuter}>
                    <div className={style.labelCards}>
                        <span>cards</span>
                    </div>
                    <div className={style.videoInner}>
                        <iframe
                            title="cards"
                            src="https://www.youtube.com/embed/hMOlGN2Yn34"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
);
export default PromoVideo;
