/* eslint-disable */
import { collapsePanelConstants } from '../constants';

export function currentCollapsePanel(state, action) {
    if (state === undefined) {
        return {
            defaultCollapseActiveKey: '1',
        };
    }
    switch (action.type) {
        case collapsePanelConstants.SELECT_COLLAPSE_PANEL:
            return {
                defaultCollapseActiveKey: action.id,
            };
        default:
            return state;
    }
}
