import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PaginationComponent from '../../../../layouts/pagination';
import FilterBlock from '../../../../layouts/filter-block';
import TableRow from '../../../../small-components/table-row';
import EmptyFilterResult from '../../../../small-components/emptyFilterResult';
import { personalAreaPath, dashboardPath, advertisement } from '../../../../../constants';

import style from './dashboard-page.module.scss';
import 'rc-pagination/assets/index.css';

const DashboardView = ({ dashboard, saveDashboardIdToRedux = () => {} }) => {
    const { t } = useTranslation();

    return (
        <div className={style.dashboard}>
            <div className={style.dashboardList}>
                <p className={style.dashboardList__title}>{t('general.dashboard')}</p>
                <FilterBlock />
                {dashboard.items.length >= 1 ? (
                    <Fragment>
                        {dashboard.items.map(item => {
                            const {
                                id,
                                smallestAmount,
                                sellAmount,
                                rate,
                                isSell,
                                coinBuy: { handle: coinBuy },
                                orderStatus: { handle: orderStatus },
                                coinSell: { handle: coinSell },
                                orderType: { handle: orderType },
                                country: { handle: country },
                                paymentMethod: { name: paymentMethod },
                                seller: {
                                    id: userId, fast, logoSrc, rating, username, online,
                                },
                            } = item;

                            const status = true;
                            const speed = true;
                            const showStatusOrder = true;

                            return (
                                <Fragment key={id}>
                                    <Link
                                        to={`${personalAreaPath}${dashboardPath}${advertisement}/${id}`}
                                        className={style.footerPersonalArea__leftSide_links}
                                        onClick={() => saveDashboardIdToRedux(id)}
                                    >
                                        <TableRow
                                            userName={username}
                                            raiting={parseInt(rating, 16)}
                                            transfer={orderType}
                                            country={country}
                                            limitsFrom={smallestAmount}
                                            limitsTo={sellAmount}
                                            cost={rate}
                                            buy={isSell}
                                            logoSrc={logoSrc}
                                            status={status}
                                            speed={speed}
                                            showStatusOrder={showStatusOrder}
                                            statusOrder={orderStatus}
                                            online={online}
                                            fast={fast}
                                            coinBuy={coinBuy}
                                            paymentMethod={paymentMethod}
                                            coinSell={coinSell}
                                            userId={userId}
                                        />
                                    </Link>
                                </Fragment>
                            );
                        })}
                    </Fragment>
                ) : (
                    <EmptyFilterResult />
                )}
            </div>

            {dashboard.totalCount > 10 ? (
                <PaginationComponent totalCount={dashboard.totalCount} />
            ) : null}
        </div>
    );
};

DashboardView.defaultProps = {
    dashboard: {},
    saveDashboardIdToRedux: () => {},
};

DashboardView.propTypes = {
    dashboard: PropTypes.object,
    saveDashboardIdToRedux: PropTypes.func,
};

export default DashboardView;
