import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { message } from 'antd';

import {
    personalAreaPath,
    p2p,
    ADDRESS_IS_NOT_VALID,
    USER_WITHDRAW_MAX_ERROR,
    USER_WITHDRAW_MIN_ERROR,
    UNABLE_TO_GENERATE_ADDRESS,
    YOU_ARE_NOT_ALLOWED_TO_REGENERATE_ADDRESS,
    INVALID_BALANCE_TO_WITHDRAW,
    UNABLE_TO_SEND_TO_THIS_ADDRESS,
    INVALID_DEPOSIT_FIAT_AMOUNT,
} from '../../../../../constants';
import toFixedBigValue from '../../../../../helpers/big-number';
import WalletView from './wallet-page-view';
import GetService from '../../../../../services/get-service';
import PostService from '../../../../../services/post-service';
import { compose } from '../../../../../utils';
import { FilterActions } from '../../../../../actions/filter.actions';
import Spinner from '../../../../spinner';

export class WalletContainer extends Component {
    getService = new GetService();

    postService = new PostService();

    state = {
        wallet: [],
        allCoins: [],
        getDeposit: [],
        loading: true,
        openModalState: false,
        isShowFiatDepositModal: false,
        isShowFiatWithdrawalModal: false,
        depositModal: false,
        spinNewAdress: false,
        isDisabledWithdrawSubmitBtn: false,
        disabledSubmitBtn: false,
        filterParams: {},
        selectedDepositCoin: '',
        selectedWithDrawCoin: '',
        selectedWithDrawAmomount: '',
        cryptoCoinHandle: '',
        fiatCoin: '',
        fiatAmount: '',
        totalPriceInFiat: '',
        cardNumber: '',
        filterCurrentCoinData: {},
        getNewAdress: [],
        fiatLimits: [],
        fiatPairs: {},
        cryptoLimits: [],
        filteredFiatCoin: [],
        modalLoading: true,
        withDrawData: {
            coin: '',
            amount: '',
            toAddress: '',
            memo: '',
        },
        errors: {
            amountError: '',
            toAddressError: '',
            memoError: '',
        },
    };

    totalBalanceRef = React.createRef();

    totalBalanceTitleRef = React.createRef();

    availableBalanceBalanceRef = React.createRef();

    availableBalanceBalanceTitleRef = React.createRef();

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            withDrawData: { coin: withDrawCoin },
            cryptoCoinHandle,
            fiatPairs,
        } = this.state;

        if (withDrawCoin !== prevState.withDrawData.coin) {
            this.filterCurrentCoinData();
        }

        if (cryptoCoinHandle !== prevState.cryptoCoinHandle) {
            const filteredFiatCoin = Object.keys(fiatPairs)
                .filter(key => cryptoCoinHandle.includes(key))
                .reduce((obj, key) => {
                    obj[key] = fiatPairs[key];
                    return obj;
                }, {});

            this.setState({
                filteredFiatCoin: filteredFiatCoin[cryptoCoinHandle],
            });
        }
    }

    loadData = () => {
        this.getService.getUserCoins().then(wallet => {
            this.setState({
                wallet,
                loading: false,
            });
        });

        this.getService.getAllCoins().then(res => {
            const allCoins = res.filter(item => item.isCrypto === true);
            this.setState({
                allCoins,
                loading: false,
            });
        });

        this.getService.getKunaFiatPairs().then(fiatPairs => {
            this.setState({
                fiatPairs,
            });
        });

        this.getService.getKunaFiatLimits('deposit').then(fiatLimits => {
            this.setState({
                fiatLimits,
            });
        });
        this.getService.getKunaFiatLimits('withdraw').then(cryptoLimits => {
            this.setState({
                cryptoLimits,
            });
        });
    };

    openModalDeposit = coin => {
        this.setState(
            {
                selectedDepositCoin: coin,
                openModalState: true,
                depositModal: true,
            },
            () => {
                this.uploadDepositWallet(coin);
            },
        );
    };

    chooseCurrency = async value => {
        const { fiatLimits, cryptoCoinHandle, fiatAmount } = this.state;
        const fiatCoins = [];
        await fiatLimits.map(item => {
            const { coin } = item;
            return fiatCoins.push(coin);
        });
        const isFiatCoin = fiatCoins.includes(value);

        this.getService
            .getMarketPrice(cryptoCoinHandle, value)
            .then(marketFiatPrice => {
                this.setState({
                    marketFiatPrice,
                });
                if (fiatAmount) {
                    this.setState({
                        marketFiatPrice,
                        totalPriceInFiat: toFixedBigValue(
                            fiatAmount * marketFiatPrice,
                            8,
                        ),
                    });
                }
            })
            .catch(err => console.log(err, 'error'));

        this.getService
            .getMarketPrice(value, cryptoCoinHandle)
            .then(marketCryptoPrice => {
                this.setState({
                    marketCryptoPrice,
                });
            })
            .catch(err => console.log(err, 'error'));

        this.setState({
            errors: {},
        });

        if (isFiatCoin) {
            this.setState({
                fiatCoin: value,
            });
        } else {
            this.setState(
                {
                    selectedDepositCoin: value,
                },
                () => {
                    this.uploadDepositWallet(value);
                },
            );
        }
    };

    uploadDepositWallet = value => {
        this.getService.getDeposit().then(deposit => {
            const getDeposit = deposit.filter(
                item => item.userCoinAddress.coin.handle === value,
            );
            this.setState({
                getDeposit,
                loading: false,
                error: false,
                modalLoading: false,
            });
        });
    };

    filterCurrentCoinData = () => {
        const {
            wallet,
            withDrawData: { coin: withDrawCoin },
        } = this.state;

        wallet
            .filter(item => item.coin.handle === withDrawCoin)
            .map(item => {
                this.setState({
                    filterCurrentCoinData: item,
                });
                return true;
            });
    };

    openModalWithDraw = (coin, amount) => {
        const { withDrawData } = this.state;
        this.setState({
            openModalState: true,
            modalLoading: false,
            selectedWithDrawCoin: coin,
            selectedWithDrawAmomount: amount.toString(),
            withDrawData: {
                ...withDrawData,
                amount,
                coin,
            },
        });
    };

    chooseWithDrawCoin = value => {
        const { wallet, withDrawData } = this.state;
        const filterAmount = wallet.filter(item => item.coin.handle === value);
        const amount = filterAmount.length >= 1 ? filterAmount[0].amount : '';

        this.setState({
            selectedWithDrawCoin: value,
            selectedWithDrawAmomount: amount.toString(),
            withDrawData: {
                ...withDrawData,
                coin: value,
            },
        });
    };

    withdrawInputOnchange = event => {
        const { withDrawData } = this.state;
        const { name, value } = event.target;

        this.setState({
            withDrawData: {
                ...withDrawData,
                [name]: value,
            },
        });
    };

    inputOnChange = event => {
        const { marketFiatPrice, marketCryptoPrice } = this.state;

        const { name, value } = event.target;
        const numbersAndDot = /^[0-9.]+$/;
        if (value === '' || numbersAndDot.test(value)) {
            if (name === 'fiatAmount' && marketFiatPrice) {
                this.setState({
                    [name]: value,
                    totalPriceInFiat: toFixedBigValue(value * marketFiatPrice, 8),
                });
            } else if (name === 'totalPriceInFiat' && marketCryptoPrice) {
                this.setState({
                    [name]: value,
                    fiatAmount: toFixedBigValue(value * marketCryptoPrice, 8),
                });
            } else {
                this.setState({
                    [name]: value,
                });
            }
        }
    };

    withdrawOnSubmit = event => {
        event.preventDefault();
        const { t } = this.props;
        const {
            withDrawData: {
                amount, toAddress, coin, memo,
            },
            filterCurrentCoinData,
        } = this.state;

        const minimumWithdrawalAmount = filterCurrentCoinData.coin
            && filterCurrentCoinData.coin.commission.minimumWithdrawValue;

        const data = {
            amount: +amount,
            toAddress,
            memo,
            coin,
        };
        const errors = {};

        if (amount.length === 0) {
            errors.amountError = t('error.input', { count: 1 });
        }

        if (+amount < +minimumWithdrawalAmount) {
            errors.amountError = t('balances.valueBelowMinimumWithdrawal');
        }

        if (toAddress.length < 3) {
            errors.toAddressError = t('error.input', { count: 3 });
        }

        if (memo.length > 60) {
            errors.memoError = t('error.input', { count: 60 });
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
        } else {
            this.setState({
                errors: {},
            });

            if (amount && toAddress) {
                this.setState({
                    isDisabledWithdrawSubmitBtn: true,
                });
                this.postService
                    .createWithdraw(data)
                    .then(() => {
                        message.success('Withdraw successfully created!', 5);
                        this.closeModal();
                    })
                    .catch(err => {
                        this.setState({
                            isDisabledWithdrawSubmitBtn: false,
                        });
                        if (err === ADDRESS_IS_NOT_VALID) {
                            message.error(t('error.addressIsNotValid'), 5);
                        } else if (
                            err === USER_WITHDRAW_MAX_ERROR
                            || err === USER_WITHDRAW_MIN_ERROR
                        ) {
                            message.error(
                                t('error.amountWithdrawal', {
                                    min: '0.00000001',
                                    max: '9999999.99999999',
                                }),
                                3,
                            );
                        } else if (err === INVALID_BALANCE_TO_WITHDRAW) {
                            message.error(t('error.invalid_amount_to_withdraw'), 5);
                        } else if (err === UNABLE_TO_SEND_TO_THIS_ADDRESS) {
                            message.error(t('error.unableToSendToThisAddress'), 5);
                        } else {
                            message.error(err, 5);
                        }
                    });
            }
        }
    };

    closeModal = () => {
        this.setState({
            openModalState: false,
            isShowFiatDepositModal: false,
            isShowFiatWithdrawalModal: false,
            depositModal: false,
            modalLoading: true,
            isDisabledWithdrawSubmitBtn: false,
            fiatCoin: '',
            cryptoCoinHandle: '',
            fiatAmount: '',
            totalPriceInFiat: '',
            marketFiatPrice: '',
            marketCryptoPrice: '',
            errors: {},
        });
    };

    trade = coin => {
        const { history, dispatch } = this.props;
        const { filterParams } = this.state;

        this.setState(
            {
                filterParams: {
                    ...filterParams,
                    coin: coin.handle,
                },
            },
            () => {
                const { filterParams: newfilter } = this.state;
                dispatch(FilterActions.selectFilter(newfilter));
                history.push(`${personalAreaPath}${p2p}`);
            },
        );
    };

    generateNewAdress = coin => {
        const { t } = this.props;
        const { getNewAdress } = this.state;

        this.setState({
            spinNewAdress: true,
        });
        const data = {
            coin,
        };

        this.postService
            .regenerateWallet(data)
            .then(newGetDeposit => {
                message.success(t('general.newAdressSuccessfullyCreated'), 5);
                const {
                    userCoinAddress: { address },
                    id,
                } = newGetDeposit;

                const newAddress = {
                    generatedAdressId: id,
                    generatedAdress: address,
                };
                this.setState({
                    spinNewAdress: false,
                    getNewAdress: [...getNewAdress, newAddress],
                });
            })
            .catch(err => {
                if (err === UNABLE_TO_GENERATE_ADDRESS) {
                    message.error(t('error.failedToCreateAddress'), 5);
                } else if (err === YOU_ARE_NOT_ALLOWED_TO_REGENERATE_ADDRESS) {
                    message.error(t('error.unableToGenerateAddress'), 5);
                } else {
                    message.error(err, 5);
                }

                this.setState({
                    spinNewAdress: false,
                });
            });
    };

    submitFiatDeposit = () => {
        const {
            fiatCoin, fiatAmount, cryptoCoinHandle, fiatLimits,
        } = this.state;
        const { t } = this.props;
        const data = {
            cryptoCoinHandle,
            fiatCoinHandle: fiatCoin,
            amount: fiatAmount,
        };
        const errors = {};

        if (!fiatCoin.length) {
            errors.fiatCoinError = t('general.selectFromTheList');
        }

        if (!fiatAmount.length) {
            errors.fiatAmountError = t('error.input', { count: 1 });
        }

        fiatLimits.map(item => {
            const { coin, limit } = item;

            if (fiatCoin && fiatCoin === coin && +fiatAmount < limit) {
                errors.fiatAmountError = `${t('general.minAmount')} ${limit}`;
            }
            return true;
        });

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
            message.error(t('general.errorFields'), 5);
        } else {
            this.setState({
                disabledSubmitBtn: true,
            });
            this.postService
                .fiatDepositCreate(data)
                .then(response => {
                    const { depositUrl } = response;
                    window.open(depositUrl, '_blank');
                    this.setState({
                        disabledSubmitBtn: false,
                        isShowFiatDepositModal: false,
                        errors: {},
                        fiatAmount: '',
                        fiatCoin: '',
                    });
                })
                .catch(err => {
                    message.error(err, 5);
                    this.setState({
                        disabledSubmitBtn: false,
                    });
                });
        }
    };

    submitFiatWithdraw = () => {
        const {
            fiatCoin,
            fiatAmount,
            cryptoCoinHandle,
            cardNumber,
            wallet,
            cryptoLimits,
        } = this.state;
        const { t } = this.props;
        const errors = {};
        let selectedCoinData = {};

        wallet
            .filter(item => item.coin.handle === cryptoCoinHandle)
            .map(item => {
                selectedCoinData = item;
                return true;
            });

        if (!fiatCoin.length) {
            errors.fiatCoinError = t('general.selectFromTheList');
        }

        if (!fiatAmount.length) {
            errors.fiatAmountError = t('error.input', { count: 1 });
        }

        if (fiatAmount > +selectedCoinData.amount) {
            errors.fiatAmountError = t('error.maxAmount', {
                max: selectedCoinData.amount,
            });
        }

        if (cardNumber.length < 14) {
            errors.cardNumberError = t('error.input', { count: 14 });
        }

        cryptoLimits.map(item => {
            const { coin, limit } = item;

            if (cryptoCoinHandle && cryptoCoinHandle === coin && +fiatAmount < limit) {
                errors.fiatAmountError = `${t('general.minAmount')} ${limit}`;
            }
            return true;
        });

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
            message.error(t('general.errorFields'), 5);
        } else {
            this.setState({
                disabledSubmitBtn: true,
            });
            const data = {
                cryptoCoinHandle,
                fiatCoinHandle: fiatCoin,
                amount: fiatAmount,
                cardNumber,
            };
            this.postService
                .fiatWithdrawCreate(data)
                .then(() => {
                    message.success(t('general.successfulWithdrawal'), 5);
                    this.setState({
                        disabledSubmitBtn: false,
                        isShowFiatWithdrawalModal: false,
                        cardNumber: '',
                        errors: {},
                        fiatAmount: '',
                        fiatCoin: '',
                    });
                })
                .catch(err => {
                    this.setState({
                        disabledSubmitBtn: false,
                    });

                    if (err === INVALID_DEPOSIT_FIAT_AMOUNT) {
                        message.error(t('error.invalid_amount_to_withdraw'), 5);
                    } else {
                        message.error(err, 5);
                    }
                });
        }
    };

    selectOnChange = (value, name, handle, amount) => {
        if (value === 'CRYPTO_DEPOSIT') {
            this.openModalDeposit(handle);
        } else if (value === 'FIAT_DEPOSIT') {
            this.setState({
                isShowFiatDepositModal: true,
                cryptoCoinHandle: handle,
            });
        } else if (value === 'CRYPTO_WITHDRAW') {
            this.openModalWithDraw(handle, amount);
        } else if (value === 'FIAT_WITHDRAW') {
            this.setState({
                isShowFiatWithdrawalModal: true,
                cryptoCoinHandle: handle,
            });
        }
    };

    successfulCopied = () => {
        const { t } = this.props;
        message.success(t('general.copySuccess'), 5);
    };

    render() {
        const {
            wallet,
            loading,
            error,
            openModalState,
            isShowFiatDepositModal,
            isShowFiatWithdrawalModal,
            depositModal,
            allCoins,
            getDeposit,
            selectedDepositCoin,
            selectedWithDrawCoin,
            spinNewAdress,
            selectedWithDrawAmomount,
            getNewAdress,
            filterCurrentCoinData,
            modalLoading,
            isDisabledWithdrawSubmitBtn,
            fiatAmount,
            fiatCoin,
            disabledSubmitBtn,
            cryptoCoinHandle,
            cardNumber,
            filteredFiatCoin,
            cryptoLimits,
            totalPriceInFiat,
            withDrawData: {
                coin: withDrawCoin, amount, toAddress, memo,
            },
            errors: {
                amountError,
                toAddressError,
                memoError,
                fiatCoinError,
                fiatAmountError,
                cardNumberError,
            },
        } = this.state;

        const hasData = !(loading || error);
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <WalletView
                trade={this.trade}
                amount={amount}
                modalLoading={modalLoading}
                toAddress={toAddress}
                memo={memo}
                memoError={memoError}
                wallet={wallet}
                fiatCoin={fiatCoin}
                allCoins={allCoins}
                amountError={amountError}
                toAddressError={toAddressError}
                withDrawCoin={withDrawCoin}
                depositModal={depositModal}
                getDeposit={getDeposit}
                getNewAdress={getNewAdress}
                spinNewAdress={spinNewAdress}
                selectedDepositCoin={selectedDepositCoin}
                selectedWithDrawCoin={selectedWithDrawCoin}
                openModalState={openModalState}
                isShowFiatDepositModal={isShowFiatDepositModal}
                isShowFiatWithdrawalModal={isShowFiatWithdrawalModal}
                selectedWithDrawAmomount={selectedWithDrawAmomount}
                filterCurrentCoinData={filterCurrentCoinData}
                isDisabledWithdrawSubmitBtn={isDisabledWithdrawSubmitBtn}
                fiatCoinError={fiatCoinError}
                fiatAmountError={fiatAmountError}
                cryptoCoinHandle={cryptoCoinHandle}
                disabledSubmitBtn={disabledSubmitBtn}
                cardNumber={cardNumber}
                cardNumberError={cardNumberError}
                fiatAmount={fiatAmount}
                totalPriceInFiat={totalPriceInFiat}
                filteredFiatCoin={filteredFiatCoin}
                cryptoLimits={cryptoLimits}
                inputOnChange={this.inputOnChange}
                closeModal={this.closeModal}
                generateNewAdress={this.generateNewAdress}
                openModalDeposit={this.openModalDeposit}
                withdrawOnSubmit={this.withdrawOnSubmit}
                chooseCurrency={this.chooseCurrency}
                chooseWithDrawCoin={this.chooseWithDrawCoin}
                withdrawInputOnchange={this.withdrawInputOnchange}
                totalBalanceRef={this.totalBalanceRef}
                totalBalanceTitleRef={this.totalBalanceTitleRef}
                successfulCopied={this.successfulCopied}
                availableBalanceBalanceRef={this.availableBalanceBalanceRef}
                availableBalanceBalanceTitleRef={this.availableBalanceBalanceTitleRef}
                selectOnChange={this.selectOnChange}
                submitFiatDeposit={this.submitFiatDeposit}
                submitFiatWithdraw={this.submitFiatWithdraw}
            />
        ) : null;

        if (openModalState) {
            document.querySelectorAll('.filter').forEach(item => {
                item.style.filter = 'grayscale(100%)';
            });
        } else {
            document.querySelectorAll('.filter').forEach(item => {
                item.style.filter = 'none';
            });
        }

        return (
            <Fragment>
                {spinner}
                {content}
            </Fragment>
        );
    }
}

WalletContainer.defaultProps = {
    t: {},
    history: {},
    dispatch: () => {},
};

WalletContainer.propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    dispatch: PropTypes.func,
};

const mapStateToProps = state => {
    const {
        currentDashboard: { currentDashboard },
    } = state;

    return {
        currentDashboard,
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter,
)(WalletContainer);
