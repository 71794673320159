/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { message } from 'antd';
import { withTranslation } from 'react-i18next';

import withGetService from '../../../../hoc';
import ProfileView from './profile-page-view';
import GetService from '../../../../../services/get-service';
import PostService from '../../../../../services/post-service';
import DeleteService from '../../../../../services/delete-service';
import Spinner from '../../../../spinner';
import ErrorIndicator from '../../../error-page/error-indicator';
import { compose } from '../../../../../utils';
import { collapsePanelActions } from '../../../../../actions';
import fetchUserInfoAction from '../../../../../actions/getUserInfo.actions';
import {
    USER_HAS_PENDING_ORDERS,
    MAXIMUM_CARDS_LIMIT_REACHED,
    UNABLE_TO_BLOCK_USER_HAS_ARBITRAGE_ORDERS,
    UNABLE_TO_BLOCK_USER_HAS_PENDING_ORDERS,
} from '../../../../../constants';

export class ProfileContainer extends Component {
    getService = new GetService();

    postService = new PostService();

    deleteService = new DeleteService();

    state = {
        userIfo: {},
        userReviews: {},
        profileId: '',
        loading: true,
        loadingAvatar: false,
        isBlocked: false,
        blockBtnRequest: false,
        cardHolder: '',
        cardNumber: '',
        isOpenedCardWrapper: false,
        cardList: [],
        errors: {
            cardNumberError: '',
        },
        isDeleteIconDisable: false,
    };

    componentDidMount() {
        const { id } = this.props;
        if (id) {
            this.loadProfileInfo();
            this.loadAllCards();
        }

        document.documentElement.style.overflowY = 'overlay';
    }

    componentDidUpdate(prevProps) {
        const {
            id,
            location: { pathname },
            filterParams,
        } = this.props;

        if (id !== prevProps.id || pathname !== prevProps.location.pathname) {
            this.loadProfileInfo();
            this.loadAllCards();
        }

        if (
            JSON.stringify(filterParams) !== JSON.stringify(prevProps.filterParams)
            && filterParams !== prevProps.filterParams
        ) {
            this.loadReviews();
        }
    }

    loadReviews = () => {
        const { match, filterParams: { currentPageNumber } } = this.props;
        const {
            params: { id },
        } = match;
        if (id) {
            this.getService
                .getUserReviews(id, currentPageNumber)
                .then(userReviews => {
                    this.setState({
                        userReviews,
                    });
                })
                .catch(this.onError);
        }
    }

    loadProfileInfo = () => {
        const { match, id: profileId } = this.props;
        const {
            params: { id },
        } = match;
        if (id) {
            this.getService
                .getUserProfile(id)
                .then(userIfo => {
                    this.setState({
                        userIfo,
                        profileId,
                        loading: false,
                        error: false,
                    });
                })
                .catch(this.onError);

            this.getService
                .getUserReviews(id)
                .then(userReviews => {
                    this.setState({
                        userReviews,
                    });
                })
                .catch(this.onError);

            this.getService
                .getBlackList()
                .then(blacklist => {
                    const findUserBlocked = blacklist.find(item => item.user.id === +id);
                    const isBlocked = !!findUserBlocked;

                    this.setState({
                        isBlocked,
                    });
                })
                .catch(this.onError);
        }
    };

    loadAllCards = () => {
        this.getService
            .getUserCard()
            .then(cardList => {
                this.setState({
                    cardList,
                });
            })
            .catch(this.onError);
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    handleCardChange = event => {
        const { t } = this.props;
        const { name, value } = event.target;
        const trimValue = value.replace(/\s/g, '');
        const onlyNumbers = /^[0-9]+$/;

        if (value === '' || onlyNumbers.test(value)) {
            this.setState({
                [name]: trimValue.slice(0, 24),
            }, () => {
                const { errors } = this.state;
                const trimСardNumber = this.state.cardNumber.replace(/\s+/g, '');
                if (trimСardNumber.length < 13) {
                    this.setState({
                        errors: {
                            ...errors,
                            cardNumberError: t('error.card'),
                        },
                    });
                }

                if (trimСardNumber.length > 13) {
                    this.setState({
                        errors: {
                            ...errors,
                            cardNumberError: '',
                        },
                    });
                }
            });
        }
    };

    cardHolderChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    };

    switchCardWrapper = () => {
        const { isOpenedCardWrapper } = this.state;

        this.setState({
            isOpenedCardWrapper: !isOpenedCardWrapper,
        });
    };

    saveCard = () => {
        const { cardHolder, cardNumber } = this.state;
        const { t } = this.props;
        const errors = {};

        const trimСardNumber = cardNumber.replace(/\s+/g, '');

        if (trimСardNumber.length < 13) {
            errors.cardNumberError = t('error.card');
        }

        if (cardHolder.length < 1) {
            errors.cardHolderError = t('error.input', { count: 1 });
        }

        if (cardHolder.length > 40) {
            errors.cardHolderError = t('error.input', { count: 40 });
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
        } else {
            this.setState({
                errors: {},
            });

            const trimCardNumber = cardNumber.replace(/\s+/g, '');
            const data = {
                cardHolder,
                cardNumber: trimCardNumber,
            };
            this.postService
                .createNewCard(data)
                .then(() => {
                    this.loadAllCards();
                    this.setState({
                        cardHolder: '',
                        cardNumber: '',
                        isOpenedCardWrapper: false,
                    });
                    message.success(t('general.cardCreateSuccessfull'), 5);
                })
                .catch(err => {
                    if (err === MAXIMUM_CARDS_LIMIT_REACHED) {
                        message.error(t('error.maximumCardsLimitReached'), 5);
                    } else {
                        message.error(err, 5);
                    }
                });
        }
    };

    deleteCard = id => {
        const { t } = this.props;

        this.setState({
            isDeleteIconDisable: true,
        });

        this.deleteService
            .deleteCard(id)
            .then(() => {
                this.loadAllCards();
                message.success(t('general.cardDeletedSuccessfull'), 5);
                setTimeout(() => {
                    this.setState({
                        isDeleteIconDisable: false,
                    });
                }, 1000);
            })
            .catch(err => {
                console.log(err, 'error');
                this.setState({
                    isDeleteIconDisable: false,
                });
            });
    };

    // Block account
    blockAccount = id => {
        const { t } = this.props;
        this.setState({
            blockBtnRequest: true,
        });

        this.postService
            .blockUser(id)
            .then(() => {
                message.success(t('settings.accountBlocked'), 5);
                this.setState({
                    isBlocked: true,
                    blockBtnRequest: false,
                });
            })
            .catch(err => {
                if (err === USER_HAS_PENDING_ORDERS) {
                    message.error(t('error.userHasPendingOrders'), 5);
                } else if (err === UNABLE_TO_BLOCK_USER_HAS_ARBITRAGE_ORDERS) {
                    message.error(t('error.unableBlockUserHasArbitrageOrders'), 5);
                } else if (err === UNABLE_TO_BLOCK_USER_HAS_PENDING_ORDERS) {
                    message.error(t('error.unableBlockUserHasPendingOrders'), 5);
                } else {
                    message.error(err, 5);
                }

                this.setState({
                    blockBtnRequest: false,
                });
            });
    };

    unBlockAccount = id => {
        const { t } = this.props;
        this.setState({
            blockBtnRequest: true,
        });
        this.deleteService
            .unBlockUser(id)
            .then(() => {
                this.setState({
                    isBlocked: false,
                    blockBtnRequest: false,
                });
                message.success(t('settings.accountUnlocked'), 5);
            })
            .catch(err => {
                console.log(err, 'error');
                this.setState({
                    blockBtnRequest: false,
                });
            });
    };

    beforeUpload = file => {
        const { t } = this.props;
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error(t('general.youCanUploadPNGFile'), 5);
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error(t('general.imageMustSmallerThan10MB'), 5);
        }
        return false;
    };

    uploadPassportChange = data => {
        const { t } = this.props;
        const isJpgOrPng = data.file.type === 'image/jpeg' || data.file.type === 'image/png';
        if (isJpgOrPng) {
            const { fetchUserInfo = () => {} } = this.props;
            const formData = new FormData();
            const user = JSON.parse(localStorage.getItem('user'));

            formData.append('file', data.file);
            fetch(`${process.env.REACT_APP_API_URL}/api/user/logo`, {
                method: 'post',
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                },
                body: formData,
            })
                .then(res => {
                    if (res.status !== 200) {
                        res.json().then(() => {
                            message.error(t('general.avatarNotUploaded'), 5);
                        });
                    } else {
                        res.json();
                        message.success(t('general.avatarUploadedSuccessfully'), 5);
                        fetchUserInfo();
                    }
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        }
    };

    render() {
        const {
            userIfo,
            userReviews,
            loading,
            error,
            loadingAvatar,
            profileId,
            isBlocked,
            blockBtnRequest,
            cardHolder,
            cardNumber,
            isOpenedCardWrapper,
            cardList,
            isDeleteIconDisable,
            errors: { cardNumberError, cardHolderError },
        } = this.state;

        const {
            userConfirmationStatus,
            logoSrc,
            openSettingsTab,
            authName,
            isConfirmed,
        } = this.props;

        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <ProfileView
                userIfo={userIfo}
                authName={authName}
                isBlocked={isBlocked}
                profileId={profileId}
                userReviews={userReviews}
                loadingAvatar={loadingAvatar}
                logoSrc={logoSrc}
                isDeleteIconDisable={isDeleteIconDisable}
                cardNumberError={cardNumberError}
                cardHolderError={cardHolderError}
                blockBtnRequest={blockBtnRequest}
                isConfirmed={isConfirmed}
                cardList={cardList}
                isOpenedCardWrapper={isOpenedCardWrapper}
                userConfirmationStatus={userConfirmationStatus}
                blockAccount={this.blockAccount}
                unBlockAccount={this.unBlockAccount}
                uploadPassportChange={this.uploadPassportChange}
                beforeUpload={this.beforeUpload}
                openSettingsTab={openSettingsTab}
                cardHolder={cardHolder}
                cardNumber={cardNumber}
                saveCard={this.saveCard}
                deleteCard={this.deleteCard}
                switchCardWrapper={this.switchCardWrapper}
                handleCardChange={this.handleCardChange}
                cardHolderChange={this.cardHolderChange}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        currentCollapsePanel: { defaultCollapseActiveKey },
        currentFilter: { filterParams },
        getUserInfo: {
            userInfo: {
                id,
                username: authName,
                userConfirmationStatus,
                logoSrc,
                isConfirmed,
            },
            loading,
        },
    } = state;

    return {
        defaultCollapseActiveKey,
        id,
        loading,
        userConfirmationStatus,
        logoSrc,
        authName,
        isConfirmed,
        filterParams,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        fetchUserInfo: fetchUserInfoAction(getService),
        openSettingsTab: id => collapsePanelActions.selectCollapsePanel(id),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(ProfileContainer);
