import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { personalAreaPath, newOrder } from '../../../constants';
import { compose } from '../../../utils';
import { authModalActions } from '../../../actions';
import style from './emptyFilterResult.module.scss';

const EmptyFilterResult = ({ loggedIn, openLogin = () => {} }) => {
    const { t } = useTranslation();

    return (
        <div className={style.emptyFilterResult}>
            <p className={style.emptyFilterResult_text}>
                {t('general.emptyFilterResult')}
            </p>
            <div className={style.emptyFilterResult_createYourOwn}>
                <p className={style.emptyFilterResult_text}>
                    {t('general.createYourOwn')}
                </p>
                {loggedIn ? (
                    <Link
                        to={`${personalAreaPath}${newOrder}`}
                        className={style.emptyFilterResult_link}
                    >
                        {t('orders.title')}.
                    </Link>
                ) : (
                    <div
                        className={style.emptyFilterResult_link}
                        onClick={() => openLogin()}
                    >
                        {t('orders.title')}.
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
    } = state;

    return {
        loggedIn,
    };
};

const mapDispatchToProps = dispatch => ({
    openLogin: () => {
        dispatch(authModalActions.openLogin());
    },
});

EmptyFilterResult.defaultProps = {
    loggedIn: false,
    openLogin: () => {},
};

EmptyFilterResult.propTypes = {
    loggedIn: PropTypes.bool,
    openLogin: PropTypes.func,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(EmptyFilterResult);
