/* eslint-disable */
import { userCountryConstants } from '../constants';

export function getCurrentLocale(state, action) {
    if (state === undefined) {
        return {
            locale: {},
        };
    }
    switch (action.type) {
        case userCountryConstants.GET_CURRENT_LOCALE:
            return {
                locale: action.locale,
            };
        default:
            return state;
    }
}
