import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import OutsideClickHandler from 'react-outside-click-handler';
import { Badge, notification, message } from 'antd';
import Moment from 'react-moment';

import GetService from '../../../../services/get-service';
import PatchService from '../../../../services/patch-service';
import withGetService from '../../../hoc';
import { compose } from '../../../../utils';
import { userActions } from '../../../../actions';
import fetchUserInfoAction from '../../../../actions/getUserInfo.actions';
import getAllCoinsAction from '../../../../actions/getAllCoins.actions';
import {
    personalAreaPath,
    p2p,
    chat,
    room,
    settingsPath,
    profilePath,
    notificationTranslation,
    handleTranslation,
    NOTIFICATION_CREATED,
} from '../../../../constants';

import Img from '../../../small-components/img';
import logout from '../images/logout.svg';
import settingsImg from '../images/settings.svg';
import p2pImage from '../images/p2p.svg';
import arrowDown from '../images/arrowDown.svg';

import style from '../header.module.scss';
import '../header.scss';

export class UserComponent extends Component {
    mounted = true;

    getService = new GetService();

    patchService = new PatchService();

    state = {
        showNotificationState: false,
        dropDownMenuState: false,
        notifications: {},
    };

    componentDidMount() {
        if (this.mounted) {
            const { fetchUserInfo = () => {}, getAllCoins = () => {} } = this.props;
            fetchUserInfo();
            getAllCoins();
            this.loadData();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    connectSocket = () => {
        const { t, emit: socket } = this.props;
        if (this.mounted && Object.keys(socket).length > 0) {
            socket.on(NOTIFICATION_CREATED, data => {
                const {
                    handle,
                    initiator: { username },
                } = data;
                const {
                    notifications: { count, userNotifications },
                } = this.state;
                if (this.mounted) {
                    userNotifications.unshift(data);
                    this.setState({
                        notifications: {
                            count: count + 1,
                            userNotifications,
                        },
                    });
                    const notificationText = notificationTranslation(handle, username, t);
                    const handleText = handleTranslation(handle, username, t);
                    this.openNotification(handleText, notificationText);
                }
            });
        }
    };

    openNotification = (handleText, notificationText) => {
        const args = {
            message: handleText,
            description: notificationText,
            duration: 5,
        };
        notification.open(args);
    };

    loadData = () => {
        this.getService
            .getNotifications()
            .then(notifications => {
                if (this.mounted) {
                    this.setState({
                        notifications,
                    });
                    this.connectSocket();
                }
            })
            .catch(this.onError);
    };

    showNotification = () => {
        const {
            showNotificationState,
            notifications: { userNotifications },
        } = this.state;
        if (this.mounted) {
            this.setState({
                showNotificationState: !showNotificationState,
            });
        }
        this.patchService
            .readAllNotifications()
            .then(() => {
                if (this.mounted) {
                    this.setState({
                        notifications: {
                            userNotifications: [...userNotifications],
                            count: 0,
                        },
                    });
                }
            })
            .catch(err => {
                message.error(err, 5);
            });
    };

    showdropDownMenu = () => {
        if (this.mounted) {
            const { dropDownMenuState } = this.state;
            this.setState({
                dropDownMenuState: !dropDownMenuState,
            });
        }
    };

    closeNotification = () => {
        if (this.mounted) {
            this.setState({
                showNotificationState: false,
            });
        }
    };

    closeDropdown = () => {
        if (this.mounted) {
            this.setState({
                dropDownMenuState: false,
            });
        }
    };

    render() {
        const {
            showNotificationState,
            dropDownMenuState,
            notifications: { count, userNotifications },
        } = this.state;

        const { t, logOut, userInfo } = this.props;
        const { logoSrc, username, id } = userInfo;

        return (
            <Fragment>
                <div className={style.header__rightSide_userComponent}>
                    <OutsideClickHandler onOutsideClick={this.closeNotification}>
                        <div className="animationBell">
                            <div
                                className={style.header__notificationWrapper}
                                onClick={this.showNotification}
                            >
                                <Badge count={count}>
                                    <svg
                                        className={style.header__rightSide_bell}
                                        width="17"
                                        height="18"
                                        viewBox="0 0 17 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.3182 13.1905H1.68182C0.772727 13.1905 0.772727
                                            11.7563 1.68182 11.7563C2.43493 11.7563 3.04545 11.1142
                                            3.04545 10.3221V6.73669C3.04545 3.56841 5.48754 1 8.5
                                            1C11.5125 1 13.9545 3.56841 13.9545 6.73669V10.3221C13.9545
                                            11.1142 14.5651 11.7563 15.3182 11.7563C16.2273 11.7563
                                            16.2273 13.1905 15.3182 13.1905Z"
                                            stroke="#fff"
                                            strokeWidth="0.761905"
                                        />
                                        <path
                                            d="M10.6376 15.9079C10.1955 16.5822 9.38106 16.9972 8.5
                                            16.9972C7.61894 16.9972 6.80453 16.5822 6.36244
                                            15.9079C6.04389 15.4221 6.44011 14.8135 7.07496
                                            14.8135H9.92504C10.5599 14.8135 10.9561 15.4221
                                            10.6376 15.9079Z"
                                            stroke="#fff"
                                            strokeWidth="0.761905"
                                        />
                                    </svg>
                                </Badge>

                                {showNotificationState ? (
                                    <div className={style.header__notificationContent}>
                                        <div
                                            className={
                                                style.header__notificationContent_overflowY
                                            }
                                        >
                                            {userNotifications.length === 0 ? (
                                                <p
                                                    className={
                                                        style.header__noNotification
                                                    }
                                                >
                                                    {' '}
                                                    {t('general.noNotifications')}
                                                </p>
                                            ) : (
                                                <Fragment>
                                                    {userNotifications.map(item => {
                                                        const {
                                                            createdAt,
                                                            handle,
                                                            initiator: { username: name },
                                                        } = item;

                                                        const notificationText = notificationTranslation(
                                                            handle,
                                                            name,
                                                            t,
                                                        );

                                                        return (
                                                            <Link
                                                                to={`${personalAreaPath}${chat}${room}/${item.chat ? item.chat.id : ''}`}
                                                                className={
                                                                    style.header__notificationContent_item
                                                                }
                                                                key={item.id}
                                                            >
                                                                <p
                                                                    className={
                                                                        style.header__notification
                                                                    }
                                                                >
                                                                    {notificationText}
                                                                </p>
                                                                <p
                                                                    className={
                                                                        style.header__notificationTime
                                                                    }
                                                                >
                                                                    <Moment
                                                                        format="DD/MM/YYYY HH:mm"
                                                                        unix
                                                                    >
                                                                        {createdAt}
                                                                    </Moment>
                                                                </p>
                                                            </Link>
                                                        );
                                                    })}
                                                </Fragment>
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </OutsideClickHandler>

                    <div className="animationPesonalAreaLink">
                        <Link
                            to={`${personalAreaPath}${profilePath}/${id}`}
                            className={style.header__rightSide_pesonalAreaLink}
                        >
                            <p className={style.header__rightSide_name}>{username}</p>

                            {logoSrc ? (
                                <img
                                    src={logoSrc}
                                    className="headerUserAvatar"
                                    alt="logo"
                                />
                            ) : (
                                <Avatar
                                    className="headerUserAvatar"
                                    unstyled
                                    name={username}
                                />
                            )}
                        </Link>
                    </div>
                    <div className="animationPesonalAreaLink">
                        <OutsideClickHandler onOutsideClick={this.closeDropdown}>
                            <div
                                onClick={this.showdropDownMenu}
                                className={style.header__dropDownMenu}
                            >
                                <img
                                    className={style.header__dropDownMenu_arrow}
                                    src={arrowDown}
                                    alt="arrowDown"
                                />
                                {dropDownMenuState ? (
                                    <div className={style.header__dropDownMenu_select}>
                                        <Link
                                            to={`${personalAreaPath}${p2p}`}
                                            className={style.header__dropDownMenu_row}
                                        >
                                            <Img
                                                src={p2pImage}
                                                alt="profile"
                                                className={
                                                    style.header__dropDownMenu_icon
                                                }
                                            />
                                            <p
                                                className={
                                                    style.header__dropDownMenu_text
                                                }
                                            >
                                                P2P
                                            </p>
                                        </Link>
                                        <Link
                                            to={`${personalAreaPath}${settingsPath}`}
                                            className={style.header__dropDownMenu_row}
                                        >
                                            <Img
                                                src={settingsImg}
                                                alt="settings"
                                                className={
                                                    style.header__dropDownMenu_icon
                                                }
                                            />

                                            <p
                                                className={
                                                    style.header__dropDownMenu_text
                                                }
                                            >
                                                {t('general.settings')}
                                            </p>
                                        </Link>
                                        <div
                                            className={style.header__dropDownMenu_row}
                                            onClick={() => logOut()}
                                        >
                                            <Img
                                                src={logout}
                                                alt="logout"
                                                className={
                                                    style.header__dropDownMenu_icon
                                                }
                                            />

                                            <p
                                                className={
                                                    style.header__dropDownMenu_text
                                                }
                                            >
                                                {t('general.logout')}
                                            </p>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </OutsideClickHandler>
                    </div>
                </div>
            </Fragment>
        );
    }
}

UserComponent.defaultProps = {
    t: () => {},
    logOut: () => {},
    fetchUserInfo: () => {},
    getAllCoins: () => {},
    userInfo: {},
    emit: {},
};

UserComponent.propTypes = {
    t: PropTypes.func,
    logOut: PropTypes.func,
    fetchUserInfo: PropTypes.func,
    getAllCoins: PropTypes.func,
    userInfo: PropTypes.object,
    emit: PropTypes.object,
};

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
        getUserInfo: { userInfo },
        socketConnected: { emit },
    } = state;

    return {
        loggedIn,
        userInfo,
        emit,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        fetchUserInfo: fetchUserInfoAction(getService),
        getAllCoins: getAllCoinsAction(getService),
        logOut: () => userActions.logout(),
    },
    dispatch,
);

export default compose(
    withTranslation(),
    withGetService(),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(UserComponent);
