import { userConstants } from '../constants';

const userInfoRequested = () => ({
    type: userConstants.FETCH_USER_REQUEST,
});

const userInfoLoaded = data => ({
    type: userConstants.FETCH_USER_SUCCESS,
    payload: data,
});

const userInfoError = error => ({
    type: userConstants.FETCH_USER_FAILURE,
    payload: error,
});

const fetchUserInfoAction = getService => () => dispatch => {
    dispatch(userInfoRequested());
    getService
        .getUserInfo()
        .then(data => dispatch(userInfoLoaded(data)))
        .catch(err => dispatch(userInfoError(err)));
};

export default fetchUserInfoAction;
