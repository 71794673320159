/* eslint-disable */

import { modalTradeConstants } from '../constants';

function openModalTrade(id) {
    return {
        type: modalTradeConstants.OPEN_MODAL_TRADE,
        id,
    };
}

function closeModalTrade() {
    return {
        type: modalTradeConstants.CLOSE_MODAL_TRADE,
    };
}

function inputChanges(inputValues) {
    return {
        type: modalTradeConstants.INPUT_CHANGES,
        inputValues,
    };
}

function inputErrors(errors) {
    return {
        type: modalTradeConstants.INPUT_ERRORS,
        errors,
    };
}

export const modalTradeActions = {
    openModalTrade,
    closeModalTrade,
    inputChanges,
    inputErrors,
};
