import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import GetService from '../../../../services/get-service';
import FilterBlock from '../../../layouts/filter-block';
import withGetService from '../../../hoc';
import { modalTradeActions, FilterActions, authModalActions } from '../../../../actions';
import { compose } from '../../../../utils';
import TableRowView from './orders-view';
import Spinner from '../../../spinner';
import ErrorIndicator from '../../error-page/error-indicator';
import getAllOrdersAction from '../../../../actions/getAllOrders.actions';
import getAllCoinsAction from '../../../../actions/getAllCoins.actions';
import {
    personalAreaPath,
    loginPath,
    registartionPath,
    referralSystemPath,
} from '../../../../constants';

import style from './orders.module.scss';

export class Orders extends Component {
    getService = new GetService();

    mounted = true;

    state = {
        cardList: [],
    };

    componentDidMount() {
        const {
            history,
            loggedIn,
            location: { pathname, search },
            openLogin,
            openSignUp,
        } = this.props;
        if (this.mounted) {
            const { fetchAllOrders = () => {}, getAllCoins = () => {} } = this.props;
            localStorage.removeItem('ordersStorageFilter');
            fetchAllOrders();
            getAllCoins();
            this.clearFilter();
        }
        if (pathname === loginPath) {
            if (loggedIn) {
                history.push('/');
            } else {
                openLogin();
            }
        } else if (pathname === registartionPath) {
            if (loggedIn) {
                if (search) {
                    history.push(
                        `${personalAreaPath}${referralSystemPath}${search}`,
                    );
                } else {
                    history.push('/');
                }
            } else {
                openSignUp();
            }
        }
        if (loggedIn) {
            this.loadAllCards();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.mounted) {
            const { filterParams } = this.props;
            if (filterParams !== prevProps.filterParams) {
                const params = Object.keys(filterParams)
                    .map(
                        keyParams => `${encodeURIComponent(keyParams)}=${encodeURIComponent(
                            filterParams[keyParams],
                        )}`,
                    )
                    .join('&');

                const { fetchAllOrders = () => {} } = this.props;
                fetchAllOrders(params);
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    clearFilter = () => {
        const { dispatchClearFilter } = this.props;
        dispatchClearFilter();
    };

    loadAllCards = () => {
        this.getService
            .getUserCard()
            .then(cardList => {
                this.setState({
                    cardList,
                });
            })
            .catch(this.onError);
    };

    render() {
        const {
            orders, loading, error, openTrade, t,
        } = this.props;

        const { cardList } = this.state;

        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <TableRowView
                orders={orders}
                openTrade={id => openTrade(id)}
                cardList={cardList}
            />
        ) : null;

        return (
            <Fragment>
                <div className={style.orders__filter}>
                    <p className={style.orders__title}>{t('orders.title')}</p>
                    <FilterBlock />
                </div>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        modalTrade: { modalTrade },
        currentFilter: { filterParams },
        getAllOrders: { orders, error, loading },
        authentication: { loggedIn },
    } = state;

    return {
        modalTrade,
        filterParams,
        orders,
        error,
        loading,
        loggedIn,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        fetchAllOrders: getAllOrdersAction(getService),
        getAllCoins: getAllCoinsAction(getService),
        openTrade: id => modalTradeActions.openModalTrade(id),
        dispatchClearFilter: () => FilterActions.selectFilter({}),
        openLogin: () => authModalActions.openLogin(),
        openSignUp: () => authModalActions.openSignUp(),
    },
    dispatch,
);

Orders.defaultProps = {
    t: () => {},
    fetchAllOrders: () => {},
    getAllCoins: () => {},
    openTrade: () => {},
    dispatchClearFilter: () => {},
    openLogin: () => {},
    openSignUp: () => {},
    filterParams: {},
    orders: {},
    location: {},
    error: false,
    loading: true,
    history: {},
    loggedIn: false,
};

Orders.propTypes = {
    t: PropTypes.func,
    fetchAllOrders: PropTypes.func,
    getAllCoins: PropTypes.func,
    openTrade: PropTypes.func,
    dispatchClearFilter: PropTypes.func,
    openLogin: PropTypes.func,
    openSignUp: PropTypes.func,
    filterParams: PropTypes.object,
    orders: PropTypes.object,
    location: PropTypes.object,
    error: PropTypes.any,
    loading: PropTypes.bool,
    history: PropTypes.object,
    loggedIn: PropTypes.bool,
};

export default compose(
    withGetService(),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(Orders);
