import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { message } from 'antd';

import HistoryCriptoView from './history-crypto-view';
import GetService from '../../../../../../services/get-service';
import PostService from '../../../../../../services/post-service';
import Spinner from '../../../../../spinner';
import ErrorIndicator from '../../../../error-page/error-indicator';
import { compose } from '../../../../../../utils';
import { REJECTED, WAITING, UNABLE_TO_CANCEL_REQUEST } from '../../../../../../constants';

export class HistoryCriptoContainer extends Component {
    getService = new GetService();

    postService = new PostService();

    state = {
        historyTransactions: {},
        withdrawRequests: [],
        rejectedRequests: [],
        waitingRequests: [],
        loading: true,
        filterParams: {},
        newDate: '',
    };

    componentDidMount() {
        this.loadData();
        this.loadWithdrawRequests();
    }

    componentDidUpdate(prevProps, prevState) {
        const { filterParams } = this.state;
        const { reduxParams } = this.props;

        if (
            filterParams !== prevState.filterParams
            || reduxParams !== prevProps.reduxParams
        ) {
            const params = Object.keys(reduxParams)
                .map(
                    keyParams => `${encodeURIComponent(keyParams)}=${encodeURIComponent(
                        reduxParams[keyParams],
                    )}`,
                )
                .join('&');

            const stateParams = Object.keys(filterParams)
                .map(
                    keyParams => `${encodeURIComponent(keyParams)}=${encodeURIComponent(
                        filterParams[keyParams],
                    )}`,
                )
                .join('&');

            if (params && stateParams) {
                this.loadData(`${params}&${stateParams}`);
            } else if (params) {
                this.loadData(params);
            } else {
                this.loadData(stateParams);
            }
        }
    }

    datePicker = date => {
        const { filterParams } = this.state;
        const convertToUnix = moment(date).unix();
        this.setState({
            filterParams: {
                ...filterParams,
                date: convertToUnix,
            },
            newDate: date,
        });
    };

    selectFilter = (value, name) => {
        const { filterParams } = this.state;
        this.setState({
            filterParams: {
                ...filterParams,
                [name.props.name]: value,
            },
        });
    };

    deleteFilter = deleteFilter => {
        const { filterParams } = this.state;
        const copyFilterParams = Object.assign({}, filterParams);
        delete copyFilterParams[deleteFilter];
        this.setState({
            filterParams: {
                ...copyFilterParams,
            },
        });

        if (deleteFilter === 'date') {
            this.setState({
                newDate: '',
            });
        }
    };

    loadData = params => {
        this.getService
            .getUserTransactions(params)
            .then(historyTransactions => {
                this.setState({
                    historyTransactions,
                    loading: false,
                    error: false,
                });
            })
            .catch(this.onError);
    };

    loadWithdrawRequests = () => {
        this.getService
            .withdrawRequests()
            .then(withdrawRequests => {
                const rejectedRequests = withdrawRequests.filter(
                    item => item.status === REJECTED,
                );
                const waitingRequests = withdrawRequests.filter(
                    item => item.status === WAITING,
                );

                this.setState({
                    withdrawRequests: waitingRequests,
                    rejectedRequests,
                    waitingRequests,
                });
            })
            .catch(this.onError);
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    isShowRejectedRequests = status => {
        const { rejectedRequests, waitingRequests } = this.state;

        if (status === false) {
            this.setState({
                withdrawRequests: waitingRequests,
            });
        }

        if (status === true) {
            this.setState({
                withdrawRequests: rejectedRequests,
            });
        }
    };

    cancelWithdrawal = id => {
        const { withdrawRequests } = this.state;
        const { t } = this.props;

        this.postService
            .cancelWithdrawal(id)
            .then(() => {
                this.setState(() => {
                    const index = withdrawRequests.findIndex(
                        element => element.id === id,
                    );
                    const newArray = [
                        ...withdrawRequests.slice(0, index),
                        ...withdrawRequests.slice(index + 1),
                    ];

                    return {
                        withdrawRequests: newArray,
                    };
                });
                message.success(t('general.withdrawalRequestSuccessfullyCanceled'), 5);
            })
            .catch(err => {
                if (err === UNABLE_TO_CANCEL_REQUEST) {
                    message.error(t('error.unable_to_cancel_request'), 5);
                }
            });
    };

    render() {
        const {
            historyTransactions,
            loading,
            error,
            newDate,
            filterParams,
            withdrawRequests,
        } = this.state;
        const { allCoins } = this.props;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <HistoryCriptoView
                historyTransactions={historyTransactions}
                allCoins={allCoins}
                filterParams={filterParams}
                withdrawRequests={withdrawRequests}
                newDate={newDate}
                datePicker={this.datePicker}
                selectFilter={this.selectFilter}
                deleteFilter={this.deleteFilter}
                isShowRejectedRequests={this.isShowRejectedRequests}
                cancelWithdrawal={this.cancelWithdrawal}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        currentFilter: { filterParams: reduxParams },
        getAllCoins: { allCoins },
    } = state;

    return {
        reduxParams,
        allCoins,
    };
};

HistoryCriptoContainer.defaultProps = {
    t: () => {},
    reduxParams: {},
    allCoins: [],
};

HistoryCriptoContainer.propTypes = {
    t: PropTypes.func,
    reduxParams: PropTypes.object,
    allCoins: PropTypes.instanceOf(Array),
};

export default compose(withTranslation(), connect(mapStateToProps))(HistoryCriptoContainer);
