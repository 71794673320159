const ONE_DAY = '24H';
const LAST_WEEK = 'LAST_WEEK';
const LAST_MONTH = 'LAST_MONTH';
const ALL_TIME = 'ALL_TIME';

export {
    ONE_DAY,
    LAST_WEEK,
    LAST_MONTH,
    ALL_TIME,
};
