import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentLocaleActions } from '../../actions';
import { compose } from '../../utils';
import PostService from '../../services/post-service';
import './change-language.scss';

import 'react-flags-select/scss/react-flags-select.scss';
import 'antd/dist/antd.css';


function SelectLangeage({ dispatch, loggedIn }) {
    const postService = new PostService();
    const { i18n } = useTranslation();

    const onSelectFlag = countryCode => {
        i18n.changeLanguage(countryCode);
        localStorage.setItem('i18nextLngCrocobit', countryCode);
        dispatch(getCurrentLocaleActions.getCurrentLocale(countryCode));

        if (loggedIn) {
            postService
                .setLanguage(countryCode.toLowerCase())
                .catch(err => {
                    console.log(err, 'error');
                });
        }
    };


    const currentLang = localStorage.getItem('i18nextLngCrocobit');

    // Country codes here
    // https://github.com/TechnologyGeek12/react-region-flag-select/blob/master/src/lib/components/subComponents/data/countryCodes.txt

    return (
        <ReactFlagsSelect
            defaultCountry={currentLang}
            countries={['GB', 'RU', 'CN', 'ES', 'TR', 'ID', 'KR']}
            customLabels={{
                GB: 'EN', RU: 'RU', CN: 'CN', ES: 'ES', TR: 'TR', ID: 'ID', KR: 'KO',
            }}
            showSelectedLabel
            className="change-language-flags"
            onSelect={onSelectFlag}
        />
    );
}

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
    } = state;

    return {
        loggedIn,
    };
};

SelectLangeage.defaultProps = {
    dispatch: () => {},
    loggedIn: false,
};

SelectLangeage.propTypes = {
    dispatch: PropTypes.func,
    loggedIn: PropTypes.bool,
};

export default compose(
    connect(mapStateToProps),
)(SelectLangeage);
