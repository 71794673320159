/* eslint-disable react/no-array-index-key */
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import GetService from '../../../services/get-service';
import { compose } from '../../../utils';
import Spinner from '../../spinner';
import ErrorIndicator from '../error-page/error-indicator';
import bitcoin from '../../assets/images/coins/bitcoin.svg';
import dash from '../../assets/images/coins/dash.svg';
import edc from '../../assets/images/coins/edc.png';
import ethereum from '../../assets/images/coins/ethereum.svg';
import litecoin from '../../assets/images/coins/litecoin.svg';
import zcash from '../../assets/images/coins/zcash.svg';
import EDC20 from '../../assets/images/coins/cde.svg';
import SFC from '../../assets/images/coins/sfc.svg';
import CROCO from '../../assets/images/coins/croco.svg';
import style from './fee-page.module.scss';

export class Fee extends Component {
    getService = new GetService();

    mounted = true;

    state = {
        coinsPublic: [],
        loading: true,
        error: false,
    };

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadData = () => {
        this.getService
            .coinsPublic()
            .then(coinsPublic => {
                const cryptoCoin = coinsPublic.filter(item => item.isCrypto === true);
                this.setState({
                    coinsPublic: cryptoCoin,
                    error: false,
                    loading: false,
                });
            })
            .catch(this.onError);
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    render() {
        const { loading, error, coinsPublic } = this.state;
        const { t } = this.props;

        const hasData = !(loading || error);
        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;

        const logoItem = classNames(
            style.fee__table_item,
            style.fee__table_logoNameWrapper,
        );

        const itemColumn = classNames(style.fee__table_item, style.fee__table_itemColumn);

        const tradeWithdrawDeposit = classNames(
            style.fee__table_item,
            style.fee__table_tradeWithdrawDeposit,
        );

        return (
            <Fragment>
                {hasData ? (
                    <div className={style.fee}>
                        <h1 className={style.fee__title}>{t('fee.title')}</h1>
                        <div className={style.fee__table}>
                            <div className={style.fee__table_column}>
                                <p className={style.fee__table_label}>{t('fee.coin')}</p>
                                {coinsPublic.length > 0
                                    && coinsPublic.map(item => {
                                        const { handle, name } = item;

                                        let logo = '';
                                        if (handle === 'ETH') {
                                            logo = ethereum;
                                        } else if (handle === 'BTC') {
                                            logo = bitcoin;
                                        } else if (handle === 'DASH') {
                                            logo = dash;
                                        } else if (handle === 'EDC') {
                                            logo = edc;
                                        } else if (handle === 'LTC') {
                                            logo = litecoin;
                                        } else if (handle === 'ZEC') {
                                            logo = zcash;
                                        } else if (handle === 'EDC20') {
                                            logo = EDC20;
                                        } else if (handle === 'SFC') {
                                            logo = SFC;
                                        } else if (handle === 'CROCO') {
                                            logo = CROCO;
                                        } else {
                                            logo = null;
                                        }
                                        return (
                                            <div key={handle} className={logoItem}>
                                                <img
                                                    className={style.fee__table_logo}
                                                    src={logo}
                                                    alt="logo"
                                                />
                                                <p className={style.fee__table_handle}>
                                                    {handle}
                                                </p>
                                                <p className={style.fee__table_coinName}>
                                                    {name}
                                                </p>
                                            </div>
                                        );
                                    })}
                            </div>
                            <div className={style.fee__table_column}>
                                <p className={style.fee__table_label}>{t('fee.trade')}</p>
                                {coinsPublic.length > 0
                                    && coinsPublic.map((item, index) => {
                                        const {
                                            commission: { percentage },
                                        } = item;
                                        return (
                                            <p
                                                key={index}
                                                className={tradeWithdrawDeposit}
                                            >
                                                {percentage} %
                                            </p>
                                        );
                                    })}
                            </div>
                            <div className={style.fee__table_column}>
                                <p className={style.fee__table_label}>
                                    {t('fee.minimumWithdrawal')}
                                </p>
                                {coinsPublic.length > 0
                                    && coinsPublic.map((item, index) => {
                                        const {
                                            handle,
                                            commission: { minimumWithdrawValue },
                                        } = item;
                                        return (
                                            <p
                                                key={index}
                                                className={tradeWithdrawDeposit}
                                            >
                                                {minimumWithdrawValue} {' '} {handle}
                                            </p>
                                        );
                                    })}
                            </div>
                            <div className={style.fee__table_column}>
                                <p className={style.fee__table_label}>
                                    {t('fee.minimumDeposit')}
                                </p>
                                {coinsPublic.length > 0
                                    && coinsPublic.map((item, index) => {
                                        const {
                                            handle,
                                            commission: { minimalDepositValue },
                                        } = item;
                                        return (
                                            <p
                                                key={index}
                                                className={tradeWithdrawDeposit}
                                            >
                                                {minimalDepositValue}{' '} {handle}
                                            </p>
                                        );
                                    })}
                            </div>
                            <div className={style.fee__table_column}>
                                <p className={style.fee__table_label}>
                                    {t('fee.depositFee')}
                                </p>
                                {coinsPublic.length > 0
                                    && coinsPublic.map((item, index) => {
                                        const {
                                            handle,
                                            commission: {
                                                depositPercentage,
                                                fixedDepositValueCommission,
                                                minDepositValueToFixedCommission,
                                            },
                                        } = item;
                                        return (
                                            <div key={index} className={itemColumn}>
                                                <p>
                                                    {depositPercentage} %
                                                    <span
                                                        className={
                                                            style.fee__table_lightColor
                                                        }
                                                    >
                                                        ( {t('fee.forDeposits')} &gt;{' '}
                                                        {minDepositValueToFixedCommission}{' '}
                                                        {' '} {handle} )
                                                    </span>
                                                </p>
                                                <p>
                                                    <span
                                                        className={
                                                            style.fee__table_lightColor
                                                        }
                                                    >
                                                        {fixedDepositValueCommission}{' '}{handle}{' '}(
                                                        {t('fee.forDeposits')} &#8804;{' '}
                                                        {minDepositValueToFixedCommission}{' '}
                                                        {' '} {handle} )
                                                    </span>
                                                </p>
                                            </div>
                                        );
                                    })}
                            </div>
                            <div className={style.fee__table_column}>
                                <p className={style.fee__table_label}>
                                    {t('fee.withdrawalFee')}
                                </p>
                                {coinsPublic.length > 0
                                    && coinsPublic.map((item, index) => {
                                        const {
                                            handle,
                                            commission: {
                                                withdrawPercentage,
                                                fixedWithdrawValueCommission,
                                                minWithdrawValueToFixedCommission,
                                            },
                                        } = item;
                                        return (
                                            <div key={index} className={itemColumn}>
                                                <p>
                                                    {withdrawPercentage} %
                                                    <span
                                                        className={
                                                            style.fee__table_lightColor
                                                        }
                                                    >
                                                        ( {t('fee.forWithdraws')} &gt;{' '}
                                                        {
                                                            minWithdrawValueToFixedCommission
                                                        }
                                                        {' '} {handle} )
                                                    </span>
                                                </p>
                                                <p>
                                                    <span
                                                        className={
                                                            style.fee__table_lightColor
                                                        }
                                                    >
                                                        {fixedWithdrawValueCommission}{' '}{handle}{' '} (
                                                        {t('fee.forWithdraws')} &#8804;{' '}
                                                        {
                                                            minWithdrawValueToFixedCommission
                                                        }
                                                        {' '} {handle} )
                                                    </span>
                                                </p>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                ) : null}
                {spinner}
                {errorMessage}
            </Fragment>
        );
    }
}

Fee.defaultProps = {
    t: () => {},
};

Fee.propTypes = {
    t: PropTypes.func,
};

export default compose(withTranslation())(Fee);
