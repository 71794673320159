/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Tooltip, Select, message, Tabs,
} from 'antd';
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { authHeader } from '../../../../../helpers';
import toFixedBigValue from '../../../../../helpers/big-number';
import Spinner from '../../../../spinner';
import GetService from '../../../../../services/get-service';
import PostService from '../../../../../services/post-service';
import PatchService from '../../../../../services/patch-service';
import Field from '../../../../small-components/field';
import Button from '../../../../small-components/button';
import { compose } from '../../../../../utils';
import RentTable from './rent-table';
import ReturnTable from './return-table';
import close from '../../../../assets/images/close.svg';
import './edc-rent.scss';
import style from './edc-rent.module.scss';

class EdcRent extends Component {
    getService = new GetService();

    postService = new PostService();

    patchService = new PatchService();

    state = {
        marketPriceBTCforEDC: '',
        marketPriceEDCforBTC: '',
        btcBalance: '',
        amount: '',
        total: '',
        period: '',
        amountLimits: '',
        totalLimits: '',
        errors: {},
        modalRentData: {},
        rentActive: [],
        rentCompleted: [],
        loading: true,
        isModalOpen: false,
    };

    componentDidMount() {
        this.loadData();
        this.getRentActive();
        this.getRentCompleted();
    }

    // componentDidUpdate(prevState) {
    //     const { amount, total } = this.state;
    //     if (amount !== prevState.amount || total !== prevState.total) {
    //         this.validateRentLimits();
    //     }
    // }

    loadData = () => {
        this.getService
            .getMarketPrice('EDC', 'BTC')
            .then(marketPriceBTCforEDC => {
                this.setState({
                    marketPriceBTCforEDC,
                });
            })
            .catch(err => console.log(err, 'error'));

        this.getService
            .getMarketPrice('BTC', 'EDC')
            .then(marketPriceEDCforBTC => {
                this.setState({
                    marketPriceEDCforBTC,
                });
            })
            .catch(err => console.log(err, 'error'));

        this.getService
            .getRentLimits('BTC')
            .then(amountLimits => {
                this.setState({
                    amountLimits,
                });
            })
            .catch(err => {
                console.log(err, 'error');
            });

        this.getService
            .getRentLimits('EDC')
            .then(totalLimits => {
                this.setState({
                    totalLimits,
                });
            })
            .catch(err => {
                console.log(err, 'error');
            });


        this.getService
            .getUserCoins()
            .then(wallet => {
                const btcWallet = wallet.find(item => item.coin.handle === 'BTC');
                const { amount, amountOnOrders } = btcWallet;
                this.setState({
                    btcBalance: +amount - +amountOnOrders,
                });
            })
            .catch(err => console.log(err, 'error'));
    };

    getRentActive = () => {
        this.getService
            .getRentActive()
            .then(rentActive => {
                this.setState({
                    rentActive,
                    loading: false,
                });
            })
            .catch(err => console.log(err, 'error'));
    };

    getRentCompleted = () => {
        this.getService
            .getRentCompleted()
            .then(rentCompleted => {
                this.setState({
                    rentCompleted,
                });
            })
            .catch(err => console.log(err, 'error'));
    };

    inputOnchange = event => {
        const {
            marketPriceBTCforEDC, marketPriceEDCforBTC, errors,
        } = this.state;
        const { name, value } = event.target;
        const onlyNumbers = /^[0-9.]+$/;

        if (value === '' || onlyNumbers.test(value)) {
            if (name === 'amount') {
                this.setState({
                    [name]: value,
                    total: toFixedBigValue(value * marketPriceEDCforBTC, 8),
                }, () => {
                    this.validateRentLimits();
                });
                if (value.length) {
                    this.setState({
                        errors: {
                            ...errors,
                            amountError: '',
                        },
                    });
                }
            } else if (name === 'total') {
                this.setState({
                    [name]: value,
                    amount: toFixedBigValue(value * marketPriceBTCforEDC, 8),
                }, () => {
                    this.validateRentLimits();
                });
            } else {
                this.setState({
                    [name]: value,
                });
            }
        }
    };

    validateRentLimits = () => {
        const { t } = this.props;
        const {
            errors, amountLimits, totalLimits, amount, total,
        } = this.state;
        if (amountLimits && amount) {
            const { min, max } = amountLimits;
            if (+amount < min || +amount > max) {
                this.setState({
                    errors: {
                        ...errors,
                        amountError: t('error.orderMaxAmount', { min, max }),
                    },
                });
            } else {
                this.setState({
                    errors: {
                        ...errors,
                        amountError: '',
                    },
                });
            }
        }

        if (totalLimits && total) {
            const { min, max } = totalLimits;
            if (+total < min || +total > max) {
                errors.totalError = t('error.orderMaxAmount', { min, max });
                this.setState({
                    errors: {
                        ...errors,
                        totalError: t('error.orderMaxAmount', { min, max }),
                    },
                });
            } else {
                this.setState({
                    errors: {
                        ...errors,
                        totalError: '',
                    },
                });
            }
        }
    }

    selectOnChange = (value, name) => {
        const { errors } = this.state;

        this.setState({
            [name.props.name]: value,
        });

        if (name.props.name === 'period') {
            if (value.length) {
                this.setState({
                    errors: {
                        ...errors,
                        periodError: '',
                    },
                });
            }
        }
    };

    submitRent = () => {
        const { t } = this.props;
        const {
            amount, total, period, amountLimits, totalLimits,
        } = this.state;
        const errors = {};

        if (amount.length < 1) {
            errors.amountError = t('error.field_cannot_be_empty');
        }

        if (+amount <= 0) {
            errors.amountError = t('general.valueMustGreaterThanZero');
        }

        if (period.length < 1) {
            errors.periodError = t('general.selectFromTheList');
        }

        if (amountLimits && amount) {
            const { min, max } = amountLimits;
            if (+amount < min || +amount > max) {
                errors.amountError = t('error.orderMaxAmount', { min, max });
            }
        }

        if (totalLimits && total) {
            const { min, max } = totalLimits;
            if (+total < min || +total > max) {
                errors.totalError = t('error.orderMaxAmount', { min, max });
            }
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
        } else {
            this.setState({
                errors: {},
            });

            const data = {
                edcAmount: total,
                period,
            };

            this.postService
                .rentPay(data)
                .then(() => {
                    message.success(t('edcRent.rentOperationSuccessfully'), 5);
                    this.setState({
                        amount: '',
                        total: '',
                        period: '',
                        errors: {},
                    });
                    this.getRentActive();
                })
                .catch(() => {
                    message.error(t('edcRent.rentLimitError'), 5);
                });
        }
    };

    closeModal = () => {
        this.setState({
            isModalOpen: false,
        });
    };

    openReturnRentModal = (id, amount) => {
        this.setState({
            isModalOpen: true,
            modalRentData: {
                id,
                amount,
            },
        });
    };

    returnFunds = id => {
        const data = {
            id,
        };
        this.patchService
            .returnRent(data)
            .then(() => {
                this.setState({
                    isModalOpen: false,
                });
                this.getRentActive();
                this.getRentCompleted();
                message.success('Success', 5);
            })
            .catch(err => console.log(err, 'error'));
    };

    generateRentPdf = id => {
        let fileName = 'edc-rent';
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        return fetch(
            `${process.env.REACT_APP_API_URL}/api/rent/${id}/pdf/generate`,
            requestOptions,
        )
            .then(res => {
                fileName = res.headers.get('content-disposition').split('filename=')[1];
                return res.blob();
            })
            .then(downloadedFile => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(downloadedFile);
                link.download = fileName;
                document.body.append(link);
                link.click();
                link.remove();
                window.addEventListener('focus', () => URL.revokeObjectURL(link.href), {
                    once: true,
                });
            })

            .catch(err => {
                console.log(err, 'err');
            });
    };

    render() {
        const {
            marketPriceBTCforEDC,
            btcBalance,
            amount,
            total,
            period,
            errors: { amountError, totalError, periodError },
            rentActive,
            rentCompleted,
            loading,
            isModalOpen,
            modalRentData: { id: modalRentId, amount: modalRentAmount },
        } = this.state;
        const { t } = this.props;

        const { Option } = Select;
        const { TabPane } = Tabs;

        if (loading) {
            return <Spinner />;
        }

        const EDC_RENT_INFO = [
            {
                item: t('edcRent.subTitle1'),
            },
            {
                item: t('edcRent.subTitle2'),
            },
            {
                item: t('edcRent.subTitle3'),
            },
            {
                item: t('edcRent.subTitle4'),
            },
            {
                item: t('edcRent.subTitle5'),
            },
            {
                item: t('edcRent.subTitle6'),
            },
            {
                item: t('edcRent.subTitle7'),
            },
        ];

        return (
            <div className={style.edcRent}>
                <div className={style.rent}>
                    <div className={style.title}>
                        <h2 className={style.titleTxt}>{t('edcRent.title')}</h2>
                    </div>
                    <div className={style.rentContainer}>
                        <div className={style.leftPart}>
                            <div className={style.paymentInfo}>
                                <div className={style.price}>
                                    <div className={style.captionWr}>
                                        <span className={style.caption}>
                                            {t('general.price')}:
                                        </span>
                                    </div>
                                    <div className={style.valueWr}>
                                        <span className={style.value}>
                                            <span className={style.overflowHidden}>
                                                <Tooltip
                                                    placement="top"
                                                    title={marketPriceBTCforEDC}
                                                >
                                                    {marketPriceBTCforEDC}
                                                </Tooltip>
                                            </span>
                                            BTC / EDC
                                        </span>
                                    </div>
                                </div>
                                <div className={style.balance}>
                                    <div className={style.captionWr}>
                                        <span className={style.caption}>
                                            {t('balances.availableBalance')}:
                                        </span>
                                    </div>
                                    <div className={style.valueWr}>
                                        <span className={style.value}>
                                            <span className={style.overflowHidden}>
                                                <Tooltip
                                                    placement="top"
                                                    title={btcBalance}
                                                >
                                                    {btcBalance}
                                                </Tooltip>
                                            </span>
                                            BTC
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={style.wrapperInput}>
                                <Field
                                    id="amount"
                                    type="text"
                                    placeholder="Amount"
                                    name="amount"
                                    value={amount}
                                    inputStyle="rent__wrapper_input"
                                    labelText="Amount"
                                    labelStyle="rent__wrapper_label"
                                    onChange={this.inputOnchange}
                                    error={amountError}
                                />
                                <p className={style.fieldTicker}>BTC</p>
                            </div>
                            <div className={style.wrapperInput}>
                                <Field
                                    id="total"
                                    type="text"
                                    placeholder="Total"
                                    name="total"
                                    value={total}
                                    inputStyle="rent__wrapper_input"
                                    labelText="Total"
                                    labelStyle="rent__wrapper_label"
                                    onChange={this.inputOnchange}
                                    error={totalError}
                                />
                                <p className={style.fieldTicker}>EDC</p>
                            </div>
                            <div className="rentalPeriodSelect">
                                <p className="filterBlock-select__title">
                                    {t('edcRent.rentalPeriod')}
                                </p>
                                <Select
                                    className="filterBlock-select"
                                    placeholder={t('general.choose')}
                                    value={period || undefined}
                                    onChange={this.selectOnChange}
                                >
                                    <Option name="period" value="3">
                                        3 {t('edcRent.month')}
                                    </Option>
                                    <Option name="period" value="6">
                                        6 {t('edcRent.months')}
                                    </Option>
                                    <Option name="period" value="12">
                                        12 {t('edcRent.months')}
                                    </Option>
                                </Select>
                                {periodError ? (
                                    <div className={style.invalid}>{periodError}</div>
                                ) : null}
                            </div>
                            <Button
                                id="rentButton"
                                type="button"
                                onClick={this.submitRent}
                                className={style.edcRent__button}
                            >
                                {t('general.exchange')}
                            </Button>
                        </div>
                        <div className={style.rightPart}>
                            <ul className={style.listInfo}>
                                {EDC_RENT_INFO.map(items => {
                                    const { item } = items;
                                    return (
                                        <li key={item} className={style.infoItem}>
                                            <p className={style.infoTxt}>{item}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={style.rentTable}>
                    <div className={style.scrollContainer}>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={t('edcRent.rent')} key="1">
                                <RentTable
                                    rentActive={rentActive}
                                    t={t}
                                    openReturnRentModal={this.openReturnRentModal}
                                    generateRentPdf={this.generateRentPdf}
                                />
                            </TabPane>
                            <TabPane tab={t('edcRent.return')} key="2">
                                <ReturnTable rentCompleted={rentCompleted} t={t} />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={this.closeModal}
                    ariaHideApp={false}
                    className="returnRentModalWindow reactModal"
                    contentLabel="Example Modal"
                    overlayClassName="modalOverlay"
                >
                    <div className={style.modalWindow}>
                        <h2 className={style.modalWindow__title}>
                            {t('edcRent.fundsReturn')}
                        </h2>
                        <p className={style.modalWindow__subTitle}>
                            {t('edcRent.returnFundsModal', { modalRentAmount })}
                        </p>
                        <div onClick={this.closeModal}>
                            <img
                                src={close}
                                className={style.modalWindow__close}
                                alt="close"
                            />
                        </div>
                        <div className={style.modalWindow__buttonWrapper}>
                            <Button
                                id="cancel"
                                type="button"
                                className={style.modalWindow__buttonWrapper_cancel}
                                onClick={this.closeModal}
                            >
                                {t('general.cancel')}
                            </Button>
                            <Button
                                id="return"
                                type="button"
                                className={style.modalWindow__buttonWrapper_return}
                                onClick={() => this.returnFunds(modalRentId)}
                            >
                                {t('edcRent.return')}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

EdcRent.defaultProps = {
    t: () => {},
};

EdcRent.propTypes = {
    t: PropTypes.func,
};

export default compose(withTranslation())(EdcRent);
