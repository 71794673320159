/* eslint-disable */

import { userCountryConstants } from '../constants';

function getUserCountry(id) {
    return {
        type: userCountryConstants.GET_USER_COUNTRY,
        id,
    };
}

export const getUserCountryActions = {
    getUserCountry,
};
