/* eslint-disable */

import { chatConstants } from '../constants';

function getAllChats(data) {
    return {
        type: chatConstants.GET_ALL_CHATS,
        data,
    };
}

export const getAllChatsActions = {
    getAllChats,
};
