/* eslint-disable */
import { filterConstants } from '../constants';

export const currentFilter = (state, action) => {
    if (state === undefined) {
        return {
            filterParams: {},
        };
    }
    switch (action.type) {
        case filterConstants.SELECT_FILTER:
            return {
                filterParams: action.payload,
            };
        default:
            return state;
    }
};
