/* eslint-disable */
import { authModalConstants } from '../constants';

export function feedbackModal(state, action) {
    if (state === undefined) {
        return {
            visibleFeedbackModal: false,
        };
    }
    switch (action.type) {
        case authModalConstants.OPEN_FEEDBACK_MODAL:
            return {
                visibleFeedbackModal: true,
            };

        case authModalConstants.CLOSE_FEEDBACK_MODAL:
            return {
                visibleFeedbackModal: false,
            };

        default:
            return state;
    }
}
