/* eslint-disable */

import { authModalConstants } from '../constants';

function socketConnected(emit) {
    return {
        type: authModalConstants.SOCKET_CONNECTED,
        emit,
    };
}

function socketDisconnected() {
    return {
        type: authModalConstants.SOCKET_DICONECTED
    };
}

export const socketConnectedActions = {
    socketConnected,
    socketDisconnected,
};
