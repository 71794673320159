import coinsTranslation from '../helpers/coins-translation';

const filterFiatCoin = (paymentMethod, fiatCoins, allCoins, t) => {
    const filterCoinsByPaymentMethod = fiatCoins.filter(
        item => item.handle === paymentMethod,
    );
    if (filterCoinsByPaymentMethod.length) {
        const resultCoins = filterCoinsByPaymentMethod[0].coins.map(coins => {
            const stageCoins = {
                handle: coins,
                isCrypto: false,
                name: coinsTranslation(coins, t),
            };
            return stageCoins;
        });
        return resultCoins;
    }
    const fiatCoin = allCoins.filter(item => item.isCrypto === false);
    return fiatCoin;
};

export default filterFiatCoin;
