import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import Field from '../../small-components/field';
import { compose } from '../../../utils';
import { FilterActions } from '../../../actions/filter.actions';
import { countries } from '../../../helpers/countries';

import deleteIcon from './images/delete.svg';
import './filter-block.scss';

export class FilterBlock extends Component {
    state = {
        filterParams: {},
    };

    componentDidUpdate(prevProps) {
        const {
            userCoutry: { value },
            loggedIn,
            filterParams,
        } = this.props;

        const { filterParams: filterParamsLocal } = this.state;

        if (value !== prevProps.userCoutry.value) {
            if (loggedIn) {
                this.setState({
                    filterParams: {
                        ...filterParamsLocal,
                        country: value,
                    },
                });
            }
        }

        if (JSON.stringify(filterParams) !== JSON.stringify(prevProps.filterParams)) {
            this.setState({
                filterParams: {
                    ...filterParams,
                },
            });
        }
    }

    selectFilter = (value, name) => {
        const { filterParams, dispatch } = this.props;
        this.setState(
            {
                filterParams: {
                    ...filterParams,
                    [name.props.name]: value,
                    currentPageNumber: 1,
                },
            },
            () => {
                const { filterParams: newfilter } = this.state;
                dispatch(FilterActions.selectFilter(newfilter));
            },
        );
    };

    InputOnchange = event => {
        const { filterParams } = this.state;
        const { dispatch } = this.props;

        const onlyNumbers = /^[0-9.]+$/;

        if (event.target.value === '' || onlyNumbers.test(event.target.value)) {
            this.setState(
                {
                    filterParams: {
                        ...filterParams,
                        [event.target.name]: event.target.value,
                    },
                },
                () => {
                    const { filterParams: newfilter } = this.state;
                    const cleanObj = Object.entries(newfilter).reduce(
                        (acc, [key, value]) => {
                            if (value) {
                                acc[key] = value;
                            }
                            return acc;
                        },
                        {},
                    );
                    dispatch(FilterActions.selectFilter(cleanObj));
                },
            );
        }
    };

    location = (value, _undefined, name) => {
        const { filterParams } = this.state;
        const { dispatch } = this.props;
        this.setState(
            {
                filterParams: {
                    ...filterParams,
                    [name.name]: value.value,
                    currentPageNumber: 1,
                },
            },
            () => {
                const { filterParams: newfilter } = this.state;
                dispatch(FilterActions.selectFilter(newfilter));
            },
        );
    };

    delete = data => {
        const { filterParams } = this.state;
        const { dispatch } = this.props;
        const copyFilterParams = Object.assign({}, filterParams);
        delete copyFilterParams[data];

        dispatch(FilterActions.selectFilter(copyFilterParams));

        this.setState({
            filterParams: {
                ...copyFilterParams,
            },
        });
    };

    render() {
        const { t, filterParams: filterParamsRedux, allCoins } = this.props;
        const {
            coin: coinRedux,
            orderType: orderTypeRedux,
            country: countryRedux,
            paymentMethod: paymentMethodRedux,
            limitsFrom: limitsFromRedux,
            limitsTo: limitsToRedux,
        } = filterParamsRedux;

        const {
            filterParams: {
                limitsFrom,
                limitsTo,
                paymentMethod,
                orderType,
                coin,
                country,
            },
        } = this.state;
        const { Option } = Select;

        const countryWrapperStyle = !country && !countryRedux
            ? 'filterBlock-select__wrapper_item'
            : 'filterBlock-select__wrapper_item filter-country__selected';

        // filters names
        const buySellName = 'orderType';
        const cryptocurrencyName = 'coin';
        const limitsFromName = 'limitsFrom';
        const limitsToName = 'limitsTo';
        const countryName = 'country';
        const paymentMethodName = 'paymentMethod';

        return (
            <Fragment>
                <div className="filterBlock-select__wrapper">
                    <div className="filterBlock-select__wrapper_item">
                        {coinRedux || coin ? (
                            <div onClick={() => this.delete(cryptocurrencyName)}>
                                <img
                                    src={deleteIcon}
                                    className="filterBlock-select__wrapper_deleteIcon"
                                    alt="deleteIcon"
                                />
                            </div>
                        ) : null}
                        <p className="filterBlock-select__title">
                            {t('general.currency')}
                        </p>
                        <Select
                            className="filterBlock-select"
                            placeholder={t('general.choose')}
                            onChange={this.selectFilter}
                            value={coinRedux || coin}
                            showArrow={Boolean(!coin && !coinRedux)}
                        >
                            {allCoins.map(items => {
                                const { handle, name } = items;

                                return (
                                    <Option
                                        key={handle}
                                        name={cryptocurrencyName}
                                        value={handle}
                                    >
                                        {name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div className="filterBlock-select__wrapper_item">
                        {orderType || orderTypeRedux ? (
                            <div onClick={() => this.delete(buySellName)}>
                                <img
                                    src={deleteIcon}
                                    className="filterBlock-select__wrapper_deleteIcon"
                                    alt="deleteIcon"
                                />
                            </div>
                        ) : null}
                        <p className="filterBlock-select__title">
                            {t('general.buySell')}
                        </p>
                        <Select
                            className="filterBlock-select"
                            placeholder={t('general.choose')}
                            onChange={this.selectFilter}
                            value={orderType || orderTypeRedux}
                            showArrow={Boolean(!orderType && !orderTypeRedux)}
                        >
                            <Option name={buySellName} value="SELL">
                                {t('general.buy')}
                            </Option>
                            <Option name={buySellName} value="BUY">
                                {t('general.sell')}
                            </Option>
                            <Option name={buySellName} value="EXCHANGE">
                                {t('general.exchange')}
                            </Option>
                        </Select>
                    </div>
                    <div className="filterBlock-select__wrapper-input">
                        <Field
                            id="limitsFrom "
                            type="text"
                            placeholder={t('general.from')}
                            name={limitsFromName}
                            value={limitsFrom || limitsFromRedux}
                            onChange={this.InputOnchange}
                            inputStyle="filterBlock-select__wrapper_input"
                            labelText={t('general.price')}
                            labelStyle="filterBlock-select__wrapper_label"
                        />
                        <span className="filterBlock-select__wrapper_dash">&mdash;</span>
                        <Field
                            id="limitsTo "
                            type="text"
                            placeholder={t('general.till')}
                            name={limitsToName}
                            value={limitsTo || limitsToRedux}
                            onChange={this.InputOnchange}
                            inputStyle="filterBlock-select__wrapper_input"
                            labelText={t('general.price')}
                            labelStyle="filterBlock-select__wrapper_label"
                        />
                    </div>
                    <div className={countryWrapperStyle}>
                        {country || countryRedux ? (
                            <div onClick={() => this.delete(countryName)}>
                                <img
                                    src={deleteIcon}
                                    className="filterBlock-select__wrapper_deleteIcon"
                                    alt="deleteIcon"
                                />
                            </div>
                        ) : null}
                        <p className="filterBlock-select__title">
                            {t('general.location')}
                        </p>
                        <SelectSearch
                            name={countryName}
                            mode="input"
                            value={country || countryRedux}
                            options={countries}
                            placeholder={t('general.choose')}
                            onChange={this.location}
                        />
                    </div>
                    <div className="filterBlock-select__wrapper_item">
                        {paymentMethod || paymentMethodRedux ? (
                            <div onClick={() => this.delete(paymentMethodName)}>
                                <img
                                    src={deleteIcon}
                                    className="filterBlock-select__wrapper_deleteIcon"
                                    alt="deleteIcon"
                                />
                            </div>
                        ) : null}
                        <p className="filterBlock-select__title">
                            {t('general.paymentMethod')}
                        </p>
                        <Select
                            className="filterBlock-select"
                            placeholder={t('general.choose')}
                            onChange={this.selectFilter}
                            value={paymentMethod || paymentMethodRedux}
                            showArrow={Boolean(!paymentMethod && !paymentMethodRedux)}
                        >
                            <Option name={paymentMethodName} value="CRYPTO_TRANSFER">
                                Crypto transfer
                            </Option>
                            <Option name={paymentMethodName} value="BANK_TRANSFER">
                                Bank transfer
                            </Option>
                        </Select>
                    </div>
                </div>
            </Fragment>
        );
    }
}

FilterBlock.defaultProps = {
    t: () => {},
    dispatch: () => {},
    filterParams: {},
    allCoins: [],
    userCoutry: {},
    loggedIn: false,
};

FilterBlock.propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.func,
    filterParams: PropTypes.object,
    allCoins: PropTypes.instanceOf(Array),
    userCoutry: PropTypes.object,
    loggedIn: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        currentFilter: { filterParams },
        getAllCoins: { allCoins },
        getUserCountry: { country: userCoutry },
        authentication: { loggedIn },
    } = state;

    return {
        filterParams,
        allCoins,
        userCoutry,
        loggedIn,
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps),
)(FilterBlock);
