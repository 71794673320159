import React, { Fragment } from 'react';
import FirstScreen from './first-screen';
import WorkWith from './work-with';
import Advantages from './advantages';
import HowToStart from './how-to-start';
import Questions from './questions';
import Orders from './orders/orders-container';

const HomePage = () => (
    <Fragment>
        <FirstScreen />
        <WorkWith />
        <Orders />
        <Advantages />
        <HowToStart />
        <Questions />
    </Fragment>
);

export default HomePage;
