import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { connect } from 'react-redux';
import * as moment from 'moment';

import { alertActions, userActions } from '../../../actions';
import Field from '../../small-components/field';
import Button from '../../small-components/button';
import { compose } from '../../../utils';
import PatchService from '../../../services/patch-service';
import GetService from '../../../services/get-service';
import Spinner from '../../spinner';
import ErrorIndicator from '../error-page/error-indicator';
import {
    clientId, personalAreaPath, p2p,
} from '../../../constants';

import style from './recovery-password.module.scss';

export class RecoveryPasswordPage extends Component {
    patchService = new PatchService();

    getService = new GetService();

    static defaultProps = {
        t: () => {},
        match: {},
        dispatch: () => {},
        history: {},
    };

    static propTypes = {
        t: PropTypes.func,
        match: PropTypes.object,
        dispatch: PropTypes.func,
        history: PropTypes.object,
    };

    state = {
        user: {
            password: '',
            repeatPassword: '',
        },
        errors: {
            passwordError: '',
            repeatPasswordError: '',
        },
        loading: true,
        error: false,
    };

    componentDidMount() {
        this.isTokenValid();
    }

    isTokenValid = () => {
        const { t, match } = this.props;
        const {
            params: { id: confirmationToken },
        } = match;

        this.getService
            .isValidConfirmationToken(confirmationToken)
            .then(() => {
                this.setState({
                    loading: false,
                    error: false,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                    error: true,
                });
                message.error(t('error.requestPasswordRecoveryAgain'), 5);
            });
    }

    InputOnchange = event => {
        const { user } = this.state;
        const {
            target: { name, value },
        } = event;

        this.setState({
            user: {
                ...user,
                [name]: value,
            },
        });
    };

    RegistrationSubmit = event => {
        event.preventDefault();
        const {
            user: { password, repeatPassword },
        } = this.state;
        const { t, dispatch, history } = this.props;
        const errors = {};

        if (password.length < 6) {
            errors.passwordError = t('error.input', { count: '6' });
        }

        if (password !== repeatPassword) {
            errors.repeatPasswordError = t('error.passwordDoesntMatch');
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
        } else {
            this.setState({
                errors: {},
            });

            if (password && repeatPassword) {
                const { match } = this.props;
                const {
                    params: { id },
                } = match;
                const data = {
                    password,
                    client_id: clientId,
                };
                this.patchService
                    .createNewPassword(data, id)
                    .then(user => {
                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.setItem('updateTokenTime', moment().unix());
                        dispatch(userActions.success(user));
                        dispatch(alertActions.success('Authorization successful'));
                        history.push(`${personalAreaPath}${p2p}`);
                        message.success(t('general.passwordChangedSuccessfully'), 5);
                    })
                    .catch(err => {
                        console.log(err, 'error');
                    });
            }
        }
    };

    render() {
        const { loading, error } = this.state;

        const hasData = !(loading || error);
        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData;

        const { t } = this.props;
        const {
            user: { password, repeatPassword },
            errors: { passwordError, repeatPasswordError },
        } = this.state;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content
                    ? (
                        <div className={style.passwordRecovery}>
                            <h2 className={style.passwordRecovery__title}>
                                {t('general.passwordRecovery')}
                            </h2>
                            <p className={style.passwordRecovery__subTitle}>
                                {t('general.сreateNewPassword')}
                            </p>
                            <form onSubmit={this.RegistrationSubmit}>
                                <div className={style.passwordRecovery__inputWrapper}>
                                    <Field
                                        id="password"
                                        type="password"
                                        placeholder={t('form.password')}
                                        name="password"
                                        value={password}
                                        onChange={this.InputOnchange}
                                        error={passwordError}
                                        inputStyle={style.passwordRecovery__input}
                                        labelText={t('form.password')}
                                        labelStyle={style.passwordRecovery__label}
                                    />
                                </div>
                                <div className={style.passwordRecovery__inputWrapper}>
                                    <Field
                                        id="repeatPassword"
                                        type="password"
                                        placeholder={t('form.repeatPassword')}
                                        name="repeatPassword"
                                        value={repeatPassword}
                                        onChange={this.InputOnchange}
                                        error={repeatPasswordError}
                                        inputStyle={style.passwordRecovery__input}
                                        labelText={t('form.repeatPassword')}
                                        labelStyle={style.passwordRecovery__label}
                                    />
                                </div>
                                <div className={style.passwordRecovery__buttonWrapper}>
                                    <Button
                                        id="registration-button"
                                        type="submit"
                                        className={style.passwordRecovery__button}
                                    >
                                        {t('general.send')}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )
                    : null}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        authentication: { loggedIn },
    } = state;

    return {
        loggedIn,
    };
};

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
    ),
    withRouter,
)(RecoveryPasswordPage);
