import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import Button from '../../../../small-components/button';
import Img from '../../../../small-components/img';
import AreUSureModal from '../../../../small-components/are-u-sure-modal';
import { countries } from '../../../../../helpers/countries';
import {
    personalAreaPath,
    dashboardPath,
    editPath,
    OPENED,
    PENDING,
    CLOSED,
    CANCELLED,
    ARBITRAGE,
    EXCHANGE,
    SELL,
    BUY,
} from '../../../../../constants';

import style from './advertisement-page.module.scss';
import 'rc-pagination/assets/index.css';

const AdvertisementView = ({
    dashboard,
    deleteModal,
    isButtonDisabled,
    switchAdvertisementStatus = () => {},
    switchDeleteModal = () => {},
    preventPendingRedirect = () => {},
    deleteAdvertisement = () => {},
    match,
}) => {
    const { t } = useTranslation();
    const {
        params: { id: matchDashboard },
    } = match;

    if (deleteModal) {
        document.documentElement.style.overflowY = 'hidden';
    } else {
        document.documentElement.style.overflowY = 'inherit';
    }

    return (
        <div className={style.advertisement}>
            <p className={style.advertisement__title}>{t('general.advertisement')}</p>

            {dashboard.items
                .filter(item => item.id.toString() === matchDashboard)

                .map(item => {
                    const {
                        id,
                        orderStatus: { handle: advertisementStatus },
                    } = item;

                    const styleAdvertisementStatus = advertisementStatus === OPENED || advertisementStatus === PENDING
                        ? style.advertisement__status_stateActive
                        : style.advertisement__status_stateRed;

                    const styleAdvertisementStatusBtn = advertisementStatus === OPENED
                        ? style.advertisement__buttonGroup_disable
                        : style.advertisement__buttonGroup_activate;

                    let statusLabel = '';

                    if (advertisementStatus === OPENED) {
                        statusLabel = t('general.opened');
                    } else if (advertisementStatus === PENDING) {
                        statusLabel = t('general.pending');
                    } else if (advertisementStatus === CANCELLED) {
                        statusLabel = t('general.disabled');
                    } else if (advertisementStatus === CLOSED) {
                        statusLabel = t('general.closed');
                    } else if (advertisementStatus === ARBITRAGE) {
                        statusLabel = t('general.arbitrage');
                    }

                    return (
                        <Fragment key={id}>
                            <p className={style.advertisement__status}>
                                {t('general.advertisementStatus')} -{' '}
                                <span className={styleAdvertisementStatus}>
                                    {statusLabel}
                                </span>
                            </p>

                            {advertisementStatus === CLOSED
                            || advertisementStatus === ARBITRAGE ? null : (
                                    <div className={style.advertisement__buttonGroup}>
                                        <p className={style.advertisement__buttonGroup_iWant}>
                                            {t('general.iwantTo')}
                                        </p>
                                        <Button
                                            id="disable"
                                            type="button"
                                            disabled={isButtonDisabled}
                                            className={styleAdvertisementStatusBtn}
                                            onClick={switchAdvertisementStatus}
                                        >
                                            {advertisementStatus === OPENED
                                                ? t('general.disable')
                                                : t('general.activate')}
                                        </Button>
                                        <Link
                                            to={`${personalAreaPath}${dashboardPath}${editPath}/${id}`}
                                            className={style.advertisement__buttonGroup_edit}
                                            onClick={preventPendingRedirect}
                                        >
                                            {t('general.edit')}
                                        </Link>
                                        <Button
                                            id="delete"
                                            type="button"
                                            onClick={switchDeleteModal}
                                            className={
                                                style.advertisement__buttonGroup_delete
                                            }
                                        >
                                            {t('general.delete')}
                                        </Button>
                                    </div>
                                )}
                        </Fragment>
                    );
                })}
            <p className={style.advertisement__title}>
                {t('general.advertisementDetails')}
            </p>
            <div className={style.advertisement__details}>
                {dashboard.items
                    .filter(item => item.id.toString() === matchDashboard)
                    .map(item => {
                        const {
                            card,
                            id,
                            smallestAmount,
                            sellAmount,
                            rate,
                            paymentWindow,
                            coinBuy: { handle: coinBuy },
                            coinSell: { handle: coinSell },
                            orderType: { handle: orderType },
                            country: { handle: country },
                            seller: { logoSrc, username },
                            paymentMethod: { name: paymentMethod },
                        } = item;

                        let humanOrderType = '';
                        let orderTypeStyle = '';

                        if (EXCHANGE === orderType) {
                            humanOrderType = t('general.exchange');
                            orderTypeStyle = style.advertisement__details_subTitleExchange;
                        } else if (SELL === orderType) {
                            humanOrderType = t('general.sell');
                            orderTypeStyle = style.advertisement__details_subTitleSell;
                        } else {
                            humanOrderType = t('general.buy');
                            orderTypeStyle = style.advertisement__details_subTitleBuy;
                        }

                        return (
                            <Fragment key={id}>
                                <Img
                                    src={logoSrc}
                                    className={style.advertisement__details_logo}
                                    userName={username}
                                    alt="logo"
                                />
                                <div className={style.advertisement__details_rightSide}>
                                    <div
                                        className={
                                            style.advertisement__details_itemWrapper
                                        }
                                    >
                                        <div
                                            className={style.advertisement__details_item}
                                        >
                                            <p
                                                className={
                                                    style.advertisement__details_title
                                                }
                                            >
                                                {t('general.trueName')}:
                                            </p>
                                            <p
                                                className={
                                                    style.advertisement__details_subTitleBlue
                                                }
                                            >
                                                {username}
                                            </p>
                                        </div>
                                        <div
                                            className={style.advertisement__details_item}
                                        >
                                            <p
                                                className={
                                                    style.advertisement__details_title
                                                }
                                            >
                                                {t('general.paymentMethod')}:
                                            </p>
                                            <p
                                                className={
                                                    style.advertisement__details_subTitle
                                                }
                                            >
                                                {paymentMethod}
                                            </p>
                                        </div>
                                        <div
                                            className={style.advertisement__details_item}
                                        >
                                            <p
                                                className={
                                                    style.advertisement__details_title
                                                }
                                            >
                                                {t('general.location')}:
                                            </p>
                                            <p
                                                className={
                                                    style.advertisement__details_subTitle
                                                }
                                            >
                                                {countries.map(countryItem => {
                                                    const { name, value } = countryItem;
                                                    return (
                                                        <Fragment key={value}>
                                                            {value === country
                                                                ? name
                                                                : ''}
                                                        </Fragment>
                                                    );
                                                })}
                                            </p>
                                        </div>
                                        <div
                                            className={style.advertisement__details_item}
                                        >
                                            <p
                                                className={
                                                    style.advertisement__details_title
                                                }
                                            >
                                                {t('general.paymentWindow')}:
                                            </p>
                                            <p
                                                className={
                                                    style.advertisement__details_subTitle
                                                }
                                            >
                                                {paymentWindow} {t('general.min')}
                                            </p>
                                        </div>
                                        <div
                                            className={style.advertisement__details_item}
                                        >
                                            <p
                                                className={
                                                    style.advertisement__details_title
                                                }
                                            >
                                                {t('general.price')}:
                                            </p>
                                            <p
                                                className={
                                                    style.advertisement__details_subTitleBlue
                                                }
                                            >
                                                {rate}{' '}
                                                {orderType === BUY ? coinSell : coinBuy} /{' '}
                                                {orderType === BUY ? coinBuy : coinSell}
                                            </p>
                                        </div>
                                        {orderType === SELL && card !== null ? (
                                            <div
                                                className={
                                                    style.advertisement__details_item
                                                }
                                            >
                                                <p
                                                    className={
                                                        style.advertisement__details_title
                                                    }
                                                >
                                                    {t('newOrder.placeHolderCardNumber')}:
                                                </p>
                                                <p
                                                    className={
                                                        style.advertisement__details_subTitle
                                                    }
                                                >
                                                    <Tooltip
                                                        placement="top"
                                                        title={card.cardNumber}
                                                    >
                                                        {card.cardNumber}
                                                    </Tooltip>
                                                </p>
                                            </div>
                                        ) : null}
                                        {orderType === SELL && card !== null ? (
                                            <div
                                                className={
                                                    style.advertisement__details_item
                                                }
                                            >
                                                <p
                                                    className={
                                                        style.advertisement__details_title
                                                    }
                                                >
                                                    {t('newOrder.cardholder')}:
                                                </p>
                                                <p
                                                    className={
                                                        style.advertisement__details_subTitle
                                                    }
                                                >
                                                    {card.cardHolder}
                                                </p>
                                            </div>
                                        ) : null}
                                        <div
                                            className={style.advertisement__details_item}
                                        >
                                            <p
                                                className={
                                                    style.advertisement__details_title
                                                }
                                            >
                                                {t('general.tradeLimits')}:
                                            </p>
                                            <p
                                                className={
                                                    style.advertisement__details_subTitle
                                                }
                                            >
                                                {smallestAmount} - {sellAmount}{' '}
                                                {orderType === BUY ? coinBuy : coinSell}
                                            </p>
                                        </div>
                                        <div
                                            className={style.advertisement__details_item}
                                        >
                                            <p
                                                className={
                                                    style.advertisement__details_title
                                                }
                                            >
                                                {t('general.pair')}:
                                            </p>
                                            <p
                                                className={
                                                    style.advertisement__details_subTitle
                                                }
                                            >
                                                {orderType === BUY ? coinBuy : coinSell} /{' '}
                                                {orderType === BUY ? coinSell : coinBuy}
                                            </p>
                                        </div>
                                        <div
                                            className={style.advertisement__details_item}
                                        >
                                            <p
                                                className={
                                                    style.advertisement__details_title
                                                }
                                            >
                                                {t('general.orderType')}:
                                            </p>
                                            <p className={orderTypeStyle}>
                                                {humanOrderType}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            style.advertisement__details_answersWrapper
                                        }
                                    >
                                        <p
                                            className={
                                                style.advertisement__details_safeToSell
                                            }
                                        >
                                            {t('general.safeToSell')} ?
                                        </p>
                                        <p
                                            className={
                                                style.advertisement__details_safeToSellDescription
                                            }
                                        >
                                            {t('general.safeToSellDescription')}
                                        </p>
                                    </div>
                                    {/*
                                    <div
                                        className={
                                            style.advertisement__details_answersWrapper
                                        }
                                    >
                                        <p
                                            className={
                                                style.advertisement__details_whenIRecive
                                            }
                                        >
                                            {t('general.whenIRecive')} EDC ?
                                        </p>
                                        <p
                                            className={
                                                style.advertisement__details_whenIReciveDescription
                                            }
                                        >
                                            {t('general.whenIReciveDescription')}
                                        </p>
                                    </div>
                                        */}
                                </div>
                            </Fragment>
                        );
                    })}
            </div>

            <AreUSureModal
                visibleModal={deleteModal}
                noButton={switchDeleteModal}
                yesButton={() => deleteAdvertisement(matchDashboard)}
            />
        </div>
    );
};

AdvertisementView.defaultProps = {
    dashboard: {},
    match: {},
    deleteModal: false,
    isButtonDisabled: false,
    switchAdvertisementStatus: () => {},
    switchDeleteModal: () => {},
    preventPendingRedirect: () => {},
    deleteAdvertisement: () => {},
};

AdvertisementView.propTypes = {
    dashboard: PropTypes.object,
    match: PropTypes.object,
    deleteModal: PropTypes.bool,
    isButtonDisabled: PropTypes.bool,
    switchAdvertisementStatus: PropTypes.func,
    switchDeleteModal: PropTypes.func,
    preventPendingRedirect: PropTypes.func,
    deleteAdvertisement: PropTypes.func,
};

export default withRouter(AdvertisementView);
