/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Upload, Icon, Tooltip } from 'antd';
import Moment from 'react-moment';
import Field from '../../../../small-components/field';
import PaginationComponent from '../../../../layouts/pagination';
import StarRating from '../../../../small-components/star-raiting';
import Button from '../../../../small-components/button';
import Img from '../../../../small-components/img';
import { personalAreaPath, settingsPath, profilePath } from '../../../../../constants';

import style from './profile-page.module.scss';
import './profile-page.scss';
import 'rc-pagination/assets/index.css';

import deleteIcon from '../../../../assets/images/delete.svg';
import chip from '../../../../assets/images/chip.svg';
import logo from '../../../../assets/images/logo.svg';

const ProfileView = ({
    userIfo,
    profileId,
    authName,
    userReviews,
    loadingAvatar,
    isBlocked,
    blockBtnRequest,
    cardHolder,
    cardNumber,
    handleCardChange,
    cardHolderChange,
    cardNumberError,
    cardHolderError,
    isOpenedCardWrapper,
    switchCardWrapper,
    cardList,
    saveCard,
    deleteCard,
    isDeleteIconDisable,
    userConfirmationStatus: { handle: userConfirmationStatus },
    blockAccount = () => {},
    unBlockAccount = () => {},
    uploadPassportChange = () => {},
    beforeUpload = () => {},
    openSettingsTab = () => {},
}) => {
    const { t } = useTranslation();

    // Button upload avatar
    const uploadButton = (
        <div>
            <Icon type={loadingAvatar ? 'loading' : 'upload'} />
            <div className={style.profile__uploadText}>{t('general.upload')}</div>
        </div>
    );

    const uploadStyle = classNames(
        style.profile__content_subTitleBlue,
        style.profile__content_upload,
    );

    const starsStyle = classNames(
        style.profile__content_item,
        style.profile__content_itemStars,
    );

    const uploadDocumentsStyle = classNames(
        style.profile__content_item,
        style.profile__content_uploadDocuments,
    );

    const accountStatusStyle = classNames(
        style.profile__content_item,
        style.profile__content_accountStatus,
    );

    const {
        id: userId,
        username,
        countTraids,
        createdAt,
        countUniqPartners,
        lastActivityAt,
        rating,
        logoSrc,
        isConfirmed,
        verifiedPhone,
    } = userIfo;

    let userConfirmationStatusText = '';
    let questUserConfirmationStatusText = '';
    let userConfirmationStatusStyle = '';
    const showUploadedDocumentsLink = userConfirmationStatus === 'WAITING' || userConfirmationStatus === 'REJECTED';

    if (userConfirmationStatus === 'WAITING') {
        userConfirmationStatusText = t('general.notSubmitted');
        userConfirmationStatusStyle = style.profile__content_subTitleRed;
    }

    if (userConfirmationStatus === 'REVIEWING') {
        userConfirmationStatusText = t('general.onReview');
        userConfirmationStatusStyle = style.profile__content_subTitleBlue;
    }

    if (userConfirmationStatus === 'CONFIRMED') {
        userConfirmationStatusText = t('settings.verified');
        userConfirmationStatusStyle = style.profile__content_subTitleBlue;
    }

    if (userConfirmationStatus === 'REJECTED') {
        userConfirmationStatusText = t('general.accountRejected');
        userConfirmationStatusStyle = style.profile__content_subTitleRed;
    }

    if (isConfirmed) {
        questUserConfirmationStatusText = t('settings.verified');
        userConfirmationStatusStyle = style.profile__content_subTitleBlue;
    } else {
        questUserConfirmationStatusText = t('general.notSubmitted');
        userConfirmationStatusStyle = style.profile__content_subTitleRed;
    }

    const noReview = username === authName ? (
        <div className={style.profile__noFeedbacksWrapper}>
            <p className={style.profile__noFeedbacksWrapper_noFeedbacks}>
                {t('general.youHaveNoFeedbacks')}
            </p>
        </div>
    ) : (
        <div className={style.profile__noFeedbacksWrapper}>
            <p className={style.profile__noFeedbacksWrapper_noFeedbacks}>
                {t('general.noFeedbacks', { user: username })}
            </p>
            <p className={style.profile__noFeedbacksWrapper_noFeedbacks}>
                {t('general.canLeaveReview')}
            </p>
        </div>
    );

    const prettify = num => num.replace(/(\d{1,4}(?=(?:\d\d\d\d)+(?!\d)))/g, `$1${' '}`);

    const cardNumberFirst = cardNumber.slice(0, 4);
    const cardNumberSecond = cardNumber.slice(4, 8);
    const cardNumberThird = cardNumber.slice(8, 12);
    const cardNumberFourth = cardNumber.slice(12, 16);
    const cardNumberFifth = cardNumber.slice(16, 20);
    const cardNumberSixth = cardNumber.slice(20, 24);

    const cardNumberWithSpace = `${cardNumberFirst} ${cardNumberSecond} ${cardNumberThird} ${cardNumberFourth}  ${cardNumberFifth}  ${cardNumberSixth}`;

    return (
        <div className={style.profile}>
            <p className={style.profile__title}>{t('general.profile')}</p>
            <div className={style.profile__logoAndBlockAccountWrapper}>
                <div className={style.profile__logoWrapper}>
                    {profileId === userId ? (
                        <div className="profile__logoWrapper">
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                onChange={uploadPassportChange}
                            >
                                {logoSrc ? (
                                    <img
                                        className={style.profile__logo}
                                        src={logoSrc}
                                        alt="avatar"
                                    />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </div>
                    ) : (
                        <div className="profile__logoWrapper">
                            <Img
                                src={logoSrc}
                                className={style.profile__logo}
                                userName={username}
                                alt="logo"
                            />
                        </div>
                    )}

                    <div className={style.profile__userName}>
                        <p className={style.profile__content_title}>
                            {t('general.username')}
                        </p>
                        <p className={style.profile__content_subTitleBlue}>{username}</p>
                    </div>
                </div>

                {profileId === userId ? null : (
                    <Button
                        disabled={blockBtnRequest}
                        className={
                            isBlocked
                                ? style.profile__unBlockAccount
                                : style.profile__blockAccount
                        }
                        type="button"
                        onClick={
                            isBlocked
                                ? () => unBlockAccount(userId)
                                : () => blockAccount(userId)
                        }
                    >
                        {isBlocked
                            ? t('general.unBlockAccount')
                            : t('general.blockAccount')}
                    </Button>
                )}
            </div>
            <div className={style.profile__personalInfoWrapper}>
                <div className={style.profile__content_item}>
                    <p className={style.profile__content_title}>
                        {t('general.numberSuccessfulTrades')}
                    </p>
                    <p className={style.profile__content_subTitle}>{countTraids}</p>
                </div>
                <div className={style.profile__content_item}>
                    <p className={style.profile__content_title}>
                        {t('general.accountCreated')}
                    </p>
                    <p className={style.profile__content_subTitle}>
                        <Moment format="DD/MM/YYYY" unix>
                            {createdAt}
                        </Moment>
                    </p>
                </div>
                <div className={accountStatusStyle}>
                    <p className={style.profile__content_title}>
                        {t('general.accountStatus')}
                    </p>
                    <p className={userConfirmationStatusStyle}>
                        {profileId === userId
                            ? userConfirmationStatusText
                            : questUserConfirmationStatusText}
                    </p>
                </div>
                <div className={uploadDocumentsStyle}>
                    {!showUploadedDocumentsLink || profileId !== userId ? null : (
                        <Link
                            to={`${personalAreaPath}${settingsPath}`}
                            className={uploadStyle}
                            onClick={() => openSettingsTab('2')}
                        >
                            {t('general.uploadDocuments')}
                        </Link>
                    )}
                </div>
                <div className={style.profile__content_item}>
                    <p className={style.profile__content_title}>
                        {t('general.numberDifferentPartners')}
                    </p>
                    <p className={style.profile__content_subTitle}>{countUniqPartners}</p>
                </div>
                <div className={style.profile__content_item}>
                    <p className={style.profile__content_title}>
                        {t('general.lastSeenTitle')}
                    </p>
                    <p className={style.profile__content_subTitle}>
                        <Moment format="DD/MM/YYYY" unix>
                            {lastActivityAt}
                        </Moment>
                    </p>
                </div>

                {profileId === userId ? (
                    <Fragment>
                        <div className={style.profile__content_item}>
                            <p className={style.profile__content_title}>
                                {t('general.phoneNumber')}
                            </p>
                            {verifiedPhone ? (
                                <p className={style.profile__content_subTitleBlue}>
                                    {t('settings.verified')}
                                </p>
                            ) : (
                                <p className={style.profile__content_subTitleRed}>
                                    {t('general.notSubmitted')}
                                </p>
                            )}
                        </div>
                        <div className={style.profile__content_itemNumber}>
                            <div className={style.profile__content_phoneNumberinput}>
                                {!verifiedPhone && profileId === userId ? (
                                    <Link
                                        to={`${personalAreaPath}${settingsPath}`}
                                        className={style.profile__phoneNumber}
                                        onClick={() => openSettingsTab('1')}
                                    >
                                        {t('general.addPhoneNumber')}
                                    </Link>
                                ) : null}
                            </div>
                        </div>
                    </Fragment>
                ) : null}

                <div className={starsStyle}>
                    <p className={style.profile__content_title}>{t('general.rating')}</p>
                    <StarRating raiting={parseInt(rating, 16)} />
                </div>
            </div>
            {profileId === userId ? (
                <Fragment>
                    {cardList.length > 0 ? (
                        <div className={style.allCards}>
                            <p className={style.cards__title}>
                                {t('settings.yourCards')}
                            </p>
                            <div className={style.allCards__wrapper}>
                                {cardList.map(item => {
                                    const {
                                        id,
                                        cardHolder: holder,
                                        cardNumber: numberCard,
                                    } = item;

                                    return (
                                        <div key={id} className={style.card}>
                                            <div className={style.card__topWrapper}>
                                                <img
                                                    className={style.card__chip}
                                                    src={chip}
                                                    alt="chip"
                                                />
                                                <img
                                                    className={style.card__logo}
                                                    src={logo}
                                                    alt="logo"
                                                />
                                            </div>
                                            <p className={style.card__number}>
                                                {prettify(numberCard)}
                                            </p>
                                            <div className={style.card__bottomWrapper}>
                                                <p className={style.card__name}>
                                                    {holder}
                                                </p>
                                                <Tooltip
                                                    placement="top"
                                                    title="Delete card"
                                                >
                                                    <img
                                                        onClick={
                                                            !isDeleteIconDisable
                                                                ? () => deleteCard(id)
                                                                : null
                                                        }
                                                        className={style.card__deleteIcon}
                                                        src={deleteIcon}
                                                        alt="deleteIcon"
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}

                    <div className={style.cards}>
                        <p className={style.cards__title}>{t('settings.addCard')}</p>
                        <div
                            className={
                                isOpenedCardWrapper
                                    ? 'cards__addNewCard cards__opened'
                                    : 'cards__addNewCard'
                            }
                        >
                            <div
                                onClick={switchCardWrapper}
                                className="cards__addNewCard_cardWrapper"
                            >
                                <div className={style.card}>
                                    <div className={style.card__topWrapper}>
                                        <img
                                            className={style.card__chip}
                                            src={chip}
                                            alt="chip"
                                        />
                                        <img
                                            className={style.card__logo}
                                            src={logo}
                                            alt="logo"
                                        />
                                    </div>
                                    <p className={style.card__number}>
                                        {cardNumberWithSpace.trim()
                                            ? cardNumberWithSpace.trim()
                                            : '**** **** **** ****'}
                                    </p>
                                    <div className={style.card__bottomWrapper}>
                                        <input
                                            className={style.card__name}
                                            value={cardHolder}
                                            type="text"
                                            placeholder="Your name"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <p className="cards__addNewCard_text">
                                    {t('settings.addCard')}
                                </p>
                            </div>
                            <div
                                className={
                                    isOpenedCardWrapper
                                        ? 'cards__addNewCard_opened'
                                        : 'cards__addNewCard_closed'
                                }
                            >
                                <div className="cards__inputWrapper">
                                    <div className={style.cards__wrapper}>
                                        <Field
                                            id="cardNumber"
                                            type="text"
                                            placeholder={t('general.typeHere')}
                                            name="cardNumber"
                                            value={cardNumber}
                                            onChange={handleCardChange}
                                            error={cardNumberError}
                                            inputStyle={style.cards__input}
                                            labelText={t(
                                                'newOrder.placeHolderCardNumber',
                                            )}
                                            labelStyle={style.cards__label}
                                            errorStyle={
                                                style.cards__input_errorCardNumber
                                            }
                                        />
                                    </div>
                                    <div className={style.cards__wrapper_cardHolderName}>
                                        <Field
                                            id="cardHolder"
                                            type="text"
                                            placeholder={t('general.typeHere')}
                                            name="cardHolder"
                                            value={cardHolder}
                                            onChange={cardHolderChange}
                                            error={cardHolderError}
                                            inputStyle={style.cards__input}
                                            labelText={t('newOrder.cardholder')}
                                            labelStyle={style.cards__label}
                                            errorStyle={style.cards__input_error}
                                        />
                                        <p className={style.cards__subLabel}>
                                            {t('general.nameAndsurnameIsRequired')}
                                        </p>
                                    </div>
                                </div>
                                <div className="cards__buttonWrapper">
                                    <Button
                                        className="cards__buttonWrapper_goBack"
                                        type="button"
                                        onClick={switchCardWrapper}
                                    >
                                        {t('general.close')}
                                    </Button>
                                    <Button
                                        className="cards__buttonWrapper_done"
                                        type="button"
                                        onClick={saveCard}
                                    >
                                        {t('general.done')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            ) : null}

            <div className={style.feedback}>
                <p className={style.feedback__title}>{t('feedback.title')}</p>
                {userReviews.totalCount === 0 ? (
                    noReview
                ) : (
                    <Fragment>
                        {userReviews.items
                            && userReviews.items.map(item => {
                                const {
                                    id,
                                    text,
                                    score,
                                    createdAt: createdAtReview,
                                    user: {
                                        id: feedbackUserId,
                                        logoSrc: imgLogo,
                                        username: feedBackname,
                                    },
                                } = item;
                                return (
                                    <Fragment key={id}>
                                        <div className={style.feedback__wrapper}>
                                            <Link
                                                to={`${personalAreaPath}${profilePath}/${feedbackUserId}`}
                                                className={style.feedback__wrapper_img}
                                            >
                                                <Img
                                                    className={style.feedback__img}
                                                    src={imgLogo}
                                                    userName={feedBackname}
                                                />
                                            </Link>
                                            <div
                                                className={
                                                    style.feedback__nameTextWrapper
                                                }
                                            >
                                                <Link
                                                    to={`${personalAreaPath}${profilePath}/${feedbackUserId}`}
                                                    className={style.feedback__name}
                                                >
                                                    {feedBackname}
                                                </Link>
                                                <p
                                                    className={
                                                        style.feedback__ratingTitle
                                                    }
                                                >
                                                    {t('feedback.ratedYou')}
                                                </p>
                                                <StarRating
                                                    raiting={parseInt(score, 16)}
                                                />
                                                {text ? (
                                                    <p className={style.feedback__text}>
                                                        {text}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <Moment
                                                className={style.feedback__date}
                                                format="DD/MM/YYYY"
                                                unix
                                            >
                                                {createdAtReview}
                                            </Moment>
                                        </div>
                                    </Fragment>
                                );
                            })}
                    </Fragment>
                )}
            </div>
            {userReviews.totalCount > 10 ? (
                <PaginationComponent totalCount={userReviews.totalCount} />
            ) : null}
        </div>
    );
};

ProfileView.defaultProps = {
    userIfo: {},
    userReviews: {},
    userConfirmationStatus: {},
    authName: '',
    loadingAvatar: false,
    isBlocked: false,
    blockBtnRequest: false,
    cardHolder: '',
    cardNumber: '',
    cardNumberError: '',
    cardHolderError: '',
    isOpenedCardWrapper: false,
    isDeleteIconDisable: false,
    cardList: [],
    saveCard: () => {},
    deleteCard: () => {},
    switchCardWrapper: () => {},
    handleCardChange: () => {},
    cardHolderChange: () => {},
    blockAccount: () => {},
    unBlockAccount: () => {},
    uploadPassportChange: () => {},
    beforeUpload: () => {},
    openSettingsTab: () => {},
};

ProfileView.propTypes = {
    userIfo: PropTypes.object,
    userReviews: PropTypes.object,
    userConfirmationStatus: PropTypes.object,
    authName: PropTypes.string,
    profileId: PropTypes.number.isRequired,
    loadingAvatar: PropTypes.bool,
    isBlocked: PropTypes.bool,
    blockBtnRequest: PropTypes.bool,
    cardHolder: PropTypes.string,
    cardNumber: PropTypes.string,
    cardNumberError: PropTypes.string,
    cardHolderError: PropTypes.string,
    isOpenedCardWrapper: PropTypes.bool,
    isDeleteIconDisable: PropTypes.bool,
    cardList: PropTypes.any,
    saveCard: PropTypes.func,
    deleteCard: PropTypes.func,
    switchCardWrapper: PropTypes.func,
    handleCardChange: PropTypes.func,
    cardHolderChange: PropTypes.func,
    blockAccount: PropTypes.func,
    unBlockAccount: PropTypes.func,
    uploadPassportChange: PropTypes.func,
    beforeUpload: PropTypes.func,
    openSettingsTab: PropTypes.func,
};

export default ProfileView;
