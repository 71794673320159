import { chatConstants } from '../constants';

const getAllChats = (state, action) => {
    if (state === undefined) {
        return {
            allChats: [],
        };
    }

    switch (action.type) {
    case chatConstants.GET_ALL_CHATS:
        return {
            allChats: action.data,
        };

    default:
        return state;
    }
};

export default getAllChats;
