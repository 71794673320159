/* eslint-disable react/no-array-index-key */
/* eslint-disable no-mixed-operators */
import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Upload, message } from 'antd';
import Countdown from 'react-countdown-now';
import StarRatings from 'react-star-ratings';
import Modal from 'react-modal';
import ModalImage from 'react-modal-image';
import Moment from 'react-moment';
import * as moment from 'moment';
import classNames from 'classnames';

import { feedbackModalActions, statusFeedbackModalActions } from '../../../../../actions';
import StarRaiting from '../../../../small-components/star-raiting';
import Img from '../../../../small-components/img';
import Field from '../../../../small-components/field';
import Button from '../../../../small-components/button';
import AreUSureModal from '../../../../small-components/are-u-sure-modal';
import { compose } from '../../../../../utils';
import { countries } from '../../../../../helpers/countries';
import {
    personalAreaPath,
    profilePath,
    chat,
    room,
    OFFER_BUYER,
    OFFER_SELLER,
    TRADE_CANCELED,
    DECLINE_OFFER,
    ACCEPT_OFFER,
    PAYMENT_SEND,
    TRADE_CLOSED,
    ARBITER_PAYMENT_DECLINE,
    ARBITRAGE,
    ORDER_PENDING,
    ARBITER_CONFIRMED_PAYMENT,
    ARBITER_NOT_CONFIRMED_PAYMENT,
    ORDER_HAS_BEEN_COMPLETED_BY_ANOTHER_USER,
    NOT_ENOUGH_BALANCE_FOR_USER_COIN,
    CHAT_MESSAGE_MAX_ERROR,
    CHAT_MESSAGE_MIN_ERROR,
    EXCHANGE,
    BUY,
    SELL,
    BANK_TRANSFER,
    CRYPTO_TRANSFER,
    SFC,
} from '../../../../../constants';

import PostService from '../../../../../services/post-service';
import GetService from '../../../../../services/get-service';

import close from '../../../../assets/images/close.svg';
import attachFile from './images/attach-file.svg';
import sentMail from './images/sent-mail.svg';
import statusOnline from '../../../../small-components/img/images/statusOnline.svg';
import statusOffline from '../../../../small-components/img/images/statusOffline.svg';
import exclamationMark from '../../../../assets/images/exclamationMark.svg';
import arrowBack from '../../../../assets/images/arrowBack.svg';
import style from './chat-page.module.scss';
import './chat-page.scss';

class ChatView extends Component {
    mounted = true;

    postService = new PostService();

    getService = new GetService();

    state = {
        changePrice: '',
        typeMessage: '',
        textareaFeedback: '',
        uploadFileList: [],
        base64Images: [],
        areYouSureModal: false,
        rating: 0,
        btnCancelTradeDisable: false,
        isShowMobileAside: false,
        showUploadList: true,
        isDisableSubmitBtn: false,
        isDisabledAcceptPrice: false,
        isDisabledIHavePaid: false,
        isDisabledReceived: false,
        isDisabledFeedbackSubmit: false,
        errors: {
            changePriceError: '',
            typeMessageError: '',
        },
    };

    messagesEndRef = React.createRef();

    componentDidMount() {
        this.hideFooter();
        this.scrollToBottom();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        clearTimeout(this.timeOut);
        this.mounted = false;
    }

    hideFooter = () => {
        const { match } = this.props;
        const {
            params: { id: matchChat },
        } = match;
        const historyLocation = window.location.pathname;
        const chatLocation = `${personalAreaPath}${chat}${room}/${matchChat}`;

        if (historyLocation === chatLocation) {
            const footer = document.getElementById('footer');
            footer.classList.add('hideOnMobile');
        }
    };

    // Show or hide mobile aside
    switchMobileAside = () => {
        const { isShowMobileAside } = this.state;
        this.setState({
            isShowMobileAside: !isShowMobileAside,
        });
    };

    // Save input changes to state
    InputOnchange = event => {
        const { order } = this.props;
        const { value, name } = event.target;
        const {
            orderType: { handle: orderType },
        } = order;
        const onlyNumbers = /^[0-9.]+$/;

        const isCryptoTransfer = orderType !== EXCHANGE && event.target.name === 'changePrice' ? 6 : 9;
        if (value === '' || onlyNumbers.test(value)) {
            if (value.indexOf('.') > -1) {
                const trimValue = value.substring(
                    0,
                    value.indexOf('.') + isCryptoTransfer,
                );
                this.setState({
                    [name]: trimValue,
                });
            } else {
                this.setState({
                    [name]: value,
                });
            }
        } else {
            this.setState({
                [name]: value,
            });
        }
    };

    // Change price
    changePriceSubmit = event => {
        event.preventDefault();
        const { t, order } = this.props;
        const {
            orderType: { handle: orderType },
        } = order;
        const { changePrice } = this.state;
        const errors = {};

        if (orderType !== EXCHANGE && +changePrice < 0.00001) {
            errors.changePriceError = t('error.priceMustBeGreater', { count: 0.00001 });
        }

        if (orderType === EXCHANGE && +changePrice < 0.00000001) {
            errors.changePriceError = t('error.priceMustBeGreater', {
                count: (1e-8).toFixed(8),
            });
        }

        if (orderType !== EXCHANGE && +changePrice > 999999999.99999) {
            errors.changePriceError = t('error.priceMustBeLess', {
                count: 999999999.99999,
            });
        }

        if (orderType === EXCHANGE && +changePrice > 999999999.99999) {
            errors.changePriceError = t('error.priceMustBeLess', {
                count: 999999999.99999999,
            });
        }

        if (changePrice.length < 1) {
            errors.changePriceError = t('error.input', { count: 1 });
        }

        if (changePrice === '0') {
            errors.changePriceError = t('general.valueMustGreaterThanZero');
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
        } else {
            this.setState({
                errors: {},
            });

            if (changePrice) {
                const { match } = this.props;
                const {
                    params: { id: matchChat },
                } = match;
                const data = {
                    chat: +matchChat,
                    suggestedRate: changePrice,
                };
                this.postService
                    .suggestNewRate(data)
                    .then(() => {
                        message.success(t('general.priceChangeRequestSent'), 5);
                    })
                    .catch(err => {
                        if (err === ORDER_HAS_BEEN_COMPLETED_BY_ANOTHER_USER) {
                            message.error(t('general.orderCompletedByAnotherUser'), 5);
                        } else {
                            message.error(err, 5);
                        }
                    });
                this.setState({
                    changePrice: '',
                });
            }
        }
    };

    // I Have Paid {
    iHavePaid = () => {
        const { t, match } = this.props;
        const {
            params: { id: matchChat },
        } = match;
        const data = {
            chatId: +matchChat,
        };

        this.setState({
            isDisabledIHavePaid: true,
        });

        this.postService
            .iHavePaid(data)
            .then(() => {
                message.success(t('general.iHavePaid'), 5);
            })
            .catch(err => {
                if (err === ORDER_HAS_BEEN_COMPLETED_BY_ANOTHER_USER) {
                    message.error(t('general.orderCompletedByAnotherUser'), 5);
                } else {
                    message.error(err, 5);
                }

                this.setState({
                    isDisabledIHavePaid: false,
                });
            });
    };

    // Cancel trade
    cancelTrade = () => {
        const { t, match, history } = this.props;
        const {
            params: { id: matchChat },
        } = match;
        const data = {
            chat: +matchChat,
        };
        this.postService
            .cancelTrade(data)
            .then(() => {
                message.success(t('general.tradeCanceled'), 5);
                history.push(`${personalAreaPath}${chat}`);
                if (this.mounted) {
                    this.setState({
                        areYouSureModal: false,
                    });
                }
            })
            .catch(err => {
                message.error(err, 5);
            });
    };

    // Finished countdown
    timer = () => {
        this.setState({
            btnCancelTradeDisable: true,
        });
    };

    // Accept price
    acceptPrice = () => {
        const { t, match } = this.props;
        this.setState({
            isDisabledAcceptPrice: true,
        });

        const {
            params: { id: matchChat },
        } = match;
        const data = {
            chat: +matchChat,
        };
        this.postService
            .acceptPrice(data)
            .then(() => {
                message.success(t('general.priceAccepted'), 5);
                localStorage.setItem('timerTime', moment().unix());
            })
            .catch(err => {
                this.setState({
                    isDisabledAcceptPrice: false,
                });
                if (err === NOT_ENOUGH_BALANCE_FOR_USER_COIN) {
                    message.error(t('error.notEnoughFundsInAccount'), 5);
                } else if (err === ORDER_HAS_BEEN_COMPLETED_BY_ANOTHER_USER) {
                    message.error(t('general.orderCompletedByAnotherUser'), 5);
                } else {
                    message.error(err, 5);
                }
            });
    };

    declinePrice = () => {
        const { t, match } = this.props;
        const {
            params: { id: matchChat },
        } = match;
        const data = {
            chat: +matchChat,
        };
        this.postService
            .declinePrice(data)
            .then(() => {
                message.error(t('general.priceDeclined'), 5);
                if (this.mounted) {
                    this.setState({
                        areYouSureModal: false,
                    });
                }
            })
            .catch(err => {
                message.error(err, 5);
            });
    };

    closeAreYouSureModal = id => {
        const { areYouSureModal } = this.state;
        this.setState({
            areYouSureModal: !areYouSureModal,
            modalItem: id,
        });
    };

    yesBtnAreYouSureModal = () => {
        const { modalItem } = this.state;

        if (modalItem === DECLINE_OFFER) {
            this.declinePrice();
        }

        if (modalItem === TRADE_CANCELED) {
            this.cancelTrade();
        }

        if (modalItem === ARBITER_CONFIRMED_PAYMENT) {
            this.arbiterPaymentConfirmed();
        }

        if (modalItem === ARBITER_NOT_CONFIRMED_PAYMENT) {
            this.arbiterPaymentNotConfirmed();
        }
    };

    // Received money
    received = () => {
        const { t, match, dispatch } = this.props;
        const {
            params: { id: matchChat },
        } = match;
        const data = {
            chatId: +matchChat,
        };

        this.setState({
            isDisabledReceived: true,
        });

        this.postService
            .ReceivedMoney(data)
            .then(() => {
                message.success(t('general.iReceivedMoney'), 5);
                dispatch(statusFeedbackModalActions.activeFeedback());
            })
            .catch(err => {
                message.error(err, 5);
                this.setState({
                    isDisabledReceived: false,
                });
            });
    };

    closeVisibleFeedbackModal = () => {
        const { dispatch } = this.props;
        dispatch(feedbackModalActions.closeFeedback());
    };

    // Not received money
    notReceived = () => {
        const { match } = this.props;
        const {
            params: { id: matchChat },
        } = match;
        const data = {
            chatId: +matchChat,
        };

        this.postService.notReceivedMoney(data).catch(err => {
            message.error(err, 5);
        });
    };

    arbiterPaymentConfirmed = () => {
        const { t, match, dispatch } = this.props;

        const {
            params: { id: matchChat },
        } = match;
        const data = {
            chatId: +matchChat,
        };
        this.postService
            .arbiterСonfirmedPayment(data)
            .then(() => {
                if (this.mounted) {
                    this.setState({
                        areYouSureModal: false,
                    });
                }
                message.success(t('general.arbiterConfirmedPayment'), 5);
                dispatch(statusFeedbackModalActions.activeFeedback());
            })
            .catch(err => {
                message.error(err, 5);
            });
    };

    arbiterPaymentNotConfirmed = () => {
        const { t, match } = this.props;
        const {
            params: { id: matchChat },
        } = match;
        const data = {
            chatId: +matchChat,
        };
        this.postService
            .arbiterDeclinedPayment(data)
            .then(() => {
                if (this.mounted) {
                    this.setState({
                        areYouSureModal: false,
                    });
                }
                message.error(t('general.arbiterNotConfirmedPayment'), 5);
            })
            .catch(err => {
                message.error(err, 5);
            });
    };

    // Feedback Rating
    changeRating = newRating => {
        this.setState({
            rating: newRating,
        });
    };

    // Submit Feedback
    feedbackSubmit = event => {
        event.preventDefault();
        const { textareaFeedback, rating } = this.state;
        const {
            chatData: {
                order: { id: orderId },
            },
        } = this.props;

        this.setState({
            isDisabledFeedbackSubmit: true,
        });

        const { t } = this.props;
        const data = {
            orderId,
            text: textareaFeedback,
            score: rating,
        };

        if (+rating < 1) {
            message.error(t('feedback.rateADeal'), 5);
            this.setState({
                isDisabledFeedbackSubmit: false,
            });
        } else {
            this.postService
                .userReview(data)
                .then(() => {
                    message.success(t('general.feedbackSentSuccessfully'), 5);
                    this.setState({
                        textareaFeedback: '',
                    });
                    this.closeVisibleFeedbackModal();
                })
                .catch(err => {
                    this.setState({
                        isDisabledFeedbackSubmit: false,
                    });
                    message.error(err, 5);
                });
        }
    };

    uploadOnChange = info => {
        const isLt5M = info.file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('Image must smaller than 5MB!');
        }

        if (!isLt5M) {
            this.setState({
                uploadFileList: [],
                showUploadList: false,
            });
        } else {
            this.setState({
                showUploadList: true,
                uploadFileList: [...info.fileList],
            });
        }
        return isLt5M;
    };

    beforeUpload = (file, fileList) => {
        const { base64Images } = this.state;
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            this.setState({
                uploadFileList: [],
                showUploadList: false,
            });
        } else {
            this.setState({
                showUploadList: true,
                uploadFileList: [...fileList],
            });
            fileList.forEach(item => {
                const getBase64 = this.getBase64(item);
                getBase64.then(getBase64Url => {
                    this.setState({
                        base64Images: [...base64Images, getBase64Url],
                    });
                });
            });
        }
        return false;
    };

    // Submit chat message
    typeMessageSubmit = async event => {
        event.preventDefault();
        const { uploadFileList, typeMessage, base64Images } = this.state;
        const { t, match } = this.props;
        const errors = {};

        if (typeMessage.length < 1 && uploadFileList.length < 1) {
            errors.typeMessageError = t('error.input', { count: 1 });
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
        } else {
            this.setState({
                errors: {},
                isDisableSubmitBtn: true,
            });

            if (typeMessage || uploadFileList.length >= 1) {
                this.setState({
                    uploadFileList: [],
                    base64Images: [],
                    typeMessage: '',
                    isDisableSubmitBtn: false,
                });

                const data = {
                    files: base64Images,
                    chatId: +match.params.id,
                    message: typeMessage,
                };
                this.postService
                    .sendChatMessages(data)
                    .then(() => {
                        this.scrollToBottom();
                    })
                    .catch(err => {
                        if (this.mounted) {
                            this.setState({
                                isDisableSubmitBtn: false,
                            });
                        }

                        if (
                            err !== CHAT_MESSAGE_MAX_ERROR
                            && err !== CHAT_MESSAGE_MIN_ERROR
                        ) {
                            message.error(err, 5);
                        }
                    });
            }
        }
    };

    getBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    // Scroll to bottom chat
    scrollToBottom = () => {
        const {
            scrollBottomChatRef: { current },
        } = this.props;
        if (current !== null) {
            current.scrollIntoView({
                inline: 'center',
                block: 'center',
            });
        }
    };

    onKeyDown = e => {
        let checkIfNum;
        if (e.key !== undefined) {
            checkIfNum = e.key === 'e' || e.key === '+' || e.key === '-';
        } else if (e.keyCode !== undefined) {
            checkIfNum = e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
    };

    onError = err => {
        console.log(err, 'errror');
    };

    render() {
        const {
            t,
            chatData,
            chatStatus,
            userInfo,
            guestInfo,
            order,
            userName,
            chatData: { buyer, seller, arbiter },
            visibleFeedbackModal,
            chatMessages,
            scrollBottomChatRef,
            currentUserInfo,
        } = this.props;
        const {
            changePrice,
            typeMessage,
            rating,
            textareaFeedback,
            areYouSureModal,
            showUploadList,
            uploadFileList,
            btnCancelTradeDisable,
            isDisableSubmitBtn,
            isShowMobileAside,
            isDisabledAcceptPrice,
            isDisabledIHavePaid,
            isDisabledReceived,
            isDisabledFeedbackSubmit,
            errors: { changePriceError, typeMessageError },
        } = this.state;

        const props = {
            listType: 'picture',
            onChange: this.uploadOnChange,
            beforeUpload: this.beforeUpload,
            multiple: true,
        };

        const canceledTrade = chatStatus === TRADE_CANCELED ? (
            <p className={style.aside__canceledTradeText}>
                {t('general.tradeCanceled')}
            </p>
        ) : null;

        const paymentFailed = chatStatus === ARBITRAGE ? (
            <p className={style.aside__canceledTradeText}>
                {t('general.paymentFailed')}
            </p>
        ) : null;

        const orderCompletedAnotherUser = chatStatus === ORDER_PENDING ? (
            <p className={style.aside__canceledTradeText}>
                {t('general.orderCompletedAnotherUser')}
            </p>
        ) : null;

        const closedTrade = chatStatus === TRADE_CLOSED ? (
            <p className={style.aside__closedTradeText}>{t('general.tradeClosed')}</p>
        ) : null;

        const arbiterPaymentDecline = chatStatus === ARBITER_PAYMENT_DECLINE ? (
            <p className={style.aside__canceledTradeText}>
                {t('general.arbiterPaymentDecline')}
            </p>
        ) : null;

        const allButtons = chatStatus !== TRADE_CLOSED && chatStatus !== TRADE_CANCELED ? (
            <Allbuttons
                buyer={buyer}
                order={order}
                seller={seller}
                arbiter={arbiter}
                userName={userName}
                chatStatus={chatStatus}
                changePrice={changePrice}
                changePriceError={changePriceError}
                btnCancelTradeDisable={btnCancelTradeDisable}
                isDisabledAcceptPrice={isDisabledAcceptPrice}
                isDisabledIHavePaid={isDisabledIHavePaid}
                isDisabledReceived={isDisabledReceived}
                timer={this.timer}
                received={this.received}
                notReceived={this.notReceived}
                modalPaymentConfirmed={this.modalPaymentConfirmed}
                modalPaymentNotConfirmed={this.modalPaymentNotConfirmed}
                declinePrice={this.declinePrice}
                iHavePaid={this.iHavePaid}
                acceptPrice={this.acceptPrice}
                onKeyDown={this.onKeyDown}
                cancelTrade={this.cancelTrade}
                InputOnchange={this.InputOnchange}
                switchMobileAside={this.switchMobileAside}
                closeAreYouSureModal={this.closeAreYouSureModal}
                changePriceSubmit={this.changePriceSubmit}
            />
        ) : null;

        let asideStyle = '';

        if (isShowMobileAside) {
            asideStyle = classNames(style.aside, style.aside__opened);
        } else {
            asideStyle = classNames(style.aside, style.aside__closed);
        }

        let feedbackTitle = '';

        if (chatStatus === TRADE_CLOSED) {
            feedbackTitle = t('general.congratulations');
        } else if (chatStatus === ARBITER_PAYMENT_DECLINE) {
            feedbackTitle = t('general.arbiterPaymentDecline');
        } else {
            feedbackTitle = '';
        }

        return (
            <div className={style.chatWrapper}>
                <div className={style.chat}>
                    <div className={style.chat__header}>
                        <Fragment>
                            <Link
                                to={`${personalAreaPath}${profilePath}/${guestInfo.id}`}
                            >
                                <Img
                                    src={guestInfo.logoSrc}
                                    className={style.chat__header_logo}
                                    userName={guestInfo.username}
                                    alt="logo"
                                    status
                                    online={guestInfo.online}
                                />
                            </Link>
                            <Link
                                className={style.chat__header_user}
                                to={`${personalAreaPath}${profilePath}/${guestInfo.id}`}
                            >
                                <p className={style.chat__header_userName}>
                                    {guestInfo.username}
                                </p>

                                <div className={style.chat__header_userWrapper}>
                                    {guestInfo.online ? (
                                        <p
                                            className={
                                                style.chat__header_userStatusOnline
                                            }
                                        >
                                            Online
                                        </p>
                                    ) : (
                                        <Fragment>
                                            <p
                                                className={
                                                    style.chat__header_userStatusOffline
                                                }
                                            >
                                                Offline
                                            </p>
                                            <div className={style.chat__header_dot} />
                                        </Fragment>
                                    )}
                                    {!guestInfo.online ? (
                                        <p className={style.chat__header_lastSeen}>
                                            {t('general.lastSeenTitle')}{' '}
                                            <Moment format="DD/MM/YYYY" unix>
                                                {guestInfo.lastActivityAt}
                                            </Moment>{' '}
                                            {t('general.in')}{' '}
                                            <Moment format="HH:mm" unix>
                                                {guestInfo.lastActivityAt}
                                            </Moment>
                                        </p>
                                    ) : null}
                                </div>
                            </Link>
                            <div
                                onClick={this.switchMobileAside}
                                className={style.chat__header_mobileMenu}
                            >
                                <img src={exclamationMark} alt="exclamationMark" />
                                Info
                            </div>
                        </Fragment>
                    </div>
                    <div className={style.chat__conversationBlock}>
                        <ul className={style.chat__messagesList}>
                            {chatMessages.map((item, index) => {
                                const {
                                    from: { username, id, online },
                                    message: messageList,
                                    files,
                                } = item;

                                const moderator = id !== userInfo.id && id !== guestInfo.id;

                                let styleMessagesList = '';

                                if (id === userInfo.id) {
                                    if (
                                        arbiter
                                        && currentUserInfo
                                        && arbiter.id === currentUserInfo.id
                                    ) {
                                        styleMessagesList = style.chat__messagesList_guest;
                                    } else {
                                        styleMessagesList = style.chat__messagesList_user;
                                    }
                                } else if (id === guestInfo.id) {
                                    styleMessagesList = style.chat__messagesList_guest;
                                } else if (moderator) {
                                    if (
                                        arbiter
                                        && currentUserInfo
                                        && arbiter.id === currentUserInfo.id
                                    ) {
                                        styleMessagesList = style.chat__messagesList_moderatorPage;
                                    } else {
                                        styleMessagesList = style.chat__messagesList_moderator;
                                    }
                                }

                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Fragment key={index}>
                                        <li className={styleMessagesList}>
                                            <span
                                                className={
                                                    style.chat__messagesList_userName
                                                }
                                            >
                                                <span
                                                    className={
                                                        style.chat__messagesList_dot
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            online
                                                                ? statusOnline
                                                                : statusOffline
                                                        }
                                                        alt="status"
                                                    />
                                                </span>
                                                <span>
                                                    {moderator
                                                        ? t('general.arbitrageModerator')
                                                        : username}
                                                </span>
                                            </span>
                                            <span
                                                className={
                                                    style.chat__messagesList_message
                                                }
                                            >
                                                {messageList}
                                                {files.length > 0 ? (
                                                    <Fragment>
                                                        {files.map(
                                                            (images, indexItem) => (
                                                                <ModalImage
                                                                    key={indexItem}
                                                                    className={
                                                                        style.chat__messagesList_img
                                                                    }
                                                                    small={images}
                                                                    large={images}
                                                                    alt="Message picture"
                                                                    hideZoom
                                                                />
                                                            ),
                                                        )}
                                                    </Fragment>
                                                ) : null}
                                            </span>
                                        </li>
                                    </Fragment>
                                );
                            })}
                            <div ref={scrollBottomChatRef} />
                        </ul>
                        <form
                            onSubmit={this.typeMessageSubmit}
                            className={style.chat__typeMessageWrapper}
                        >
                            <div className={style.chat__typeMessageInput}>
                                <Field
                                    id="typeMessage"
                                    type="text"
                                    placeholder={t('form.typyMessage')}
                                    name="typeMessage"
                                    value={typeMessage}
                                    error={typeMessageError}
                                    onChange={this.InputOnchange}
                                    inputStyle={style.chat__typeMessageWrapper_input}
                                    labelStyle={style.chat__typeMessageWrapper_label}
                                />

                                <Button
                                    id="typeMessageSubmit"
                                    className={
                                        style.chat__typeMessageWrapper_submitButton
                                    }
                                    type="submit"
                                    disabled={isDisableSubmitBtn}
                                >
                                    <img src={sentMail} alt="sentMail" />
                                </Button>
                            </div>

                            <div className="chat__attachFile">
                                <Upload
                                    {...props}
                                    showUploadList={showUploadList}
                                    fileList={uploadFileList}
                                >
                                    <img
                                        className={
                                            style.chat__typeMessageWrapper_attachFile
                                        }
                                        src={attachFile}
                                        alt="attachFile"
                                    />
                                </Upload>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={asideStyle}>
                    <div className={style.aside__header_wrapper}>
                        <div
                            className={style.aside__header_back}
                            onClick={this.switchMobileAside}
                        >
                            <img src={arrowBack} alt="arrowBack" />
                            {t('general.back')}
                        </div>
                        <Link
                            to={`${personalAreaPath}${profilePath}/${guestInfo.id}`}
                            className={style.aside__header}
                        >
                            <div className={style.aside__header_traderWrapper}>
                                <span className={style.aside__header_trader}>
                                    Trader:
                                </span>
                                <span className={style.aside__header_name}>
                                    {guestInfo.username}
                                </span>
                            </div>
                            <div className={style.aside__header_starRating}>
                                <StarRaiting raiting={parseInt(guestInfo.rating, 16)} />
                            </div>
                        </Link>
                    </div>
                    <div className={style.aside__header_traderInfo}>
                        <div className={style.aside__userInformation_item}>
                            <p className={style.aside__userInformation_title}>
                                {t('general.registered')}:
                            </p>
                            <p className={style.aside__userInformation_subTitle}>
                                <Moment format="DD/MM/YYYY" unix>
                                    {guestInfo.createdAt}
                                </Moment>
                            </p>
                        </div>
                        <div className={style.aside__userInformation_item}>
                            <p className={style.aside__userInformation_title}>
                                {t('general.verifiedEmail')}:
                            </p>
                            <p className={style.aside__userInformation_subTitle}>
                                {guestInfo.verifiedEmail
                                    ? t('general.yes')
                                    : t('general.no')}
                            </p>
                        </div>
                    </div>
                    <div className={style.aside__userInformation}>
                        <Fragment>
                            <div className={style.aside__userInformation_item}>
                                <p className={style.aside__userInformation_title}>
                                    {t('general.paymentMethod')}:
                                </p>
                                <p className={style.aside__userInformation_subTitle}>
                                    {chatData.order.paymentMethod.name}
                                </p>
                            </div>
                            <div className={style.aside__userInformation_item}>
                                <p className={style.aside__userInformation_title}>
                                    {t('general.tradeLimits')}:
                                </p>
                                <p className={style.aside__userInformation_subTitle}>
                                    {order.sellAmount}{' '}
                                    {order.orderType.handle !== BUY
                                        ? order.coinSell.handle
                                        : order.coinBuy.handle}
                                </p>
                            </div>
                            <div className={style.aside__userInformation_item}>
                                <p className={style.aside__userInformation_title}>
                                    {t('general.location')}:
                                </p>
                                <p className={style.aside__userInformation_subTitle}>
                                    {countries.map(itemCountry => {
                                        const { name, value } = itemCountry;
                                        return (
                                            <Fragment key={value}>
                                                {value === order.country.handle
                                                    ? name
                                                    : ''}
                                            </Fragment>
                                        );
                                    })}
                                </p>
                            </div>
                            <div className={style.aside__userInformation_item}>
                                <p className={style.aside__userInformation_title}>
                                    {t('general.paymentWindow')}:
                                </p>
                                <p className={style.aside__userInformation_subTitle}>
                                    {order.paymentWindow} {t('general.min')}
                                </p>
                            </div>
                            <div className={style.aside__userInformation_item}>
                                <p className={style.aside__userInformation_title}>
                                    {t('general.chatId')}
                                </p>
                                <p className={style.aside__userInformation_subTitle}>
                                    {chatData.id}
                                </p>
                            </div>

                            {chatData.order.paymentMethod.handle === BANK_TRANSFER
                            && chatData.sellerBankCard
                            && chatData.sellerBankCard.cardNumber ? (
                                    <div className={style.aside__userInformation_itemFull}>
                                        <p className={style.aside__userInformation_title}>
                                            {t('newOrder.placeHolderCardNumber')}:
                                        </p>
                                        <p className={style.aside__userInformation_subTitle}>
                                            {chatData.sellerBankCard.cardNumber}
                                        </p>
                                    </div>
                                ) : null}
                            {chatData.order.paymentMethod.handle !== CRYPTO_TRANSFER
                            && chatData.order.paymentMethod.handle !== BANK_TRANSFER
                            && BUY === order.orderType.handle
                            && chatData.paymentMethodNumber
                            && chatData.paymentMethodNumber ? (
                                    <div className={style.aside__userInformation_itemFull}>
                                        <p className={style.aside__userInformation_title}>
                                            {t('newOrder.paymentSystemNumber')}:
                                        </p>
                                        <p className={style.aside__userInformation_subTitle}>
                                            {chatData.paymentMethodNumber}
                                        </p>
                                    </div>
                                ) : null}
                            {chatData.order.paymentMethod.handle !== CRYPTO_TRANSFER
                            && chatData.order.paymentMethod.handle !== BANK_TRANSFER
                            && SELL === order.orderType.handle
                            && chatData.order.paymentMethodNumber
                            && chatData.order.paymentMethodNumber ? (
                                    <div className={style.aside__userInformation_itemFull}>
                                        <p className={style.aside__userInformation_title}>
                                            {t('newOrder.paymentSystemNumber')}:
                                        </p>
                                        <p className={style.aside__userInformation_subTitle}>
                                            {chatData.order.paymentMethodNumber}
                                        </p>
                                    </div>
                                ) : null}
                        </Fragment>
                    </div>

                    {/* Requested price */}
                    {chatStatus !== TRADE_CLOSED && chatStatus !== TRADE_CANCELED ? (
                        <div className={style.aside__requestedPrice}>
                            <span className={style.aside__requestedPrice_title}>
                                {t('general.requestedPrice')}
                            </span>
                            <span className={style.aside__requestedPrice_value}>
                                {chatData.suggestedRate}
                            </span>
                            <span className={style.aside__requestedPrice_currency}>
                                {order.orderType.handle !== BUY
                                    ? order.coinBuy.handle
                                    : order.coinSell.handle}{' '}
                                /{' '}
                                {order.orderType.handle !== BUY
                                    ? order.coinSell.handle
                                    : order.coinBuy.handle}
                            </span>
                        </div>
                    ) : null}
                    {/* Requested price */}

                    {/* All buttons controller */}
                    {canceledTrade}
                    {paymentFailed}
                    {closedTrade}
                    {arbiterPaymentDecline}
                    {orderCompletedAnotherUser}
                    {allButtons}
                    {/* All buttons controller */}
                </div>
                <Modal
                    isOpen={visibleFeedbackModal}
                    onRequestClose={this.closeVisibleFeedbackModal}
                    ariaHideApp={false}
                    className="feedBackModalWindow reactModal"
                    contentLabel="Example Modal"
                    overlayClassName="modalOverlay"
                >
                    <div className={style.feedback}>
                        <h2 className={style.feedback__title}>{feedbackTitle}</h2>
                        <p className={style.feedback__subTitle}>
                            {t('feedback.sucessfullyBuyer')}
                        </p>
                        <div onClick={this.closeVisibleFeedbackModal}>
                            <img
                                src={close}
                                className={style.feedback__close}
                                alt="close"
                            />
                        </div>
                        <form
                            onSubmit={this.feedbackSubmit}
                            className={style.feedback__form}
                        >
                            <StarRatings
                                rating={rating}
                                starRatedColor="#1d73ff"
                                starHoverColor="#1d73ff"
                                starEmptyColor="transparent"
                                changeRating={this.changeRating}
                                numberOfStars={5}
                                name="rating"
                            />

                            <textarea
                                id="textareaFeedback"
                                placeholder={t('form.typyFeedback')}
                                name="textareaFeedback"
                                value={textareaFeedback}
                                onChange={this.InputOnchange}
                                className={style.feedback__textarea}
                                maxLength="500"
                            />
                            <div className={style.feedback__submitBtnWrapper}>
                                <Button
                                    id="submitFeedback"
                                    className={style.feedback__submitBtn}
                                    disabled={isDisabledFeedbackSubmit}
                                    type="submit"
                                >
                                    {t('general.send')}
                                </Button>
                            </div>
                        </form>
                    </div>
                </Modal>
                <AreUSureModal
                    visibleModal={areYouSureModal}
                    noButton={this.closeAreYouSureModal}
                    yesButton={this.yesBtnAreYouSureModal}
                />
            </div>
        );
    }
}

ChatView.defaultProps = {
    t: () => {},
    chatData: {},
    chatStatus: '',
    userName: '',
    dispatch: () => {},
    match: {},
    userInfo: {},
    currentUserInfo: {},
    guestInfo: {},
    order: {},
    history: {},
    scrollBottomChatRef: {},
    chatMessages: [],
    visibleFeedbackModal: false,
    showFeedback: false,
};

ChatView.propTypes = {
    t: PropTypes.func,
    chatData: PropTypes.object,
    chatStatus: PropTypes.string,
    userName: PropTypes.string,
    dispatch: PropTypes.func,
    match: PropTypes.object,
    userInfo: PropTypes.object,
    currentUserInfo: PropTypes.object,
    guestInfo: PropTypes.object,
    order: PropTypes.object,
    history: PropTypes.object,
    scrollBottomChatRef: PropTypes.object,
    visibleFeedbackModal: PropTypes.bool,
    showFeedback: PropTypes.bool,
    chatMessages: PropTypes.instanceOf(Array),
};

const mapStateToProps = state => {
    const {
        feedbackModal: { visibleFeedbackModal },
        activeFeedback: { showFeedback },
    } = state;

    return {
        visibleFeedbackModal,
        showFeedback,
    };
};

export default compose(withTranslation(), connect(mapStateToProps), withRouter)(ChatView);

const Allbuttons = ({
    order,
    buyer,
    seller,
    arbiter,
    userName,
    chatStatus,
    changePrice,
    changePriceError,
    btnCancelTradeDisable,
    isDisabledAcceptPrice,
    isDisabledIHavePaid,
    isDisabledReceived,
    timer = () => {},
    received = () => {},
    iHavePaid = () => {},
    notReceived = () => {},
    acceptPrice = () => {},
    onKeyDown = () => {},
    InputOnchange = () => {},
    closeAreYouSureModal = () => {},
    changePriceSubmit = () => {},
}) => {
    const { t } = useTranslation();

    const { coinSell: { handle: coinSell }, coinBuy: { handle: coinBuy } } = order;
    const isOfferBuyerSellerDecline = [OFFER_BUYER, OFFER_SELLER, DECLINE_OFFER];
    const isSfcCoinSell = [SFC].includes(coinSell);
    const isSfcCoinBuy = [SFC].includes(coinBuy);

    // Renderer callback with condition
    // eslint-disable-next-line react/prop-types
    const renderer = ({ hours, minutes, seconds }) => (
        <span className={style.aside__counter}>
            {hours}:{minutes}:{seconds}
        </span>
    );

    // Disable cancel trade button
    const btnCancelTradeFullWidth = btnCancelTradeDisable
        ? style.aside__btnCancelTradeFullWidth
        : style.aside__btnCancelTradeFullWidthDisable;

    const styleNotReceived = btnCancelTradeDisable
        ? style.aside__notReceived
        : style.aside__notReceivedDisable;

    const styleIhavePaid = chatStatus === PAYMENT_SEND
        ? style.aside__btnIHavePaidDisabled
        : style.aside__btnIHavePaid;

    const btnCancelTrade = chatStatus === PAYMENT_SEND
        ? style.aside__btnCancelTradeDisabled
        : style.aside__btnCancelTrade;

    return (
        <div className={style.aside__allButtonsWrapper}>
            {/* Accept/Decline price */}

            {(chatStatus === OFFER_BUYER && seller.username === userName)
            || (chatStatus === OFFER_SELLER && buyer.username === userName) ? (
                    <Fragment>
                        <div className={style.aside__acceptDeclineWrapper}>
                            <Button
                                onClick={acceptPrice}
                                className={style.aside__acceptPrice}
                                disabled={isDisabledAcceptPrice}
                                type="button"
                            >
                                {t('general.accept')}
                            </Button>

                            <Button
                                onClick={() => closeAreYouSureModal(DECLINE_OFFER)}
                                className={style.aside__declinePrice}
                                type="button"
                            >
                                {t('general.decline')}
                            </Button>
                        </div>
                    </Fragment>
                ) : null}
            {/* Accept/Decline price */}

            {/* Change price */}
            {!isSfcCoinSell && !isSfcCoinBuy && isOfferBuyerSellerDecline.includes(chatStatus) ? (
                <form
                    onSubmit={changePriceSubmit}
                    className={style.aside__changePriceWrapper}
                >
                    <div className={style.aside__changePrice}>
                        <Field
                            id="changePrice"
                            type="number"
                            placeholder={t('general.changePrice')}
                            name="changePrice"
                            value={changePrice}
                            error={changePriceError}
                            onChange={InputOnchange}
                            onKeyDown={onKeyDown}
                            step="0.00000001"
                            inputStyle={style.aside__changePrice_input}
                            labelStyle={style.aside__changePrice_label}
                        />
                        <span className={style.aside__changePrice_currency}>
                            {order.orderType.handle !== BUY
                                ? order.coinBuy.handle
                                : order.coinSell.handle}{' '}
                            /{' '}
                            {order.orderType.handle !== BUY
                                ? order.coinSell.handle
                                : order.coinBuy.handle}
                        </span>
                    </div>
                    <Button
                        id="btnRequestForPrice"
                        className={style.aside__btnRequestForPrice}
                        type="submit"
                    >
                        {t('general.requestForPrice')}
                    </Button>
                </form>
            ) : null}
            {/* Change price */}

            <div className={style.aside__timerWrapper}>
                {/* Timer */}
                {chatStatus === ACCEPT_OFFER || chatStatus === PAYMENT_SEND ? (
                    <Countdown
                        onComplete={timer}
                        date={order.timeToStartTrades * 1000}
                        // date={Date.now() + 5000}
                        renderer={renderer}
                    />
                ) : null}
                {/* Timer */}

                {/* iHavePaid */}
                {[ACCEPT_OFFER, PAYMENT_SEND].includes(chatStatus)
                && ((SELL === order.orderType.handle && buyer.username === userName)
                    || (BUY === order.orderType.handle && seller.username === userName)) ? (
                        <div className={style.aside__havePaidCancelTradeWrapper}>
                            <Button
                                onClick={iHavePaid}
                                className={styleIhavePaid}
                                disabled={isDisabledIHavePaid}
                                type="button"
                            >
                                {t('general.iHavePaid')}
                            </Button>

                            <Button
                                onClick={() => closeAreYouSureModal(TRADE_CANCELED)}
                                className={btnCancelTrade}
                                type="button"
                                disabled={chatStatus === PAYMENT_SEND}
                            >
                                {t('general.cancelTrade')}
                            </Button>
                        </div>
                    ) : null}
                {/* iHavePaid */}

                {/* Full btn Width */}
                {ACCEPT_OFFER === chatStatus
                && ((BUY === order.orderType.handle && buyer.username === userName)
                    || (SELL === order.orderType.handle && seller.username === userName)) ? (
                        <div className={style.aside__fullWidthCancelTradeWrapper}>
                            <Button
                                onClick={notReceived}
                                className={btnCancelTradeFullWidth}
                                type="button"
                                disabled={!btnCancelTradeDisable}
                            >
                                {t('general.cancelTrade')}
                            </Button>
                        </div>
                    ) : null}
                {/* Full btn Width */}

                {/* Received / notReceived money */}
                {PAYMENT_SEND === chatStatus
                && ((BUY === order.orderType.handle && buyer.username === userName)
                    || (SELL === order.orderType.handle && seller.username === userName)) ? (
                        <div className={style.aside__receivedNotReceivedWrapper}>
                            <Button
                                onClick={received}
                                className={style.aside__btnReceived}
                                disabled={isDisabledReceived}
                                type="button"
                            >
                                {t('general.received')}
                            </Button>

                            <Button
                                onClick={notReceived}
                                className={styleNotReceived}
                                type="button"
                                disabled={!btnCancelTradeDisable}
                            >
                                {t('general.notReceived')}
                            </Button>
                        </div>
                    ) : null}
                {/* Received / notReceived money */}

                {/* Arbitrage */}
                {chatStatus === ARBITRAGE && arbiter && arbiter.username === userName ? (
                    <div className={style.aside__receivedNotReceivedWrapper}>
                        <Button
                            onClick={() => closeAreYouSureModal(ARBITER_CONFIRMED_PAYMENT)
                            }
                            className={style.aside__btnReceived}
                            type="button"
                        >
                            {t('general.paymentConfirmed')}
                        </Button>

                        <Button
                            onClick={() => closeAreYouSureModal(ARBITER_NOT_CONFIRMED_PAYMENT)
                            }
                            className={style.aside__notReceived}
                            type="button"
                        >
                            {t('general.paymentNotConfirmed')}
                        </Button>
                    </div>
                ) : null}
                {/* Arbitrage */}
            </div>
        </div>
    );
};

Allbuttons.defaultProps = {
    userName: '',
    chatStatus: '',
    changePrice: '',
    changePriceError: '',
    btnCancelTradeDisable: false,
    isDisabledAcceptPrice: false,
    isDisabledIHavePaid: false,
    isDisabledReceived: false,
    order: {},
    buyer: {},
    seller: {},
    arbiter: {},
    timer: () => {},
    received: () => {},
    iHavePaid: () => {},
    notReceived: () => {},
    acceptPrice: () => {},
    onKeyDown: () => {},
    InputOnchange: () => {},
    closeAreYouSureModal: () => {},
    changePriceSubmit: () => {},
};

Allbuttons.propTypes = {
    timer: PropTypes.func,
    buyer: PropTypes.object,
    order: PropTypes.object,
    received: PropTypes.func,
    seller: PropTypes.object,
    arbiter: PropTypes.object,
    iHavePaid: PropTypes.func,
    userName: PropTypes.string,
    notReceived: PropTypes.func,
    acceptPrice: PropTypes.func,
    onKeyDown: PropTypes.func,
    chatStatus: PropTypes.string,
    InputOnchange: PropTypes.func,
    changePrice: PropTypes.string,
    closeAreYouSureModal: PropTypes.func,
    changePriceSubmit: PropTypes.func,
    changePriceError: PropTypes.string,
    btnCancelTradeDisable: PropTypes.bool,
    isDisabledAcceptPrice: PropTypes.bool,
    isDisabledIHavePaid: PropTypes.bool,
    isDisabledReceived: PropTypes.bool,
};
