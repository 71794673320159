import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { message } from 'antd';

import AdvertisementView from './advertisement-page-view';
import GetService from '../../../../../services/get-service';
import PostService from '../../../../../services/post-service';
import Spinner from '../../../../spinner';
import ErrorIndicator from '../../../error-page/error-indicator';
import {
    OPENED,
    PENDING,
    CLOSED,
    dashboardPath,
    personalAreaPath,
} from '../../../../../constants';
import { compose } from '../../../../../utils';

export class AdvertisementContainer extends Component {
    getService = new GetService();

    postService = new PostService();

    state = {
        dashboard: [],
        loading: true,
        deleteModal: false,
        isButtonDisabled: false,
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        this.setState({
            isButtonDisabled: true,
        });
        const storagePageNumber = localStorage.getItem('dashboardStorageFilter');

        this.getService
            .getUserOrders(storagePageNumber)
            .then(dashboard => {
                this.setState({
                    dashboard,
                    loading: false,
                    error: false,
                    isButtonDisabled: false,
                });
            })
            .catch(this.onError);
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    switchAdvertisementStatus = () => {
        const { t, match } = this.props;
        const { dashboard } = this.state;
        const {
            params: { id: matchDashboard },
        } = match;

        const currentOrder = dashboard.items.filter(
            item => item.id.toString() === matchDashboard,
        );

        const orderStatus = currentOrder[0].orderStatus.handle;

        if (orderStatus === PENDING || orderStatus === CLOSED) {
            message.error(t('general.cannotChangeOrder'), 5);
        } else {
            this.setState({
                isButtonDisabled: true,
            });
            const data = {
                order: currentOrder[0].id,
            };
            const request = orderStatus === OPENED
                ? this.postService.cancelOrder(data).catch(err => {
                    message.error(err, 5);
                })
                : this.postService.activateOrder(data).catch(err => {
                    message.error(err, 5);
                });

            request.then(() => {
                this.setState({
                    isButtonDisabled: false,
                });
                this.loadData();
            });
        }
    };

    switchDeleteModal = () => {
        const { t, match } = this.props;
        const { deleteModal, dashboard } = this.state;

        const {
            params: { id: matchDashboard },
        } = match;

        const currentOrder = dashboard.items.filter(
            item => item.id.toString() === matchDashboard,
        );

        const orderStatus = currentOrder[0].orderStatus.handle;

        if (orderStatus === PENDING || orderStatus === CLOSED) {
            message.error(t('general.cannotChangeOrder'), 5);
        } else {
            this.setState({
                deleteModal: !deleteModal,
            });
        }
    };

    preventPendingRedirect = e => {
        const { t, match } = this.props;
        const { dashboard } = this.state;

        const {
            params: { id: matchDashboard },
        } = match;

        const currentOrder = dashboard.items.filter(
            item => item.id.toString() === matchDashboard,
        );

        const orderStatus = currentOrder[0].orderStatus.handle;

        if (orderStatus === PENDING || orderStatus === CLOSED) {
            e.preventDefault();
            message.error(t('general.cannotChangeOrder'), 5);
        }
    };

    deleteAdvertisement = id => {
        const { t, history } = this.props;
        const data = {
            order: +id,
        };

        this.postService
            .deleteOrder(data)
            .then(() => {
                message.success(t('general.advertisementDeleted'), 5);
                this.setState({
                    deleteModal: false,
                });
                history.push(`${personalAreaPath}${dashboardPath}`);
            })
            .catch(err => {
                message.error(err, 5);
            });
    };

    render() {
        const {
            dashboard, loading, error, deleteModal, isButtonDisabled,
        } = this.state;
        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <AdvertisementView
                dashboard={dashboard}
                deleteModal={deleteModal}
                isButtonDisabled={isButtonDisabled}
                switchDeleteModal={this.switchDeleteModal}
                preventPendingRedirect={this.preventPendingRedirect}
                deleteAdvertisement={this.deleteAdvertisement}
                switchAdvertisementStatus={this.switchAdvertisementStatus}
                loadData={this.loadData}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

AdvertisementContainer.defaultProps = {
    t: () => {},
    match: {},
    history: {},
};

AdvertisementContainer.propTypes = {
    t: PropTypes.func,
    match: PropTypes.object,
    history: PropTypes.object,
};

export default compose(
    withTranslation(),
    withRouter,
)(AdvertisementContainer);
