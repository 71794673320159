import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from 'react-slick';

import Button from '../../../small-components/button';
import { compose } from '../../../../utils';
import Img from '../../../small-components/img';
import { authModalActions } from '../../../../actions';
import { personalAreaPath, newOrder } from '../../../../constants/pathLocation';

import mouse from './images/mouse.svg';
import firstScreenBg from './images/firstScreenBg.png';
import cardBanner from './images/cardBanner.png';
import roundBlack from './images/roundBlack.svg';
import roundRed from './images/roundRed.svg';

import style from './first-screen.module.scss';
import './first-screen.scss';

export class FirstScreen extends PureComponent {
    render() {
        const { t, openLogin = () => {}, loggedIn } = this.props;

        const sliderSettings = {
            dots: true,
            infinite: true,
            pauseOnHover: false,
            speed: 1000,
            autoplaySpeed: 5000,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };

        return (
            <Fragment>
                <div className="filter firstScreen">
                    <Slider {...sliderSettings}>
                        <div className={style.firstScreen}>
                            <img
                                className={style.firstScreen__backGround}
                                src={firstScreenBg}
                                alt="firstScreenBg"
                            />
                            <div className={style.firstScreen__leftSide}>
                                <div>
                                    <div
                                        className={style.firstScreen__leftSide_title}
                                    >
                                        <TextTruncate
                                            line={2}
                                            element="h1"
                                            truncateText="…"
                                            text={t('firstScreen.title')}
                                        />
                                    </div>
                                    <div
                                        className={
                                            style.firstScreen__leftSide_subtitleSecondRow
                                        }
                                    >
                                        <TextTruncate
                                            line={5}
                                            element="div"
                                            truncateText="…"
                                            text={t('firstScreen.subtitleSecondRow')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {loggedIn ? (
                                        <Link
                                            to={`${personalAreaPath}${newOrder}`}
                                            className={
                                                style.firstScreen__leftSide_btn
                                            }
                                        >
                                            {t('general.postAtrade')}
                                        </Link>
                                    ) : (
                                        <Button
                                            onClick={() => openLogin()}
                                            type="button"
                                            className={
                                                style.firstScreen__leftSide_btn
                                            }
                                        >
                                            {t('firstScreen.button')}
                                        </Button>
                                    )}
                                </div>
                                <AnchorLink href="#workWith">
                                    <div className={style.firstScreen__scrollDown}>
                                        <Img
                                            className={
                                                style.firstScreen__scrollDown_mouse
                                            }
                                            src={mouse}
                                            alt="mouse"
                                        />
                                        {t('firstScreen.scrollDown')}
                                    </div>
                                </AnchorLink>
                            </div>
                            <div className={style.firstScreen__rightSide} />
                        </div>
                        {/*<div className={style.cardFirstScreen}>*/}
                        {/*    <img*/}
                        {/*        className={style.firstScreen__cardBackGround}*/}
                        {/*        src={cardBanner}*/}
                        {/*        alt="cardBanner"*/}
                        {/*    />*/}
                        {/*    <div className={style.firstScreen__rightSide}>*/}
                        {/*        <AnchorLink href="#workWith">*/}
                        {/*            <div className={style.firstScreen__scrollDown}>*/}
                        {/*                <Img*/}
                        {/*                    className={*/}
                        {/*                        style.firstScreen__scrollDown_mouse*/}
                        {/*                    }*/}
                        {/*                    src={mouse}*/}
                        {/*                    alt="mouse"*/}
                        {/*                />*/}
                        {/*                {t('firstScreen.scrollDown')}*/}
                        {/*            </div>*/}
                        {/*        </AnchorLink>*/}
                        {/*    </div>*/}
                        {/*    <div className={style.firstScreen__cards}>*/}
                        {/*        <div>*/}
                        {/*            <h3 className={style.firstScreen__cards_title}>*/}
                        {/*                {t('card.title')}*/}
                        {/*            </h3>*/}
                        {/*            <p className={style.firstScreen__cards_subtitle}>*/}
                        {/*                {t('card.subTitle')}*/}
                        {/*            </p>*/}
                        {/*        </div>*/}

                        {/*        <a*/}
                        {/*            href="https://cards.crocobit.net/"*/}
                        {/*            target="_blank"*/}
                        {/*            rel="noopener noreferrer"*/}
                        {/*            className={style.firstScreen__cards__button}*/}
                        {/*        >*/}
                        {/*            <img*/}
                        {/*                className={style.firstScreen__cards__button_roundBlack}*/}
                        {/*                src={roundBlack}*/}
                        {/*                alt="roundBlack"*/}
                        {/*            />*/}
                        {/*            <div className={style.firstScreen__cards__button_bounce}>*/}
                        {/*                <img*/}
                        {/*                    className={style.firstScreen__cards__button_roundRed}*/}
                        {/*                    src={roundRed}*/}
                        {/*                    alt="roundRed"*/}
                        {/*                />*/}
                        {/*                <p className={style.firstScreen__cards__button_text}>{t('card.button')}</p>*/}
                        {/*            </div>*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Slider>
                </div>
            </Fragment>
        );
    }
}

FirstScreen.defaultProps = {
    t: () => {},
    openLogin: () => {},
    loggedIn: false,
};

FirstScreen.propTypes = {
    t: PropTypes.func,
    openLogin: PropTypes.func,
    loggedIn: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        authModal: { visibleLogin },
        authentication: { loggedIn },
    } = state;

    return {
        visibleLogin,
        loggedIn,
    };
};

const mapDispatchToProps = dispatch => ({
    openLogin: () => {
        dispatch(authModalActions.openLogin());
    },

    closeModal() {
        dispatch(authModalActions.closeModal());
    },
});

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(FirstScreen);
