/* eslint-disable */
import { chatConstants } from '../constants';

export function currentChat(state, action) {
    if (state === undefined) {
        return {
            currentChat: '',
        };
    }
    switch (action.type) {
        case chatConstants.SELECT_CHAT:
            return {
                currentChat: action.id,
            };
        default:
            return state;
    }
}
