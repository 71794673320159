import coinsConstants from '../constants/coins.constants';
import i18n from '../i18n';

const getAllCoinsRequested = () => ({
    type: coinsConstants.FETCH_ALL_COINS_REQUEST,
});

const getAllCoinsLoaded = data => ({
    type: coinsConstants.FETCH_ALL_COINS_SUCCESS,
    payload: data,
});

const getAllCoinsError = error => ({
    type: coinsConstants.FETCH_ALL_COINS_FAILURE,
    payload: error,
});

const getAllCoinsAction = getService => () => dispatch => {
    dispatch(getAllCoinsRequested());
    getService
        .getAllCoins()
        .then(data => {
            const translatedName = [];
            data.map(item => {
                const { handle, isCrypto } = item;
                if (isCrypto) {
                    const translatedData = {
                        ...item,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'EUR') {
                    const translatedData = {
                        name: i18n.t('coins.EUR'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'USD') {
                    const translatedData = {
                        name: i18n.t('coins.USD'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'INR') {
                    const translatedData = {
                        name: i18n.t('coins.INR'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'PKR') {
                    const translatedData = {
                        name: i18n.t('coins.PKR'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'NGN') {
                    const translatedData = {
                        name: i18n.t('coins.NGN'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'CNY') {
                    const translatedData = {
                        name: i18n.t('coins.CNY'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'IDR') {
                    const translatedData = {
                        name: i18n.t('coins.IDR'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'COP') {
                    const translatedData = {
                        name: i18n.t('coins.COP'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'VES') {
                    const translatedData = {
                        name: i18n.t('coins.VES'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'TRY') {
                    const translatedData = {
                        name: i18n.t('coins.TRY'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'KZT') {
                    const translatedData = {
                        name: i18n.t('coins.KZT'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'CLP') {
                    const translatedData = {
                        name: i18n.t('coins.CLP'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'RUB') {
                    const translatedData = {
                        name: i18n.t('coins.RUB'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'UAH') {
                    const translatedData = {
                        name: i18n.t('coins.UAH'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else if (handle === 'AMD') {
                    const translatedData = {
                        name: i18n.t('coins.AMD'),
                        handle,
                        isCrypto,
                    };
                    translatedName.push(translatedData);
                } else {
                    const translatedData = {
                        ...item,
                    };
                    translatedName.push(translatedData);
                }
                return true;
            });
            dispatch(getAllCoinsLoaded(translatedName));
        })
        .catch(err => dispatch(getAllCoinsError(err)));
};

export default getAllCoinsAction;
