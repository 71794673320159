import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { compose } from '../../../../../utils';
import { DashboardActions } from '../../../../../actions';
import { FilterActions } from '../../../../../actions/filter.actions';
import DashboardView from './dashboard-page-view';
import GetService from '../../../../../services/get-service';
import Spinner from '../../../../spinner';
import ErrorIndicator from '../../../error-page/error-indicator';

export class DashboardContainer extends Component {
    getService = new GetService();

    state = {
        dashboard: [],
        loading: true,
    };

    componentDidMount() {
        const { userCoutry, dispatch } = this.props;
        if (userCoutry.value) {
            const filterParamsData = {
                country: userCoutry.value,
            };
            localStorage.removeItem('ordersStorageFilter');
            dispatch(FilterActions.selectFilter(filterParamsData));
        }
    }

    componentDidUpdate(prevProps) {
        const { filterParams } = this.props;
        if (filterParams && filterParams !== prevProps.filterParams) {
            this.getFilterParams();
        }
    }

    getFilterParams = () => {
        const { filterParams } = this.props;
        const params = Object.keys(filterParams)
            .map(
                keyParams => `${encodeURIComponent(keyParams)}=${encodeURIComponent(
                    filterParams[keyParams],
                )}`,
            )
            .join('&');
        localStorage.setItem('dashboardStorageFilter', params);

        this.loadData(params);
    };

    loadData = params => {
        const storagePageNumber = localStorage.getItem('dashboardStorageFilter');
        this.getService
            .getUserOrders(params || storagePageNumber)
            .then(dashboard => {
                this.setState({
                    dashboard,
                    loading: false,
                    error: false,
                });
            })
            .catch(this.onError);
    };

    clearDashboardFilter = () => {
        const { dispatch } = this.props;
        localStorage.removeItem('dashboardStorageFilter');
        dispatch(FilterActions.selectFilter({}));
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    // Save dashboard id to redux after select
    saveDashboardIdToRedux = id => {
        const { dispatch } = this.props;
        dispatch(DashboardActions.selectDashboard(id.toString()));
    };

    render() {
        const { dashboard, loading, error } = this.state;

        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <DashboardView
                dashboard={dashboard}
                saveDashboardIdToRedux={this.saveDashboardIdToRedux}
            />
        ) : null;

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        currentFilter: { filterParams },
        getUserCountry: { country: userCoutry },
    } = state;

    return {
        filterParams,
        userCoutry,
    };
};

DashboardContainer.defaultProps = {
    dispatch: () => {},
    filterParams: null,
    userCoutry: {},
};

DashboardContainer.propTypes = {
    dispatch: PropTypes.func,
    filterParams: PropTypes.object,
    userCoutry: PropTypes.object,
};

export default compose(connect(mapStateToProps))(DashboardContainer);
