const txPROD = [
    {
        value: 'BTC',
        explorer: 'https://live.blockcypher.com/btc/tx/',
    },
    {
        value: 'ETH',
        explorer: 'https://etherscan.io/tx/',
    },
    {
        value: 'LTC',
        explorer: 'https://live.blockcypher.com/ltc/tx/',
    },
    {
        value: 'DASH',
        explorer: 'https://live.blockcypher.com/dash/tx/',
    },
    {
        value: 'EDC',
        explorer: 'https://explorer.blockchain.mn/operation/#',
    },
    {
        value: 'ZEC',
        explorer: 'https://explorer.zcha.in/transactions/',
    },
    {
        value: 'EDC20',
        explorer: 'https://etherscan.io/tx/',
    },
    {
        value: 'SFC',
        explorer: 'https://explorer.blockchain.mn/operation/#',
    },
];

const txDEV = [
    {
        value: 'BTC',
        explorer: 'https://live.blockcypher.com/btc-testnet/tx/',
    },
    {
        value: 'ETH',
        explorer: 'https://ropsten.etherscan.io/tx/',
    },
    {
        value: 'LTC',
        explorer: 'https://sochain.com/tx/LTCTEST/',
    },
    {
        value: 'DASH',
        explorer: 'https://sochain.com/tx/DASHTEST/',
    },
    {
        value: 'EDC',
        explorer: 'https://testnet-explorer.blockchain.mn/operation/#',
    },
    {
        value: 'ZEC',
        explorer: 'https://explorer.testnet.z.cash/tx/',
    },
    {
        value: 'EDC20',
        explorer: 'https://etherscan.io/tx/',
    },
    {
        value: 'SFC',
        explorer: 'https://explorer.blockchain.mn/operation/#',
    },
];

const txLinks = process.env.REACT_APP_ENV === 'development' ? txDEV : txPROD;

export default txLinks;
