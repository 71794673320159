import coinsConstants from '../constants/coins.constants';

const getAllCoins = (state, action) => {
    if (state === undefined) {
        return {
            allCoins: [],
            loading: true,
            error: null,
        };
    }

    switch (action.type) {
    case coinsConstants.FETCH_ALL_COINS_REQUEST:
        return {
            allCoins: [],
            loading: true,
            error: null,
        };

    case coinsConstants.FETCH_ALL_COINS_SUCCESS:
        return {
            allCoins: action.payload,
            loading: false,
            error: null,
        };

    case coinsConstants.FETCH_ALL_COINS_FAILURE:
        return {
            allCoins: [],
            loading: false,
            error: action.payload,
        };

    default:
        return state;
    }
};

export default getAllCoins;
