import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import GetService from '../../../services/get-service';
import withGetService from '../../hoc';
import { modalTradeActions, FilterActions } from '../../../actions';
import getAllOrdersAction from '../../../actions/getAllOrders.actions';
import getAllCoinsAction from '../../../actions/getAllCoins.actions';
import { compose } from '../../../utils';
import TableRowView from './orders-page-view';
import Spinner from '../../spinner';
import ErrorIndicator from '../error-page/error-indicator';
import FilterBlock from '../../layouts/filter-block';
import SliderWithRightSideText from '../../layouts/slider-with-right-side-text';

import EDC from '../home-page/work-with/images/EDC.png';
import BTC from '../home-page/work-with/images/BTC.svg';
import DASH from '../home-page/work-with/images/DASH.svg';
import ETH from '../home-page/work-with/images/ETH.svg';
import LTC from '../home-page/work-with/images/LTC.svg';
import ZEC from '../home-page/work-with/images/ZCASH.svg';
import EDC20 from '../../assets/images/coins/cde.svg';
import SFC from '../../assets/images/coins/sfc.svg';
import CROCO from '../../assets/images/coins/croco.svg';

import style from './orders-page.module.scss';

export class Orders extends Component {
    getService = new GetService();

    mounted = true;

    state = {
        cardList: [],
    };

    componentDidMount() {
        const {
            loggedIn,
        } = this.props;

        this.filterParamsToQuery();
        if (loggedIn) {
            this.loadAllCards();
        }
    }

    componentDidUpdate(prevProps) {
        const { filterParams } = this.props;
        if (filterParams !== prevProps.filterParams) {
            this.filterParamsToQuery();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    filterParamsToQuery = () => {
        if (this.mounted) {
            const { filterParams, fetchAllOrders = () => {}, getAllCoins = () => {} } = this.props;
            const params = Object.keys(filterParams)
                .map(
                    keyParams => `${encodeURIComponent(keyParams)}=${encodeURIComponent(
                        filterParams[keyParams],
                    )}`,
                )
                .join('&');
            localStorage.setItem('ordersStorageFilter', params);
            const ordersStorageFilter = localStorage.getItem('ordersStorageFilter');

            fetchAllOrders(params || ordersStorageFilter);
            getAllCoins();
        }
    };

    loadAllCards = () => {
        this.getService
            .getUserCard()
            .then(cardList => {
                this.setState({
                    cardList,
                });
            })
            .catch(this.onError);
    };

    setCoinToFilter = coin => {
        const { filterParams, dispatchCoinToFilter } = this.props;

        const data = {
            ...filterParams,
            coin,
        };

        dispatchCoinToFilter(data);
    }

    render() {
        const {
            openTrade, orders, loading, error, t,
        } = this.props;

        const { cardList } = this.state;

        const hasData = !(loading || error);
        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;
        const content = hasData ? (
            <TableRowView
                orders={orders}
                openTrade={id => openTrade(id)}
                InputOnchange={this.InputOnchange}
                closeModalWindow={this.closeModalWindow}
                formSubmit={this.formSubmit}
                cardList={cardList}
            />
        ) : null;


        const slickImg = [
            {
                img: BTC,
                alt: 'BTC',
            },
            {
                img: DASH,
                alt: 'DASH',
            },
            {
                img: ETH,
                alt: 'ETH',
            },
            {
                img: LTC,
                alt: 'LTC',
            },
            {
                img: ZEC,
                alt: 'ZEC',
            },
            {
                img: SFC,
                alt: 'SFC',
            },
            {
                img: EDC,
                alt: 'EDC',
            },
            {
                img: CROCO,
                alt: 'CROCO',
            },
        ];

        return (
            <Fragment>
                <div className="filter" id="orders">
                    <div className={style.orders__wrapper}>
                        <SliderWithRightSideText
                            slickImg={slickImg}
                            title={t('workWith.title')}
                            subtitle={t('workWith.subtitle')}
                            setCoinToFilter={this.setCoinToFilter}
                        />
                        <div className={style.orders__filter}>
                            <p className={style.orders__title}>{t('orders.title')}</p>
                            <FilterBlock />
                        </div>
                        {errorMessage}
                        {spinner}
                        {content}
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        modalTrade: { modalTrade },
        currentFilter: { filterParams },
        getAllOrders: { orders, error, loading },
        authentication: { loggedIn },
    } = state;

    return {
        modalTrade,
        filterParams,
        orders,
        error,
        loading,
        loggedIn,
    };
};

const mapDispatchToProps = (dispatch, { getService }) => bindActionCreators(
    {
        fetchAllOrders: getAllOrdersAction(getService),
        getAllCoins: getAllCoinsAction(getService),
        openTrade: id => modalTradeActions.openModalTrade(id),
        dispatchCoinToFilter: data => FilterActions.selectFilter(data),
    },
    dispatch,
);

Orders.defaultProps = {
    t: () => {},
    fetchAllOrders: () => {},
    getAllCoins: () => {},
    openTrade: () => {},
    dispatchCoinToFilter: () => {},
    filterParams: {},
    orders: {},
    error: false,
    loading: true,
    loggedIn: false,
};

Orders.propTypes = {
    t: PropTypes.func,
    fetchAllOrders: PropTypes.func,
    getAllCoins: PropTypes.func,
    openTrade: PropTypes.func,
    dispatchCoinToFilter: PropTypes.func,
    filterParams: PropTypes.object,
    orders: PropTypes.object,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    loggedIn: PropTypes.bool,
};

export default compose(
    withGetService(),
    withTranslation(),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(Orders);
