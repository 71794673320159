/* eslint-disable */

import { dashboardConstants } from '../constants';

function selectDashboard(id) {
    return {
        type: dashboardConstants.SELECT_DASHBOARD,
        id,
    };
}

export const DashboardActions = {
    selectDashboard,
};
