/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Select, Tooltip, Switch } from 'antd';
import Moment from 'react-moment';

import Button from '../../../../../small-components/button';
import PaginationComponent from '../../../../../layouts/pagination';
import {
    PENDING,
    COMPLETED,
    CANCELLED,
    WAITING,
    REJECTED,
} from '../../../../../../constants';
import deleteIcon from '../../../../../layouts/filter-block/images/delete.svg';
import txLinks from '../../../../../../constants/tx.constants';
import style from './history-crypto.module.scss';
import './history-crypto.scss';
import 'rc-pagination/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';

const HistoryCriptoView = ({
    historyTransactions,
    allCoins,
    withdrawRequests,
    newDate,
    filterParams,
    deleteFilter,
    datePicker = () => {},
    selectFilter = () => {},
    cancelWithdrawal,
    isShowRejectedRequests,
}) => {
    const { Option } = Select;
    const { t } = useTranslation();

    const { coin, status: statusState, type: typeState } = filterParams;

    function WithdrawalIsWaiting() {
        return (
            <p
                className={
                    style.tableRowWrapper_statusPending
                }
            >
                {t('general.waiting')}
            </p>
        );
    }

    function WithdrawalIsRejected() {
        return (
            <p
                className={
                    style.tableRowWrapper_statusCanceled
                }
            >
                {t('general.rejected')}
            </p>
        );
    }

    return (
        <div className={style.historyPage}>
            <div className={style.withdrawRequests}>
                <div className={style.withdrawRequests__titleWrapper}>
                    <p className={style.historyPage__title}>
                        {t('general.withdrawalRequests')}
                    </p>
                    <div className={style.withdrawRequests__showRejected}>
                        <p className={style.withdrawRequests__showRejected_title}>
                            {t('general.showRejectedRequests')}
                        </p>
                        <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            defaultChecked={false}
                            onChange={isShowRejectedRequests}
                        />
                    </div>

                </div>
                {withdrawRequests.length > 0 ? (
                    <Fragment>
                        {withdrawRequests.map((item, index) => {
                            const {
                                id,
                                amount,
                                originalAmount,
                                coin: coinValue,
                                createdAt,
                                toAddress,
                                status,
                            } = item;

                            function WithdrawalStatus() {
                                if (status === WAITING) {
                                    return <WithdrawalIsWaiting />;
                                }

                                if (status === REJECTED) {
                                    return <WithdrawalIsRejected />;
                                }
                            }

                            let originalAmountFixed = '';

                            const trimOriginalAmountFixed = parseFloat(originalAmount).toFixed(8);
                            originalAmountFixed = trimOriginalAmountFixed.replace(/0*$/, '');

                            return (
                                <Fragment key={id}>
                                    <div className={style.tableRowWrapper}>
                                        <div
                                            className={
                                                style.tableRowWrapper__numberWrapper
                                            }
                                        >
                                            <p
                                                className={
                                                    style.tableRowWrapper_numberLabel
                                                }
                                            >
                                                №
                                            </p>
                                            <p className={style.tableRowWrapper_number}>
                                                {index + 1}
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                style.tableRowWrapper__amountWrapperWithdraw
                                            }
                                        >
                                            <p
                                                className={
                                                    style.tableRowWrapper_amountLabel
                                                }
                                            >
                                                {t('general.amount')}
                                            </p>
                                            <p className={style.tableRowWrapper_amount}>
                                                <Tooltip
                                                    placement="top"
                                                    title={+originalAmountFixed}
                                                >
                                                    {originalAmount}
                                                </Tooltip>
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                style.tableRowWrapper__amountWrapperWithComission
                                            }
                                        >
                                            <p
                                                className={
                                                    style.tableRowWrapper_amountLabelWithComission
                                                }
                                            >
                                                <Tooltip
                                                    placement="top"
                                                    title={t(
                                                        'general.amountIncludingCommission',
                                                    )}
                                                >
                                                    {t(
                                                        'general.amountIncludingCommission',
                                                    )}
                                                </Tooltip>
                                            </p>
                                            <p className={style.tableRowWrapper_amount}>
                                                <Tooltip placement="top" title={amount}>
                                                    {amount}
                                                </Tooltip>
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                style.tableRowWrapper__currencyWrapper
                                            }
                                        >
                                            <p className={style.tableRowWrapper_currency}>
                                                {coinValue}
                                            </p>
                                        </div>
                                        <div
                                            className={style.tableRowWrapper__dateWrapper}
                                        >
                                            <p className={style.tableRowWrapper_date}>
                                                <Moment format="DD/MM/YYYY" unix>
                                                    {createdAt}
                                                </Moment>
                                            </p>
                                        </div>
                                        <div
                                            className={style.tableRowWrapper__timeWrapper}
                                        >
                                            <p className={style.tableRowWrapper_time}>
                                                <Moment format="HH:mm:ss" unix>
                                                    {createdAt}
                                                </Moment>
                                            </p>
                                        </div>
                                        <div className={style.tableRowWrapper__txWrapper}>
                                            <p className={style.tableRowWrapper_txLabel}>
                                                {t('general.address')}
                                            </p>
                                            <p className={style.tableRowWrapper_address}>
                                                <Tooltip
                                                    placement="top"
                                                    title={toAddress}
                                                >
                                                    {toAddress}
                                                </Tooltip>
                                            </p>
                                        </div>
                                        <div
                                            className={style.tableRowWrapper__typeWrapper}
                                        >
                                            <p
                                                className={
                                                    style.tableRowWrapper_typeLabel
                                                }
                                            >
                                                {t('general.type')}
                                            </p>
                                            <p className={style.tableRowWrapper_type}>
                                                {t('balances.withdraw')}
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                style.tableRowWrapper__statusWrapper
                                            }
                                        >
                                            <p
                                                className={
                                                    style.tableRowWrapper_statusLabel
                                                }
                                            >
                                                {t('general.status')}
                                            </p>
                                            <WithdrawalStatus />
                                        </div>
                                        <div
                                            className={
                                                style.tableRowWrapper__cancelWithdraw
                                            }
                                        >
                                            {status === WAITING
                                                ? (
                                                    <Button
                                                        onClick={() => cancelWithdrawal(id)}
                                                        type="button"
                                                        className={style.tableRowWrapper__cancelWithdraw_button}
                                                    >
                                                        {t('general.cancel')}
                                                    </Button>
                                                )
                                                : null}
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        })}
                    </Fragment>
                ) : (
                    <p className={style.noHistory}> {t('general.noHistory')}</p>
                )}
            </div>

            <div className={style.historyPage__table}>
                <p className={style.historyPage__title}>
                    {t('general.transactionHistory')}
                </p>
                <div className={style.historyPage__filter}>
                    <div className="historyPage__filter">
                        <div className="filterBlock-select__wrapper_item">
                            {coin ? (
                                <div onClick={() => deleteFilter('coin')}>
                                    <img
                                        src={deleteIcon}
                                        className="filterBlock-select__wrapper_deleteIcon"
                                        alt="deleteIcon"
                                    />
                                </div>
                            ) : null}
                            <p className="filterBlock-select__title">
                                {t('general.currency')}
                            </p>
                            <Select
                                className="filterBlock-select"
                                placeholder={t('general.choose')}
                                value={coin}
                                onChange={selectFilter}
                                showArrow={Boolean(!coin)}
                            >
                                {allCoins.map(items => {
                                    const { handle, name } = items;
                                    return (
                                        <Option key={handle} name="coin" value={handle}>
                                            {name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="filterBlock-select__wrapper_item">
                            {newDate ? (
                                <div onClick={() => deleteFilter('date')}>
                                    <img
                                        src={deleteIcon}
                                        className="filterBlock-select__wrapper_deleteIcon"
                                        alt="deleteIcon"
                                    />
                                </div>
                            ) : null}
                            <p className="filterBlock-select__title">
                                {t('general.date')}
                            </p>
                            <DatePicker
                                selected={newDate}
                                onChange={datePicker}
                                dateFormat="dd.MM.yyyy"
                                className="historyPagedatePicker"
                                placeholderText={t('general.choose')}
                            />
                        </div>
                        <div className="filterBlock-select__wrapper_item">
                            <p className="filterBlock-select__title">
                                {typeState ? (
                                    <div onClick={() => deleteFilter('type')}>
                                        <img
                                            src={deleteIcon}
                                            className="filterBlock-select__wrapper_deleteIcon"
                                            alt="deleteIcon"
                                        />
                                    </div>
                                ) : null}
                                {t('general.type')}
                            </p>
                            <Select
                                className="filterBlock-select"
                                placeholder={t('general.choose')}
                                value={typeState}
                                onChange={selectFilter}
                                showArrow={Boolean(!typeState)}
                            >
                                <Option name="type" value="DEPOSIT">
                                    Deposit
                                </Option>
                                <Option name="type" value="WITHDRAW">
                                    Withdraw
                                </Option>
                            </Select>
                        </div>
                        <div className="filterBlock-select__wrapper_item">
                            {statusState ? (
                                <div onClick={() => deleteFilter('status')}>
                                    <img
                                        src={deleteIcon}
                                        className="filterBlock-select__wrapper_deleteIcon"
                                        alt="deleteIcon"
                                    />
                                </div>
                            ) : null}
                            <p className="filterBlock-select__title">
                                {t('general.status')}
                            </p>
                            <Select
                                className="filterBlock-select"
                                placeholder={t('general.choose')}
                                onChange={selectFilter}
                                value={statusState}
                                showArrow={Boolean(!statusState)}
                            >
                                <Option name="status" value="PENDING">
                                    Pending
                                </Option>
                                <Option name="status" value="COMPLETED">
                                    Completed
                                </Option>
                            </Select>
                        </div>
                    </div>
                </div>
                {historyTransactions.items.length > 0 ? (
                    <Fragment>
                        {historyTransactions.items.map(item => {
                            const {
                                id,
                                amount,
                                userCoin,
                                createdAt,
                                hash,
                                type,
                                status,
                            } = item;
                            const pending = PENDING === status.handle ? (
                                <p className={style.tableRowWrapper_statusPending}>
                                    {t('general.pending')}
                                </p>
                            ) : null;
                            const completed = COMPLETED === status.handle ? (
                                <p className={style.tableRowWrapper_statusCompleted}>
                                    {t('general.completed')}
                                </p>
                            ) : null;
                            const canceled = CANCELLED === status.handle ? (
                                <p className={style.tableRowWrapper_statusCanceled}>
                                    {t('general.canceled')}
                                </p>
                            ) : null;

                            const filterResultLinks = txLinks.filter(
                                element => element.value === userCoin.coin.handle,
                            );

                            const validTxLinks = filterResultLinks.length > 0
                                ? filterResultLinks[0].explorer
                                : '';


                            let originalAmountFixed = '';

                            const trimOriginalAmountFixed = parseFloat(amount).toFixed(8);
                            originalAmountFixed = trimOriginalAmountFixed.replace(/0*$/, '');

                            return (
                                <Fragment key={id}>
                                    <div className={style.tableRowWrapper}>
                                        <div
                                            className={
                                                style.tableRowWrapper__numberWrapper
                                            }
                                        >
                                            <p
                                                className={
                                                    style.tableRowWrapper_numberLabel
                                                }
                                            >
                                                №
                                            </p>
                                            <p className={style.tableRowWrapper_number}>
                                                {id}
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                style.tableRowWrapper__amountWrapper
                                            }
                                        >
                                            <p
                                                className={
                                                    style.tableRowWrapper_amountLabel
                                                }
                                            >
                                                {t('general.amount')}
                                            </p>
                                            <p className={style.tableRowWrapper_amount}>
                                                <Tooltip placement="top" title={+originalAmountFixed}>
                                                    {amount}
                                                </Tooltip>
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                style.tableRowWrapper__currencyWrapper
                                            }
                                        >
                                            <p className={style.tableRowWrapper_currency}>
                                                {userCoin.coin.handle}
                                            </p>
                                        </div>
                                        <div
                                            className={style.tableRowWrapper__dateWrapper}
                                        >
                                            <p className={style.tableRowWrapper_date}>
                                                <Moment format="DD/MM/YYYY" unix>
                                                    {createdAt}
                                                </Moment>
                                            </p>
                                        </div>
                                        <div
                                            className={style.tableRowWrapper__timeWrapper}
                                        >
                                            <p className={style.tableRowWrapper_time}>
                                                <Moment format="HH:mm:ss" unix>
                                                    {createdAt}
                                                </Moment>
                                            </p>
                                        </div>
                                        <div className={style.tableRowWrapper__txWrapper}>
                                            <p className={style.tableRowWrapper_txLabel}>
                                                Tx
                                            </p>
                                            <a
                                                href={`${validTxLinks}${hash}`}
                                                target="blank"
                                                className={style.tableRowWrapper_tx}
                                            >
                                                <Tooltip placement="top" title={hash}>
                                                    {hash}
                                                </Tooltip>
                                            </a>
                                        </div>
                                        <div
                                            className={style.tableRowWrapper__typeWrapper}
                                        >
                                            <p
                                                className={
                                                    style.tableRowWrapper_typeLabel
                                                }
                                            >
                                                {t('general.type')}
                                            </p>
                                            <p className={style.tableRowWrapper_type}>
                                                {type.name}
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                style.tableRowWrapper__statusWrapper
                                            }
                                        >
                                            <p
                                                className={
                                                    style.tableRowWrapper_statusLabel
                                                }
                                            >
                                                {t('general.status')}
                                            </p>
                                            {pending}
                                            {completed}
                                            {canceled}
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        })}
                    </Fragment>
                ) : (
                    <p className={style.noHistory}> {t('general.noHistory')}</p>
                )}
            </div>

            {historyTransactions.totalCount > 10 ? (
                <PaginationComponent totalCount={historyTransactions.totalCount} />
            ) : null}
        </div>
    );
};

HistoryCriptoView.defaultProps = {
    datePicker: () => {},
    selectFilter: () => {},
    deleteFilter: () => {},
    isShowRejectedRequests: () => {},
    cancelWithdrawal: () => {},
    historyTransactions: {},
    allCoins: [],
    withdrawRequests: [],
    newDate: '',
    filterParams: {},
};

HistoryCriptoView.propTypes = {
    datePicker: PropTypes.func,
    selectFilter: PropTypes.func,
    deleteFilter: PropTypes.func,
    cancelWithdrawal: PropTypes.func,
    isShowRejectedRequests: PropTypes.func,
    historyTransactions: PropTypes.object,
    allCoins: PropTypes.arrayOf(PropTypes.object),
    withdrawRequests: PropTypes.arrayOf(PropTypes.object),
    newDate: PropTypes.any,
    filterParams: PropTypes.object,
};

export default HistoryCriptoView;
