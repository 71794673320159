/* eslint-disable no-mixed-operators */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import { Select, Radio, message } from 'antd';

import { compose } from '../../../../../utils';
import Field from '../../../../small-components/field';
import Button from '../../../../small-components/button';
import { countries } from '../../../../../helpers/countries';
import PostService from '../../../../../services/post-service';
import GetService from '../../../../../services/get-service';
import filterFiatCoin from '../../../../../constants/fiat-coins-by-peyment-method';
import {
    personalAreaPath,
    dashboardPath,
    profilePath,
    BUY,
    SELL,
    EXCHANGE,
    USER_NOT_CONFIRMED,
    NOT_ENOUGH_BALANCE_FOR_USER_COIN,
    UNABLE_TO_SET_ORDER_RATE,
    ORDER_HAS_OPENED_CHATS,
    INVALID_CARD_CREDENTIALS,
    SFC,
    CRYPTO_TRANSFER,
    BANK_TRANSFER,
} from '../../../../../constants';

import path from './images/Path.svg';
import style from './edit-page.module.scss';
import './edit-page.scss';

class Edit extends Component {
    postService = new PostService();

    getService = new GetService();

    mounted = true;

    state = {
        newOrder: {
            coinBuy: '',
            coinSell: '',
            orderType: '',
            country: '',
            amount: '',
            smallestAmount: '',
            paymentMethod: '',
            paymentWindow: '',
            paymentMethodNumber: '',
            rate: '',
            ratePercent: '',
            isFixedRate: true,
            card: '',
        },
        errors: {
            coinBuyError: '',
            coinSellError: '',
            orderTypeError: '',
            countryError: '',
            amountError: '',
            smallestAmountError: '',
            paymentWindowError: '',
            paymentMethodError: '',
            paymentMethodNumberError: '',
            rateError: '',
            ratePercentError: '',
            isFixedRateError: '',
            cardError: '',
        },
        cryptoCoin: [],
        fiatCoin: [],
        cardList: [],
        wallet: [],
        paymentMethods: [],
        getPaymentMethodCoins: [],
        marketPrice: '',
        disabledSubmitBtn: false,
        isDisableFixedPrice: false,
        isDisableMarketPrice: false,
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { allCoins, locale, t } = this.props;
        const {
            newOrder,
            errors,
            getPaymentMethodCoins,
            newOrder: {
                isFixedRate, orderType, coinBuy, coinSell, paymentMethod,
            },
        } = this.state;
        if (locale !== prevProps.locale) {
            setTimeout(() => {
                this.setState({
                    fiatCoin: filterFiatCoin(paymentMethod, getPaymentMethodCoins, allCoins, t),
                });
            }, 100);
        }
        if (isFixedRate !== prevState.newOrder.isFixedRate) {
            if (isFixedRate === false) {
                this.setState({
                    newOrder: {
                        ...newOrder,
                        rate: '',
                    },
                    errors: {
                        ...errors,
                        rateError: '',
                    },
                });
            }

            if (isFixedRate === true) {
                this.setState({
                    newOrder: {
                        ...newOrder,
                        ratePercent: '',
                    },
                    errors: {
                        ...errors,
                        ratePercentError: '',
                    },
                });
            }
        }

        if (
            prevState.newOrder.paymentMethod
            && paymentMethod !== prevState.newOrder.paymentMethod
        ) {
            this.setState({
                newOrder: {
                    ...newOrder,
                    card: '',
                    paymentMethodNumber: '',
                    coinBuy: '',
                    coinSell: '',
                },
                errors: {
                    ...errors,
                    cardError: '',
                    paymentMethodNumber: '',
                    coinBuyError: '',
                    coinSellError: '',
                },
                fiatCoin: filterFiatCoin(paymentMethod, getPaymentMethodCoins, allCoins, t),
            });
        }

        if (
            paymentMethod !== prevState.newOrder.paymentMethod
            && paymentMethod === CRYPTO_TRANSFER
        ) {
            this.setState({
                newOrder: {
                    ...newOrder,
                    orderType: EXCHANGE,
                },
                errors: {
                    ...errors,
                    orderTypeError: '',
                },
            });
        }

        if (prevState.newOrder.orderType && orderType !== prevState.newOrder.orderType) {
            this.setState({
                newOrder: {
                    ...newOrder,
                    coinBuy: '',
                    coinSell: '',
                },
                marketPrice: '',
                errors: {
                    ...errors,
                    coinBuyError: '',
                    coinSellError: '',
                },
            });
        }

        if (
            coinBuy !== prevState.newOrder.coinBuy
            || coinSell !== prevState.newOrder.coinSell
        ) {
            const isCoinSFC = [SFC];

            if (coinBuy === SFC || coinSell === SFC) {
                this.setState({
                    newOrder: {
                        ...newOrder,
                        isFixedRate: false,
                        rate: '',
                    },
                    errors: {
                        ...errors,
                        ratePercentError: '',
                    },
                    isDisableFixedPrice: true,
                    isDisableMarketPrice: false,
                });
            }

            if (!isCoinSFC.includes(coinBuy) && !isCoinSFC.includes(coinSell)) {
                this.setState({
                    isDisableFixedPrice: false,
                    isDisableMarketPrice: false,
                    errors: {
                        ...errors,
                        ratePercentError: '',
                    },
                });
            }
        }

        if (orderType !== prevState.newOrder.orderType && orderType === EXCHANGE) {
            this.setState({
                newOrder: {
                    ...newOrder,
                    paymentMethod: CRYPTO_TRANSFER,
                    paymentMethodNumber: '',
                    card: '',
                },
                errors: {
                    ...errors,
                    paymentMethodError: '',
                    paymentMethodNumberError: '',
                    cardError: '',
                },
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadData = async () => {
        await this.setToStateCurrentOrder();
        await this.getPaymentMethodCoins();
        this.getAllCoins();
        this.getPaymentMethods();
        this.getAllCards();
        this.getComission();
    };

    setToStateCurrentOrder = () => {
        const { edit } = this.props;
        const {
            card,
            paymentWindow,
            smallestAmount,
            coinBuy,
            coinSell,
            country,
            fixedPrice: rate,
            sellAmount: amount,
            orderType: { handle: orderType },
            isFixedRate,
            ratePercent,
            order,
            paymentMethod: { handle: paymentMethod },
            paymentMethodNumber,
        } = edit;

        const isCard = card ? card.id : '';

        this.setState({
            newOrder: {
                card: isCard,
                rate,
                coinBuy,
                coinSell,
                order,
                orderType,
                country,
                amount,
                smallestAmount,
                paymentWindow,
                ratePercent,
                isFixedRate,
                paymentMethod,
                paymentMethodNumber,
            },
        });
    };

    getPaymentMethods = () => {
        const {
            newOrder: { orderType },
        } = this.state;

        this.getService
            .getPaymentMethods()
            .then(paymentMethods => {
                this.setState(() => {
                    if (orderType !== EXCHANGE) {
                        const index = paymentMethods.findIndex(
                            element => element.handle === CRYPTO_TRANSFER,
                        );
                        const newArray = [
                            ...paymentMethods.slice(0, index),
                            ...paymentMethods.slice(index + 1),
                        ];

                        return {
                            paymentMethods: newArray,
                        };
                    }
                    const findCryptoMethod = paymentMethods.find(
                        element => element.handle === CRYPTO_TRANSFER,
                    );

                    return {
                        paymentMethods: [findCryptoMethod],
                    };
                });
            })
            .catch(this.onError);
    };

    getAllCoins = () => {
        const { allCoins } = this.props;
        const cryptoCoin = allCoins.filter(item => item.isCrypto === true);
        this.setState(
            {
                cryptoCoin,
            },
            () => {
                const {
                    newOrder: { orderType, coinSell, coinBuy },
                } = this.state;

                let firstCoin = '';
                let secondCoin = '';

                if (orderType === BUY) {
                    firstCoin = coinBuy;
                    secondCoin = coinSell;
                }

                if (orderType === SELL || orderType === EXCHANGE) {
                    firstCoin = coinSell;
                    secondCoin = coinBuy;
                }

                this.getService
                    .getMarketPrice(firstCoin, secondCoin)
                    .then(marketPrice => {
                        this.setState(
                            {
                                marketPrice,
                            },
                            () => {
                                if (orderType === 'orderType') {
                                    this.setState({
                                        marketPrice: '',
                                    });
                                }

                                if (orderType === EXCHANGE) {
                                    const {
                                        newOrder: orderTypeData,
                                        errors: error,
                                    } = this.state;
                                    this.setState({
                                        newOrder: {
                                            ...orderTypeData,
                                            card: '',
                                        },

                                        errors: {
                                            ...error,
                                            cardError: '',
                                        },
                                    });
                                }
                            },
                        );
                    })
                    .catch(err => console.log(err, 'error'));
            },
        );
    };

    getPaymentMethodCoins = () => {
        const { t, allCoins } = this.props;
        const { newOrder: { paymentMethod } } = this.state;
        this.getService
            .getPaymentMethodCoins()
            .then(getPaymentMethodCoins => {
                this.setState({
                    getPaymentMethodCoins,
                    fiatCoin: filterFiatCoin(
                        paymentMethod,
                        getPaymentMethodCoins,
                        allCoins,
                        t,
                    ),
                });
            })
            .catch(this.onError);
    };

    getAllCards = () => {
        this.getService
            .getUserCard()
            .then(cardList => {
                this.setState({
                    cardList,
                });
            })
            .catch(this.onError);
    };

    getComission = () => {
        this.getService.getUserCoins().then(wallet => {
            this.setState({
                wallet,
            });
        });
    };

    // save input changes to state
    InputOnchange = event => {
        const { value, name } = event.target;
        const {
            newOrder,
            newOrder: { orderType },
        } = this.state;
        const onlyNumbers = /^[0-9]/;
        const numbersAndDot = /^[0-9.]+$/;
        const numbersPlusDotMinus = /^[0-9+.-]+$/;
        const isCryptoTransfer = orderType !== EXCHANGE ? 6 : 9;

        if (name === 'ratePercent') {
            if (value === '' || numbersPlusDotMinus.test(value)) {
                const trimValue = value.substring(0, value.indexOf() + 6);
                this.setState({
                    newOrder: {
                        ...newOrder,
                        [name]: trimValue,
                    },
                });
            }
        } else if (name === 'paymentWindow') {
            if (value === '' || onlyNumbers.test(value)) {
                const trimValue = value.substring(0, value.indexOf() + 4);
                this.setState({
                    newOrder: {
                        ...newOrder,
                        [name]: trimValue,
                    },
                });
            }
        } else if (name === 'smallestAmount' || name === 'amount') {
            if (value === '' || numbersAndDot.test(value)) {
                if (value.indexOf('.') > -1) {
                    const trimValue = value.substring(
                        0,
                        value.indexOf('.') + isCryptoTransfer,
                    );
                    this.setState({
                        newOrder: {
                            ...newOrder,
                            [name]: trimValue,
                        },
                    });
                } else {
                    this.setState({
                        newOrder: {
                            ...newOrder,
                            [name]: value,
                        },
                    });
                }
            }
        } else if (name === 'rate') {
            if (value === '' || numbersAndDot.test(value)) {
                if (value.indexOf('.') > -1) {
                    const trimValue = value.substring(
                        0,
                        value.indexOf('.') + 9,
                    );
                    this.setState({
                        newOrder: {
                            ...newOrder,
                            [name]: trimValue,
                        },
                    });
                } else {
                    this.setState({
                        newOrder: {
                            ...newOrder,
                            [name]: value,
                        },
                    });
                }
            }
        } else {
            this.setState({
                newOrder: {
                    ...newOrder,
                    [name]: value,
                },
            });
        }
    };

    paymentWindowKeyDown = e => {
        let checkIfNum;
        if (e.key !== undefined) {
            checkIfNum = e.key === 'e' || e.key === '+' || e.key === '-' || e.key === '.';
        } else if (e.keyCode !== undefined) {
            checkIfNum = e.keyCode === 69
                || e.keyCode === 187
                || e.keyCode === 189
                || e.keyCode === 190;
        }
        return checkIfNum && e.preventDefault();
    };

    // save select changes to state
    selectOnChange = (value, name) => {
        const {
            newOrder,
            newOrder: { orderType },
        } = this.state;
        const {
            props: { name: orderTypeName },
        } = name;
        this.setState(
            {
                newOrder: {
                    ...newOrder,
                    [name.props.name]: value,
                },
            },
            () => {
                const {
                    newOrder: { coinSell, coinBuy },
                } = this.state;
                if (coinBuy.length > 0 && coinSell.length > 0) {
                    let firstCoin = coinBuy;
                    let secondCoin = coinSell;

                    if (orderType === BUY) {
                        firstCoin = coinBuy;
                        secondCoin = coinSell;
                    }

                    if (orderType === SELL || orderType === EXCHANGE) {
                        firstCoin = coinSell;
                        secondCoin = coinBuy;
                    }

                    if (coinSell === coinBuy) {
                        this.setState({
                            marketPrice: '',
                        });
                    }

                    if (firstCoin !== secondCoin) {
                        this.getService
                            .getMarketPrice(firstCoin, secondCoin)
                            .then(marketPrice => {
                                this.setState(
                                    {
                                        marketPrice,
                                    },
                                    () => {
                                        if (orderTypeName === 'orderType') {
                                            this.setState({
                                                marketPrice: '',
                                            });
                                        }

                                        if (value === EXCHANGE) {
                                            const {
                                                newOrder: orderTypeData,
                                                errors: error,
                                            } = this.state;
                                            this.setState({
                                                newOrder: {
                                                    ...orderTypeData,
                                                    card: '',
                                                },

                                                errors: {
                                                    ...error,
                                                    cardError: '',
                                                },
                                            });
                                        }
                                    },
                                );
                            })
                            .catch(err => console.log(err, 'error'));
                    }
                }
            },
        );
    };

    // Select country
    country = event => {
        const { newOrder } = this.state;
        const { value } = event;
        this.setState({
            newOrder: {
                ...newOrder,
                country: value,
            },
        });
    };

    // select radio group
    isFixedRate = e => {
        const { newOrder } = this.state;
        this.setState({
            newOrder: {
                ...newOrder,
                isFixedRate: e.target.value,
            },
        });
    };

    submitNewOrder = event => {
        event.preventDefault();
        const { t, history, allCoins } = this.props;
        const { newOrder } = this.state;
        const errors = {};
        const {
            newOrder: {
                orderType,
                coinBuy,
                coinSell,
                card,
                country,
                smallestAmount,
                amount,
                isFixedRate,
                ratePercent,
                rate,
                paymentWindow,
                paymentMethodNumber,
                paymentMethod,
            },
        } = this.state;

        const cryptoCoinArray = [];
        const fiatCoinArray = [];

        allCoins
            .filter(item => item.isCrypto === true)
            .map(item => {
                const { handle } = item;
                return cryptoCoinArray.push(handle);
            });

        allCoins
            .filter(item => item.isCrypto === false)
            .map(item => {
                const { handle } = item;
                return fiatCoinArray.push(handle);
            });

        if (orderType === 'BUY') {
            if (cryptoCoinArray.includes(coinSell)) {
                errors.coinSellError = t('newOrder.eurOrusd');
            }

            if (fiatCoinArray.includes(coinBuy)) {
                errors.coinBuyError = t('newOrder.mustBeCrypto');
            }
        }

        if (orderType === 'SELL') {
            if (fiatCoinArray.includes(coinSell)) {
                errors.coinSellError = t('newOrder.mustBeCrypto');
            }

            if (cryptoCoinArray.includes(coinBuy)) {
                errors.coinBuyError = t('newOrder.eurOrusd');
            }
        }

        if (orderType === 'EXCHANGE') {
            if (fiatCoinArray.includes(coinBuy) || fiatCoinArray.includes(coinSell)) {
                errors.coinBuyError = t('newOrder.mustBeCrypto');
                errors.coinSellError = t('newOrder.mustBeCrypto');
            }

            if (coinBuy === coinSell) {
                errors.coinBuyError = t('newOrder.differentCurrencies');
                errors.coinSellError = t('newOrder.differentCurrencies');
            }
        }

        if (orderType !== EXCHANGE && +smallestAmount < 0.00001) {
            errors.smallestAmountError = t('error.amountMustBeLess', { count: 0.00001 });
        }

        if (orderType === EXCHANGE && +smallestAmount < 0.00000001) {
            errors.smallestAmountError = t('error.amountMustBeLess', {
                count: (1e-8).toFixed(8),
            });
        }

        if (orderType !== EXCHANGE && +smallestAmount > 9999999.99999) {
            errors.smallestAmountError = t('error.amountMustBeGreater', {
                count: 9999999.99999,
            });
        }

        if (orderType === EXCHANGE && +smallestAmount > 9999999.99999) {
            errors.smallestAmountError = t('error.amountMustBeGreater', {
                count: 9999999.99999999,
            });
        }

        if (orderType !== EXCHANGE && +amount < 0.00001) {
            errors.amountError = t('error.amountMustBeLess', { count: 0.00001 });
        }

        if (orderType === EXCHANGE && +amount < 0.00000001) {
            errors.amountError = t('error.amountMustBeLess', {
                count: (1e-8).toFixed(8),
            });
        }

        if (orderType !== EXCHANGE && +amount > 9999999.99999) {
            errors.amountError = t('error.amountMustBeGreater', { count: 9999999.99999 });
        }

        if (orderType === EXCHANGE && +amount > 9999999.99999) {
            errors.amountError = t('error.amountMustBeGreater', {
                count: 9999999.99999999,
            });
        }

        if (orderType.length < 1) {
            errors.orderTypeError = t('general.selectFromTheList');
        }

        if (coinBuy.length < 1) {
            errors.coinBuyError = t('general.selectFromTheList');
        }

        if (coinSell.length < 1) {
            errors.coinSellError = t('general.selectFromTheList');
        }

        if (
            orderType !== EXCHANGE
            && paymentMethod !== BANK_TRANSFER
            && paymentMethodNumber.length < 1
        ) {
            errors.paymentMethodNumberError = t('error.input', { count: 1 });
        }

        if (
            orderType !== EXCHANGE
            && paymentMethod === BANK_TRANSFER
            && card.length < 1
        ) {
            errors.cardError = t('general.selectFromTheList');
        }

        if (country.length < 1) {
            errors.countryError = t('general.selectFromTheList');
        }

        if (smallestAmount.length < 1) {
            errors.smallestAmountError = t('error.input', { count: 1 });
        }

        if (amount.length < 1) {
            errors.amountError = t('error.input', { count: 1 });
        }

        if (smallestAmount === '0') {
            errors.smallestAmountError = t('general.valueMustGreaterThanZero');
        }

        if (amount.length === '0') {
            errors.amountError = t('general.valueMustGreaterThanZero');
        }

        if (+smallestAmount > +amount) {
            errors.smallestAmountError = t('general.leftValueMustLessThanRight');
            errors.amountError = t('general.rightValueMustGreaterThanLeft');
        }

        if (paymentWindow < 60 || paymentWindow > 720) {
            errors.paymentWindowError = t('newOrder.enterNumberMinutes');
        }
        if (coinBuy !== SFC && coinSell !== SFC) {
            if (isFixedRate === false) {
                if (+ratePercent > 2000 || +ratePercent < -100) {
                    errors.ratePercentError = t('error.typeFrom2000');
                }
            }
        }
        if (isFixedRate === true) {
            if (orderType !== EXCHANGE && +rate < 0.00001) {
                errors.rateError = t('error.amountMustBeLess', { count: 0.00001 });
            }

            if (orderType === EXCHANGE && +rate < 0.00000001) {
                errors.rateError = t('error.amountMustBeLess', {
                    count: (1e-8).toFixed(8),
                });
            }

            if (orderType !== EXCHANGE && +rate > 999999999.99999) {
                errors.rateError = t('error.amountMustBeGreater', {
                    count: 999999999.99999,
                });
            }

            if (orderType === EXCHANGE && +rate > 999999999.99999) {
                errors.rateError = t('error.amountMustBeGreater', {
                    count: 999999999.99999999,
                });
            }

            if (rate.length < 1) {
                errors.rateError = t('error.input', { count: 1 });
            }
        }

        if (coinBuy === SFC || coinSell === SFC) {
            if (+ratePercent < -27.7) {
                errors.ratePercentError = `${t('error.minAmount', { min: '-27.7' })} %`;
            }
            if (+ratePercent > 2000) {
                errors.ratePercentError = `${t('error.maxAmount', { max: '2000' })} %`;
            }
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
            message.error(t('general.errorFields'), 5);
        } else {
            this.setState({
                disabledSubmitBtn: true,
            });

            let data = {};
            if (!isFixedRate && ratePercent === '') {
                data = {
                    ...newOrder,
                    ratePercent: '0',
                };
            } else {
                data = newOrder;
            }

            this.postService
                .editOrder(data)
                .then(() => {
                    message.success(t('newOrder.successUpdate'), 5);
                    history.push(`${personalAreaPath}${dashboardPath}`);
                    if (this.mounted) {
                        this.setState({
                            disabledSubmitBtn: false,
                        });
                    }
                })
                .catch(err => {
                    if (err === USER_NOT_CONFIRMED) {
                        message.error(t('error.verifyYourAccount'), 5);
                    } else if (err === NOT_ENOUGH_BALANCE_FOR_USER_COIN) {
                        message.error(t('error.notEnoughFundsInAccount'), 5);
                    } else if (err === UNABLE_TO_SET_ORDER_RATE) {
                        message.error(t('error.invalidOrderPrice'), 5);
                    } else if (err === ORDER_HAS_OPENED_CHATS) {
                        message.error(t('error.orderHasOpenedChats'), 5);
                    } else if (err === INVALID_CARD_CREDENTIALS) {
                        message.error(t('error.invalidCardCredentials'), 5);
                    } else {
                        message.error(t(err, 5));
                    }
                    this.setState({
                        disabledSubmitBtn: false,
                    });
                });
        }
    };

    render() {
        const { t, userId, allCoins } = this.props;
        const {
            newOrder: {
                orderType,
                isFixedRate,
                ratePercent,
                rate,
                smallestAmount,
                amount,
                coinBuy,
                coinSell,
                card,
                country,
                paymentWindow,
                order,
                paymentMethodNumber,
                paymentMethod,
            },
            errors: {
                orderTypeError,
                coinBuyError,
                coinSellError,
                cardError,
                countryError,
                smallestAmountError,
                amountError,
                ratePercentError,
                rateError,
                paymentWindowError,
                paymentMethodNumberError,
                paymentMethodError,
            },
            cryptoCoin,
            fiatCoin,
            cardList,
            paymentMethods,
            disabledSubmitBtn,
            wallet,
            marketPrice,
            isDisableFixedPrice,
            isDisableMarketPrice,
        } = this.state;

        const { Option } = Select;

        const buySellName = 'orderType';
        const coinBuyName = 'coinBuy';
        const coinSellName = 'coinSell';

        let comissionMarketPrice = '';

        if (orderType === BUY) {
            const filterWallet = wallet.filter(el => el.coin.handle === coinBuy);

            filterWallet.map(item => {
                const {
                    coin: { commission },
                } = item;
                comissionMarketPrice = commission.percentage;
                return true;
            });
        }

        if (orderType === SELL || orderType === EXCHANGE) {
            const filterWallet = wallet.filter(el => el.coin.handle === coinSell);

            filterWallet.map(item => {
                const {
                    coin: { commission },
                } = item;
                comissionMarketPrice = commission.percentage;
                return true;
            });
        }

        const marketPriceIsNumber = +marketPrice;

        const marginValue = (marketPriceIsNumber * ratePercent) / 100;
        const getFee = (+amount * comissionMarketPrice) / 100;
        const youWillGet = (marginValue + marketPriceIsNumber) * +amount;

        let firstCoinStyle = '';
        let firstCoinErrorStyle = '';
        let secondCoinStyle = '';
        let secondCoinErrorStyle = '';

        if (orderType === BUY) {
            firstCoinStyle = 'filterBlock-select coinSellInput';
            firstCoinErrorStyle = 'inputWrapper__invalid coinSellInput';
            secondCoinStyle = 'filterBlock-select coinBuyInput';
            secondCoinErrorStyle = 'inputWrapper__invalid coinBuyInput';
        } else {
            firstCoinStyle = 'filterBlock-select';
            firstCoinErrorStyle = 'inputWrapper__invalid';
            secondCoinStyle = 'filterBlock-select';
            secondCoinErrorStyle = 'inputWrapper__invalid';
        }

        let firstCryptoCurrencyCoins = [];
        let secondCryptoCurrencyCoins = [];

        let cardNumberStyle = 'filterBlock-select';
        let whichToTrade = t('newOrder.whichToTrade');
        let whichToTradeWith = t('newOrder.whichToTradeWith');

        if (orderType === BUY) {
            firstCryptoCurrencyCoins = fiatCoin;
            secondCryptoCurrencyCoins = cryptoCoin;
            cardNumberStyle = 'filterBlock-select';
            whichToTrade = t('newOrder.whichToTradeBuy');
            whichToTradeWith = t('newOrder.whichToTradeWithBuy');
        } else if (orderType === SELL) {
            firstCryptoCurrencyCoins = cryptoCoin;
            secondCryptoCurrencyCoins = fiatCoin;
            cardNumberStyle = 'filterBlock-select';
            whichToTrade = t('newOrder.whichToTradeSell');
            whichToTradeWith = t('newOrder.whichToTradeWithSell');
        } else if (orderType === EXCHANGE) {
            firstCryptoCurrencyCoins = cryptoCoin;
            secondCryptoCurrencyCoins = cryptoCoin;
            cardNumberStyle = 'filterBlock-select cardDisabled';
            whichToTrade = t('newOrder.whichToTradeExchange');
            whichToTradeWith = t('newOrder.whichToTradeWithExchange');
        } else {
            firstCryptoCurrencyCoins = allCoins;
            secondCryptoCurrencyCoins = allCoins;
            cardNumberStyle = 'filterBlock-select';
            whichToTrade = t('newOrder.whichToTrade');
            whichToTradeWith = t('newOrder.whichToTradeWith');
        }

        let dotForMarketPrice = '';

        if (orderType === BUY || orderType === SELL) {
            dotForMarketPrice = 5;
        } else if (orderType === EXCHANGE) {
            dotForMarketPrice = 8;
        } else {
            dotForMarketPrice = 8;
        }
        const trimMarketPrice = parseFloat(marketPriceIsNumber).toFixed(
            dotForMarketPrice,
        );
        const marketPriceFixed = trimMarketPrice.replace(/0*$/, '');

        const trimGetFee = parseFloat(getFee).toFixed(8);
        let getFeeFixed = trimGetFee.replace(/0*$/, '');
        getFeeFixed = getFeeFixed.replace(/\.$/, '');

        let youWillGetFixed = '';
        if (orderType === EXCHANGE) {
            const trimYouWillGet = parseFloat(youWillGet).toFixed(8);
            youWillGetFixed = trimYouWillGet.replace(/0*$/, '');
        } else {
            const trimYouWillGet = parseFloat(youWillGet).toFixed(5);
            youWillGetFixed = trimYouWillGet.replace(/0*$/, '');
        }

        const isOrderTypeExchange = orderType === EXCHANGE;

        let rightSideWrapperStyles = '';
        let leftSideWrapperStyles = '';

        if (isDisableFixedPrice) {
            rightSideWrapperStyles = classNames(
                style.newOrder__setPrice_rightSide,
                style.newOrder__setPrice_rightSideDisabled,
            );
        } else {
            rightSideWrapperStyles = style.newOrder__setPrice_rightSide;
        }

        if (isDisableMarketPrice) {
            leftSideWrapperStyles = classNames(
                style.newOrder__setPrice_leftSide,
                style.newOrder__setPrice_rightSideDisabled,
            );
        } else {
            leftSideWrapperStyles = style.newOrder__setPrice_leftSide;
        }

        const isPayMethodBankOrCrypto = [BANK_TRANSFER, CRYPTO_TRANSFER, ''];

        return (
            <form noValidate className={style.newOrder}>
                <div className="newOrder__wrapperPage editPage">
                    <p className={style.newOrder__title}>
                        {t('newOrder.editOrder')} № {order}
                    </p>
                    <div className={style.newOrder__WrapperRow}>
                        <div className={style.newOrder__row}>
                            <div
                                className="filterBlock-select__wrapper_item
                            newOrderField newOrder__row_buyOrSell"
                            >
                                <p className="filterBlock-select__title">
                                    {t('newOrder.cannotChangeTypeOrder')}
                                </p>
                                <Select
                                    className="filterBlock-select"
                                    value={orderType || undefined}
                                    placeholder={t('general.choose')}
                                    onChange={this.selectOnChange}
                                    disabled
                                >
                                    <Option name={buySellName} value="BUY">
                                        {t('general.buy')}
                                    </Option>
                                    <Option name={buySellName} value="SELL">
                                        {t('general.sell')}
                                    </Option>
                                    <Option name={buySellName} value="EXCHANGE">
                                        {t('general.exchange')}
                                    </Option>
                                </Select>
                                {orderTypeError ? (
                                    <div className={style.inputWrapper__invalid}>
                                        {orderTypeError}
                                    </div>
                                ) : null}
                            </div>
                            <div className="filterBlock-select__wrapper_item newOrder__row_paymentMethod">
                                <p className="filterBlock-select__title">
                                    {t('newOrder.paymentMethod')}
                                </p>
                                <Select
                                    className="filterBlock-select"
                                    placeholder={t('general.choose')}
                                    onChange={this.selectOnChange}
                                    value={paymentMethod || undefined}
                                >
                                    {paymentMethods.map(item => {
                                        const { handle, name } = item;
                                        return (
                                            <Option
                                                key={handle}
                                                name="paymentMethod"
                                                value={handle}
                                            >
                                                {name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {paymentMethodError ? (
                                    <div className={style.inputWrapper__invalid}>
                                        {paymentMethodError}
                                    </div>
                                ) : null}
                            </div>
                            {isPayMethodBankOrCrypto.includes(paymentMethod) ? (
                                <div
                                    className="filterBlock-select__wrapper_item
                            newOrderField newOrder__row_firstCryptocurrency"
                                >
                                    {isOrderTypeExchange ? (
                                        <p className="filterBlock-select__title disableBlock">
                                            {t('newOrder.cannotСhooseCard')}
                                        </p>
                                    ) : (
                                        <p className="filterBlock-select__title">
                                            {t('newOrder.chooseYourCard')}
                                        </p>
                                    )}
                                    {cardList.length > 0 ? (
                                        <Select
                                            className={cardNumberStyle}
                                            placeholder={t('general.choose')}
                                            onChange={this.selectOnChange}
                                            value={card || undefined}
                                        >
                                            {cardList.map(items => {
                                                const { cardNumber, id } = items;
                                                return (
                                                    <Option
                                                        key={id}
                                                        name="card"
                                                        value={id}
                                                    >
                                                        {cardNumber}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    ) : (
                                        <Select
                                            className={cardNumberStyle}
                                            placeholder={t('general.choose')}
                                        >
                                            <Option name="card" value={undefined}>
                                                <Link
                                                    to={`${personalAreaPath}${profilePath}/${userId}`}
                                                >
                                                    {t('settings.addCard')}
                                                </Link>
                                            </Option>
                                        </Select>
                                    )}
                                    {cardError ? (
                                        <div className={style.inputWrapper__invalid}>
                                            {cardError}
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <div
                                    className="filterBlock-select__wrapper-input
                            newOrderField newOrder__row_paymentWindow paymentMethod"
                                >
                                    <Field
                                        id="paymentMethodNumber"
                                        type="text"
                                        placeholder={t('general.typeHere')}
                                        name="paymentMethodNumber"
                                        value={paymentMethodNumber}
                                        error={paymentMethodNumberError}
                                        onChange={this.InputOnchange}
                                        inputStyle="newOrder__wrapper_input"
                                        labelText={t('newOrder.paymentSystemNumber')}
                                        labelStyle="filterBlock-select__wrapper_label"
                                    />
                                </div>
                            )}
                        </div>
                        <div className={style.newOrder__row}>
                            <div
                                className="filterBlock-select__wrapper_item
                    newOrderField newOrder__row_location"
                            >
                                <p className="filterBlock-select__title">
                                    {t('newOrder.location')}
                                </p>
                                <SelectSearch
                                    name="country"
                                    mode="input"
                                    value={country}
                                    options={countries}
                                    placeholder={t('general.choose')}
                                    onChange={this.country}
                                />
                                {countryError ? (
                                    <div className={style.inputWrapper__invalid}>
                                        {countryError}
                                    </div>
                                ) : null}
                            </div>
                            <div
                                className="filterBlock-select__wrapper_item
                            newOrderField newOrder__row_firstCryptocurrency"
                            >
                                <p className="filterBlock-select__title">
                                    {whichToTrade}
                                </p>
                                <Select
                                    className={firstCoinStyle}
                                    placeholder={t('general.choose')}
                                    onChange={this.selectOnChange}
                                    value={coinSell || undefined}
                                >
                                    {firstCryptoCurrencyCoins.map(items => {
                                        const { handle, name } = items;
                                        return (
                                            <Option
                                                key={handle}
                                                name={coinSellName}
                                                value={handle}
                                            >
                                                {name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {coinSellError ? (
                                    <div className={firstCoinErrorStyle}>
                                        {coinSellError}
                                    </div>
                                ) : null}
                            </div>
                            <div
                                className="filterBlock-select__wrapper_item
                            newOrderField newOrder__row_whichToTradeWith"
                            >
                                <p className="filterBlock-select__title">
                                    {whichToTradeWith}
                                </p>
                                <Select
                                    className={secondCoinStyle}
                                    placeholder={t('general.choose')}
                                    name="category"
                                    value={coinBuy || undefined}
                                    onChange={this.selectOnChange}
                                >
                                    {secondCryptoCurrencyCoins.map(items => {
                                        const { handle, name } = items;
                                        return (
                                            <Option
                                                key={handle}
                                                name={coinBuyName}
                                                value={handle}
                                            >
                                                {name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {coinBuyError ? (
                                    <div className={secondCoinErrorStyle}>
                                        {coinBuyError}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className={style.newOrder__row}>
                            <div
                                className="filterBlock-select__wrapper-input
                            newOrderField newOrder__row_paymentWindow"
                            >
                                <Field
                                    id="paymentWindow"
                                    type="text"
                                    placeholder={`${t('general.from')} 60 ${t(
                                        'general.toLow',
                                    )} 720 ${t('general.min')}`}
                                    name="paymentWindow"
                                    value={paymentWindow}
                                    error={paymentWindowError}
                                    onChange={this.InputOnchange}
                                    inputStyle="newOrder__wrapper_input"
                                    labelText={`${t('general.paymentWindow')} ( ${t(
                                        'general.fromLow',
                                    )} 60
                            ${t('general.toLow')} 720 ${t('general.min')} )`}
                                    labelStyle="filterBlock-select__wrapper_label"
                                />
                            </div>
                            <div
                                className="filterBlock-select__wrapper-input
                            newOrderField newOrder__row_limitsFrom"
                            >
                                <Field
                                    id="smallestAmount"
                                    type="text"
                                    placeholder={t('general.from')}
                                    name="smallestAmount"
                                    value={smallestAmount}
                                    error={smallestAmountError}
                                    onChange={this.InputOnchange}
                                    inputStyle="newOrder__wrapper_input"
                                    labelText={t('newOrder.limits')}
                                    labelStyle="filterBlock-select__wrapper_label"
                                />
                            </div>
                            <div
                                className="filterBlock-select__wrapper-input
                            newOrderField newOrder__row_limitsFrom"
                            >
                                <Field
                                    id="amount"
                                    type="text"
                                    placeholder={t('general.to')}
                                    name="amount"
                                    value={amount}
                                    error={amountError}
                                    onChange={this.InputOnchange}
                                    inputStyle="newOrder__wrapper_input"
                                    labelStyle="filterBlock-select__wrapper_label"
                                />
                            </div>
                            <img src={path} className={style.newOrder__path} alt="path" />
                        </div>
                    </div>
                    <p className={style.newOrder__title}>{t('newOrder.subtitle')}</p>

                    <Radio.Group
                        onChange={this.isFixedRate}
                        value={isFixedRate}
                        className="setPrice"
                    >
                        <div className={style.newOrder__setPrice}>
                            <div className={style.newOrder__setPrice_leftSideWrapper}>
                                <div className={leftSideWrapperStyles}>
                                    <Radio value={false}>
                                        {t('newOrder.marketPrice')}
                                    </Radio>
                                    <div
                                        className={`${
                                            isFixedRate === false
                                                ? 'filterBlock-select__wrapper-input'
                                                : 'filterBlock-select__wrapper-input disableBlock'
                                        }`}
                                    >
                                        <div
                                            className={
                                                style.newOrder__marketPriceInputWrapper
                                            }
                                        >
                                            <Field
                                                id="ratePercent"
                                                type="text"
                                                placeholder="- / + %"
                                                name="ratePercent"
                                                value={ratePercent}
                                                error={ratePercentError}
                                                onChange={this.InputOnchange}
                                                inputStyle="newOrder__setPrice_input"
                                                labelText={t('newOrder.setMarginValue')}
                                                labelStyle="filterBlock-select__wrapper_label"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        isFixedRate === false
                                            ? 'newOrder__marketPriceFields'
                                            : 'newOrder__marketPriceFields newOrder__marketPriceDisableFields'
                                    }
                                >
                                    <div
                                        className={
                                            style.newOrder__marketPriceInputWrapper
                                        }
                                    >
                                        <p
                                            className={
                                                style.newOrder__marketPriceInputWrapper_label
                                            }
                                        >
                                            {t('newOrder.marketPrice')}
                                        </p>
                                        <p
                                            className={
                                                style.newOrder__marketPriceInputWrapper_value
                                            }
                                        >
                                            {marketPriceFixed.replace(/\.$/gm, '')}{' '}
                                            {orderType === BUY ? coinSell : coinBuy}
                                        </p>
                                    </div>
                                    <div
                                        className={
                                            style.newOrder__marketPriceInputWrapper
                                        }
                                    >
                                        <p
                                            className={
                                                style.newOrder__marketPriceInputWrapper_label
                                            }
                                        >
                                            {t('general.fee')} {comissionMarketPrice}%
                                        </p>
                                        <p
                                            className={
                                                style.newOrder__marketPriceInputWrapper_value
                                            }
                                        >
                                            {getFeeFixed}{' '}
                                            {orderType === BUY ? coinBuy : coinSell}
                                        </p>
                                    </div>
                                    <div
                                        className={
                                            style.newOrder__marketPriceInputWrapper
                                        }
                                    >
                                        <p
                                            className={
                                                style.newOrder__marketPriceInputWrapper_label
                                            }
                                        >
                                            {orderType === BUY
                                                ? t('general.youWillPay')
                                                : t('general.youWillGet')}
                                        </p>
                                        <p
                                            className={
                                                style.newOrder__marketPriceInputWrapper_value
                                            }
                                        >
                                            {youWillGetFixed.replace(/\.$/gm, '')}{' '}
                                            {orderType === BUY ? coinSell : coinBuy}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={rightSideWrapperStyles}>
                                <Radio value>{t('newOrder.fixedPrice')}</Radio>
                                <div
                                    className={`${
                                        isFixedRate === true
                                            ? 'filterBlock-select__wrapper-input'
                                            : 'filterBlock-select__wrapper-input disableBlock'
                                    }`}
                                >
                                    <Field
                                        id="rate"
                                        type="text"
                                        placeholder={t('general.input')}
                                        name="rate"
                                        value={rate}
                                        error={rateError}
                                        maxLength="75"
                                        onChange={this.InputOnchange}
                                        inputStyle="newOrder__setPrice_input"
                                        labelText={t('newOrder.setFixedPrice')}
                                        labelStyle="filterBlock-select__wrapper_label"
                                    />
                                    {coinBuy.length > 1 && coinSell.length > 1 ? (
                                        <p
                                            className={
                                                style.newOrder__setPrice_underInput
                                            }
                                        >
                                            {orderType === BUY ? coinSell : coinBuy}{' '}
                                            {t('newOrder.per')}{' '}
                                            {orderType === BUY ? coinBuy : coinSell}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </Radio.Group>
                </div>
                <Button
                    id="postAtrade"
                    type="button"
                    disabled={disabledSubmitBtn}
                    className={style.newOrder__btnSubmit}
                    onClick={this.submitNewOrder}
                >
                    {t('newOrder.editOrder')}
                </Button>
            </form>
        );
    }
}

Edit.defaultProps = {
    t: () => {},
    history: {},
    userId: '',
    edit: {},
    allCoins: [],
    locale: {},
};

Edit.propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    userId: PropTypes.any,
    locale: PropTypes.any,
    edit: PropTypes.object,
    allCoins: PropTypes.instanceOf(Array),
};

const mapStateToProps = state => {
    const {
        getUserInfo: {
            userInfo: { id: userId },
        },
        getAllCoins: { allCoins },
        getCurrentLocale: { locale },
    } = state;

    return {
        userId,
        allCoins,
        locale,
    };
};

export default compose(withTranslation(), connect(mapStateToProps), withRouter)(Edit);
