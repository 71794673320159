/* eslint-disable */
import { authModalConstants } from '../constants';

export default function socketConnected(state, action) {
    if (state === undefined) {
        return {
            isSocketConnect: false,
            emit: {},
        };
    }
    switch (action.type) {
        case authModalConstants.SOCKET_CONNECTED:
            return {
                isSocketConnect: true,
                emit: action.emit,
            };

            case authModalConstants.SOCKET_DICONECTED:
            return {
                isSocketConnect: false,
                emit: {},
            };

        default:
            return state;
    }
}
