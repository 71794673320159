/* eslint-disable */

import { userConstants } from '../constants';

function userLoginName(userName) {
    return {
        type: userConstants.USER_EMAIL,
        userName,
    };
}

export const userLoginNameActions = {
    userLoginName,
};
