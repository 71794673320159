import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Moment from 'react-moment';

import { chatActions, getAllChatsActions } from '../../../../../actions';
import { compose } from '../../../../../utils';
import {
    personalAreaPath,
    chat,
    room,
    CHAT_CREATE,
    CHAT_UPDATED,
} from '../../../../../constants';
import Img from '../../../../small-components/img';
import GetService from '../../../../../services/get-service';
import Spinner from '../../../../spinner';
import ErrorIndicator from '../../../error-page/error-indicator';

import style from './chat-page.module.scss';
import './chat-page.scss';

class AllChats extends Component {
    mounted = true;

    getService = new GetService();

    state = {
        allChats: [],
        getChats: [],
        userName: '',
        loading: true,
        error: false,
    };

    componentDidMount() {
        if (this.mounted) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps) {
        const { locale } = this.props;
        if (locale !== prevProps.locale) {
            setTimeout(() => {
                this.translateAutoMessages();
            }, 100);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadData = () => {
        this.getService
            .getAllChats()
            .then(getChats => {
                const {
                    dispatch,
                    userInfo: { username },
                } = this.props;

                if (this.mounted) {
                    this.setState({
                        getChats,
                        userName: username,
                        loading: false,
                        error: false,
                    }, () => {
                        this.translateAutoMessages();
                    });
                }
                this.connectSocket();
                const unreadChats = getChats.filter(el => el.unreadMessageCount > 0);
                dispatch(getAllChatsActions.getAllChats(unreadChats));
            })
            .catch(this.onError);
    };

    translateAutoMessages = () => {
        const { getChats } = this.state;
        const { t } = this.props;
        const copyFilterParams = Object.assign([], getChats);
        let translatedMessages = {};
        const resultChatsData = [];

        copyFilterParams.map(item => {
            const {
                lastMessage,
                lastMessage: { message, isAutogenerated },
            } = item;
            if (isAutogenerated) {
                const parseMessageObject = JSON.parse(message);
                const { message: constant, amount, rate } = parseMessageObject;
                if (constant === 'I_WANT_TO_BUY') {
                    const autogeneratedMessages = {
                        ...lastMessage,
                        message: t('autogeneratedMessages.i_want_to_buy', {
                            amount,
                            rate,
                        }),
                    };
                    translatedMessages = {
                        ...item,
                        lastMessage: autogeneratedMessages,
                    };
                }
                if (constant === 'I_WANT_TO_SELL') {
                    const autogeneratedMessages = {
                        ...lastMessage,
                        message: t('autogeneratedMessages.i_want_to_sell', {
                            amount,
                            rate,
                        }),
                    };
                    translatedMessages = {
                        ...item,
                        lastMessage: autogeneratedMessages,
                    };
                }
                if (constant === 'I_WANT_TO_EXCHANGE') {
                    const autogeneratedMessages = {
                        ...lastMessage,
                        message: t('autogeneratedMessages.i_want_to_exchange', {
                            amount,
                            rate,
                        }),
                    };
                    translatedMessages = {
                        ...item,
                        lastMessage: autogeneratedMessages,
                    };
                }
                if (constant === 'TRADE_STARTED') {
                    const autogeneratedMessages = {
                        ...lastMessage,
                        message: t('autogeneratedMessages.trade_started', {
                            amount,
                            rate,
                        }),
                    };
                    translatedMessages = {
                        ...item,
                        lastMessage: autogeneratedMessages,
                    };
                }
                if (constant === 'NO_THANKS') {
                    const autogeneratedMessages = {
                        ...lastMessage,
                        message: t('autogeneratedMessages.no_thanks'),
                    };
                    translatedMessages = {
                        ...item,
                        lastMessage: autogeneratedMessages,
                    };
                }
                if (constant === 'PAYMENT_FAILED') {
                    const autogeneratedMessages = {
                        ...lastMessage,
                        message: t('autogeneratedMessages.payment_failed'),
                    };
                    translatedMessages = {
                        ...item,
                        lastMessage: autogeneratedMessages,
                    };
                }
                if (constant === 'PAYMENT_SUCCESSFUL') {
                    const autogeneratedMessages = {
                        ...lastMessage,
                        message: t('autogeneratedMessages.payment_successful'),
                    };

                    translatedMessages = {
                        ...item,
                        lastMessage: autogeneratedMessages,
                    };
                }
                if (constant === 'PAYMENT_SENT') {
                    const autogeneratedMessages = {
                        ...lastMessage,
                        message: t('autogeneratedMessages.payment_sent'),
                    };
                    translatedMessages = {
                        ...item,
                        lastMessage: autogeneratedMessages,
                    };
                }
                if (constant === 'ORDER_COMPLETED_BY_ANOTHER_USER') {
                    const autogeneratedMessages = {
                        ...lastMessage,
                        message: t('autogeneratedMessages.order_completed_by_another_user'),
                    };
                    translatedMessages = {
                        ...item,
                        lastMessage: autogeneratedMessages,
                    };
                }
                if (constant === 'MOVED_CHAT_TO_ARBITRAGE') {
                    const autogeneratedMessages = {
                        ...lastMessage,
                        message: t(
                            'autogeneratedMessages.moved_chat_to_arbitrage',
                        ),
                    };
                    translatedMessages = {
                        ...item,
                        lastMessage: autogeneratedMessages,
                    };
                }
            } else {
                translatedMessages = {
                    ...item,
                };
            }

            resultChatsData.push(translatedMessages);

            return true;
        });

        this.setState({
            allChats: resultChatsData,
            loading: false,
            error: false,
        });
    }

    connectSocket = () => {
        const {
            userInfo: { socketRoomHash },
            isSocketConnect,
            emit: socket,
        } = this.props;

        if (isSocketConnect) {
            socket.emit('join', socketRoomHash);

            socket.on(CHAT_CREATE, data => {
                const { getChats } = this.state;
                const copyAllchats = [...getChats];
                copyAllchats.unshift(data);
                if (this.mounted) {
                    this.setState({
                        getChats: copyAllchats,
                    }, () => {
                        this.translateAutoMessages();
                    });
                }
            });

            socket.on(CHAT_UPDATED, data => {
                const { getChats } = this.state;
                if (this.mounted) {
                    this.setState(() => {
                        const index = getChats.findIndex(
                            element => element.id === data.id,
                        );
                        const newArray = [
                            ...getChats.slice(0, index),
                            ...getChats.slice(index + 1),
                        ];

                        newArray.unshift(data);

                        return {
                            getChats: newArray,
                        };
                    }, () => {
                        this.translateAutoMessages();
                    });
                }
            });
        }
    };

    onError = () => {
        this.setState({
            error: true,
            loading: false,
        });
    };

    // Save chat id to redux after select chat
    saveChatIdToRedux = id => {
        const { dispatch } = this.props;
        dispatch(chatActions.selectChat(id));
    };

    render() {
        const { t } = this.props;
        const {
            allChats, userName, loading, error,
        } = this.state;

        const hasData = !(loading || error);

        const errorMessage = error ? <ErrorIndicator /> : null;
        const spinner = loading ? <Spinner /> : null;

        const filterChatListInfo = allChats.filter(
            item => item.buyer.username !== userName || item.seller.username !== userName,
        );

        const hasMatch = filterChatListInfo.map(item => (item.buyer.username === userName ? 'seller' : 'buyer'));

        return (
            <Fragment>
                {errorMessage}
                {spinner}
                {hasData ? (
                    <div className={style.allChats}>
                        <p className={style.allChats__title}>{t('general.chat')}</p>
                        {allChats.length < 1 ? (
                            <p className={style.allChats__noMessages}>
                                {t('general.noMessages')}
                            </p>
                        ) : null}
                        {filterChatListInfo.map((item, index) => {
                            const {
                                id,
                                unreadMessageCount,
                                lastMessage: { message },
                                [hasMatch[index]]: {
                                    lastActivityAt: lastSeen,
                                    logoSrc,
                                    username,
                                    online,
                                },
                            } = item;

                            const status = true;
                            return (
                                <Fragment key={id}>
                                    <Link
                                        to={`${personalAreaPath}${chat}${room}/${id}`}
                                        className={style.allChats__item}
                                        onClick={() => this.saveChatIdToRedux(id)}
                                    >
                                        <Img
                                            className={style.allChats__item_img}
                                            src={logoSrc}
                                            userName={username}
                                            status={status}
                                            online={online}
                                        />
                                        <div className={style.allChats__namePreview}>
                                            <p
                                                className={
                                                    style.allChats__namePreview_name
                                                }
                                            >
                                                {username}
                                            </p>
                                            <div
                                                className={style.allChats__lastSeenMobile}
                                            >
                                                {online ? (
                                                    <p
                                                        className={
                                                            style.allChats__lastSeenMobile_online
                                                        }
                                                    >
                                                        Online
                                                    </p>
                                                ) : (
                                                    <p
                                                        className={
                                                            style.allChats__lastSeenMobile_title
                                                        }
                                                    >
                                                        {t('general.lastSeenTitle')}{' '}
                                                        <Moment format="DD/MM/YYYY" unix>
                                                            {lastSeen}
                                                        </Moment>{' '}
                                                        {t('general.in')}{' '}
                                                        <Moment format="HH:mm" unix>
                                                            {lastSeen}
                                                        </Moment>
                                                    </p>
                                                )}
                                            </div>
                                            <p
                                                className={
                                                    style.allChats__namePreview_preview
                                                }
                                            >
                                                {message}
                                            </p>
                                        </div>
                                        <div className={style.allChats__lastSeen}>
                                            {online ? (
                                                <p
                                                    className={
                                                        style.allChats__lastSeen_online
                                                    }
                                                >
                                                    Online
                                                </p>
                                            ) : (
                                                <p
                                                    className={
                                                        style.allChats__lastSeen_title
                                                    }
                                                >
                                                    {t('general.lastSeenTitle')}{' '}
                                                    <Moment format="DD/MM/YYYY" unix>
                                                        {lastSeen}
                                                    </Moment>{' '}
                                                    {t('general.in')}{' '}
                                                    <Moment format="HH:mm" unix>
                                                        {lastSeen}
                                                    </Moment>
                                                </p>
                                            )}

                                            {unreadMessageCount ? (
                                                <p
                                                    className={
                                                        style.allChats__lastSeen_unreadMessages
                                                    }
                                                >
                                                    {unreadMessageCount}
                                                </p>
                                            ) : null}
                                        </div>
                                    </Link>
                                </Fragment>
                            );
                        })}
                    </div>
                ) : null}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        currentChat: { currentChat },
        getUserInfo: { userInfo },
        socketConnected: { isSocketConnect, emit },
        getCurrentLocale: { locale },
    } = state;

    return {
        currentChat,
        userInfo,
        isSocketConnect,
        emit,
        locale,
    };
};

AllChats.defaultProps = {
    t: () => {},
    dispatch: () => {},
    userInfo: {},
    isSocketConnect: false,
    emit: {},
    locale: {},
};

AllChats.propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.func,
    userInfo: PropTypes.object,
    isSocketConnect: PropTypes.bool,
    emit: PropTypes.object,
    locale: PropTypes.any,
};

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter,
)(AllChats);
